import { useMemo } from 'react';
import { CardBarFiltersChart, ChartWithFiltersType, SeriesWithFiltersType } from 'src/components';
import { monthsShortLabels } from 'src/constants/constants';

export const CommissionsChart = () => {
  const generatedMockDataForChart = useMemo<ChartWithFiltersType>(() => {
    const series: SeriesWithFiltersType[] = [
      {
        seriesFilter: 'Week',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index),
          },
        ],
      },
      {
        seriesFilter: 'Month',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index * 4),
          },
        ],
      },
      {
        seriesFilter: 'Year',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index * 48),
          },
        ],
      },
    ];

    return {
      categories: monthsShortLabels,
      series,
    };
  }, []);

  return (
    <CardBarFiltersChart
      cardProps={{ sx: { flex: 1 } }}
      title='Commission Payments, kr'
      seriesValues={['Week', 'Month', 'Year'].reverse()}
      chart={generatedMockDataForChart}
    />
  );
};
