import Box, { BoxProps } from '@mui/material/Box';
import { memo } from 'react';
import { HEADER, NAV } from 'src/constants/constants';
import { useResponsive, useSettingsContext } from 'src/hooks';

const SPACING = 48;

export const ReportMain = memo((props: BoxProps) => {
  const {
    children,
    sx,
    ...other
  } = props;

  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');

  return (
    <>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          minHeight: 1,
          maxWidth: settings.themeStretch ? '100%' : 'xl',
          display: 'flex',
          flexDirection: 'column',
          mx: 'auto',
          py: `${HEADER.H_MOBILE + SPACING}px`,
          ...(!lgUp && {
            width: '100%',
            px: 3,
          }),
          ...(lgUp && {
            px: 2,
            py: `${HEADER.H_DESKTOP + SPACING}px`,
            width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          }),
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
    </>
  );
});
