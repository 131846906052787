import { identity, omit, pickBy } from 'lodash';
import { UploadedFilesType } from 'src/components';
import { demoDetailsView, demoLeads } from 'src/constants/demo-mock-data';
import { useDocuments } from 'src/hooks';
import { extractFileExtension } from 'src/utils';

import {
  Asset,
  AssigneeType,
  AssignmentMember,
  CoverImagesType,
  DetailViewData,
  Lead,
  LeadBodyParams,
  LeadResponse,
  LocalArea,
  LocalMarket,
  School,
  Station,
} from '../../../../types';
import { apiFetch } from '../api';
import { deleteDocument } from '../financial';
import { deleteCoverImageThunk, setDetailViewData, setImageAsCoverThunk, setIsLoading, updateAsset, updateLeadDetailViewData, uploadCoverImageToAsset } from '../storage';
import { AppThunk } from '../store';
import { addLead, deleteLead, setLeads } from './index';

export const loadLeadDetails = (leadIId: string, forceDemo?: boolean, callback?: (detailViewData: DetailViewData) => void): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  const isDemo = getState().App.config?.isDemo;

  if (isDemo || forceDemo) {
    dispatch(setDetailViewData(demoDetailsView));
    dispatch(setIsLoading(false));
    return;
  }
  try {
    const detailViewData: DetailViewData = await apiFetch(`lead/details/${leadIId}`, { method: 'get' });
    callback?.(detailViewData);
    dispatch(setDetailViewData(detailViewData));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const loadLeads = (paramBody: LeadBodyParams): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  const isDemo = getState().App.config?.isDemo;

  if (isDemo) {
    dispatch(setLeads({
      leads: demoLeads,
      counters: {
        All: 0,
        'Waiting for Review': 0,
        'In Progress': 0,
        'Approved': 0,
        'Rejected': 0,
      },
      netCount: 0,
    }));
    dispatch(setIsLoading(false));
    return;
  }
  try {
    const leads: LeadResponse = await apiFetch('lead/list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });
    if (leads !== undefined) {
      dispatch(setLeads(leads));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const deleteLeadThunk = (leadId: string, callBack?: VoidFunction): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(deleteLead(leadId));
    dispatch(setIsLoading(false));
    return;
  }
  const lead = getState().Lead.leads.leads.find(lead => lead._id === leadId);

  if (lead) {
    try {
      const res = await apiFetch(`lead/${leadId}`, {
        method: 'post',
        body: JSON.stringify({ isDeleted: true }),
        headers: { 'Content-Type': 'application/json' },
        returnError: true,
      });
      const deletedLeadId = res.leadId;
      if (deletedLeadId) {
        dispatch(deleteLead(deletedLeadId));
        dispatch(setIsLoading(false));
        callBack?.();
      } else {
        throw new Error(JSON.stringify(res));
      }
    } catch (error) {
      console.error(error);
      dispatch(setIsLoading(false));
    }
  }

};

export type CreateLead = {
  lead: Partial<Lead>;
  assignedMembers?: AssignmentMember[];
  assigneesToDelete?: AssigneeType[];
  files?: UploadedFilesType[];
  filesToDelete?: UploadedFilesType[];
  asset?: Asset;
  coverImages?: CoverImagesType[];
  newCoverImageId?: string;
  offeringClosingDate?: string;
  coverImagesToDelete?: CoverImagesType[];
  callback?: Function;
}

export const createLead = ({
  lead,
  files,
  filesToDelete,
  asset,
  newCoverImageId,
  offeringClosingDate,
  coverImages = [],
  coverImagesToDelete = [],
  assigneesToDelete = [],
  assignedMembers = [],
  callback,
}: CreateLead): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  const { createAndUploadDocument } = useDocuments();

  dispatch(setIsLoading(true));
  try {
    lead.country = 'SWE';
    const filteredLead = omit({
      ...pickBy(lead, identity),
      buyerFeeAmount: lead.buyerFeeAmount,
      contactDetails: pickBy(lead.contactDetails, identity),
    }, ['note']);

    const res = await apiFetch(`lead${lead._id ? `/${lead._id}` : ''}`, {
      method: 'post',
      body: JSON.stringify({ ...filteredLead }),
      headers: { 'Content-Type': 'application/json' },
      returnError: true,
    });

    const leadId = res?.leadId;
    const assetNote = lead.note;
    const assetIdResultId: string = res?.assetId || asset?._id;

    if (leadId) {
      dispatch(lead._id ? updateLeadDetailViewData(filteredLead as Lead) : addLead(res));
      await Promise.all([
        assigneeTeamMember(leadId, [...assignedMembers, ...assigneesToDelete]),
        files?.forEach(file => createAndUploadDocument(file, '', leadId, {
          leadId: leadId,
          section: 'Lead',
          sectionFilename: file.filename.replace(extractFileExtension(file.filename), ''),
        })),
        filesToDelete?.forEach(fileToDelete => deleteDocument(fileToDelete._id, isDemo)),
      ]);
      if (assetIdResultId) {
        coverImages?.forEach(file => file.file && uploadCoverImageToAsset(assetIdResultId, file, isDemo));
        coverImagesToDelete?.forEach(file => {
          dispatch(deleteCoverImageThunk(file._id));
        });
        newCoverImageId && dispatch(setImageAsCoverThunk(newCoverImageId));
        const shouldCallAssetUpdate = !!offeringClosingDate || !!assetNote;

        (shouldCallAssetUpdate && assetIdResultId) && dispatch(updateAsset({
          ...asset,
          _id: assetIdResultId,
          general: {
            ...asset?.general,
            offeringClosingDate: String(offeringClosingDate),
          },
          marketPlaceListing: {
            ...asset?.marketPlaceListing,
            coverImages: coverImages,
            description: assetNote,
          },
        }));
      }
      callback?.();
      dispatch(setIsLoading(false));
    } else {
      throw new Error(JSON.stringify(res));
    }


  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const assigneeTeamMember = async (leadId: string, assignedMembers?: AssignmentMember[]) => {
  const responses = [];
  for (const member of assignedMembers || []) {
    try {
      const response = await apiFetch(`lead/assignment/${member?._id ? `${member?._id}` : ''}`, {
        method: 'post',
        body: JSON.stringify({
          ...member,
          leadId,
        }),
        headers: { 'Content-Type': 'application/json' },
        returnError: true,
      });
      responses.push(response?.body?.data);
    } catch (error) {
      console.error(error);
    }
  }
  return responses;
};

export const uploadFile = async (leadId: string, files?: UploadedFilesType[]) => {
  for (const file of files || []) {
    try {
      await apiFetch(`storage/file/${leadId}?section=${file.section}&name=${file.filename}&type=${file.type}`, {
        method: 'post',
        headers: { 'Content-Type': file.file?.type || file.uploadingFileType || 'image/png' },
        body: file.file,
        returnError: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const deleteFiles = async (filesToDelete?: Partial<UploadedFilesType>[]) => {
  for (const file of filesToDelete || []) {
    try {
      await apiFetch(`storage/file/${file?._id}`, {
        method: 'delete',
        returnError: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

export const getLocalMarket = async (coordinates: number[] = []) => {
  try {
    const res: LocalMarket = await apiFetch('local-market', {
      method: 'post',
      body: JSON.stringify({
        longitude: coordinates[0],
        latitude: coordinates[1],
        // TODO remove hardcode
        options: {
          minOffersInMonth: 30,
          monthCount: 12,
        },
        projectType: 'New build houses and apartments',
      }),
      headers: { 'Content-Type': 'application/json' },
    });

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getLocalArea = async (coordinates: number[] = []) => {
  try {
    const res: LocalArea = await apiFetch(`local-area/${coordinates[0]}/${coordinates[1]}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getLocalAreaSchools = async (coordinates: number[]) => {
  try {
    const res: School[] = await apiFetch(`local-area/schools/${coordinates[0]}/${coordinates[1]}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        language: 'SE',
      },
    });

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getLocalAreaStations = async (coordinates: number[]) => {
  try {
    const res: Station[] = await apiFetch(`local-area/stations/${coordinates[0]}/${coordinates[1]}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        language: 'SE',
      },
    });

    return res;
  } catch (error) {
    console.error(error);
  }
};
