import { CardHeader } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCardHeader = styled(CardHeader)(() => ({
  padding: 24,
  '& .MuiCardHeader-title': {
    fontSize: '14px',
    color: '#212B36',
    fontWeight: 600,

  },
  '& .MuiCardHeader-subheader': {
    fontSize: '12px',
    color: '#919EAB',
    fontWeight: 400,
  },
}));
