import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { Fragment, memo, MouseEvent, ReactNode } from 'react';
import { SendIcon } from 'src/assets';

import { CustomPopover } from '../custom-popover';
import { Iconify } from '../iconify';

export interface MoreOptionsProps {
  options: OptionType[];
  popoverOpen: HTMLElement | null;
  popoverOnClose: VoidFunction;
  popoverOnOpen: (event: MouseEvent<HTMLElement>) => void;
  clickElement?: ReactNode;
}

export type OptionType = {
  icon?: keyof typeof icons;
  label?: string;
  itemOnClickHandler?: VoidFunction;
  closePopover?: VoidFunction;
  color?: string,
  customItem?: ReactNode;
  hidden?: boolean;
  disabled?: boolean;
} | null

export const icons = {
  send: 'send',
  view: 'solar:eye-bold',
  edit: 'solar:pen-bold',
  replace: 'material-symbols:replay-rounded',
  delete: 'solar:trash-bin-trash-bold',
  close: 'mingcute:close-line',
  research: 'quill:link-out',
  download: 'solar:cloud-download-bold',
  empty: '',
};

export const MoreOptions = memo((props: MoreOptionsProps) => {
  const {
    options,
    popoverOpen,
    popoverOnClose,
    popoverOnOpen,
    clickElement,
  } = props;

  const defaultItem = (option: OptionType) => {
    return (
      <MenuItem
        onClick={() => {
          option?.itemOnClickHandler?.();
          option?.closePopover?.();
        }}
        disabled={option?.disabled}
        sx={{ color: option?.color ?? '' }}
      >
        {option?.icon === icons.send ?
          <SendIcon/> :
          <Iconify icon={icons[option?.icon ?? 'empty']}/>
        }
        {option?.label}
      </MenuItem>
    );
  };

  return (
    <>
      {
        clickElement ?? (
          <IconButton color={popoverOpen ? 'inherit' : 'default'} onClick={popoverOnOpen}>
            <Iconify icon='eva:more-vertical-fill'/>
          </IconButton>
        )
      }
      <CustomPopover
        open={popoverOpen}
        onClose={popoverOnClose}
        arrow='right-top'
        sx={{ minWidth: 160 }}
      >
        {options.filter(opt => !opt?.hidden).map((option, index) => {
          if (!option) return;
          return (
            <Fragment key={index}>
              {option.customItem || defaultItem(option)}
            </Fragment>
          );
        })}
      </CustomPopover>
    </>
  );
});
