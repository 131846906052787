import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UpdateStatus, User } from '../../../../types';

interface State {
  status: UpdateStatus;
  user?: User;
  error?: string;
  notVerifiedEmail?: string;
  logout?: boolean;
}

export const initialState: State = { status: 'new' };

const Slice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setAuthStatus(state, action: PayloadAction<UpdateStatus>) {
      state.status = action.payload;
    },
    authSuccess(state, action: PayloadAction<User | null>) {
      state.user = action.payload ?? undefined;
      state.status = 'success';
    },
    authReset(state) {
      state.user = undefined;
      state.status = 'new';
    },
    authFailure(state, action: PayloadAction<string>) {
      state.status = 'error';
      state.error = action.payload;
    },
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload ?? undefined;
    },
    setNotVerified(state, action: PayloadAction<string | null>) {
      state.notVerifiedEmail = action.payload ?? undefined;
    },
    setLogout(state, action: PayloadAction<boolean>) {
      state.logout = action.payload;
    },
  },
});
export const {
  reducer: AuthReducer,
  actions: {
    setAuthStatus,
    authSuccess,
    authFailure,
    authReset,
    setUser,
    setNotVerified,
    setLogout,
  },
} = Slice;

export * from './actions';
