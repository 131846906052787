import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Report, ReportProps, useScenarioReport } from 'src/components';
import { usePortalFlags, useResponsive } from 'src/hooks';
import { RootState } from 'src/store';

import { ReportSettingsType } from '../../../../types';

export interface ReportViewProps {
  showMenu?: boolean;
  settingForReport?: ReportSettingsType;
}

export const ReportView = (props: ReportViewProps) => {
  const {
    showMenu,
    settingForReport,
  } = props;

  const { isCalculatorReport } = usePortalFlags();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const mdUp = useResponsive('up', 'md');

  const scenario = detailsData.project?.scenario;
  const activeSections = settingForReport?.sections?.filter(section => section.isActive).map(section => section.section);

  const checkIsSectionActive = useCallback((sectionName: string) => {
    return settingForReport ? activeSections?.includes(sectionName) : true;
  }, [activeSections, settingForReport]);

  const showAboutProject = (isCalculatorReport) && checkIsSectionActive('Project Summary');

  const {
    localMarket,
    localArea,
    comparables,
    timelineCashflow,
    proposedUnits,
  } = useScenarioReport(scenario);

  const reportProps = useMemo<ReportProps>(() => {
    return {
      aboutProject: (mdUp ? showAboutProject : (!!settingForReport?.images?.length && checkIsSectionActive('Gallery'))) ? {
        showGallery: checkIsSectionActive('Gallery'),
        noteSetting: settingForReport?.notes?.find(note => note.section === 'Project Summary'),
        images: settingForReport?.images,
        isGetDataFromSettings: true,
        showVatNote: true,
        showAboutProject: mdUp,
      } : undefined,
      siteAndPurchase: checkIsSectionActive('Site & Purchase') ? {
        scenario: scenario,
        noteSetting: settingForReport?.notes?.find(note => note.section === 'Site & Purchase'),
      } : undefined,
      proposedUnits: checkIsSectionActive('Proposed Units') ? {
        ...proposedUnits,
        noteSetting: settingForReport?.notes?.find(note => note.section === 'Proposed Units'),
      } : undefined,
      construction: checkIsSectionActive('Construction') ? { noteSetting: settingForReport?.notes?.find(note => note.section === 'Construction') } : undefined,
      finance: checkIsSectionActive('Finance') ? { noteSetting: settingForReport?.notes?.find(note => note.section === 'Finance') } : undefined,
      timelineCashflow: (checkIsSectionActive('Timeline & Cashflow') && mdUp) ? {
        ...timelineCashflow,
        noteSetting: settingForReport?.notes?.find(note => note.section === 'Timeline & Cashflow'),
      } : undefined,
      sensitivityAnalysis: (checkIsSectionActive('Sensitivity Analysis') && mdUp) ? { noteSetting: settingForReport?.notes?.find(note => note.section === 'Sensitivity Analysis') } : undefined,
      sales: checkIsSectionActive('Sales') ? { noteSetting: settingForReport?.notes?.find(note => note.section === 'Sales') } : undefined,
      projectMultiples: checkIsSectionActive('Project Multiples') ? { noteSetting: settingForReport?.notes?.find(note => note.section === 'Project Multiples') } : undefined,
      localArea: (checkIsSectionActive('Local Area') && mdUp) ? {
        ...localArea,
        noteSetting: settingForReport?.notes?.find(note => note.section === 'Local Area'),
      } : undefined,
      localMarket: (checkIsSectionActive('Local Market') && mdUp) ? {
        ...localMarket,
        noteSetting: settingForReport?.notes?.find(note => note.section === 'Local Market'),
      } : undefined,
      comparables: (checkIsSectionActive('Comparables') && mdUp) ? {
        ...comparables,
        noteSetting: settingForReport?.notes?.find(note => note.section === 'Comparables'),
      } : undefined,
    };
  }, [checkIsSectionActive, comparables, localArea, localMarket, mdUp, proposedUnits, scenario, settingForReport?.images, settingForReport?.notes, showAboutProject, timelineCashflow]);

  return (
    <Report {...reportProps} showAll showMenu={showMenu}/>
  );
};
