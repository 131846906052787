import { DetailViewData } from '../../../types';
import { StepType } from '../components';

export const genStepsByLeadDetails = (details: DetailViewData) => [{
  label: 'Lead',
  active: details.lead?.status === 'In Progress',
  completed: details.lead?.status === 'Approved',
}, {
  label: 'Research',
  active: !!details.project?._id,
  completed: details.project?.scenario?.status === 'Approved',
}, {
  label: 'Project Preparation',
  active: details.assets?.status === 'New',
  completed: details.assets?.status === 'Done',
}, ...(details.lead?.distribution?.tendering ? [{
  label: 'Tendering',
  active: (details.tendering?.length || 0) > 0,
  completed: details?.tendering?.find?.((item) => (item.termSheet?.response?.documentId?.length || 0) > 0),
}] : []), ...(details.lead?.distribution?.debtFinancing ? [{
  label: 'Debt',
  active: (details.financing?.length || 0) > 0,
  completed: details?.financing?.find?.((item) => item.debts?.find?.((debt) => debt.offers?.find?.((offer) => !!offer.termSheetId))),
}] : []), ...(details.lead?.distribution?.equityFinancing ? [{
  label: 'Equity',
  active: (details.equityDistributionPartners?.length || 0) > 0,
  completed: false,
}] : []), ...(details?.lead?.distribution?.sell ? [{
  label: 'Sell',
  active: details.project?.projectDistributionStatus === 'On Sale',
  completed: details.project?.projectDistributionStatus === 'Sold',
}] : [])] as StepType[];
