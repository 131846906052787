import { useMemo } from 'react';
import { fDate } from 'src/utils';

import { Lead } from '../../../../../types';
import { ReportProps } from '../report';

export const useLeadCalculation = (lead?: Lead) => {
  const reportProps: ReportProps = useMemo(() => {
    const medianPriceMonths: string[] = [];
    const medianPrices: number[] = [];
    const listingsPrices: number[] = [];
    const distributionLabels: string[] = Object.keys(lead?.localMarket?.distribution || {});
    const distributionData: number[] = Object.values(lead?.localMarket?.distribution || {});
    lead?.localMarket?.timeSeries?.forEach(({
      median,
      month,
      count,
    }) => {
      medianPriceMonths.push(fDate(month, 'dd/MM/yyyy'));
      medianPrices.push(median);
      listingsPrices.push(count);
    });
    const localMarket = lead?.localMarket ? {
      showData: Boolean(lead?.localMarket),
      marketMappingValues: lead?.localMarket?.marketMappingValues?.result,
      ...lead?.localMarket?.market,
      // TODO change key in future
      narrativeTitle: lead?.localArea?.title,
      narrative: lead?.localMarket?.narrative,
      coordinates: lead?.location?.coordinates,
      medianPriceMonths,
      medianPrices,
      listingsPrices,
      distributionLabels,
      distributionData,
    } : undefined;

    const localArea = lead?.localArea ? {
      locality: lead?.localArea?.locality,
      meanIncomeLocal: lead?.localArea?.local?.meanIncomeLocal,
      medianAgeLocal: lead?.localArea?.local?.medianAgeLocal,
      mapCoordinates: lead?.location?.coordinates,
      narrativeAreaTitle: lead?.localArea?.title,
      narrative: lead?.localArea?.narrative,
      schools: lead?.schools,
      stations: lead?.stations,
      showData: Boolean(lead?.localArea),
    } : undefined;

    return {
      localMarket,
      localArea,
    };
  }, [lead]);
  return { reportProps };
};
