import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export type RouterType = {
  reload: () => void;
  forward: () => void;
  replace: (href: string) => void;
  back: () => void;
  push: (href: string) => void
}

export const useRouter = (): RouterType => {
  const history = useHistory();

  const router = useMemo(
    () => ({
      back: () => history.goBack(),
      forward: () => history.goForward(),
      reload: () => window.location.reload(),
      push: (href: string) => history.push(href),
      replace: (href: string) => history.replace(href),
    }),
    [history],
  );

  return router;
};
