import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { memo } from 'react';
import { useMenuData } from 'src/components';
import { HEADER } from 'src/constants/constants';
import { bgBlur } from 'src/theme/css';

import { HeaderShadow, NavSectionHorizontal } from '../index';

export const NavHorizontal = memo(() => {
  const theme = useTheme();
  const menuData = useMenuData();

  return (
    <AppBar
      component='nav'
      sx={{ top: HEADER.H_DESKTOP_OFFSET }}
    >
      <Toolbar
        sx={{ ...bgBlur({ color: theme.palette.background.default }) }}
      >
        <NavSectionHorizontal
          data={menuData}
        />
      </Toolbar>

      <HeaderShadow/>
    </AppBar>
  );
});

