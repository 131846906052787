import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, { formControlLabelClasses, FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { ReactNode, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';


interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  helperText?: ReactNode;
  checkboxProps?: CheckboxProps;
}

export const RHFCheckbox = (props: RHFCheckboxProps) => {
  const {
    name,
    helperText,
    checkboxProps,
    ...other
  } = props;
  const { control } = useFormContext();
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <>
          <FormControlLabel
            control={
              <Checkbox  {...checkboxProps} {...field} ref={ref} checked={field.value}/>
            }
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

export type CheckboxOptionType = {
  label: string;
  value: string,
  disabled?: boolean;
}

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: CheckboxOptionType[];
  row?: boolean;
  label?: string;
  spacing?: number;
  helperText?: ReactNode;
}

export const RHFMultiCheckbox = (props: RHFMultiCheckboxProps) => {
  const {
    row,
    name,
    label,
    options,
    spacing,
    helperText,
    sx,
    ...other
  } = props;
  const { control } = useFormContext();
  const ref = useRef<HTMLButtonElement | null>(null);

  const getSelected = (selectedItems: string[] = [], item: string) => {
    return selectedItems?.includes(item)
      ? selectedItems?.filter((value) => value !== item)
      : [...selectedItems, item];
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => {
        return (
          <FormControl component='fieldset' name={name}>
            {label && (
              <FormLabel component='legend' sx={{ typography: 'body2' }}>
                {label}
              </FormLabel>
            )}

            <FormGroup
              ref={ref}
              sx={{
                ...(row && { flexDirection: 'row' }),
                [`& .${formControlLabelClasses.root}`]: {
                  '&:not(:last-of-type)': { mb: spacing || 0 },
                  ...(row && {
                    mr: 0,
                    '&:not(:last-of-type)': { mr: spacing || 2 },
                  }),
                },
                ...sx,
              }}
            >
              {options.map((option) => {
                return (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <Checkbox
                        disabled={option.disabled}
                        checked={field.value?.includes(option.value)}
                        onChange={() => field.onChange(getSelected(field.value, option.value))}
                      />
                    }
                    label={option.label}
                    {...other}
                  />
                );
              })}
            </FormGroup>

            {(!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ mx: 0 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
