import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import merge from 'lodash/merge';
import { ReactNode, useMemo } from 'react';
import { useLocales, useSettingsContext } from 'src/hooks';
import { componentsOverrides, contrast, customShadows, darkMode, direction, palette, presets, RTL, shadows, typography } from 'src/theme';

type ThemeProviderProps = {
  children: ReactNode;
};

export const ThemeProvider = (props: ThemeProviderProps) => {
  const { children } = props;
  const { currentLang } = useLocales();
  const settings = useSettingsContext();

  const darkModeOption = darkMode(settings.themeMode);
  const presetsOption = presets(settings.themeColorPresets);
  const contrastOption = contrast(settings.themeContrast === 'bold', settings.themeMode);
  const directionOption = direction(settings.themeDirection);

  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      typography,
      shape: { borderRadius: 8 },
    }),
    [],
  );

  const memoizedValue = useMemo(
    () =>
      merge(
        baseOption,
        directionOption,
        darkModeOption,
        presetsOption,
        contrastOption.theme,
      ),
    [baseOption, directionOption, darkModeOption, presetsOption, contrastOption.theme],
  );

  const theme = createTheme(memoizedValue as ThemeOptions);

  theme.components = merge(componentsOverrides(theme), contrastOption.components);

  const themeWithLocale = useMemo(
    () => createTheme(theme, currentLang.systemValue),
    [currentLang.systemValue, theme],
  );

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <RTL themeDirection={settings.themeDirection}>
        <CssBaseline/>
        {children}
      </RTL>
    </MuiThemeProvider>
  );
};
