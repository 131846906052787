import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { TowerCraneIcon } from 'src/assets/svg';
import { UseBooleanReturnType } from 'src/hooks';

type SuccessDrawerProps = {
  title: string;
  successDrawer: UseBooleanReturnType;
  onCloseClickHandler: VoidFunction;
};

export function SuccessDrawer(props: SuccessDrawerProps) {
  const {
    title,
    successDrawer,
    onCloseClickHandler,
  } = props;
  const theme = useTheme();

  return (
    <Dialog open={successDrawer.value} onClose={onCloseClickHandler}>
      <DialogContent sx={{ width: 400 }}>
        <Stack
          sx={{ mt: 5 }}
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          gap={3}
        >
          <TowerCraneIcon
            width={56}
            height={56}
            color={theme.palette.success.main}
          />
          <Typography variant='h4'>{title}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          size='medium'
          variant='outlined'
          type='button'
          onClick={onCloseClickHandler}
          fullWidth
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
