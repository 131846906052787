import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { CustomSnackbar, LoadingScreen, SplashScreen } from './components';
import { routeConfig } from './config';
import { useLayoutByPath, useRestrictRouts } from './hooks';
import { RootState } from './store';
import { loadConfig } from './store/app';
import { authHelper } from './utils';

export const Router = () => {
  const Layout = useLayoutByPath();
  useRestrictRouts();

  const dispatch = useDispatch();
  const configStatus = useSelector((state: RootState) => state.App.configStatus);
  const authStatus = useSelector((state: RootState) => state.Auth.status);
  const user = useSelector((state: RootState) => state.Auth.user);

  useEffect(() => {
    dispatch(loadConfig());
  }, [dispatch]);

  useEffect(() => {
    authHelper({
      authStatus,
      user,
    });
  }, [user, authStatus]);

  if (configStatus === 'load' || authStatus === 'load') {
    return <SplashScreen/>;
  }

  return (
    <Layout>
      <Suspense fallback={<LoadingScreen/>}>
        <Switch>
          {Object.values(routeConfig).map((route, index) => {
            return (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
        <CustomSnackbar/>
      </Suspense>
    </Layout>
  );
};
