import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { ConfirmDialog, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useBoolean, usePopover } from 'src/hooks';
import { fNumber } from 'src/utils';

import { MarketplaceInvestorType } from '../../../../../../types';

interface MarketplaceInvestorsTableRowProps {
  row: MarketplaceInvestorType;
  onDeleteRow: VoidFunction;
}

export const MarketplaceInvestorsTableRow = memo((props: MarketplaceInvestorsTableRowProps) => {
  const theme = useTheme();
  const { row, onDeleteRow } = props;

  const {
    name,
    registered,
    email,
    phone,
    invested,
    softCommitted,
    kycStatus,
    organisation,
  } = row;

  const isApproved = kycStatus === 'approved';
  const statusStr = isApproved ? 'Approved' : 'Rejected';

  const confirm = useBoolean();
  const popover = usePopover();
  const moreOptions: OptionType[] = [
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const onDeleteRowWrapper = () => {
    onDeleteRow();
    confirm.onFalse();
  };

  return (
    <>
      <StyledTableRow hover>
        <TableCell>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2' color='text.primary'>
                {name}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}>
                {registered}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2' color='text.primary'>
                {email}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}>
                {phone}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <Typography sx={{ ml: 'auto' }} variant='body2' color='text.primary'>
            {fNumber(invested)} {DEFAULT_CURRENCY}
          </Typography>
          <Typography
            variant='caption'
            sx={{ ml: 'auto', display: 'block' }}
            color={theme.palette.text.secondary}>
            {fNumber(softCommitted)} {DEFAULT_CURRENCY}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip
            size='small'
            variant='soft'
            color={isApproved ? 'success' : 'error'}
            label={statusStr}
          />
        </TableCell>

        <TableCell>
          <Typography variant='body2' color='text.primary'>
            {organisation}
          </Typography>
        </TableCell>

        <TableCell
          align='right'
        >
          <MoreOptions
            options={moreOptions}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
      </StyledTableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button
            variant='contained'
            color='error'
            onClick={onDeleteRowWrapper}
          >
            Delete
          </Button>
        }
      />
    </>
  );
});
