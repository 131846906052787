import { FileForUpload, FileSectionsType } from '../../../types';
import { onlyNumbers } from './format-strings';

export const readFileAsync = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsArrayBuffer(file);
  });
};

export const createBlobFile = async (file: File) => {
  return new Blob([await readFileAsync(file) ?? ''], { type: file.type });
};

export const getAllFiles = (data: FileSectionsType[]) => {
  const allFiles: FileForUpload[] = [];

  data.forEach(section => {
    section.filenames.forEach(fileObj => {
      const files = fileObj.files || [];
      if (files?.length > 0) {
        const updatedFiles = files.map(file => {
          const size = file.size || file.filesize;
          return {
            ...file,
            isMiscellaneous: fileObj.filename === 'Miscellaneous',
            size: +onlyNumbers(size?.toString()),
          };
        });
        allFiles.push(...updatedFiles);
      }
    });
  });

  return allFiles;
};
