import { Box, Modal } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactElement, ReactNode } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.querySelector('#modal-root');

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  p: 0,
  maxHeight: '70vh',
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

export interface ModalCustomProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  modalSx?: SxProps<Theme>;
  headChildren?: ReactElement;
  bodyChildren?: ReactNode;
  footerChildren?: ReactNode;
  headChildrenSx?: SxProps<Theme>;
  bodyChildrenSx?: SxProps<Theme>;
  footerChildrenSx?: SxProps<Theme>;
}

export const ModalCustom = (props: ModalCustomProps) => {
  const {
    open,
    onClose,
    modalSx,
    bodyChildrenSx,
    footerChildrenSx,
    headChildrenSx,
    footerChildren,
    headChildren,
    bodyChildren,
  } = props;

  if (!open || !modalRoot) {
    return null;
  }


  return createPortal(
    <Modal open={open} onClose={onClose} sx={{ ...modalSx }}>
      <Box bgcolor='white' borderRadius={2} sx={style}>
        {headChildren &&
            <Box
              p={3}
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ ...headChildrenSx }}
            >
              {headChildren}
            </Box>
        }
        <Box
          p={3}
          gap={3}
          display='flex'
          flexDirection='column'
          sx={{ ...bodyChildrenSx }}
        >
          {bodyChildren}
        </Box>
        <Box
          p={3}
          display='flex'
          width='100%'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            mt: 'auto',
            ...footerChildrenSx,
          }}
        >
          {footerChildren}
        </Box>
      </Box>
    </Modal>,
    modalRoot,
  );
};
