import { demoResearches } from 'src/constants/demo-mock-data';

import { LeadBodyParams, ResearchResponse } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { deleteResearch, setResearches } from './index';

export const loadResearch = (paramBody: LeadBodyParams): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));

  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(setResearches({
      researches: demoResearches,
      counters: {
        All: 0,
        New: 0,
        Recalculate: 0,
        'Approved': 0,
        'In Progress': 0,
        'Rejected': 0,
      },
      netCount: 0,
    }));
    dispatch(setIsLoading(false));
    return;
  }

  try {
    const researches: ResearchResponse = await apiFetch('research/list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });

    if (researches !== undefined) {
      dispatch(setResearches(researches));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const deleteResearchThunk = (researchId: string): AppThunk => async (dispatch) => {
  dispatch(deleteResearch(researchId));
};
