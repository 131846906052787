import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';
import { forwardRef, ReactNode } from 'react';
import { NavianLogoIcon } from 'src/assets/svg';

import { RouterLink } from '../router-link';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  icon?: ReactNode;
  href?: string;
}

export const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({
    disabledLink = false,
    icon,
    sx,
    href,
    ...other
  }, ref) => {

    const logo = (
      <Box
        ref={ref}
        component='div'
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        {icon || <NavianLogoIcon/>}
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href={href || '/'} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  },
);
