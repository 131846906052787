import { Iconify, IconifyProps } from '../iconify';

type Props = {
  icon?: IconifyProps;
  isRTL?: boolean;
};

export const LeftIcon = (props: Props) => {
  const {
    icon = 'eva:arrow-ios-forward-fill',
    isRTL,
  } = props;
  return (
    <Iconify
      icon={icon}
      sx={{
        transform: ' scaleX(-1)',
        ...(isRTL && { transform: ' scaleX(1)' }),
      }}
    />
  );
};

export const RightIcon = (props: Props) => {
  const {
    icon = 'eva:arrow-ios-forward-fill',
    isRTL,
  } = props;
  return (
    <Iconify
      icon={icon}
      sx={{ ...(isRTL && { transform: ' scaleX(-1)' }) }}
    />
  );
};
