import { Button, Card, CardHeader, Stack } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import _, { omit } from 'lodash';
import { MouseEventHandler, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { CustomTab, TableCustom, TablePaginationCustom, TabType } from 'src/components';
import { TableHeadType } from 'src/features/leads-page';
import { useTable } from 'src/hooks';
import { sortCustom } from 'src/utils';

import { DeptNameType, FinancialTableType, OptionBlockKey } from '../../../../../types';
import { defaultFinancialTableFilters, FinancialTableFilters } from '../mock-data';
import { FinancialTableRow } from './financial-table-row';

export interface FinancialTableProps {
  title: string;
  btnTitle?: string;
  btnConfig?: ButtonProps;
  showTabs?: boolean;
  showIsCalculate?: boolean;
  financialTableData?: FinancialTableType[];
  termSheets?: FinancialTableType[];
  debtAgreements?: FinancialTableType[];
  headLabels: TableHeadType[];
  tableRowBtnTitle?: string;
  tableRowBtnConfig?: ButtonProps;
  tableRowBtnOnClickHandler?: (row: FinancialTableType) => MouseEventHandler<HTMLButtonElement>;
  resendRequestClickHandler?: (row: FinancialTableType) => VoidFunction;
  requestFinancingAgreement?: (rowId: string) => VoidFunction;
  seeDetailsClickHandler?: (row: FinancialTableType) => VoidFunction;
  uploadTermSheetClickHandler?: (row: FinancialTableType) => VoidFunction;
  uploadDebtFinancingDocuments?: (rowId: string) => VoidFunction;
  uploadDebtFinancingAgreement?: (rowId: string) => VoidFunction;
  deleteClickHandler?: (row: FinancialTableType, block: OptionBlockKey) => VoidFunction;
  editClickHandler?: (row: FinancialTableType, block: OptionBlockKey) => VoidFunction;
  moreOptionBlockKey: OptionBlockKey;
}

export const FinancialTable = (props: FinancialTableProps) => {
  const {
    btnConfig,
    btnTitle,
    title,
    showTabs = false,
    showIsCalculate = false,
    financialTableData = [],
    headLabels,
    tableRowBtnConfig,
    tableRowBtnTitle,
    tableRowBtnOnClickHandler,
    resendRequestClickHandler,
    requestFinancingAgreement,
    seeDetailsClickHandler,
    uploadTermSheetClickHandler,
    deleteClickHandler,
    editClickHandler,
    moreOptionBlockKey,
    uploadDebtFinancingDocuments,
    uploadDebtFinancingAgreement,
  } = props;

  const [filters, setFilters] = useState<FinancialTableFilters>(defaultFinancialTableFilters);
  const table = useTable();

  const getTabsLength = useCallback((type: DeptNameType) => {
    return financialTableData?.filter((item) => item.type === type)?.length;
  }, [financialTableData]);

  const flattenDetails = (array: FinancialTableType[]) => {
    return _.map(array, (obj) => {
      return _.assign({}, obj, omit(obj.preliminaryDebtRequest, '_id'));
    });
  };

  const dataFiltered: FinancialTableType[] = applyFilter(sortCustom(flattenDetails(financialTableData), table), filters);

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  const tabs: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: financialTableData?.length,
      },
      {
        value: 'Senior',
        label: 'Senior',
        count: getTabsLength('Senior'),
      },
      {
        value: 'Junior',
        label: 'Junior',
        count: getTabsLength('Junior'),
      },
      {
        value: 'Bridge',
        label: 'Bridge',
        count: getTabsLength('Bridge'),
      },
      {
        value: 'Other',
        label: 'Other',
        count: getTabsLength('Other'),
      },
    ];
  }, [getTabsLength, financialTableData?.length]);

  const handleTabOnChange = useCallback((event: SyntheticEvent, newValue: string) => {
    setFilters({ type: newValue as DeptNameType });
  }, []);

  const noDataFound = (dataFiltered?.length === 0 && filters.type !== 'All') || dataFiltered?.length === 0;

  // const checkIfTermSheetWithTypeExist = useCallback((type: DeptNameType) => {
  //   if (moreOptionBlockKey === 'offers') {
  //     const existInTermSheets = termSheets?.find(termSheet => termSheet.type === type);
  //     const existInDebtAgreement = debtAgreements?.find(agreement => agreement.type === type);
  //     return (existInDebtAgreement || existInTermSheets) ? '' : tableRowBtnTitle;
  //   }
  //   return tableRowBtnTitle;
  // }, [moreOptionBlockKey, tableRowBtnTitle, termSheets, debtAgreements]);

  return (
    <Card>
      <CardHeader
        sx={{ p: 3 }}
        title={
          <Stack
            direction='row'
            justifyContent='space-between'
          >
            {title}
            {btnTitle &&
              <Button
                variant='outlined'
                {...btnConfig}
              >
                {btnTitle}
              </Button>
            }
          </Stack>
        }
      />
      {showTabs &&
        <CustomTab
          tabs={tabs}
          defaultTabValue='All'
          currentTabValue={filters.type}
          handleTabOnChange={handleTabOnChange}
        />
      }
      <TableCustom
        headLabels={headLabels}
        table={table}
        rowCount={financialTableData?.length}
        noDataFound={noDataFound}
        bodyCells={(
          <>
            {dataInPage?.map((row) => (
              <FinancialTableRow
                key={row._id}
                row={row}
                showIsCalculate={showIsCalculate}
                moreOptionBlockKey={moreOptionBlockKey}
                btnConfig={tableRowBtnConfig}
                btnTitle={tableRowBtnTitle}
                btnOnClickHandler={tableRowBtnOnClickHandler?.(row)}
                resendRequestClickHandler={resendRequestClickHandler?.(row)}
                requestFinancingAgreement={requestFinancingAgreement?.(row._id)}
                seeDetailsClickHandler={seeDetailsClickHandler?.(row)}
                uploadTermSheetClickHandler={uploadTermSheetClickHandler?.(row)}
                deleteClickHandler={deleteClickHandler?.(row, moreOptionBlockKey)}
                editClickHandler={editClickHandler?.(row, moreOptionBlockKey)}
                uploadDebtFinancingAgreement={uploadDebtFinancingAgreement?.(row._id)}
                uploadDebtFinancingDocuments={uploadDebtFinancingDocuments?.(row._id)}
              />
            ))}
          </>
        )}
      />
      <TablePaginationCustom
        count={dataFiltered?.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
      />
    </Card>
  );
};

const applyFilter = (data: FinancialTableType[], filters: FinancialTableFilters) => {
  let resultData = _.cloneDeep(data);
  if (filters.type !== 'All') {
    resultData = data?.filter((finance) => finance.type === filters.type);
  }
  return resultData;
};
