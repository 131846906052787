import { CardContent, CardHeader } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Carousel from 'react-slick';
import Image from 'src/components/image';

import { CarouselArrows, CarouselDots, useCarousel } from '../carousel';

export interface SlideType {
  _id?: string;
  title?: string;
  label?: string;
  imgSrc?: string;
}

type SliderCustomProps = {
  mainLabel?: string;
  boxSx?: SxProps<Theme>;
  slides?: SlideType[];
  onBtnClickHandler?: (slide: SlideType) => () => void;
  btnTitle?: string;
  height?: number;
  showArrows?: boolean;
}

export const SliderCustom = (props: SliderCustomProps) => {
  const {
    slides = [],
    boxSx,
    showArrows,
    height = 522,
    ...rest
  } = props;

  const carousel = useCarousel({
    speed: 800,
    autoplay: true,
    ...CarouselDots({
      sx: {
        right: 20,
        bottom: 20,
        position: 'absolute',
        color: 'primary.light',
      },
    }),
  });


  return (
    <Box
      sx={{
        ...boxSx,
        height: height,
      }}
    >
      {showArrows &&
        <CardHeader
          action={<CarouselArrows onNext={carousel.onNext} onPrev={carousel.onPrev}/>}
          sx={{
            p: 0,
            mb: 3,
          }}
        />
      }

      <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
        {slides.map((slide, index) => (
          <CarouselItem key={index} height={height} item={slide} {...rest}/>
        ))}
      </Carousel>
    </Box>
  );
};

type CarouselItemProps = Pick<SliderCustomProps, 'height' | 'btnTitle' | 'onBtnClickHandler' | 'mainLabel'> & {
  item: SlideType;
};

export const CarouselItem = (props: CarouselItemProps) => {
  const {
    item,
    onBtnClickHandler,
    btnTitle,
    mainLabel,
    height,
  } = props;
  const theme = useTheme();

  const {
    title,
    imgSrc,
    label,
  } = item;

  const renderImg = (
    <Image
      alt={title}
      src={imgSrc || ''}
      overlay={`linear-gradient(to bottom, ${alpha(theme.palette.grey[900], 0)} 0%, ${
        theme.palette.grey[900]
      } 75%)`}
      sx={{
        width: 1,
        height: height,
      }}
    />
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <CardContent
        sx={{
          left: 0,
          width: 1,
          bottom: 0,
          zIndex: 9,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
        }}
      >
        <Typography variant='overline' sx={{ opacity: 0.48 }}>
          {mainLabel || label}
        </Typography>

        <Typography
          noWrap
          variant='h5'
          sx={{
            mt: 1,
            mb: 3,
          }}
        >
          {title}
        </Typography>

        <Button
          color='primary'
          variant='contained'
          onClick={onBtnClickHandler?.(item)}
          sx={{
            color: theme.palette.text.primary,
            background: 'white',
          }}
        >
          {btnTitle}
        </Button>
      </CardContent>

      {renderImg}
    </Box>
  );
};
