import { Grid, Skeleton } from '@mui/material';

export const ProposedSkeleton = () => {
  return (
    <Grid container spacing={3} pl={3}>
      <Grid container item xs={7} spacing={3}>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Skeleton variant='rounded' width='100%' height='550px'/>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='rounded' width='100%' height='250px'/>
      </Grid>
    </Grid>
  );
};
