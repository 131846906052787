import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { memo, useCallback, useState } from 'react';
import { navVerticalConfig } from 'src/constants/nav-config';

import { NavConfigProps, NavListProps, NavSectionProps } from '../../types';
import { NavListVertical } from './nav-list-vertical';
import { StyledSubheader } from './styles';

export const NavSectionVertical = memo((props: NavSectionProps) => {
  const {
    data,
    config,
    sx,
    ...other
  } = props;

  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          disabled={group.disabled}
          items={group.items}
          config={navVerticalConfig(config)}
        />
      ))}
    </Stack>
  );
});

type GroupProps = {
  subheader?: string;
  disabled?: boolean;
  items: NavListProps[];
  config: NavConfigProps;
};

export const Group = (props: GroupProps) => {
  const {
    subheader,
    items,
    config,
  } = props;
  const [open, setOpen] = useState(true);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const renderContent = items.map((list) => (
    <NavListVertical
      key={list.title + list.path}
      data={list}
      disabled={list.disabled}
      depth={1}
      hasChild={!!list.children}
      config={config}
    />
  ));

  return (
    <List disablePadding sx={{ px: 2 }}>
      {subheader ? (
        <>
          <StyledSubheader
            disableGutters
            disableSticky
            onClick={handleToggle}
            config={config}
            sx={{ textTransform: 'none' }}
          >
            {subheader}
          </StyledSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </List>
  );
};
