import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SplashScreen, TabContextCustom, TabType } from 'src/components';
import { useBoolean, useResponsive } from 'src/hooks';
import { RootState } from 'src/store';
import { getFileSections } from 'src/store/app';
import { getReportDetails } from 'src/store/storage';
import { capitalizeFirstLetters } from 'src/utils';

import { DebtDetailsType, ReportKeyType } from '../../../../types';
import { ReportMain } from '../report-page';
import { EditDrawer } from '../report-page/edit-drawer/edit-drawer';
import { Header } from '../report-page/header';
import { FinRequest } from './fin-request/fin-request';
import { getSettingConfig } from './helpers';
import { Overview } from './overview';
import { ReportDocuments } from './report-documents';
import { ReportView } from './report-view';


type ReportDetailProps = {
  type: ReportKeyType;
}

const ReportDetail = (props: ReportDetailProps) => {
  const { type } = props;
  const {
    id,
    identifier,
  } = useParams<{ id: string, identifier?: string }>();

  const dispatch = useDispatch();
  const editDrawer = useBoolean();

  const [currentTab, setCurrentTab] = useState<string>('overview');
  const [detailsList, setDetailsList] = useState<DebtDetailsType | undefined>();
  const mdUp = useResponsive('up', 'md');

  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const reportSettings = useSelector((state: RootState) => state.Storage.reportSettings);
  const user = useSelector((state: RootState) => state.Auth.user);

  const isSettingExist = getSettingConfig(reportSettings, type, id, identifier);
  const debt = detailsData.debt;
  const quotation = detailsData.quotation;
  const project = detailsData.project;
  const assets = detailsData.assets;
  const lead = detailsData.lead;
  const distribution = detailsData.distribution;

  const projectUserId = project?.userId;
  const quotationUserId = quotation?.userId;
  const debtUserId = debt?.userId;
  const distributionUserId = distribution?.userId;
  const isUserProjectManager = user?.role === 'project-manager';

  const isEditAvailable: Record<ReportKeyType, boolean> = {
    calculator: Boolean(projectUserId === user?.id || isUserProjectManager),
    calculatorOriginal: Boolean(projectUserId === user?.id || isUserProjectManager),
    calculatorModified: Boolean(projectUserId === user?.id || isUserProjectManager),
    bank: Boolean(debtUserId === user?.id || isUserProjectManager),
    supplier: Boolean(quotationUserId === user?.id || isUserProjectManager),
    seller: Boolean(distributionUserId === user?.id || isUserProjectManager),
  };

  const isBank = type === 'bank';
  const isSale = type === 'seller';
  const isCalculator = type === 'calculator';
  const showAddOfferButton = !isSale && !isCalculator;
  const titleEnd: Record<typeof type, string> = {
    bank: 'Preliminary Debt Requests',
    supplier: 'Preliminary Debt Requests',
    seller: 'Sale Offer',
    calculator: 'General Report',
    calculatorOriginal: 'General Report',
    calculatorModified: 'General Report',
  };

  useEffect(() => {
    if (isCalculator) {
      setCurrentTab('report');
    }
  }, [isCalculator]);

  const loadDebtDetails = useCallback(async () => {
    if (debt) {
      const detailsList: DebtDetailsType = {
        ...debt,
        rate: debt.interestRate,
      };
      setDetailsList(detailsList);
    }
  }, [debt]);

  const getData = useCallback(() => {
    if (isBank) {
      loadDebtDetails();
      return;
    }
  }, [isBank, loadDebtDetails]);

  useEffect(() => {
    dispatch(getReportDetails(id, type, identifier));
  }, [dispatch, id, identifier, type]);

  useEffect(() => {
    if (detailsData.lead?._id) {
      getData();
      dispatch(getFileSections(detailsData.lead?._id, detailsData.project?._id));
    }
  }, [detailsData.lead?._id, detailsData.project?._id, dispatch, getData]);

  const openDocumentsClickHandler = useCallback(() => {
    setCurrentTab('documents');
  }, []);

  const openDetailedProjectClickHandler = useCallback(() => {
    setCurrentTab('report');
  }, []);

  const tabs = useMemo<TabType[]>(() => [
    {
      label: 'Overview',
      value: 'overview',
      hidden: isCalculator,
    },
    {
      label: 'Financing Request',
      value: 'financing-request',
      hidden: isSale || isCalculator,
    },
    {
      label: 'Report',
      value: 'report',
    },
    {
      label: 'Documents',
      value: 'documents',
    },
    {
      label: 'Seller Details',
      value: 'sellerDetails',
      hidden: !isSale || isCalculator,
    },
  ], [isCalculator, isSale]);

  const tabPanels = useMemo(() => [
    {
      tabKey: 'overview',
      component: <Overview
        type={type}
        openDetailedProjectClickHandler={openDetailedProjectClickHandler}
        openDocumentsClickHandler={openDocumentsClickHandler}
        detailsList={detailsList}
        isSettingExist={isSettingExist}
      />,
    },
    {
      tabKey: 'financing-request',
      component: <FinRequest
        type={type}
        detailsList={detailsList}
        isSettingExist={isSettingExist}
      />,
    },
    {
      tabKey: 'sellerDetails',
      component: <FinRequest
        type={type}
        openDocumentsClickHandler={openDocumentsClickHandler}
        openDetailedProjectClickHandler={openDetailedProjectClickHandler}
        isSettingExist={isSettingExist}
      />,
    },
    {
      tabKey: 'report',
      component: <ReportView settingForReport={isSettingExist} showMenu={!isCalculator}/>,
    },
    {
      tabKey: 'documents',
      component: <ReportDocuments files={isSettingExist?.files}/>,
    },
  ], [type, openDetailedProjectClickHandler, openDocumentsClickHandler, detailsList, isSettingExist, isCalculator]);

  const handleTabOnChange = (_: SyntheticEvent, value: string) => {
    setCurrentTab(value);
  };

  const goToOfferForm = () => {
    setCurrentTab('overview');
    window.scrollTo({
      top: mdUp ? 0 : document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  if (isLoading || !lead?._id) {
    return <SplashScreen/>;
  }

  return (
    <>
      <Header
        isEditAvailable={isEditAvailable[type]}
        onEditClick={editDrawer.onTrue}
      />
      <ReportMain>
        <Stack gap={5}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={5}
          >
            <Typography variant='h4'>
              {capitalizeFirstLetters(assets?.general?.projectName || project?.name)} {titleEnd[type]}
            </Typography>
            {showAddOfferButton &&
              <Button
                sx={{ textWrap: 'nowrap' }}
                variant='contained'
                size='medium'
                onClick={goToOfferForm}
              >
                Add Offer
              </Button>
            }
          </Stack>

          <TabContextCustom
            tabs={tabs}
            defaultTabValue='overview'
            handleTabOnChange={handleTabOnChange}
            tabsSX={{
              p: 0,
              m: 0,
            }}
            tabPanels={tabPanels}
            tabPanelsSx={{
              m: 0,
              p: 0,
            }}
            currentTabValue={currentTab}
          />
        </Stack>
      </ReportMain>
      <EditDrawer
        type={type}
        drawer={editDrawer}
        reportName={type}
      />
    </>
  );
};

export default ReportDetail;
