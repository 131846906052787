import { Box, IconButton, Stack, Toolbar } from '@mui/material';
import { memo, ReactNode } from 'react';
import { ArrowIcon } from 'src/assets/svg';
import { useRouter } from 'src/hooks';

import { BreadcrumbsCustom, BreadcrumbsCustomProps } from '../breadcrumbs-custom';
import { ProjectDetailsHead, ProjectDetailsHeadProps } from '../project-details-head';

type ProjectToolbarProps = ProjectDetailsHeadProps & BreadcrumbsCustomProps & {
  children?: ReactNode;
  showBackIcon?: boolean;
};

export const ProjectToolbar = memo((props: ProjectToolbarProps) => {
  const {
    showSkeleton = false,
    showBackIcon = true,
    title,
    badgeTitle,
    crumbs,
    children,
    badgeColor,
    badgeVariant,
    showBadge,
  } = props;
  const router = useRouter();

  const backArrowClick = () => {
    router.back();
  };

  return (
    <Toolbar
      sx={{
        height: '66px',
        marginBottom: '40px',
        width: '100%',
        background: '#FFFF',
      }}
    >
      <Stack
        flexGrow={0}
        direction='row'
        alignItems='flex-start'
        width='100%'
        justifyContent='space-between'
        spacing={{
          xs: 0.5,
          sm: 2,
        }}
      >
        <Box>
          <Stack spacing={3} direction='row'>
            {showBackIcon &&
                <Box
                  sx={{
                    transform: 'rotate(180deg)',
                    marginBottom: '36px',
                  }}
                >
                  <IconButton aria-label='back' onClick={backArrowClick}>
                    <ArrowIcon/>
                  </IconButton>
                </Box>
            }
            <Stack direction='column'>
              <ProjectDetailsHead
                showBadge={showBadge}
                showSkeleton={showSkeleton}
                title={title}
                badgeTitle={badgeTitle}
                badgeColor={badgeColor}
                badgeVariant={badgeVariant}
              />
              <BreadcrumbsCustom
                showSkeleton={showSkeleton}
                crumbs={crumbs}
              />
            </Stack>
          </Stack>
        </Box>
        <Box>
          {!showSkeleton && children}
        </Box>
      </Stack>
    </Toolbar>
  );
});
