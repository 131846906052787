import { Typography } from '@mui/material';
import { secondaryDefaultTextColor } from 'src/constants/colors';

export interface TimelineContentChildProps {
  title: string;
  subTitle: string;
}

export const TimelineContentChild = (props: TimelineContentChildProps) => {
  const {
    title,
    subTitle,
  } = props;
  return (
    <>
      <Typography variant='subtitle2' component='span'>
        {title}
      </Typography>
      <Typography fontSize='12px' color={secondaryDefaultTextColor}>
        {subTitle}
      </Typography>
    </>
  );
};
