import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Editor } from 'src/components/editor';
import { RootState } from 'src/store';

import { EmailTemplateAssetType } from '../../../../../../types';
import { DefaultAssetTabProps } from './assets';

export const EmailTemplate = (props: DefaultAssetTabProps) => {
  const { debouncedUpdateAsset } = props;
  const [equityOffer, setEquityOffer] = useState('');
  const [debtOffer, setDebtOffer] = useState('');
  const [fullBuyoutOffer, setFullBuyoutOffer] = useState('');
  const assets = useSelector((state: RootState) => state.Storage.detailsData.assets);

  useEffect(() => {
    if (assets?._id) {
      setEquityOffer(assets.emailTemplate?.equityOfferTemplate || '');
      setDebtOffer(assets.emailTemplate?.debtOfferTemplate || '');
      setFullBuyoutOffer(assets.emailTemplate?.fullBuyoutOfferTemplate || '');
    }
  }, [assets?._id, assets?.emailTemplate?.debtOfferTemplate, assets?.emailTemplate?.equityOfferTemplate, assets?.emailTemplate?.fullBuyoutOfferTemplate]);

  const editorOnChangeHandler = (setter: Dispatch<SetStateAction<string>>, keyInAsset: keyof EmailTemplateAssetType) => (value: string) => {
    setter(value);
    if (assets?._id) {
      debouncedUpdateAsset({
        ...assets,
        emailTemplate: {
          ...assets?.emailTemplate,
          [keyInAsset]: value,
        },
      });
    }
  };

  return (
    <Stack gap={3}>
      <Card sx={{ flex: 1 }}>
        <CardHeader title='Equity Offer Email Template'/>
        <CardContent>
          <Editor
            id='equityOffer-email-template'
            placeholder='Email Template'
            value={equityOffer}
            onChange={editorOnChangeHandler(setEquityOffer, 'equityOfferTemplate')}
          />
        </CardContent>
      </Card>

      <Card sx={{ flex: 1 }}>
        <CardHeader title='Debt Offer Email Template'/>
        <CardContent>
          <Editor
            id='debtOffer-email-template'
            placeholder='Email Template'
            value={debtOffer}
            onChange={editorOnChangeHandler(setDebtOffer, 'debtOfferTemplate')}
          />
        </CardContent>
      </Card>

      <Card sx={{ flex: 1 }}>
        <CardHeader title='Full Buyout Offer Email Template'/>
        <CardContent>
          <Editor
            id='fullBuyoutOffer-email-template'
            placeholder='Email Template'
            value={fullBuyoutOffer}
            onChange={editorOnChangeHandler(setFullBuyoutOffer, 'fullBuyoutOfferTemplate')}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};
