import { omit } from 'lodash';
import { _financingItems, FinancingBodyParams } from 'src/features/finance-page';

import { Finance, FinancingResponse } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { deleteFinance, setFinances } from '.';

export const loadFinancing = (paramBody: FinancingBodyParams): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(setFinances({
      finances: _financingItems,
      counters: {
        All: 0,
        'In Progress': 0,
        Done: 0,
        New: 0,
      },
      metadata: {
        total: 0,
        page: 0,
      },
    }));
    return;
  }

  try {
    dispatch(setIsLoading(true));
    const financing: FinancingResponse = await apiFetch('financing/list', {
      method: 'post',
      body: JSON.stringify({
        ...omit(paramBody, 'stage'),
        status: paramBody.stage,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (financing !== undefined) {
      const updatedFinancingItems: Finance[] = financing.projects.map(finance => {
        return {
          _id: finance._id,
          stage: finance.stage,
          type: finance.project?.scenario?.calculate?.input?.type_of_project || 'New build houses',
          status: finance.project?.scenario?.status,
          location: { address: finance?.address },
          scenario: finance.project?.scenario,
          name: finance.project?.name,
          projectId: finance.project?._id,
          lastActivity: finance.timeline?.message,
          lastActivityDate: finance.timeline?.date,
          totalFunds: finance.project.scenario?.calculate?.output?.['Total project cost'] || 0,
          structure: finance.debts?.map(debt => debt.section.replace(' debt', '')),
          debt: finance.project?.scenario?.calculate?.input?.funding_array?.reduce((acc, el) => {
            return acc + (el?.section?.includes?.('Debt') ? (el?.percentage || 0) : 0);
          }, 0) || 0,
          equity: finance.project.scenario?.calculate?.input?.funding_array?.reduce((acc, el) => {
            return acc + (el?.section?.includes?.('Equity') ? (el?.percentage || 0) : 0);
          }, 0) || 0,
        };
      });
      dispatch(setFinances({
        counters: financing.counters,
        metadata: financing.metadata,
        finances: updatedFinancingItems,
      }));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const deleteFinancingThunk = (financingId: string): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    dispatch(deleteFinance(financingId));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};
