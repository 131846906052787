import { Box, Skeleton } from '@mui/material';
import { m } from 'framer-motion';
import { varTranHover } from 'src/animate';
import Image from 'src/components/image';

import { FileForUpload } from '../../../../types';
import { Lightbox, useLightBox } from '../lightbox';

interface ImageStackProps {
  showData?: boolean;
  sliceImages?: number;
  images?: FileForUpload[];
}

export const ImageStack = (props: ImageStackProps) => {

  const {
    images = [],
    showData = false,
    sliceImages = 5,
  } = props;

  const slides = images.map((slide) => ({ src: slide.url || '' }));

  const {
    selected: selectedImage,
    open: openLightbox,
    onOpen: handleOpenLightbox,
    onClose: handleCloseLightbox,
  } = useLightBox(slides);

  return (
    <>
      {showData
        ?
        <>
          <Box
            gap={1}
            display='grid'
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
            }}
            sx={{
              mb: {
                xs: 3,
                md: 5,
              },
            }}
          >
            <m.div
              key={slides[0].src}
              whileHover='hover'
              variants={{ hover: { opacity: 0.8 } }}
              transition={varTranHover()}
            >
              <Image
                alt={slides[0].src}
                src={slides[0].src}
                ratio='1/1'
                onClick={() => handleOpenLightbox(slides[0].src)}
                sx={{
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              />
            </m.div>
            <Box gap={1} display='grid' gridTemplateColumns='repeat(2, 1fr)'>
              {slides.slice(1, sliceImages).map((slide) => {
                return (
                  <m.div
                    key={slide.src}
                    whileHover='hover'
                    variants={{ hover: { opacity: 0.8 } }}
                    transition={varTranHover()}
                  >
                    <Image
                      alt={slide.src}
                      src={slide.src}
                      ratio='1/1'
                      onClick={() => handleOpenLightbox(slide.src)}
                      sx={{
                        borderRadius: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </m.div>
                );
              })}
            </Box>
          </Box>

          <Lightbox
            index={selectedImage}
            slides={slides}
            open={openLightbox}
            close={handleCloseLightbox}
          />
        </>
        : <Skeleton variant='rounded' height='336px' width='100%'/>
      }
    </>
  );
};
