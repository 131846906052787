import { matchPath, useLocation } from 'react-router-dom';

export const useActiveLink = (path: string, deep = true): boolean => {
  const { pathname } = useLocation();

  const normalActive = path ? !!matchPath(pathname, {
    path,
    exact: true,
  }) : false;

  const deepActive = path ? !!matchPath(pathname, {
    path,
    exact: false,
  }) : false;

  return deep ? deepActive : normalActive;
};
