import Box, { BoxProps } from '@mui/material/Box';
import { memo } from 'react';
import { HEADER, NAV } from 'src/constants/constants';
import { useResponsive, useSettingsContext } from 'src/hooks';

const SPACING = 8;

export const Main = memo((props: BoxProps) => {
  const {
    children,
    sx,
    ...other
  } = props;

  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');

  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';

  if (isNavHorizontal) {
    return (
      <Box
        component='main'
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          maxWidth: settings.themeStretch ? '100%' : 'xl',
          pb: 10,
          mx: 'auto',
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
        minHeight: 1,
        maxWidth: settings.themeStretch ? '100%' : 'xl',
        display: 'flex',
        flexDirection: 'column',
        mx: 'auto',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(!lgUp && { width: '100%' }),
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && { width: `calc(100% - ${NAV.W_MINI}px)` }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
});
