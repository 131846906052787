import { CardProps } from '@mui/material/Card';
import { StackProps } from '@mui/material/Stack';
import { ComponentType, ReactElement } from 'react';
import { ControllerProps, Path } from 'react-hook-form';
import { NoLayoutProps } from 'src/components';

export type ConfigType<P, C, L> = {
  leftBlock?: ReactElement;
  rightBlock: RightBlockType<P, C>[];
  layout: ComponentType<L>;
  stackConfig?: StackProps;
  stackWidth?: number;
};


export type RightBlockType<P, C> = C & Partial<ControllerProps> & {
  name: Path<P>;
  correspondingComponent: ComponentType<C>;
  helperText?: string;
}

export const defaultStackConfig: StackProps = {
  p: 3,
  rowGap: 3,
};

export type LayoutTypes = Partial<CardProps> & Partial<NoLayoutProps>;
