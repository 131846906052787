import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { Key, ReactNode, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';


type RHFRadioGroupProps = RadioGroupProps & {
  name: string;
  options: { label: string; value: unknown }[];
  label?: string;
  spacing?: number;
  helperText?: ReactNode;
};

export const RHFRadioGroup = (props: RHFRadioGroupProps) => {
  const {
    row,
    name,
    label,
    options,
    spacing,
    helperText,
    ...other
  } = props;
  const { control } = useFormContext();
  const ref = useRef<HTMLDivElement | null>(null);

  const labelledby = label ? `${name}-${label}` : '';

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <FormControl component='fieldset'>
          {label && (
            <FormLabel component='legend' id={labelledby} sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <RadioGroup {...field} ref={ref} aria-labelledby={labelledby} row={row} {...other}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value as Key}
                value={option.value}
                control={<Radio/>}
                label={option.label}
                sx={{
                  '&:not(:last-of-type)': { mb: spacing || 0 },
                  ...(row && {
                    mr: 0,
                    '&:not(:last-of-type)': { mr: spacing || 2 },
                  }),
                }}
              />
            ))}
          </RadioGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
