import { UNSPECIFIED_VALUE } from 'src/constants/constants';

import { allSummaryItems } from './data';
import { ItemType } from './summary';

export type AllSummaryItemsKeyType = keyof typeof allSummaryItems;

export type GenerateSummaryFieldProps = {
  [key in AllSummaryItemsKeyType]?: ItemType['value'];
}

export const generateSummaryField = (props: GenerateSummaryFieldProps): ItemType[] => {
  const propsKeys = Object.keys(props) as AllSummaryItemsKeyType[];
  const summaryItems: ItemType[] = propsKeys.map(key => {
    return {
      ...allSummaryItems[key],
      value: props[key] || UNSPECIFIED_VALUE,
    };
  });
  return summaryItems;
};
