import { timelineDotClasses, timelineItemClasses } from '@mui/lab';
import { Card, CardHeader, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import { EcommerceWidgetSummary, ReportNote, TableCustom, TimelineCustom, TimeLineStep } from 'src/components';
import { TableHeadType } from 'src/features/leads-page';

import { MainBudget, NoteSettingType } from '../../../../../../types';
import { TimelineCashflowSkeleton } from './timeline-cashflow-skeleton';
import { TimelineCollapse } from './timeline-collapse';
import { TimelineContentChild } from './timeline-content-child';

export interface TimelineCashflowProps {
  showData?: boolean;
  totalCosts?: string;
  costPSQM?: string;
  niagea?: string;
  totalNia?: string;
  purchaseComplete?: string;
  constructionStarts?: string;
  constructionComplete?: string;
  lastUnitSold?: string;
  cashflowItems?: MainBudget[];
  cashflowItemHeader?: TableHeadType[];
  noteSetting?: NoteSettingType;
}

export const TimelineCashflow = (props: TimelineCashflowProps) => {
  const {
    showData = false,
    cashflowItems = [],
    cashflowItemHeader = [],
    costPSQM = 'Unspecified',
    totalCosts = 'Unspecified',
    totalNia = 'Unspecified',
    niagea = 'Unspecified',
    purchaseComplete = 'Unspecified',
    constructionStarts = 'Unspecified',
    constructionComplete = 'Unspecified',
    lastUnitSold = 'Unspecified',
    noteSetting,
  } = props;
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const handleRowClick = (key: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const timelineSteps: TimeLineStep[] = [
    {
      dotColor: 'success',
      showConnector: true,
      timeLineDotChild: 1,
      timeLineContent: <TimelineContentChild title='Purchase complete' subTitle={purchaseComplete}/>,
    },
    {
      dotColor: 'success',
      showConnector: true,
      timeLineDotChild: 2,
      timeLineContent: <TimelineContentChild title='Construction starts' subTitle={constructionStarts}/>,
    },
    {
      dotColor: 'success',
      showConnector: true,
      timeLineDotChild: 3,
      timeLineContent: <TimelineContentChild title='Construction complete' subTitle={constructionComplete}/>,
    },
    {
      dotColor: 'success',
      timeLineDotChild: 4,
      timeLineContent: <TimelineContentChild title='Last unit sold' subTitle={lastUnitSold}/>,
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>Timeline & Cashflow</Typography>
      </Grid>
      {showData ? (
        <Grid container item spacing={3}>
          {noteSetting?.isActive &&
                <Grid item md={12}>
                  {showData
                    ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
                    : <Skeleton variant='rounded' height='150px' width='100%'/>
                  }
                </Grid>
          }
          <Grid item xs={4}>
            <Card sx={{ p: '2px 8px' }}>
              <TimelineCustom
                timelineSteps={timelineSteps}
                timelineSx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  [`& .${timelineDotClasses.root}`]: {
                    backgroundColor: '#3698af',
                    p: '0 7px',
                  },
                }}
              />
            </Card>
          </Grid>
          <Grid container item xs={8} spacing={3} height='356px'>
            <Grid item xs={6}>
              <EcommerceWidgetSummary
                title='Total Сosts'
                total={totalCosts}
              />
            </Grid>
            <Grid item xs={6}>
              <EcommerceWidgetSummary
                title='Cost PSQM (NIA/GEA)'
                total={costPSQM}
              />
            </Grid>
            <Grid item xs={6}>
              <EcommerceWidgetSummary
                title='NIA/GEA'
                total={niagea}
              />
            </Grid>
            <Grid item xs={6}>
              <EcommerceWidgetSummary
                title='Total NIA'
                total={totalNia}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title='Cashflow '/>
              <TableCustom
                headLabels={[{
                  label: 'Cashflow',
                  _id: 'cashflow',
                }, ...cashflowItemHeader]}
                tableHeadSx={{ whiteSpace: 'nowrap' }}
                noDataFound={cashflowItems.length === 0}
                tableContainerSx={{ marginTop: '24px' }}
                bodyCells={
                  <>
                    {cashflowItems?.map((item, index) => {
                      return (
                        <TimelineCollapse
                          key={index}
                          item={item}
                          level={1}
                          maxLevel={3}
                          borderLevel={2}
                          siblingsItems={cashflowItems}
                          parentKey={String(index)}
                          expandedRows={expandedRows}
                          onRowClick={handleRowClick}
                        />
                      );
                    })}
                  </>
                }
              />
            </Card>
          </Grid>
        </Grid>
      ) :
        <TimelineCashflowSkeleton/>
      }
    </Grid>
  );
};
