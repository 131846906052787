import { Dispatch, SetStateAction } from 'react';
import { Editor } from 'src/components/editor';

export interface SummaryTextProps {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
}

export const SummaryText = (props: SummaryTextProps) => {
  const {
    setText,
    text,
  } = props;

  return (
    <Editor
      id='minimal-quill-summary'
      value={text}
      onChange={setText}
      placeholder='Description'
      headings={[]}
      simple
    />
  );
};
