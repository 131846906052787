import { Chip, Skeleton, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';

import { LabelColor } from '../../../../types';
import { LabelVariant } from '../label';

export interface ProjectDetailsHeadProps {
  title?: string;
  showSkeleton?: boolean;
  showBadge?: boolean;
  badgeTitle?: string;
  badgeVariant?: LabelVariant;
  badgeColor?: LabelColor;
}

export const ProjectDetailsHead = memo((props: ProjectDetailsHeadProps) => {
  const {
    title = UNSPECIFIED_VALUE,
    showSkeleton = false,
    showBadge = true,
    badgeTitle = UNSPECIFIED_VALUE,
    badgeVariant = 'outlined',
    badgeColor = 'default',
  } = props;

  return (
    <>
      <Stack spacing={1} direction='row' alignItems='center'>
        {showSkeleton ? (
          <>
            <Skeleton variant='rounded' height='36px' width='300px'/>
            <Skeleton variant='rounded' height='24px' width='200px' sx={{ marginTop: '6px' }}/>
          </>
        ) : (
          <>
            <Typography variant='h4'>
              {title}
            </Typography>
            {showBadge &&
                <Chip
                  size='small'
                  variant={badgeVariant}
                  color={badgeColor}
                  label={badgeTitle}
                />
            }
          </>
        )}
      </Stack>
    </>
  );
});


