import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import sumBy from 'lodash/sumBy';
import { Chart, useChart } from 'src/components/chart';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { fNumber } from 'src/utils';

export interface CardRadialBarChartProps extends CardProps {
  title?: string;
  chartHeader?: string;
  subheader?: string;
  customTotal?: number;
  showWidget?: boolean;
  chart: {
    colors?: string[];
    series: {
      label: string;
      value: number;
    }[];
    options?: ApexOptions;
  };
  chartTotalFormat?: (total: number) => string;
  chartOnly?: boolean;
  totalLabel?: string;
}

export const CardRadialBarChart = (props: CardRadialBarChartProps) => {
  const {
    title,
    chartHeader,
    subheader,
    customTotal,
    showWidget = true,
    chart,
    chartTotalFormat,
    chartOnly,
    totalLabel,
    ...other
  } = props;

  const theme = useTheme();

  const {
    colors: chartColors,
    series,
    options,
  } = chart;
  const colors = chartColors || ['#00B8D9', '#3296AE'];

  const total = customTotal || sumBy(series, 'value');

  const totalBuildCostsSeries = series?.find((i) => i?.label === (totalLabel || 'Total build costs'));
  const chartSeries = totalBuildCostsSeries ? (totalBuildCostsSeries.value / total) * 100 : 0;

  const chartOptions = useChart({
    legend: { show: false },
    grid: {
      padding: {
        top: -32,
        bottom: -32,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          {
            offset: 0,
            color: colors[0],
          },
          {
            offset: 100,
            color: colors[1],
          },
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        dataLabels: {
          name: { offsetY: -16 },
          value: { offsetY: 8 },
          total: {
            label: chartHeader || 'Total',
            formatter: chartTotalFormat
              ? () => chartTotalFormat(total)
              : () => fNumber(total),
          },
        },
      },
    },
    ...options,
  });

  return (
    <>
      {
        showWidget ?
          <>
            {chartOnly
              ? <Chart
                type='radialBar'
                series={[chartSeries]}
                options={chartOptions}
                height={323}
              />
              : <Card {...other}>
                <CardHeader title={title} subheader={subheader} sx={{ mb: 8 }}/>

                <Chart
                  type='radialBar'
                  series={[chartSeries]}
                  options={chartOptions}
                  height={323}
                />

                <Stack spacing={2} sx={{ p: 5 }}>
                  {series.map((item) => (
                    <Stack
                      key={item.label}
                      spacing={1}
                      direction='row'
                      alignItems='center'
                      sx={{ typography: 'subtitle2' }}
                    >
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          bgcolor: alpha(theme.palette.grey[500], 0.16),
                          borderRadius: 0.75,
                          ...(item.label === 'Total build costs' && { bgcolor: colors[1] }),
                        }}
                      />
                      <Box sx={{
                        color: 'text.secondary',
                        flexGrow: 1,
                      }}>{item.label}</Box>
                      {DEFAULT_CURRENCY} {fNumber(item?.value)}
                    </Stack>
                  ))}
                </Stack>
              </Card>
            }

          </>
          : <Skeleton variant='rounded' height='492px' width='100%'/>
      }
    </>
  );
};
