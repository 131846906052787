import { Card, CardHeader, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CardDonutChart, EcommerceWidgetSummary, ReportNote } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { RootState } from 'src/store';
import { fNumber, removeSubstrings } from 'src/utils';

import { NoteSettingType } from '../../../../../../types';
import { InfoBlock } from '../info-block';

interface Row {
  [key: string]: string | number;
}

export interface SalesProps {
  noteSetting?: NoteSettingType;
}

export const Sales = (props: SalesProps) => {
  const { noteSetting } = props;
  const scenario = useSelector((state: RootState) => state.Storage.detailsData.project?.scenario);
  const rows = useMemo(() => {
    const types: string[] = [], rows: Row[] = [];
    for (const elem in scenario?.calculate?.output?.['Sales cost breakdown']) {
      types.push(elem);
    }
    types.map((elem: string) => {
      rows.push({
        type: elem || 'Unspecified',
        amount: scenario?.calculate?.output?.['Sales cost breakdown']?.[elem]?.[0] || 'Unspecified',
        gdv: scenario?.calculate?.output?.['Sales cost breakdown']?.[elem]?.[1] || 'Unspecified',
        project_costs: scenario?.calculate?.output?.['Sales cost breakdown']?.[elem]?.[2] || 'Unspecified',
        per_unit: scenario?.calculate?.output?.['Sales cost breakdown']?.[elem]?.[3] || 'Unspecified',
      });
    });
    return rows;
  }, [scenario]);
  const mdUp = useResponsive('up', 'md');

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>Sales</Typography>
      </Grid>
      {noteSetting?.isActive &&
          <Grid hidden={!mdUp} item md={12}>
            {scenario
              ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
              : <Skeleton variant='rounded' height='150px' width='100%'/>
            }
          </Grid>
      }
      <Grid item md={8} xs={12}>
        <CardDonutChart
          showWidget={!!scenario}
          title='Total Sales Costs'
          chartHeader={`Total sales costs, ${DEFAULT_CURRENCY}`}
          chart={{
            series: [
              {
                label: 'Estate agent',
                value: rows?.find((elem) => 'Sales cost - Estate Agent' === elem.type)?.amount || 0,
              },
              {
                label: 'Marketing',
                value: rows?.find((elem) => 'Sales cost - Marketing' === elem.type)?.amount || 0,
              },
              {
                label: 'Solicitor',
                value: rows?.find((elem) => 'Sales cost - Solicitor' === elem.type)?.amount || 0,
              },
              {
                label: 'Show home',
                value: rows?.find((elem) => 'Sales cost - Show home' === elem.type)?.amount || 0,
              },
            ],
          }}
        />
      </Grid>
      {mdUp &&
          <>
            <Grid container item md={4} rowGap={3}>
              <Grid item md={12}>
                <EcommerceWidgetSummary
                  showWidget={!!scenario}
                  title='Agent Fees'
                  total={scenario?.calculate?.output?.['Agent fees']}
                  postfix={DEFAULT_CURRENCY}
                />
              </Grid>
              <Grid item md={12}>
                <EcommerceWidgetSummary
                  showWidget={!!scenario}
                  title='Legal Fees Sales'
                  total={scenario?.calculate?.output?.['Legal fees sales']}
                  postfix={DEFAULT_CURRENCY}
                />
              </Grid>
              <Grid item md={12}>
                <EcommerceWidgetSummary
                  showWidget={!!scenario}
                  title='Show home'
                  total={scenario?.calculate?.output?.['Show home']}
                  postfix={DEFAULT_CURRENCY}
                />
              </Grid>
            </Grid>
            <Grid item md={12}>
              {scenario ?
                <Card>
                  <CardHeader title='Sales Costs Breakdown'/>
                  <Table sx={{ marginTop: '24px' }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell align='right'>Amount, {DEFAULT_CURRENCY}</TableCell>
                        <TableCell align='right'>% of GDV</TableCell>
                        <TableCell align='right'>% of cost</TableCell>
                        <TableCell align='right'>Per unit, {DEFAULT_CURRENCY}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => {
                        const correspondingRowType = removeSubstrings(['Sales cost -', 'sales costs'], row.type.toString());
                        return (
                          <TableRow
                            key={row.type}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component='th' scope='row'>{correspondingRowType}</TableCell>
                            <TableCell align='right'>{fNumber(row.amount)}</TableCell>
                            <TableCell align='right'>{fNumber(row.gdv)}%</TableCell>
                            <TableCell align='right'>{fNumber(row.project_costs)}%</TableCell>
                            <TableCell align='right'>{fNumber(row.per_unit)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Card>
                : <Skeleton variant='rounded' height='407px' width='100%'/>
              }
            </Grid>
          </>
      }
      <Grid item xs={12} hidden={mdUp}>
        <InfoBlock/>
      </Grid>
    </Grid>
  );
};
