import { SxProps, Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { memo } from 'react';

import { EmptyContent } from './empty-content';

interface TableNoDatProps {
  noDataFound: boolean;
  sx?: SxProps<Theme>;
}

export const TableNoData = memo((props: TableNoDatProps) => {
  const {
    noDataFound,
    sx,
  } = props;
  return (
    <TableRow>
      {noDataFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            filled
            title='No Data'
            sx={{
              py: 10,
              ...sx,
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }}/>
      )}
    </TableRow>
  );
});
