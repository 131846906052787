import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store';
import { copyToClipboard, fNumber, fPercent, getMarketplaceUrl } from 'src/utils';

export const OfferingSummary = () => {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const offeringDetails = detailsData?.offeringDetails;
  const publishedStr = offeringDetails?.publishedAt;
  const editedStr = offeringDetails?.lastEdited;
  const { enqueueSnackbar } = useSnackbar();

  const onCopy = () => {
    copyToClipboard(getMarketplaceUrl(id));
    enqueueSnackbar('Link copied!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant='h6'>Summary</Typography>
          <Stack gap={2}>
            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Offering Type</Typography>
              <Typography variant='subtitle2'>Equity</Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Total views</Typography>
              <Typography variant='subtitle2'>{fNumber(offeringDetails?.views)}</Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Partner Fee</Typography>
              <Typography variant='subtitle2'>
                {fPercent(detailsData.partnerFees)}
              </Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Published</Typography>
              <Typography variant='subtitle2'>
                {publishedStr}
              </Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Last Edit</Typography>
              <Typography variant='subtitle2'>
                {editedStr}
              </Typography>
            </Stack>

            <Stack gap={0.5}>
              <Typography variant='body2' color={theme.palette.text.secondary}>Referral Link</Typography>
              <Typography
                variant='subtitle2'
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={onCopy}
              >
                Copy
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
