import { useMemo } from 'react';

import { CalculateInput, CalculateOutput } from '../../../../../../types';

export const useFinanceResult = (input?: CalculateInput, output?: CalculateOutput, showTotals: boolean = true) => {

  const finance_type_array = useMemo(() => output?.['Finance costs breakdown']?.map?.(item => item.type), [output]);

  const funding_array = useMemo(() => finance_type_array?.map?.((type, i) => ({
    type,
    profit_share: input?.['funding_array']?.[i]?.profit_share,
    interest: input?.['funding_array']?.[i]?.interest,
    fees: input?.['funding_array']?.[i]?.fees,
    rolled_up_interest: input?.['funding_array']?.[i]?.rolled_up_interest,
    section: input?.['funding_array']?.[i]?.section,
  }), []), [finance_type_array, input]);

  const finance_table_data = useMemo(() => funding_array?.map?.((item, i) => ({
    type: item.type,
    totalFunds: output?.['Finance costs breakdown']?.[i]?.amount,
    'total funds': output?.['Finance costs breakdown']?.[i]?.amount,
    rate: item.interest,
    fees: item.fees,
    fee: item.fees,
    'profit share': item.profit_share,
    ltc: output?.['Finance costs breakdown']?.[i]?.ltc,
    ltgdv: output?.['Finance costs breakdown']?.[i]?.ltgdv,
    cost: output?.['Finance costs breakdown']?.[i]?.cost,
    rolled_up_interest: item.rolled_up_interest,
    section: item.section,
  })), [output, funding_array]);

  const rows = useMemo(() => {
    const totals = (finance_table_data || []).reduce((acc, cur) => ({
      ...acc,
      'total funds': acc['total funds'] + (cur['total funds'] || 0),
      'profit share': acc['profit share'] + +((cur['profit share'] || 0)),
      ltc: acc.ltc + +(cur.ltc || 0),
      ltgdv: acc.ltgdv + +(cur.ltgdv || 0),
      cost: acc.cost + (cur.cost || 0),
    }), {
      rate: undefined,
      fees: undefined,
      type: 'Total',
      'total funds': 0,
      'profit share': 0,
      ltc: 0,
      ltgdv: 0,
      cost: 0,
      rolled_up_interest: false,
    });
    const resultArray = [...(finance_table_data || []), showTotals ? totals : null].filter(Boolean);
    // eslint-disable-next-line
    return resultArray?.map?.((item: any) => {
      return {
        ...item,
        total_funds: item['total funds'] || 0,
        rate: item.rate || 0,
        fees: item.fees || 0,
        profit_share: item['profit share'] !== undefined && item['section'] === 'Equity' ? item['profit share'] : 0,
        profitShare: item['profit share'] !== undefined && item['section'] === 'Equity' ? item['profit share'] : 0,
        ltc: item.ltc || 0,
        ltgdv: item.ltgdv || 0,
        costs: item.cost || 0,
      };
    });
  }, [finance_table_data, showTotals]);

  const series = useMemo(() => [
    {
      label: 'Senior debt',
      value: rows?.find((elem) => 'Senior debt' === elem.type)?.total_funds || 0,
    },
    {
      label: 'Junior debt',
      value: rows?.find((elem) => 'Junior debt' === elem.type)?.total_funds || 0,
    },
    {
      label: 'Developer\'s equity',
      value: rows?.find((elem) => 'Developer\'s equity' === elem.type)?.total_funds || 0,
    },
    {
      label: 'JV equity',
      value: rows?.find((elem) => 'JV Equity' === elem.type)?.total_funds || 0,
    },
  ].filter((elem) => elem.value !== 0), [rows]);

  return {
    rows,
    series,
  };
};
