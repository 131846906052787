import { DeptNameType, FinancialSheet, FinancialTableType } from '../../../../types';
import { TableHeadType } from '../leads-page';

export const _mockFinancialSheets: FinancialSheet[] = [
  {
    type: 'Senior',
    completedSteps: 0,
    maxSteps: 0,
    fillColor: '#00B8D9',
    strokeColor: '#00B8D97A',
  },
  {
    type: 'Junior',
    completedSteps: 0,
    maxSteps: 0,
    fillColor: '#C684FF',
    strokeColor: '#8E33FF7A',
  },
  {
    type: 'Bridge',
    completedSteps: 0,
    maxSteps: 0,
    fillColor: '#77ED8B',
    strokeColor: '#22C55E7A',
  },
  {
    type: 'Other',
    completedSteps: 0,
    maxSteps: 0,
    fillColor: '#FFD666',
    strokeColor: '#FFAB007A',
  },
];

export const _mockFinancialTableData: FinancialTableType[] = [
  {
    _id: '1',
    debtRequestId: '1',
    bankSrc: 'BankA',
    bankName: 'Bank A',
    preliminaryDebtRequest: {
      date: new Date('2024-01-18T10:30:00').toISOString(),
      totalFunds: 49_175_725,
      rate: 11,
      fee: 2,
      profitShare: 0,
      ltc: 81.99,
      ltgdv: 18.01,
    },
    type: 'Senior',
  },
  {
    _id: '2',
    debtRequestId: '2',
    bankSrc: 'BankB',
    bankName: 'Bank B',
    preliminaryDebtRequest: {
      date: new Date('2024-01-19T12:45:00').toISOString(),
      totalFunds: 6_464_987,
      rate: 14,
      fee: 3,
      profitShare: 0.12,
      ltc: 81.99,
      ltgdv: 18.01,
    },
    type: 'Junior',
  },
  {
    _id: '3',
    debtRequestId: '3',
    bankSrc: 'BankC',
    bankName: 'Bank C',
    preliminaryDebtRequest: {
      date: new Date('2024-01-20T14:15:00').toISOString(),
      totalFunds: 49_175_725,
      rate: 11,
      fee: 2,
      profitShare: 0,
      ltc: 90.02,
      ltgdv: 9.98,
    },
    type: 'Bridge',
  },
  {
    _id: '4',
    debtRequestId: '4',
    bankSrc: 'BankD',
    bankName: 'Bank D',
    preliminaryDebtRequest: {
      date: new Date('2024-01-21T09:00:00').toISOString(),
      totalFunds: 1_837_034,
      rate: 12,
      fee: 0,
      profitShare: 1,
      ltc: 79.54,
      ltgdv: 20.46,
    },
    type: 'Other',
  },
  {
    _id: '5',
    debtRequestId: '5',
    bankSrc: 'BankE',
    bankName: 'Bank E',
    preliminaryDebtRequest: {
      date: new Date('2024-01-22T16:30:00').toISOString(),
      totalFunds: 1_500_000,
      rate: 15,
      fee: 1,
      profitShare: 0,
      ltc: 58.64,
      ltgdv: 19.56,
    },
    type: 'Senior',
  },
];

export type FinancialTableFilters = {
  type: DeptNameType;
}

export const defaultFinancialTableFilters: FinancialTableFilters = { type: 'All' };

export const defaultHeadLabels: TableHeadType[] = [
  {
    _id: 'totalFunds',
    label: 'Total funds / Rate',
    width: 300,
  },
  {
    _id: 'fee',
    label: 'Fee',
    width: 170,
  },
  {
    _id: 'ltc',
    label: 'LTC / LTGDV',
    width: 200,
  },
  {
    _id: 'type',
    label: 'Type',
    width: 100,
  },
];
