import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerCustom, Iconify } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useBoolean, UseBooleanReturnType } from 'src/hooks';
import { RootState } from 'src/store';
import { createInvestmentActivity } from 'src/store/investor';
import { setDetailViewData } from 'src/store/storage';
import { fNumber, onlyNumbers } from 'src/utils';

import { ReserveSharesSuccessDrawer } from './reserve-shares-success';

const checkboxWrapSx = {
  m: 0,
  alignItems: 'flex-start',
};

export type ReserveSharesDrawerProps = {
  drawer: UseBooleanReturnType;
}

export const ReserveSharesDrawer = (props: ReserveSharesDrawerProps) => {
  const { drawer } = props;
  const successDrawer = useBoolean();
  const theme = useTheme();

  const [amount, setAmount] = useState('');
  const [viewedDisclaimer, setViewedDisclaimer] = useState(false);
  const [riskConfirm1, setRiskConfirm1] = useState(false);
  const [riskConfirm2, setRiskConfirm2] = useState(false);

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const user = useSelector((state: RootState) => state.Auth.user);
  const dispatch = useDispatch();

  const offeringDetails = detailsData.offeringDetails;
  const minAmount = offeringDetails?.investmentAmount?.min || 0;
  const maxAmount = offeringDetails?.investmentAmount?.max || 0;
  const textSecondary = theme.palette.text.secondary;

  const amountNum = Number(amount);
  const isAmountValid = amountNum >= minAmount && amountNum <= maxAmount;
  const isValid = isAmountValid && viewedDisclaimer && riskConfirm1 && riskConfirm2;

  const onReserveClick = async () => {
    const response = await createInvestmentActivity({
      amount: amountNum,
      isDisclaimerSigned: viewedDisclaimer,
      isGuaranteeSigned: riskConfirm1,
      isFinancialUnderstandingSigned: riskConfirm2,
    }, offeringDetails?._id);
    if (response?._id) {
      dispatch(setDetailViewData({
        ...detailsData,
        offeringDetails: {
          ...offeringDetails,
          activities: {
            ...offeringDetails?.activities,
            data: [...(offeringDetails?.activities?.data || []), {
              _id: response?._id,
              customerId: user?.id,
              status: 'Reserved',
            }],
          },
        },
      }));
      drawer.onFalse();
      successDrawer.onTrue();
    }
  };

  return (
    <>
      <DrawerCustom
        containerSx={{ width: 480 }}
        open={drawer.value}
        onCloseDrawerHandler={drawer.onFalse}
        headChildren={
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Typography variant='h6'>Reserve Shares</Typography>
            <IconButton
              onClick={drawer.onFalse}
            >
              <Iconify icon='mingcute:close-line' width={18}/>
            </IconButton>
          </Stack>
        }
        bodyChildren={
          <Stack gap={3}>
            <Typography variant='subtitle2'>
              How much would you like to invest?
            </Typography>
            <TextField
              value={amount ? fNumber(amount) : ''}
              onChange={e => setAmount(onlyNumbers(e.currentTarget.value))}
              placeholder={`Amount, ${DEFAULT_CURRENCY}`}
              helperText={
                <Stack gap={0.5} direction='row'>
                  <Iconify
                    color={textSecondary}
                    width={16}
                    height={16}
                    icon='eva:info-fill'
                  />
                  <Typography variant='caption' color={textSecondary}>
                    Min {fNumber(minAmount)} {DEFAULT_CURRENCY} | Max {fNumber(maxAmount)} {DEFAULT_CURRENCY}
                  </Typography>
                </Stack>
              }
            />
            <Divider sx={{ borderStyle: 'dashed' }}/>
            <Typography variant='subtitle2'>
              Please confirm that you have viewed the following dislaimer
            </Typography>
            <Typography variant='body2'>
              The information provided on this portal is for informational purposes only and should not be considered as
              investment advice, an offer, or an invitation to buy or sell investment instruments. The site
              administration is not responsible for investment decisions made based on this information, nor for any
              losses related to investment activities. Investing involves risks, including the loss of the invested
              capital. Before making investment decisions, it is recommended to consult with a qualified financial
              advisor.
            </Typography>
            <FormControlLabel
              sx={checkboxWrapSx}
              control={
                <Checkbox
                  checked={viewedDisclaimer}
                  onChange={e =>
                    setViewedDisclaimer(e.currentTarget.checked)
                  }
                  size='medium'
                  color='primary'
                />
              }
              label='I hereby acknowledge that I have read and understood the aforementioned disclaimer'
            />
            <Divider sx={{ borderStyle: 'dashed' }}/>
            <Typography variant='subtitle2'>
              Please review the following acknowledgements
            </Typography>
            <FormControlLabel
              sx={checkboxWrapSx}
              control={
                <Checkbox
                  checked={riskConfirm1}
                  onChange={e =>
                    setRiskConfirm1(e.currentTarget.checked)
                  }
                  size='medium'
                  color='primary'
                />
              }
              label='I understand that there is guarantee of any financial return on this investment and that I run the risk of losing my entire investment'
            />
            <FormControlLabel
              sx={checkboxWrapSx}
              control={
                <Checkbox
                  checked={riskConfirm2}
                  onChange={e =>
                    setRiskConfirm2(e.currentTarget.checked)
                  }
                  size='medium'
                  color='primary'
                />
              }
              label='I have sufficient knowledge and experience in financial and business matters to be capable of evaluating the merits and risks associated with investing in this offering and understand the same'
            />
          </Stack>
        }
        footerChildren={
          <Stack direction='row' gap={2} sx={{ flex: 1 }}>
            <Button
              sx={{ flex: 1 }}
              onClick={drawer.onFalse}
              variant='outlined' size='large'
            >
              Cancel
            </Button>
            <Button
              sx={{ flex: 1 }}
              onClick={onReserveClick}
              disabled={!isValid}
              variant='contained'
              size='large'
            >
              Reserve Shares
            </Button>
          </Stack>
        }
      />

      <ReserveSharesSuccessDrawer drawer={successDrawer}/>
    </>
  );
};
