import { useSelector } from 'react-redux';
import { CardReportItem } from 'src/features/report-detail';
import { useGetDebtChainedItems, useGetProjectSummaryChainedItems, useGetQuotationChainedItems } from 'src/hooks';
import { RootState } from 'src/store';
import { fDate } from 'src/utils';

import { DebtDetailsType, ReportKeyType, ReportSettingsType } from '../../../../../../../types';

type OverviewInfoAboutProps = {
  type: ReportKeyType;
  detailsList?: DebtDetailsType;
  isSettingExist?: ReportSettingsType;
}

export const OverviewInfoAbout = (props: OverviewInfoAboutProps) => {
  const {
    type,
    detailsList,
    isSettingExist,
  } = props;
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);

  const firstPreliminaryQuotation = detailsData.quotation;
  const firstPreliminaryRequest = detailsData.debt;
  const assets = detailsData.assets;
  const project = detailsData.project;
  const lead = detailsData.lead;
  const scenario = project?.scenario;

  const isSummaryActive = isSettingExist?.sections?.find(section => section.section === 'Project Summary');
  const isRequestActive = isSettingExist?.sections?.find(section => section.section === 'Our Request');
  const isBank = type === 'bank' && isRequestActive?.isActive;
  const isSupplier = type === 'supplier' && isRequestActive?.isActive;
  const projectSummaryChainedItems = useGetProjectSummaryChainedItems(scenario, lead);
  const quotationChainedItems = useGetQuotationChainedItems(firstPreliminaryQuotation, scenario);
  const debtChainedItems = useGetDebtChainedItems(scenario, firstPreliminaryRequest, detailsList);

  return (
    <>
      {isSummaryActive?.isActive &&
        <CardReportItem
          title='Project Summary'
          chainedItems={projectSummaryChainedItems}
          postChainedSuTitle={assets?.marketPlaceListing?.description}
        />
      }

      {isBank &&
        <CardReportItem
          title='Preliminary Debt Requests'
          suTitle='Construction credit for a new production project with good profitability.'
          chainedItems={debtChainedItems}
          date={fDate(firstPreliminaryRequest?.updatedAt)}
        />
      }
      {isSupplier &&
        <CardReportItem
          title='Preliminary Quotation Request'
          suTitle='Det färdigställda projektet, som uppförs i totalt två etapper om 27 bostäder,
            har ett beräknat
            intäktsvärde om ca 59,6 mkr med en belåningsgrad om ca 60% (LTGDV).'
          chainedItems={quotationChainedItems}
        />
      }
    </>
  );
};
