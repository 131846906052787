/* eslint-disable unicorn/prefer-query-selector */

export const scrollToSection = (id: string, offset?: number) => {
  const el = document.getElementById(id);
  if (!el) return;

  const top = el.getBoundingClientRect().top + window.pageYOffset + (offset || 0);
  window.scrollTo({
    top,
    behavior: 'smooth',
  });
};
