import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { ApexOptions } from 'apexcharts';
import { Chart, useChart } from 'src/components/chart';

export type ChartTypeForCardBar = {
  labels: string[];
  colors?: string[];
  series: {
    fill?: string;
    type?: string;
    name: string;
    data: number[];
  }[];
  options?: ApexOptions;
};

export interface CardBarChartProps {
  chart: ChartTypeForCardBar;
  subheader?: string;
  title: string;
}

export const CardBarChart = (props: CardBarChartProps) => {
  const {
    title,
    subheader,
    chart,
  } = props;

  const {
    labels,
    colors = ['#266D7D', '#00B8D9'],
    series,
    options,
  } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: series.map((i) => i.fill) as string[] },
    labels,
    xaxis: { type: 'datetime' || 'numeric' },
    tooltip: {
      shared: true,
      intersect: false,
    },
    ...options,
  });

  return (
    <Card>
      <CardHeader title={title} subheader={subheader}/>
      <Box
        sx={{
          p: 3,
          pb: 1,
        }}
      >
        <Chart
          dir='ltr'
          type='line'
          series={series}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
};
