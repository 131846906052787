import { useParams } from 'react-router-dom';

import { SupplierProductForm } from './supplier-product-form';

const NewSupplierProduct = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <SupplierProductForm partnerId={id}/>
  );
};

export default NewSupplierProduct;
