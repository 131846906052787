import { Asset, FinancialType, Lead, QuotationType } from '../../../types';


export type GetCompletedProjectStepsParams = {
  lead?: Lead;
  assets?: Asset;
  tendering?: QuotationType[];
  financing?: FinancialType;
}

export type OrderStepKey = 'pp' | 'suppliers' | 'debt' | 'equity' | 'sell'
export type OrderItemType = {
  orderNumber: number;
  isCompleted: boolean;
  isChecked: boolean;
}

export const isPrevStepsCompleted = (currentStep: OrderStepKey, params: GetCompletedProjectStepsParams) => {
  const {
    isEquityCompleted,
    isTenderingCompleted,
    isDebtCompleted,
    isProjectPreparationCompleted,
  } = getCompletedProjectSteps(params);
  const { lead } = params;
  const distribution = lead?.distribution;
  const order: Record<OrderStepKey, OrderItemType> = {
    pp: {
      orderNumber: 0,
      isCompleted: isProjectPreparationCompleted,
      isChecked: true,
    },
    suppliers: {
      orderNumber: 1,
      isCompleted: isTenderingCompleted,
      isChecked: !!distribution?.tendering,
    },
    debt: {
      orderNumber: 2,
      isCompleted: isDebtCompleted,
      isChecked: !!distribution?.debtFinancing,
    },
    equity: {
      orderNumber: 3,
      isCompleted: isEquityCompleted,
      isChecked: !!distribution?.equityFinancing,
    },
    sell: {
      orderNumber: 4,
      isCompleted: false,
      isChecked: !!distribution?.sell,
    },
  };
  const currentItem = order[currentStep];

  for (let i = 0; i < currentItem.orderNumber; i++) {
    const prevStep = Object.values(order)[i];
    if (prevStep.isChecked && !prevStep.isCompleted) {
      return false;
    }
  }

  return true;
};

export const getCompletedProjectSteps = (params: GetCompletedProjectStepsParams) => {
  const {
    lead,
    assets,
    tendering,
    financing,
  } = params;
  const distribution = lead?.distribution;

  const isProjectPreparationCompleted = Boolean(assets?.general?.projectName
    && assets?.general.offeringClosingDate
    && assets?.marketPlaceListing?.coverImages?.length || 0 > 0
    && assets?.marketPlaceListing?.description?.length || 0 > 0
    && (distribution?.tendering || distribution?.debtFinancing
      || distribution?.equityFinancing || distribution?.sell
    ),
  );

  const isTenderingCompleted = Boolean(tendering?.find(item => item.status === 'Term Sheet'));
  const isDebtCompleted = Boolean(financing?.termSheets?.find(item => item.status === 'Signed'));
  const isEquityCompleted = true;

  return {
    isProjectPreparationCompleted,
    isTenderingCompleted,
    isDebtCompleted,
    isEquityCompleted,
  };
};
