import Card from '@mui/material/Card';
import { useMemo } from 'react';
import { generateSummaryField, ItemType, useScenarioCalculation } from 'src/components';
import { CardReportItem, ChainedItemType } from 'src/features/report-detail';
import { fNumberWithDefaultArea } from 'src/utils';

import { DetailViewData } from '../../../../../../../../../../types';

export interface OverviewInfoAboutProps {
  detailsData?: DetailViewData;
}

export const OverviewInfoAbout = (props: OverviewInfoAboutProps) => {
  const { detailsData = {} } = props;
  const scenario = detailsData.project?.scenario;
  const assets = detailsData.assets;
  const lead = detailsData.lead;
  const output = scenario?.calculate?.output;
  const { units } = useScenarioCalculation(scenario);

  const fields: ItemType[] = useMemo(() => {
    return generateSummaryField({
      type: output?.['Project type'],
      size: fNumberWithDefaultArea(output?.['Total floor area']),
      location: lead?.location?.placeName,
      units: units,
    });
  }, [lead?.location?.placeName, output, units]);

  const chainedItems: ChainedItemType[] = fields.map(field => {
    return {
      ...field,
      title: field.label,
      value: String(field.value),
    };
  });

  return (
    <Card>
      <CardReportItem
        title='About Project'
        chainedItems={chainedItems}
        postChainedSuTitle={assets?.marketPlaceListing?.description}
        tags={assets?.marketPlaceListing?.tags}
      />
    </Card>
  );
};
