import { useCallback, useEffect, useState } from 'react';


export function useLocalStorage<T, >(key: string, initialState: T) {
  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    const restored = getStorage(key);

    if (restored) {
      setState((prevValue) => ({
        ...prevValue,
        ...restored,
      }));
    }
  }, [key]);

  // eslint-disable-next-line
  const updateState = useCallback((updateValue: any) => {
    setState((prevValue) => {
      setStorage(key, {
        ...prevValue,
        ...updateValue,
      });

      return {
        ...prevValue,
        ...updateValue,
      };
    });
  }, [key]);

  // eslint-disable-next-line
  const update = useCallback((name: string, updateValue: any) => {
    updateState({ [name]: updateValue });
  }, [updateState]);

  const reset = useCallback(() => {
    removeStorage(key);
    setState(initialState);
  }, [initialState, key]);

  return {
    state,
    update,
    reset,
  };
}

export const getStorage = (key: string) => {
  let value = null;

  try {
    const result = window.localStorage.getItem(key);

    if (result) {
      value = JSON.parse(result);
    }
  } catch (error) {
    console.error(error);
  }

  return value;
};

export const setStorage = <T, >(key: string, value: T) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
};

export const removeStorage = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};
