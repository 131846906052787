import _ from 'lodash';
import { useEffect } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';

export const useScrollToError = <T extends FieldValues>(errors: FieldErrors<T> | null) => {
  useEffect(() => {
    if (!errors) return;
    const elements = Object.keys(errors).map(name => {
      const errorValue = _.get(errors, name);
      if (errorValue) {
        let elementName = '';
        if (Object.hasOwn(errorValue, 'ref')) {
          // eslint-disable-next-line
          elementName = (errorValue?.ref as any)?.name;
        } else {
          elementName = Object.values(errorValue)[0]?.ref?.name;
        }
        return document.getElementsByName(elementName)[0];
      }
    }).filter(el => !!el);
    const element = elements[0];
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [errors]);
};
