import { Button, Stack, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { StackProps } from '@mui/material/Stack';

export interface NoDataProps {
  title: string;
  subTitle: string;
  btnTitle: string;
  btnConfig: ButtonProps;
  stackConfig?: StackProps;
}

export const NoData = (props: NoDataProps) => {
  const {
    btnConfig,
    title,
    btnTitle,
    subTitle,
    stackConfig,
  } = props;
  return (
    <Stack
      alignItems='center'
      m='auto'
      {...stackConfig}
    >
      <Typography
        sx={{ textAlign: 'center' }}
        variant='h4'
      >
        {title}
      </Typography>
      <Typography
        sx={{ mt: 2, textAlign: 'center' }}
        variant='body1'
      >
        {subTitle}
      </Typography>
      <Button
        sx={{ mt: 5 }}
        variant='contained'
        fullWidth={false}
        {...btnConfig}
      >
        {btnTitle}
      </Button>
    </Stack>
  );
};
