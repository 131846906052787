import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { ChangeEvent, useState } from 'react';
import { DocumentFileGreyIcon } from 'src/assets/svg';
import { UploadedFilesType } from 'src/components';
import { useBoolean } from 'src/hooks';

import { FinanceUploadFileType } from '../../../../../../../types';
import { AddDocuments } from './add-documents/add-documents';
import { ProjectDocument } from './project-document';

interface ProjectDocumentsProps {
  uploadedDocuments?: UploadedFilesType[];
  onAddDocumentsClickHandler?: (docs: UploadedFilesType[], missingDocs?: UploadedFilesType[]) => void;
  documentsModalList?: UploadedFilesType[];
  subheader?: string;
  subTitle?: string;
  showCheckbox?: boolean;
  checkboxHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  modalUploadFileHandler: (file: FinanceUploadFileType) => void;
  onRemoveUploadedFileClickHandler?: (file: UploadedFilesType) => void;
  allowToDownloadDefault?: boolean;
}

export const ProjectDocuments = (props: ProjectDocumentsProps) => {
  const {
    uploadedDocuments = [],
    documentsModalList = [],
    subheader,
    subTitle,
    showCheckbox,
    checkboxHandler,
    modalUploadFileHandler,
    onAddDocumentsClickHandler,
    onRemoveUploadedFileClickHandler,
    allowToDownloadDefault = false,
  } = props;
  const theme = useTheme();

  const [allowToDownload, setAllowToDownload] = useState(allowToDownloadDefault);

  const isEmpty = uploadedDocuments.length <= 0;

  const onRemoveClick = (doc: UploadedFilesType) => {
    onRemoveUploadedFileClickHandler?.(doc);
  };

  // Add
  const drawerBool = useBoolean();
  const onAddClick = () => {
    drawerBool.onTrue();
  };

  const onAddDocuments = (docs: UploadedFilesType[]) => {
    const missingDocs = _.difference(uploadedDocuments, docs);
    const filteredDocs = docs.filter(doc => !uploadedDocuments.find(uploadedDoc => uploadedDoc._id === doc._id));
    drawerBool.onFalse();
    onAddDocumentsClickHandler?.(filteredDocs, missingDocs);
  };

  const checkboxOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    checkboxHandler?.(event);
    setAllowToDownload(value);
  };

  return (
    <>
      <Card>
        {isEmpty ?
          <CardContent>
            <Stack gap={3} textAlign='center' alignItems='center'>
              <Stack
                gap={2}
                textAlign='center'
                alignItems='center'
                justifyContent='center'
              >
                <DocumentFileGreyIcon width={64} height={64}/>
                <Typography variant='h6'>No documents</Typography>
                {subTitle &&
                    <Typography variant='body2' color={theme.palette.text.secondary}>
                      {subTitle}
                    </Typography>
                }
              </Stack>

              <Button onClick={onAddClick} variant='contained' size='medium'>
                Add Documents
              </Button>
            </Stack>
          </CardContent> :
          <>
            <CardHeader
              title='Documents'
              subheader={subheader}
              sx={{ '.MuiCardHeader-action': { m: 0 } }}
              action={
                <Button onClick={onAddClick} variant='contained' size='medium'>
                  Add Documents
                </Button>
              }
            />
            <CardContent>
              <Stack gap={3}>
                <Stack gap={1} sx={{ mt: 2.5 }}>
                  {uploadedDocuments.map(doc =>
                    <ProjectDocument
                      key={doc._id}
                      document={doc}
                      onRemoveClick={onRemoveClick}
                    />,
                  )}
                </Stack>
                {showCheckbox &&
                    <FormControlLabel
                      sx={{ m: 0 }}
                      control={
                        <Checkbox
                          checked={allowToDownload}
                          onChange={checkboxOnChangeHandler}
                          size='medium'
                          color='primary'
                        />
                      }
                      label='Allow potential investors to download the documents'
                    />
                }
              </Stack>
            </CardContent>
          </>
        }
      </Card>

      <AddDocuments
        alreadySelectedDocs={uploadedDocuments}
        modalUploadFileHandler={modalUploadFileHandler}
        drawer={drawerBool}
        documentsList={documentsModalList}
        onAdd={onAddDocuments}
      />
    </>
  );
};
