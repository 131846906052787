import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Iconify } from 'src/components';
import { paths } from 'src/config';
import { UseBooleanReturnType, useRouter } from 'src/hooks';

type ReserveSharesSuccessDrawerProps = {
  drawer: UseBooleanReturnType;
};

export const ReserveSharesSuccessDrawer = (props: ReserveSharesSuccessDrawerProps) => {
  const { drawer } = props;
  const theme = useTheme();
  const router = useRouter();

  const onMarketplaceClick = () => {
    drawer.onFalse();
    router.push(paths.investorPortal.projects.list);
  };

  return (
    <Dialog open={drawer.value} onClose={drawer.onFalse}>
      <DialogContent sx={{ width: 400 }}>
        <Stack
          sx={{ mt: 5 }}
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          gap={3}
        >
          <Iconify
            width={64}
            height={64}
            color={theme.palette.success.main}
            icon='solar:diploma-bold-duotone'
          />
          <Typography variant='h4'>
            Shares Reserved
          </Typography>
          <Typography variant='body2' color='text.primary'>
            We thank you for reserving shares. Our manager will contact you in the next few minutes to discuss the next
            steps. We appreciate your interest and are ready to provide all the necessary support in this process.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          size='medium'
          variant='outlined'
          type='button'
          onClick={drawer.onFalse}
          fullWidth
        >
          Close
        </Button>
        <Button
          size='medium'
          variant='contained'
          type='button'
          onClick={onMarketplaceClick}
          fullWidth
        >
          To Marketplace
        </Button>
      </DialogActions>
    </Dialog>
  );
};
