import { List, ListItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchField } from 'src/components';
import { updateSelectedFileInSection } from 'src/store/app';

import { FileNameSectionType } from '../../../../../types';

export interface DrawerBodyProps {
  documentTypes?: FileNameSectionType[];
}

export const DrawerBody = (props: DrawerBodyProps) => {
  const { documentTypes = [] } = props;
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearchFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleCheckboxOnClick = (id: string) => () => {
    dispatch(updateSelectedFileInSection({
      section: 'Financing',
      _id: id,
    }));
  };

  const endIconOnClickHandler = () => {
    setSearchValue('');
  };

  const filteredDocumentTypes = documentTypes.filter((docType) =>
    docType.filename.includes(searchValue),
  );

  return (
    <>
      <SearchField
        showEndIcon={Boolean(searchValue)}
        endIconOnClickHandler={endIconOnClickHandler}
        value={searchValue}
        handleFieldOnChange={handleSearchFieldOnChange}
      />
      <List sx={{ pt: 2.5 }}>
        {filteredDocumentTypes.map((documentType) => (
          <ListItem
            key={documentType._id}
            sx={{
              ml: -1,
              pl: 0,
            }}
          >
            <Checkbox
              disableRipple
              size='small'
              checked={documentType.selected}
              onClick={handleCheckboxOnClick(documentType._id)}
            />
            {documentType.filename}
          </ListItem>
        ))}
      </List>
    </>
  );
};
