import Collapse from '@mui/material/Collapse';
import { useCallback, useEffect, useState } from 'react';
import { useActiveLink, usePathname } from 'src/hooks';

import { NavConfigProps, NavListProps } from '../../types';
import { NavItemVertical } from './nav-item-vertical';

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  disabled?: boolean;
  config: NavConfigProps;
};

export const NavListVertical = (props: NavListRootProps) => {
  const {
    data,
    depth,
    hasChild,
    disabled,
    config,
  } = props;

  const pathname = usePathname();
  const active = useActiveLink(data.path);
  const [open, setOpen] = useState(active);

  const externalLink = data.path.includes('http');

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    //   eslint-disable-next-line
  }, [pathname]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <NavItemVertical
        item={data}
        depth={depth}
        open={open}
        active={active}
        disabled={disabled}
        externalLink={externalLink}
        onClick={handleToggle}
        config={config}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList disabled={data.disabled} data={data.children} depth={depth} config={config}/>
        </Collapse>
      )}
    </>
  );
};

type NavListSubProps = {
  data?: NavListProps[];
  depth: number;
  disabled?: boolean;
  config: NavConfigProps;
};

const NavSubList = (props: NavListSubProps) => {
  const {
    data,
    depth,
    config,
  } = props;
  return (
    <>
      {data?.map((list) => (
        <NavListVertical
          key={list.title + list.path}
          data={list}
          disabled={list.disabled}
          depth={depth + 1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
};
