import _ from 'lodash';
import { TableProps } from 'src/hooks';

import { getComparator } from './comparators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortCustom = (data: any[], table: TableProps) => {
  const stabilizedThis = data?.map((el, index) => [el, index] as const);
  stabilizedThis?.sort((a, b) => {
    const order = getComparator(table.order, table.orderBy)(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
};

export const customizer = <T, >(objValue: T, srcValue: T): T => {
  if (_.isObject(objValue)) {
    return _.mergeWith(objValue, srcValue, customizer);
  }
  return _.isUndefined(objValue) ? srcValue : objValue;
};
