import { TabContext, TabPanel } from '@mui/lab';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CustomTab, CustomTabProps } from '../custom-tab';

interface TabContextCustomProps extends CustomTabProps {
  tabPanels: TabPanelsType[];
  hideTabs?: boolean;
  tabPanelsSx?: SxProps<Theme>;
}

export type TabPanelsType = {
  tabKey: string;
  component: ReactNode;
}

export const TabContextCustom = (props: TabContextCustomProps) => {
  const {
    hideTabs,
    defaultTabValue,
    currentTabValue,
    handleTabOnChange,
    tabs,
    tabPanels,
    tabsSX,
    tabPanelsSx,
  } = props;

  return (
    <TabContext value={currentTabValue}>
      {!hideTabs &&
          <CustomTab
            tabs={tabs}
            tabsSX={tabsSX}
            defaultTabValue={defaultTabValue}
            currentTabValue={currentTabValue}
            handleTabOnChange={handleTabOnChange}
          />
      }

      {tabPanels.map((tabPanel) => {
        return (
          <TabPanel
            key={tabPanel.tabKey}
            value={tabPanel.tabKey}
            sx={{
              px: 3,
              pt: 0,
              mt: hideTabs ? 0 : 5,
              ...tabPanelsSx,
            }}
          >
            {tabPanel.component}
          </TabPanel>
        );
      })}
    </TabContext>
  );
};
