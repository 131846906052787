import { ReactElement } from 'react';
import {
  AiFileIcon,
  AudioFileIcon,
  ExcelFileIcon,
  ImgFileIcon,
  NoFileIcon,
  PdfFileIcon,
  PowerPointFileIcon,
  PtsFileIcon,
  TxtFileIcon,
  VideoFileIcon,
  WordFileIcon,
  ZipFileIcon,
} from 'src/assets/svg';

import { FileType } from '../../../../types';

export type FileConfigType = {
  [key in string]: {
    icon: ReactElement
    type: FileType
  };
}

export const fileConfig: FileConfigType = {
  'pdf': {
    icon: <PdfFileIcon/>,
    type: 'Documents',
  },
  'txt|text/plain': {
    icon: <TxtFileIcon/>,
    type: 'Documents',
  },
  'psd': {
    icon: <PtsFileIcon/>,
    type: 'Photos',
  },
  'xls|xlsx|excel|sheet': {
    icon: <ExcelFileIcon/>,
    type: 'Documents',
  },
  'zip|rar|iso': {
    icon: <ZipFileIcon/>,
    type: 'Documents',
  },
  'ai|eps': {
    icon: <AiFileIcon/>,
    type: 'Photos',
  },
  'ppt|pptx|presentation': {
    icon: <PowerPointFileIcon/>,
    type: 'Documents',
  },
  'wav|aif|mp3|aac': {
    icon: <AudioFileIcon/>,
    type: 'Documents',
  },
  'jpg|jpeg|gif|bmp|png|svg|image': {
    icon: <ImgFileIcon/>,
    type: 'Photos',
  },
  'm4v|avi|mpg|mp4|webm': {
    icon: <VideoFileIcon/>,
    type: 'Photos',
  },
  'doc|docx': {
    icon: <WordFileIcon/>,
    type: 'Documents',
  },
  '': {
    icon: <NoFileIcon/>,
    type: 'Photos',
  },
};

export const getFileConfig = (fileType: string = '') => {
  for (const config in fileConfig) {
    const extensions = config.split('|');
    if (extensions.some(ext => fileType.includes(ext))) {
      return fileConfig[config];
    }
  }
  return fileConfig[''];
};
