import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UploadCloudIcon } from 'src/assets';
import { CustomSelect, FileModal, SearchField, TableCustom } from 'src/components';
import { sectionsList } from 'src/constants/constants';
import { useBoolean, useDocuments, useTable } from 'src/hooks';
import { RootState } from 'src/store';
import { getFileSections } from 'src/store/app';
import { deleteDocument, uploadDocument } from 'src/store/financial';
import { deleteFiles } from 'src/store/lead';
import { getAllFiles } from 'src/utils';

import { FinanceUploadFileType, MarketplaceProjectFile } from '../../../../../../../../types';
import { MARKETPLACE_PROJECT_FILES_TABLE_HEAD } from '../../mock-data';
import { FilesTableRow } from './files-table-row';

export interface FilesProps {
  customFiles?: MarketplaceProjectFile[];
  useCustomFiles?: boolean;
}

export const Files = (props: FilesProps) => {
  const {
    customFiles = [],
    useCustomFiles,
  } = props;
  const [tableData, setTableData] = useState<MarketplaceProjectFile[]>([]);
  const [filesFromDb, setFilesFromDb] = useState<MarketplaceProjectFile[]>([]);
  const [section, setSection] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const table = useTable();
  const fileModalBool = useBoolean();
  const dispatch = useDispatch();

  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const fileSections = useSelector((state: RootState) => state.App.fileSections);
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);
  const { createAndUploadDocument } = useDocuments();

  const loadFiles = useCallback(async () => {
    const files = getAllFiles(fileSections);
    const updatedFiles: MarketplaceProjectFile[] = files.map((file) => {
      return {
        ...file,
        uploadingFileType: file.filetype,
      };
    });
    setFilesFromDb(useCustomFiles ? customFiles : updatedFiles);
    setTableData(useCustomFiles ? customFiles : updatedFiles);
  }, [customFiles, fileSections, useCustomFiles]);

  useEffect(() => {
    loadFiles();
  }, [loadFiles]);

  const noDataFound = tableData?.length === 0;

  const onSectionChange = (e: SelectChangeEvent<string[]>) => {
    const value = e.target.value as string;
    setSection(value);
    setTableData(value ? filesFromDb.filter(item => item.section === value) : filesFromDb);
  };

  const handleDeleteRow = useCallback((fileToDelete: MarketplaceProjectFile) => () => {
    fileToDelete.isMiscellaneous
      ? deleteFiles([{ _id: fileToDelete._id }])
      : deleteDocument(fileToDelete._id);

    setTableData(prev => prev.filter(file => file._id !== fileToDelete._id));
  }, []);

  const onUploadClickHandler = useCallback((item: MarketplaceProjectFile) => async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const file = files[0];
    if (file) {
      await uploadDocument({
        ...file,
        _id: item._id,
        type: 'Documents',
        filename: file.name,
      }, isDemo);
    }
    dispatch(getFileSections(detailsData.lead?._id, detailsData?.project?._id));
  }, [detailsData.lead?._id, detailsData?.project?._id, dispatch, isDemo]);

  const modalUploadFileHandler = async (file: FinanceUploadFileType) => {
    await createAndUploadDocument(file, detailsData.project?._id, detailsData.lead?._id);
    dispatch(getFileSections(detailsData.lead?._id, detailsData?.project?._id));
    setSection('');
  };

  const openFileModalHandler = () => {
    fileModalBool.onTrue();
  };

  const handleSearchOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearch(newValue);
    filterFilesDebounce(newValue);
  };

  const filterFiles = useCallback((filterValue: string) => {
    const filteredFiles = filesFromDb.filter(item => {
      const filename = item.filename?.toLowerCase();
      const uploadedAt = item.uploadedAt?.toLowerCase() || '';
      const uploadedBy = item.uploadedBy?.toLowerCase() || '';
      const requestedAt = item.requestedAt?.toLowerCase() || '';
      const requestedBy = item.requestedBy?.toLowerCase() || '';
      const size = item.size?.toString().toLowerCase() || '';
      const section = item.section?.toLowerCase() || '';
      const loweredFilterValue = filterValue?.toLowerCase();
      return filename?.includes(loweredFilterValue)
        || uploadedBy?.includes(loweredFilterValue)
        || requestedAt?.includes(loweredFilterValue)
        || requestedBy?.includes(loweredFilterValue)
        || requestedBy?.includes(loweredFilterValue)
        || uploadedAt?.includes(loweredFilterValue)
        || size?.includes(loweredFilterValue)
        || section?.includes(loweredFilterValue);
    });
    setTableData(filteredFiles);
  }, [filesFromDb]);

  const filterFilesDebounce = useMemo(() => {
    return debounce(filterFiles, 500);
  }, [filterFiles]);

  return (
    <Stack gap={3}>
      <Stack direction='row' gap={2}>
        <Box
          sx={{
            flex: 1,
            maxWidth: 260,
          }}
        >
          <SearchField
            value={search}
            inputSx={{ width: 260 }}
            handleFieldOnChange={handleSearchOnChangeHandler}
            isFullWidth
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            maxWidth: 248,
          }}
        >
          <CustomSelect
            controlSx={{ width: '100%' }}
            value={section ? [section] : []}
            handleSelectOnChange={onSectionChange}
            label='Section'
            options={['', ...sectionsList]}
          />
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <Button
            startIcon={<UploadCloudIcon/>}
            variant='outlined'
            size='small'
            onClick={openFileModalHandler}
          >
            Upload
          </Button>
        </Box>
      </Stack>

      <TableCustom
        scrollbar={false}
        separateRows
        headLabels={MARKETPLACE_PROJECT_FILES_TABLE_HEAD}
        table={table}
        rowCount={tableData?.length}
        noDataFound={noDataFound}
        bodyCells={(
          <>
            {tableData?.map((row) => (
              <FilesTableRow
                key={row._id}
                row={row}
                onDeleteRow={handleDeleteRow(row)}
                onUploadClickHandler={onUploadClickHandler(row)}
              />
            ))}
          </>
        )}
      />

      <FileModal
        openModal={fileModalBool.value}
        modalUploadFileHandler={modalUploadFileHandler}
        onClose={fileModalBool.onFalse}
      />
    </Stack>
  );
};
