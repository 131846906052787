import { Button, Link, List, SelectChangeEvent, Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import _ from 'lodash';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDialog, CustomSelect, ListItemCustom } from 'src/components';
import { paths } from 'src/config';
import { AddPartnerModal, bankCheckboxes, PartnerFormType } from 'src/features/partners-page';
import { useBoolean } from 'src/hooks';
import { RootState } from 'src/store';
import { createPartner } from 'src/store/partners';
import { fPercent } from 'src/utils';

import { BankType, PartnerItemType } from '../../../../../types';
import { DebtName } from '../debt-request';
import { partnersModalTypeOptions } from '../mock-data';

export interface DebtModalProps {
  open: boolean;
  onClose: () => void;
  selectedBanks?: BankType[];
  bankSelectHandler: (selectedBanks: BankType[], debtKey: DebtName, bankId: string) => (event: MouseEvent<HTMLElement>) => void;
  selectAllHandler: (filteredBanks: BankType[], debtKey: DebtName) => () => void;
  banks?: BankType[];
  debtKey: DebtName;
}

export const DebtModal = (props: DebtModalProps) => {
  const {
    open,
    onClose,
    selectedBanks = [],
    banks = [],
    debtKey,
    bankSelectHandler,
    selectAllHandler,
  } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const modalView = useBoolean();
  const dispatch = useDispatch();
  const financialFromStore = useSelector((state: RootState) => state.Financial.financial);
  const inUseUserIds = Object.values(financialFromStore).flat().map(item => item?.bankId);

  const handleFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const endIconOnClickHandler = () => {
    setSearchValue('');
  };

  const banksReplacedWithSelected = _.map(banks, bank => {
    const correspondingElement = _.find(selectedBanks, { '_id': bank._id });
    return correspondingElement || bank;
  });

  const filteredBanks = banksReplacedWithSelected.filter((bank) => {
    const updatedNewType = typeFilter.map(type => type === 'Financing Partners' ? 'Equity Partner' : type);
    return (bank.companyName?.toLowerCase().includes(searchValue.toLowerCase()) &&
      (updatedNewType.length ? updatedNewType.includes(bank.companyType || '') : true));
  });

  const addBankClickHandler = () => {
    modalView.onTrue();
  };

  const saveChangesHandler = (data: PartnerFormType) => {
    const updatedData: PartnerItemType = {
      ...data,
      _id: '',
      companyType: 'Bank',
    };
    dispatch(createPartner(updatedData, 'banks'));
  };

  const handleSelectOnChange = (event: SelectChangeEvent<string[]>) => {
    const newType = event.target.value as string[];
    setTypeFilter(newType);
  };

  return (
    <>
      <CustomDialog
        showCustomHead
        customHeadButtonTitle='Add'
        customHeadBtnProps={{ onClick: addBankClickHandler }}
        customHeadTitle='Banks or Financing Partners'
        customFilters={
          <CustomSelect
            multiple
            showCheckbox
            controlSx={{
              width: {
                xs: 1,
                md: 210,
              },
            }}
            value={typeFilter}
            handleSelectOnChange={handleSelectOnChange}
            label='Type'
            options={partnersModalTypeOptions}
            selectedOptions={typeFilter}
          />
        }
        searchFieldProps={{
          value: searchValue,
          showEndIcon: Boolean(searchValue),
          endIconOnClickHandler: endIconOnClickHandler,
          handleFieldOnChange: handleFieldOnChange,
        }}
        dialogProps={{
          open,
          onClose,
        }}
        dialogContentChildren={
          <>
            <List sx={{ p: 0 }}>
              {filteredBanks.map(bank => {
                const isSelected = selectedBanks.find(selectedBank => selectedBank._id === bank._id)?.selected || false;
                return (
                  <ListItemCustom
                    showBtnIcon
                    showAvatar
                    listItemSx={{
                      cursor: 'pointer',
                      alignItems: 'center',
                    }}
                    secondaryTextTypographySx={{ maxWidth: 300 }}
                    btnContainerSx={{ width: 100 }}
                    key={bank._id}
                    avatarSrc={bank.logoUrl}
                    avatarSx={{ borderRadius: 1.5 }}
                    avatarAlt={bank.companyName}
                    primaryText={bank.companyName}
                    secondaryText={`Fee ${fPercent(bank.fee)}`}
                    onItemClick={bankSelectHandler(banksReplacedWithSelected, debtKey, bank._id)}
                    btnConfig={{ onClick: bankSelectHandler(banksReplacedWithSelected, debtKey, bank._id) }}
                    btnTitle={isSelected ? 'Selected' : 'Select'}
                    iconChangeClause={isSelected}
                    children={
                      <Stack alignSelf='center' mr={1}>
                        {inUseUserIds?.find(userInUse => userInUse === bank._id) &&
                          <Chip size='small' variant='outlined' color='success' label='In use'/>
                        }
                      </Stack>
                    }
                    secondaryChildren={
                      <Link
                        underline='none'
                        target='_blank'
                        href={
                          bank.companyType === 'Bank'
                            ? paths.bankPartners.products(bank._id)
                            : paths.equityPartners.list
                        }
                      >
                        Profile
                      </Link>
                    }
                  />
                );
              })}
            </List>
          </>
        }
        dialogActionsChildren={
          <>
            <Button
              fullWidth
              variant='outlined'
              onClick={selectAllHandler(filteredBanks, debtKey)}
            >
              Select All
            </Button>
          </>
        }
      />
      <AddPartnerModal
        drawerSx={{ zIndex: 2000 }}
        partnerDrawer={modalView}
        saveChangesHandler={saveChangesHandler}
        checkboxes={bankCheckboxes}
        headerLabel='Add bank'
        btnTitle='Save Changes'
      />
    </>
  );
};
