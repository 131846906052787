import { TextFieldProps, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import _, { omit, omitBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DrawerCustom, FormComponent, NoLayout } from 'src/components';
import { ConfigType, LayoutTypes } from 'src/features/new-lead';
import { UseBooleanReturnType } from 'src/hooks';
import { RootState } from 'src/store';
import { updateDebtRequest } from 'src/store/financial';
import { fNumber, limitLength, splitAndCapitalize } from 'src/utils';

import { DebtDetailsType } from '../../../../../types';
import { DebtName, DebtRequestType } from '../debt-request';

export interface EditDrawerProps {
  editDrawer: UseBooleanReturnType;
  currentDebt: DebtName;
  debtId: string;
  sectionId?: string;
  debtDetailsData?: DebtDetailsType;
  updateForm: UseFormSetValue<DebtRequestType>;
}

export const EditDrawer = (props: EditDrawerProps) => {
  const {
    editDrawer,
    currentDebt,
    debtDetailsData,
    updateForm,
    debtId,
    sectionId,
  } = props;
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<DebtDetailsType>();
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);
  const [totalFunds, fee, ltc, ltgdv, rate, profitShare] = watch(['totalFunds', 'fee', 'ltc', 'ltgdv', 'rate', 'profitShare']);

  const onSubmit = (data: DebtDetailsType) => {
    updateForm(`${currentDebt}.detailsList`, data);
    const filteredData = omitBy(data, _.isNil);
    updateDebtRequest(debtId, {
      _id: sectionId,
      ...(omit(filteredData, 'rate')),
      interestRate: data.rate,
    }, isDemo);
    editDrawer.onFalse();
  };

  useEffect(() => {
    reset({
      totalFunds: debtDetailsData?.totalFunds,
      rate: debtDetailsData?.rate,
      fee: debtDetailsData?.fee,
      ltc: debtDetailsData?.ltc,
      ltgdv: debtDetailsData?.ltgdv,
      profitShare: debtDetailsData?.profitShare,
    });
  }, [debtDetailsData?.fee, debtDetailsData?.ltc, debtDetailsData?.ltgdv, debtDetailsData?.profitShare, debtDetailsData?.rate, debtDetailsData?.totalFunds, reset]);

  const editDebtConfig: ConfigType<DebtDetailsType, TextFieldProps, LayoutTypes>[] = useMemo(() => {
    return [
      {
        rightBlock: [
          {
            required: true,
            name: 'totalFunds',
            label: 'Total funds, kr',
            value: fNumber(totalFunds),
            onChange: (event) => {
              setValue('totalFunds', +limitLength(event.target.value));
            },
            sx: { width: 'calc(50% - 8px)' },
            correspondingComponent: TextField,
          },
          {
            required: true,
            name: 'rate',
            label: 'Rate, %',
            sx: { width: 'calc(50% - 8px)' },
            value: fNumber(rate),
            onChange: (event) => {
              setValue('rate', +limitLength(event.target.value));
            },
            correspondingComponent: TextField,
          },
          {
            required: true,
            name: 'fee',
            label: 'Fee, %',
            sx: { width: 'calc(50% - 8px)' },
            value: fNumber(fee),
            onChange: (event) => {
              setValue('fee', +limitLength(event.target.value));
            },
            correspondingComponent: TextField,
          },
          {
            required: true,
            name: 'ltc',
            label: 'LTC, %',
            sx: { width: 'calc(50% - 8px)' },
            value: fNumber(ltc),
            onChange: (event) => {
              setValue('ltc', +limitLength(event.target.value));
            },
            correspondingComponent: TextField,
          },
          {
            required: true,
            name: 'ltgdv',
            label: 'LTGDV, %',
            sx: { width: 'calc(50% - 8px)' },
            value: fNumber(ltgdv),
            onChange: (event) => {
              setValue('ltgdv', +limitLength(event.target.value));
            },
            correspondingComponent: TextField,
          },
          {
            required: true,
            name: 'profitShare',
            label: 'Profit Share',
            sx: { width: 'calc(50% - 8px)' },
            value: fNumber(profitShare),
            onChange: (event) => {
              setValue('profitShare', +limitLength(event.target.value));
            },
            correspondingComponent: TextField,
          },
        ],
        layout: NoLayout,
        stackWidth: 432,
        stackConfig: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          rowGap: 3,
          pl: 0,
        },
      },
    ];
  }, [fee, ltc, ltgdv, profitShare, rate, setValue, totalFunds]);

  return (
    <DrawerCustom
      open={editDrawer.value}
      onCloseDrawerHandler={editDrawer.onFalse}
      headChildren={
        <Typography variant='h6'>{splitAndCapitalize(currentDebt)}</Typography>
      }
      bodyChildren={
        <>
          <Typography variant='subtitle2'>Debt Request</Typography>
          <FormComponent
            onSubmitHandler={handleSubmit(onSubmit)}
            formConfig={editDebtConfig}
            btnTitle='Save Changes'
            control={control}
            formStackProps={{
              p: 0,
              height: '100%',
              justifyContent: 'space-between',
            }}
            configStackWrapperProps={{ p: 0 }}
            btnConfig={{
              fullWidth: true,
              variant: 'outlined',
            }}
          />
        </>
      }
      bodyChildrenSx={{
        gap: 3,
        height: '100%',
      }}
    />
  );
};
