import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { ChangeEvent, MouseEvent, useCallback, useMemo } from 'react';
import { ConfirmDialog, getFileConfig, Iconify, MoreOptions, OptionType } from 'src/components';
import { UploadButton } from 'src/components/request-file/components/upload-button';
import { BorderedTableRow } from 'src/components/table/bordered-table-row';
import { useBoolean, usePopover } from 'src/hooks';
import { isCLickOnRow, openInNewTab, showEmptyValue } from 'src/utils';

import { MarketplaceProjectFile } from '../../../../../../../../types';

type FilesTableRowProps = {
  row: MarketplaceProjectFile;
  onDeleteRow: VoidFunction;
  onUploadClickHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FilesTableRow = (props: FilesTableRowProps) => {
  const theme = useTheme();
  const {
    row,
    onDeleteRow,
    onUploadClickHandler,
  } = props;

  const {
    requestedAt,
    requestedBy,
    uploadedBy,
    uploadedAt,
    url,
  } = row;

  const onViewRow = useCallback(() => {
    if (url) {
      openInNewTab(url);
    }
  }, [url]);

  const confirm = useBoolean();
  const popover = usePopover();
  const moreOptions: OptionType[] = useMemo(() => {
    return [
      {
        label: 'View',
        icon: 'view',
        closePopover: popover.onClose,
        itemOnClickHandler: onViewRow,
      },
      {
        label: 'Delete',
        icon: 'delete',
        closePopover: popover.onClose,
        itemOnClickHandler: confirm.onTrue,
        color: 'error.main',
      },
    ];
  }, [confirm.onTrue, onViewRow, popover.onClose]);

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onViewRow();
  };

  return (
    <>
      <BorderedTableRow hover onClick={handleRowOnClick}>
        <TableCell>
          <Stack direction='row' gap={2} alignItems='center'>
            {getFileConfig(row.uploadingFileType || row.filetype).icon}
            <Stack>
              <Typography variant='body2' color='text.primary'>{row.filename || row.sectionFilename}</Typography>
              <Typography
                variant='caption'
                color={theme.palette.text.secondary}
              >
                {row.size} KB
              </Typography>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell>
          <Chip
            size='small'
            variant='soft'
            color='default'
            label={row.section}
          />
        </TableCell>

        <TableCell>
          <Stack gap={0.5}>
            <Typography variant='body2' color='text.primary'>{showEmptyValue(uploadedAt)}</Typography>
            <Typography variant='caption' color={theme.palette.text.secondary}>{showEmptyValue(uploadedBy)}</Typography>
          </Stack>
        </TableCell>

        <TableCell>
          <Stack gap={0.5}>
            <Typography variant='body2' color='text.primary'>{showEmptyValue(requestedAt)}</Typography>
            <Typography variant='caption'
              color={theme.palette.text.secondary}>{showEmptyValue(requestedBy)}</Typography>
          </Stack>
        </TableCell>

        <TableCell align='right'>
          <Stack direction='row' justifyContent='flex-end' alignItems='center'>
            {url ?
              <Button
                onClick={onViewRow}
                startIcon={
                  <Iconify
                    icon='mingcute:download-3-fill'
                    width={18}
                    height={18}
                  />
                }
                variant='outlined'
                size='small'
              >
                Download
              </Button> :
              <UploadButton
                btnTitle='Upload'
                fileInputOnChangeHandler={onUploadClickHandler}
                variant='outlined'
                size='small'
              />
            }

            <MoreOptions
              options={moreOptions}
              popoverOnClose={popover.onClose}
              popoverOnOpen={popover.onOpen}
              popoverOpen={popover.open}
            />
          </Stack>
        </TableCell>
      </BorderedTableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
};
