import { AppBar, IconButton, Stack, Toolbar, useTheme } from '@mui/material';
import { AccountPopover, Logo, NotificationsPopover, SettingsButton, SvgColor } from 'src/components';
import { INVESTOR_PORTAL } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { bgBlur } from 'src/theme/css';

import partnerLogo from './logo.svg';
import { InvestorNav } from './nav';

interface HeaderProps {
  onOpenNav: VoidFunction;
}

export const Header = (props: HeaderProps) => {
  const { onOpenNav } = props;

  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');

  const renderContent = (
    <>
      <Logo
        href='/investor/portfolio'
        icon={<img src={partnerLogo} alt='Pareto'/>}
        sx={{ width: 94, height: 41 }}
      />

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src='/assets/navbar/burger-menu-icon.svg'/>
        </IconButton>
      )}

      {lgUp && <InvestorNav/>}

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        spacing={{
          xs: 0.5,
          sm: 1,
        }}
      >
        <NotificationsPopover/>

        <SettingsButton/>

        <AccountPopover/>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: INVESTOR_PORTAL.HEADER_H,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({ color: theme.palette.background.default }),
        transition: theme.transitions.create(['height'], { duration: theme.transitions.duration.shorter }),
        boxShadow: theme.customShadows.z8,
        ...(lgUp && {
          left: 0,
          right: 0,
          width: '100%',
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { sm: 0 },
          width: '100%',
          margin: '0 auto',
          maxWidth: INVESTOR_PORTAL.MAX_WIDTH,
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};
