import mime from 'mime';

import { CoverImagesType } from '../../../types';

export const getCoverImagesByUrl = async (imageUrls: string[]): Promise<CoverImagesType[]> => Promise.all(imageUrls.map(async (url, i) => {
  const response = await fetch(url);
  const file = await response.blob();
  const fileType = response.headers.get('Content-Type') || 'image/jpg';

  return {
    _id: `defaultImage${i + 1}`,
    url,
    fileType,
    uploadingFileType: fileType,
    filename: `defaultImage${i + 1}.${mime.getExtension(fileType) || 'jpg'}`,
    sequenceNumber: i,
    isCoverImage: i === 0,
    file,
    type: 'Photos',
  };
}));
