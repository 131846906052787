import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';

export interface LinearProgressWithLabelProps extends LinearProgressProps {
  value?: number;
  labelValue?: string | number;
  linearWidth?: number;
  inheritColor?: string;
}

export const LinearProgressWithLabel = (props: LinearProgressWithLabelProps) => {
  const {
    value = 0,
    labelValue,
    inheritColor,
    linearWidth = 162,
  } = props;

  return (
    <Box
      sx={{
        width: linearWidth,
        color: inheritColor,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            mr: 1,
          }}
        >
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant='body2'
            sx={{ color: 'text.secondary' }}
          >
            {labelValue || value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
