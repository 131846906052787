import { CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DetailsList, generateSummaryField, ItemType } from 'src/components';
import { RootState } from 'src/store';
import { fNumberWithDefaultCurrency, fPercent } from 'src/utils';

export const OverviewDetails = () => {
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const output = detailsData.project?.scenario?.calculate?.output;

  const fields: ItemType[] = useMemo(() => {
    return generateSummaryField({
      totalRequiredCapital: fNumberWithDefaultCurrency(detailsData.jvEquity),
      expectedReturns: fNumberWithDefaultCurrency(output?.ROI),
      investmentType: 'Equity',
      partnerFee: fPercent(detailsData.partnerFees),
    });
  }, [detailsData.jvEquity, detailsData.partnerFees, output?.ROI]);

  return (
    <Card>
      <CardHeader title='Proposal Details'/>
      <DetailsList
        fields={fields}
        itemsContainerSx={{
          justifyContent: 'flex-start',
          gap: 3,
        }}
        valueContainerSx={{ whiteSpace: 'nowrap' }}
        labelContainerSx={{
          width: 148,
          fontSize: 14,
          whiteSpace: 'nowrap',
        }}
      />
    </Card>
  );
};
