import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CrossIcon } from 'src/assets';
import { DrawerCustom, FormProvider, RHFTextField } from 'src/components';
import { UseBooleanReturnType } from 'src/hooks';
import { isEmailValid, isPhoneNumberValid } from 'src/utils';
import * as Yup from 'yup';

import { ClientType } from '../../../../../types';

export interface AddClientModalProps {
  modalView: UseBooleanReturnType;
  addClientHandler: (data: ClientType) => void;
}

export const AddClientModal = (props: AddClientModalProps) => {
  const {
    modalView,
    addClientHandler,
  } = props;

  const NewClientSchema: Yup.ObjectSchema<Omit<ClientType, '_id' | 'logoUrl' | 'registeredData' | 'type'>> = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phone: Yup.string().test(
      'phone',
      'Invalid phone number format',
      (value) => value ? isPhoneNumberValid(value) : true,
    ),
    email: Yup.string().required('Email is required').test(
      'email',
      'Email must be a valid email address',
      (value) => isEmailValid(value),
    ),
  });

  const defaultValues = useMemo(() => {
    return {
      firstName: '',
      lastName: '',
      email: '',
    };
  }, []);

  const form = useForm<ClientType>(
    {
      resolver: yupResolver(NewClientSchema),
      defaultValues: defaultValues,
    },
  );

  const {
    reset,
    handleSubmit,
  } = form;

  const resetValues = useMemo(() => {
    return { ...defaultValues };
  }, [defaultValues]);

  useEffect(() => {
    reset(resetValues);
  }, [reset, resetValues]);

  const onSubmit = handleSubmit(async (data: ClientType) => {
    addClientHandler(data);
    closeHandler();
  });

  const closeHandler = () => {
    modalView.onFalse();
    reset(defaultValues);
  };

  return (
    <DrawerCustom
      drawerSx={{ zIndex: 2000 }}
      open={modalView.value}
      onCloseDrawerHandler={closeHandler}
      headChildren={
        <>
          <Typography variant='h6'>New Client</Typography>
          <IconButton onClick={closeHandler}>
            <CrossIcon/>
          </IconButton>
        </>
      }
      bodyChildren={
        <>
          <FormProvider fullHeight methods={form} onSubmit={onSubmit}>
            <Stack gap={3} height='100%'>
              <RHFTextField fullWidth name='firstName' label='* First name'/>
              <RHFTextField fullWidth name='lastName' label='* Last name'/>
              <RHFTextField
                fullWidth
                name='email'
                label='* Email'
                rules={{
                  validate: value => {
                    if (!value) return;
                    return isEmailValid(value) || 'Invalid email format';
                  },
                }}
              />
              <RHFTextField
                fullWidth
                name='phone'
                label='Phone'
                rules={{
                  validate: value => {
                    if (!value) return;
                    return isPhoneNumberValid(value) || 'Invalid phone number format';
                  },
                }}
              />
              <Button type='submit' fullWidth variant='contained' sx={{ mt: 'auto' }}>
                Add
              </Button>
            </Stack>
          </FormProvider>
        </>
      }
      bodyChildrenSx={{ height: '100%' }}
    />
  );
};
