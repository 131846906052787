import { MouseEvent } from 'react';

export const isCLickOnRow = (event: MouseEvent<HTMLTableRowElement>) => {
  const element = event.target as HTMLElement;
  const partnerElement = element.parentElement;
  return element.tagName === 'TD'
    || (element.tagName === 'DIV' && !partnerElement?.classList.contains('MuiModal-root'))
    || (element.tagName === 'DIV' && !element?.classList.contains('MuiBackdrop-root'))
    || element.tagName === 'P'
    || element.tagName === 'SPAN';
};
