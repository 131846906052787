import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { MouseEvent } from 'react';
import { ConfirmDialog, ListItemCustom, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useBoolean, usePopover } from 'src/hooks';
import { fNumber, fPercent, isCLickOnRow } from 'src/utils';

import { BankProductType } from '../../../../../../types';

export interface BankProductTableRowProps {
  item: BankProductType;
  onDeleteRowHandler: VoidFunction;
  onRowClickHandler: VoidFunction;
}

export const BankProductTableRow = (props: BankProductTableRowProps) => {
  const {
    item,
    onRowClickHandler,
    onDeleteRowHandler,
  } = props;
  const {
    name,
    minLoan,
    maxLoan,
    maxLtgdv,
    monthlyRateStartingFrom,
    subType,
  } = item;

  const confirm = useBoolean();
  const popover = usePopover();

  const moreOptions: OptionType[] = [
    {
      label: 'Edit',
      icon: 'edit',
      closePopover: popover.onClose,
      itemOnClickHandler: onRowClickHandler,
    },
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onRowClickHandler();
  };

  const onDeleteClickHandlerWrapper = () => {
    confirm.onFalse();
    onDeleteRowHandler();
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell>
          <ListItemCustom
            listItemTextProps={{
              primaryTypographyProps: {
                whiteSpace: 'nowrap',
                variant: 'body2',
              },
            }}
            secondaryTextTypographySx={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
            primaryText={name}
            secondaryText={subType}
          />
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {fPercent(monthlyRateStartingFrom)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {fNumber(minLoan)} {DEFAULT_CURRENCY}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {fNumber(maxLoan)} {DEFAULT_CURRENCY}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {fPercent(maxLtgdv)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {fPercent(maxLtgdv)}
          </Typography>
        </TableCell>

        <TableCell
          align='right'
          size='small'
          padding='none'
          sx={{ px: 1 }}
        >
          <MoreOptions
            options={moreOptions}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title='Delete'
          content='Are you sure want to delete?'
          action={
            <Button variant='contained' color='error' onClick={onDeleteClickHandlerWrapper}>
              Delete
            </Button>
          }
        />
      </StyledTableRow>
    </>
  );
};
