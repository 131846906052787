import { Button, Divider, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FolderMailIcon, NoDocumentIcon } from 'src/assets/svg';
import { InfoModal } from 'src/features/debt-request';
import { useBoolean } from 'src/hooks';
import { RootState } from 'src/store';
import { addFileSectionThunk, resetSelectedSections } from 'src/store/app';
import { createDocument, deleteDocument, uploadDocument } from 'src/store/financial';
import { createBlobFile, isEmailValid } from 'src/utils';

import { ChipColorsType, DocumentListBadgeType, FileNameSectionType, FinanceUploadFileType } from '../../../../types';
import { DrawerCustom } from '../drawer-custom';
import { ModalCustom } from '../modal';
import { DocumentsList, DrawerBody, DrawerFooter, DrawerHead } from './components';

export type RequestFileProps = {
  title?: string;
  subTitle?: string;
  requestBtnTitle?: string;
  debtRequestId: string;
  openDrawer: boolean;
  showRequestAbility?: boolean;
  setFormValue: (files: FinanceUploadFileType[]) => void;
  documentTypes: FileNameSectionType[];
  selectedDocumentTypes?: FinanceUploadFileType[];
  toggleOpenDrawer: VoidFunction;
  badgeTitleForNew?: DocumentListBadgeType;
  badgeColorForNew?: ChipColorsType;
  badgeColorForUploaded?: ChipColorsType;
  uploadedDocuments?: FinanceUploadFileType[];
}

export const RequestFile = (props: RequestFileProps) => {
  const {
    subTitle,
    requestBtnTitle,
    debtRequestId,
    showRequestAbility = true,
    title,
    setFormValue,
    toggleOpenDrawer,
    selectedDocumentTypes = [],
    uploadedDocuments = [],
    documentTypes,
    openDrawer,
    badgeColorForUploaded,
    badgeTitleForNew,
    badgeColorForNew,
  } = props;
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [textFieldError, setTextFieldError] = useState<boolean>(false);
  const [newDocumentTypeValue, setNewDocumentTypeValue] = useState<string>('');
  const {
    value,
    onTrue,
    onFalse,
  } = useBoolean();
  const newDocument = useBoolean();
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);
  const dispatch = useDispatch();

  const drawerRequestOnClickHandler = async () => {
    if (!textFieldValue || !isEmailValid(textFieldValue)) {
      setTextFieldError(true);
      return;
    }

    const newSelectedDocumentTypes: FinanceUploadFileType[] = documentTypes.reduce((acc: FinanceUploadFileType[], currentEl) => {
      if (currentEl.selected) {
        const additionalConfig = badgeTitleForNew
          ? {
            badgeTitle: badgeTitleForNew,
            badgeColor: badgeColorForNew,
            badgeDisabled: true,
          }
          : {};
        return [...acc, { ...currentEl, ...additionalConfig }];
      }
      return acc;
    }, []);

    const selectedIds = selectedDocumentTypes.map(item => item._id);
    const filteredNewDocuments = newSelectedDocumentTypes.filter(item => !selectedIds.includes(item._id));
    if (filteredNewDocuments.length !== 0) {
      const documentIds = await createDocument({
        debtRequestId,
        email: textFieldValue,
        sectionIds: filteredNewDocuments.map(item => item._id),
      }, isDemo);
      _.forEach(newSelectedDocumentTypes, (obj, index) => {
        obj._id = documentIds?.[index] || obj._id;
      });
    }
    dispatch(resetSelectedSections('Financing'));
    setFormValue([...selectedDocumentTypes, ...newSelectedDocumentTypes]);
    onTrue();
    onCloseDrawerHandler();
  };

  const uploadClickHandler = (itemId: string) => async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    const updatedDocumentsTypes = await Promise.all(
      selectedDocumentTypes.map(async documentType => {
        const additionalConfig = badgeTitleForNew
          ? {
            badgeTitle: badgeTitleForNew,
            badgeColor: badgeColorForUploaded,
            badgeDisabled: false,
          }
          : {};
        return documentType._id === itemId ? {
          ...documentType,
          uploaded: true,
          url: new Date().toISOString(),
          ...additionalConfig,
          file: await createBlobFile(Array.from(files)[0]),
        } : documentType;
      }));

    for (const document of updatedDocumentsTypes) {
      if (document.file) {
        await uploadDocument(document, isDemo);
      }
    }

    setFormValue(updatedDocumentsTypes);
  };

  const deleteOnClickHandler = (itemId: string) => async () => {
    const updatedDocumentsTypes = selectedDocumentTypes.filter(documentType => documentType._id !== itemId);
    await deleteDocument(itemId, isDemo);
    setFormValue(updatedDocumentsTypes);
  };

  const onCloseDrawerHandler = () => {
    toggleOpenDrawer();
    setTextFieldValue('');
    setNewDocumentTypeValue('');
  };

  const onCloseNewDocumentHandler = () => {
    newDocument.onFalse();
    setNewDocumentTypeValue('');
  };

  const newDocumentTypeOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewDocumentTypeValue(value);
  };

  const addDocumentHandler = () => {
    const newDocument: FinanceUploadFileType = {
      _id: Math.random().toString(),
      section: 'Financing',
      filename: newDocumentTypeValue,
      type: 'Documents',
    };
    dispatch(addFileSectionThunk(newDocument));
    onCloseNewDocumentHandler();
  };

  const requestAbility = showRequestAbility
    ? (selectedDocumentTypes?.length === 0
      ? <Stack
        pt={3}
        pb={4}
        spacing={2}
        alignItems='center'
      >
        <NoDocumentIcon/>
        <Typography variant='h6'>{title}</Typography>
        <Typography textAlign='center' variant='body2' width='434px'>{subTitle}</Typography>
        <Button
          onClick={toggleOpenDrawer}
          sx={{
            mt: 1,
            width: 'fit-content',
          }}
          variant='contained'
        >
          {requestBtnTitle}
        </Button>
      </Stack>
      : <DocumentsList
        showMoreOptions
        listSx={{ pb: uploadedDocuments.length > 0 ? 0 : 3 }}
        selectedDocumentTypes={selectedDocumentTypes}
        fileInputOnChangeHandler={uploadClickHandler}
        deleteOnClickHandler={deleteOnClickHandler}
      />)
    : null;

  return (
    <>
      {requestAbility}
      {uploadedDocuments.length > 0 &&
          <>
            <Divider
              hidden={selectedDocumentTypes?.length !== 0 || !showRequestAbility}
            />
            <DocumentsList
              showDownloadButton
              showBorderTop={selectedDocumentTypes?.length !== 0}
              listSx={{ pt: selectedDocumentTypes?.length === 0 ? 3 : '0' }}
              selectedDocumentTypes={uploadedDocuments}
              fileInputOnChangeHandler={uploadClickHandler}
              deleteOnClickHandler={deleteOnClickHandler}
            />
          </>
      }
      <DrawerCustom
        open={openDrawer}
        onCloseDrawerHandler={onCloseDrawerHandler}
        headChildren={
          <DrawerHead
            drawerTitle='Request Documents'
            drawerBtnConfig={{ onClick: newDocument.onTrue }}
            drawerBtnTitle='Add document'
          />
        }
        bodyChildren={
          <DrawerBody
            documentTypes={documentTypes}
          />
        }
        footerChildrenSx={{ borderTop: '1px solid rgba(145, 158, 171, 0.20)' }}
        footerChildren={
          <DrawerFooter
            setTextFieldError={setTextFieldError}
            textFieldError={textFieldError}
            setTextFieldValue={setTextFieldValue}
            disableBtn={!textFieldValue || textFieldError}
            textFieldValue={textFieldValue}
            drawerRequestOnClickHandler={drawerRequestOnClickHandler}
          />
        }
      />
      <InfoModal
        open={value}
        onClose={onFalse}
        title='Documents Request Sent Successfully!'
        icon={<FolderMailIcon/>}
        btnTitle='Close'
        btnConfig={{ onClick: onFalse }}
      />
      <ModalCustom
        open={newDocument.value}
        onClose={onCloseNewDocumentHandler}
        headChildren={
          <Typography variant='h6'>Add New Document Type</Typography>
        }
        bodyChildren={
          <TextField
            required
            value={newDocumentTypeValue}
            label='Type Name'
            placeholder='Type Name'
            onChange={newDocumentTypeOnChangeHandler}
          />
        }
        bodyChildrenSx={{ pt: 0 }}
        footerChildren={
          <>
            <Button
              variant='contained'
              onClick={addDocumentHandler}
            >
              Add Document
            </Button>
            <Button
              variant='outlined'
              onClick={onCloseNewDocumentHandler}
            >
              Cancel
            </Button>

          </>
        }
        footerChildrenSx={{
          justifyContent: 'flex-end',
          gap: 1.5,
        }}
      />
    </>
  );
};
