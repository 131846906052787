import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { MouseEvent } from 'react';
import { getFileConfig, Iconify, UploadedFilesType } from 'src/components';
import { openInNewTab } from 'src/utils';

const rootSx = {
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: 1.5,
  borderRadius: 1,
  padding: '8px 12px',
};

interface ProjectDocumentProps {
  document: UploadedFilesType;
  onRemoveClick: (doc: UploadedFilesType) => void;
}

export const ProjectDocument = (props: ProjectDocumentProps) => {
  const { document, onRemoveClick } = props;
  const theme = useTheme();

  const handleRemove = (document: UploadedFilesType) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onRemoveClick(document);
  };

  const clickOnFile = () => {
    openInNewTab(document.url);
  };

  return (
    <Stack
      sx={{
        ...rootSx,
        border: `1px solid ${theme.palette.divider}`,
        cursor: 'pointer',
      }}
      onClick={clickOnFile}
    >
      {getFileConfig(document.uploadingFileType || document.filetype).icon}
      <Stack>
        <Typography variant='subtitle2'>
          {document.filename}
        </Typography>
        <Typography variant='caption' color={theme.palette.text.secondary}>
          {document.size || document.filesize} KB
        </Typography>
      </Stack>
      <IconButton
        onClick={handleRemove(document)}
        sx={{ ml: 'auto', alignSelf: 'center' }}
      >
        <Iconify icon='mingcute:close-line' width={18}/>
      </IconButton>
    </Stack>
  );
};
