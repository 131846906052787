import { TableHeadType } from 'src/features/leads-page';

export const RaiseMonitoringTableHead: TableHeadType[] = [
  {
    _id: '',
    label: 'Name / Registered',
    width: 50,
  },
  {
    _id: '',
    label: 'Email / Phone',
    width: 160,
  },
  {
    _id: '',
    label: 'REPLACE',
    width: 160,
  },
  {
    _id: 'controls',
    label: '',
    width: 50,
  },
];
