import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { MapBox } from 'src/components';
import { RootState } from 'src/store';

export type OverviewLocationProps = {
  showAddress?: boolean;
  mapHeight?: number;
}

export const OverviewLocation = (props: OverviewLocationProps) => {
  const {
    showAddress = true,
    mapHeight = 333,
  } = props;
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const lead = detailsData.lead;

  return (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Stack gap={2}>
          <Typography variant='h6'>Location</Typography>
          {showAddress &&
            <Typography variant='body2'>
              {lead?.location?.address}
            </Typography>
          }
          {lead?.location?.coordinates?.length &&
            <MapBox
              height={`${mapHeight}px`}
              width='100%'
              mapStyle='street'
              coordinates={lead?.location?.coordinates || null}
              zoom={16}
            />
          }
        </Stack>
      </CardContent>
    </Card>
  );
};
