import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';
import { UploadBox } from 'src/components/upload';
import { uploadImagesAccept } from 'src/components/upload/helpers';

import { CoverImagesType } from '../../../../types';
import { SortableItem } from './element';
import cls from './upload-photos.module.css';
import { boxSx } from './utils';

interface ContainerProps extends SortableContainerProps {
  coverImages: CoverImagesType[];
  onActionsClick: (coverImage: CoverImagesType, actionBtn: HTMLButtonElement) => void;
  onUploadDrop: (files: File[]) => void;
  boxSxProps?: SxProps<Theme>;
  imageSxProps?: SxProps<Theme>;
  chipSx?: SxProps<Theme>;
  error?: boolean;
  helperText?: string;
}

export const SortableList = SortableContainer<ContainerProps>((props: ContainerProps) => {
  const {
    coverImages,
    onUploadDrop,
    boxSxProps,
    error,
    helperText,
    ...restProps
  } = props;
  return (
    <Stack>
      <Stack direction='row' alignItems='flex-start' flexWrap='wrap' gap={1}>
        <Box className={cls.list_item} sx={boxSxProps}>
          <UploadBox
            label='Upload photo'
            icon='solar:camera-add-bold'
            sx={boxSx}
            onDrop={onUploadDrop}
            accept={uploadImagesAccept}
            error={error}
          />
        </Box>
        {coverImages.map((image, index) => (
          <SortableItem
            key={image._id}
            index={index}
            photo={image}
            isCover={image.isCoverImage}
            {...restProps}
          />
        ))}
      </Stack>
      {(error || helperText) && (
        <FormHelperText error={error} sx={{ px: 2 }}>
          {helperText}
        </FormHelperText>
      )}
    </Stack>
  );
});
