import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReadyForInvestors, ReadyForInvestorsListResponse } from '../../../../types';


interface State {
  readyForInvestorsList: ReadyForInvestorsListResponse;
  currentReadyForInvestorsItem?: ReadyForInvestors;
}

export const initialState: State = {
  readyForInvestorsList: {
    counter: {
      All: 0,
      New: 0,
      Shared: 0,
      Published: 0,
      Raised: 0,
    },
    metadata: {
      total: 0,
      page: 0,
    },
    projects: [],
  },
};

const Slice = createSlice({
  name: 'Ready For Investors',
  initialState,
  reducers: {
    setReadyForInvestors(state, action: PayloadAction<ReadyForInvestorsListResponse>) {
      state.readyForInvestorsList = action.payload;
    },
    deleteReadyForInvestorItem(state, action: PayloadAction<string>) {
      state.readyForInvestorsList.projects = state.readyForInvestorsList.projects.filter(item => item._id !== action.payload);
    },
    setCurrentReadyForInvestorsItem(state, action: PayloadAction<string>) {
      state.currentReadyForInvestorsItem = state.readyForInvestorsList.projects.find(item => item._id === action.payload);
    },
  },
});

export const {
  reducer: ReadyForInvestorsReducer,
  actions: {
    setReadyForInvestors,
    setCurrentReadyForInvestorsItem,
    deleteReadyForInvestorItem,
  },
} = Slice;

export * from './actions';
