import { Icon } from '@iconify/react';
import { Box, Chip, IconButton, ListItem, Typography } from '@mui/material';
import { ChangeEvent, ChangeEventHandler } from 'react';
import { Iconify, icons, MoreOptions, OptionType } from 'src/components';
import { dashedBorder } from 'src/constants/colors';
import { checkboxIcon, closeIcon } from 'src/constants/constants';
import { usePopover } from 'src/hooks';
import { extractFileExtension, openInNewTab } from 'src/utils';

import { FinanceUploadFileType } from '../../../../../types';
import { UploadButton } from './upload-button';

export interface DocumentListItemProps {
  selectedDocument: FinanceUploadFileType;
  index: number;
  showBorderTop?: boolean;
  showMoreOptions?: boolean;
  showDownloadButton?: boolean;
  fileInputOnChangeHandler: ChangeEventHandler<HTMLInputElement>;
  deleteOnClickHandler: VoidFunction;
}

export const DocumentListItem = (props: DocumentListItemProps) => {
  const {
    selectedDocument,
    index,
    fileInputOnChangeHandler,
    deleteOnClickHandler,
    showBorderTop = false,
    showMoreOptions = false,
    showDownloadButton = false,
  } = props;
  const popover = usePopover();

  const requestFileOptions: OptionType[] = [
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: deleteOnClickHandler,
      color: 'error.main',
    },
  ];

  const fileInputOnChangeWrapper = (event: ChangeEvent<HTMLInputElement>) => {
    fileInputOnChangeHandler(event);
    popover.onClose();
  };

  const downloadClickHandler = () => {
    if (selectedDocument.url) {
      openInNewTab(selectedDocument.url);
    }
  };

  const uploadedFileOptions: OptionType[] = [
    {
      closePopover: popover.onClose,
      customItem: <UploadButton
        fullWidth
        variant='text'
        sx={{
          justifyContent: 'flex-start',
          color: '#212B36',
          fontWeight: 400,
        }}
        btnTitle='Replace file'
        startIcon={
          <Iconify
            sx={{
              ml: 0.5,
              mr: 1,
            }}
            icon='material-symbols:replay-rounded'
          />
        }
        fileInputOnChangeHandler={fileInputOnChangeWrapper}
        acceptedTypes='.pdf'
      />,
    },
    {
      label: 'Download',
      icon: 'download',
      closePopover: popover.onClose,
      itemOnClickHandler: downloadClickHandler,
    },
    ...requestFileOptions,
  ];

  return (
    <ListItem
      sx={{
        px: 0,
        justifyContent: 'space-between',
        borderTop: (index === 0 && !showBorderTop) ? '' : dashedBorder,
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        gap={1}
      >
        <Icon
          icon={selectedDocument.url ? checkboxIcon : closeIcon}
          color={selectedDocument.url ? '#3698AF' : '#919EABCC'}
        />
        <Typography color={selectedDocument.url ? '#212B36' : '#919EAB'}>
          {selectedDocument.sectionFilename + extractFileExtension(selectedDocument?.filename)}
        </Typography>
      </Box>
      <Box>
        {selectedDocument.badgeTitle &&
            <Chip
              sx={{ mr: 2 }}
              size='small'
              variant='soft'
              disabled={selectedDocument.badgeDisabled}
              label={selectedDocument.badgeTitle}
              color={selectedDocument.badgeColor}
            />
        }
        {!selectedDocument.url &&
            <UploadButton
              sx={{ mr: 2 }}
              size='small'
              variant='outlined'
              btnTitle='Upload'
              fileInputOnChangeHandler={fileInputOnChangeHandler}
              acceptedTypes='.pdf'
            />
        }
        {showDownloadButton &&
            <IconButton onClick={downloadClickHandler}>
              <Iconify icon={icons.download}/>
            </IconButton>
        }
        {showMoreOptions &&
            <MoreOptions
              options={selectedDocument.url ? uploadedFileOptions : requestFileOptions}
              popoverOpen={popover.open}
              popoverOnClose={popover.onClose}
              popoverOnOpen={popover.onOpen}
            />
        }
      </Box>
    </ListItem>
  );
};
