export const notSelectedColor = 'rgba(145, 158, 171, 0.80)';
export const selectedColor = '#22C55E';
export const primaryDefaultTextColor = '#212B36';
export const secondaryDefaultTextColor = '#637381';

export const greyCircle = '#919EAB29';
export const greyCheckmark = '#919EAB33';
export const dashedBorder = '1px dashed rgba(145, 158, 171, 0.20)';

export const orangeGradient = 'linear-gradient(135deg, rgba(255, 214, 102, 0.16) 0%, rgba(255, 171, 0, 0.16) 100%), #FFF';
export const blueGradient = 'linear-gradient(135deg, rgba(97, 243, 243, 0.16) 0%, rgba(0, 184, 217, 0.16) 100%)';
export const greenGradient = 'linear-gradient(135deg, rgba(119, 237, 139, 0.16) 0%, rgba(34, 197, 94, 0.16) 100%), #FFF';
