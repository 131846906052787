import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Fragment, memo, ReactNode } from 'react';
import { TableHeadType } from 'src/features/leads-page';
import { TableProps } from 'src/hooks';

import { Scrollbar } from '../scrollbar';
import { TableHeadCustom } from './table-head-custom';
import { TableNoData } from './table-no-data';

export interface TableCustomProps {
  tableMinWidth?: number;
  rowCount?: number;
  headLabels: TableHeadType[];
  table?: TableProps;
  noDataFound: boolean;
  bodyCells: ReactNode;
  tableHeadSx?: SxProps<Theme>;
  tableSx?: SxProps<Theme>;
  tableContainerSx?: SxProps<Theme>;
  onSort?: (id: string) => void;
  separateRows?: boolean;
  scrollbar?: boolean;
}

export const TableCustom = memo((props: TableCustomProps) => {
  const {
    tableMinWidth = 800,
    headLabels,
    table,
    rowCount,
    bodyCells,
    noDataFound,
    tableHeadSx,
    tableContainerSx,
    tableSx: tableSxProp,
    onSort,
    separateRows,
    scrollbar = true,
  } = props;

  const separateTableSx = separateRows
    ? {
      borderCollapse: 'separate',
      borderSpacing: '0 16px',
    } as SxProps<Theme>
    : undefined;
  const separateTableHeadSx = separateRows ? {
    [`& .${tableCellClasses.head}`]: {
      '&:first-of-type': {
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
      },
      '&:last-of-type': {
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
      },
    },
  } : undefined;

  const tableSx = { ...separateTableSx, ...tableSxProp };
  const ScrollbarComp = scrollbar ? Scrollbar : Fragment;

  return (
    <TableContainer
      sx={{
        position: 'relative',
        overflow: 'unset',
        ...tableContainerSx,
      }}
    >
      <ScrollbarComp>
        <Table
          size={table?.dense ? 'small' : 'medium'}
          sx={{
            minWidth: tableMinWidth,
            ...tableSx,
          }}
        >
          <TableHeadCustom
            order={table?.order}
            orderBy={table?.orderBy}
            headLabel={headLabels}
            rowCount={rowCount}
            onSort={onSort || table?.onSort}
            sx={{
              whiteSpace: 'nowrap',
              ...tableHeadSx,
              ...separateTableHeadSx,
            }}
          />

          <TableBody>
            {bodyCells}
            {noDataFound &&
                <TableNoData noDataFound={noDataFound}/>
            }
          </TableBody>
        </Table>
      </ScrollbarComp>
    </TableContainer>
  );
});
