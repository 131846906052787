import { Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TableCustom, TableFiltersResult, TablePaginationCustom, TableToolbar } from 'src/components';
import { chipVariants, sourceColor, statusColor } from 'src/constants/constants';
import { TableHeadType } from 'src/features/leads-page';
import { defaultFilters } from 'src/features/partners-page/mock-data';
import { TableFilters, TableFilterValue } from 'src/features/partners-page/types';
import { useTable } from 'src/hooks';
import { deletePartnerThunk } from 'src/store/partners';
import { sortCustom } from 'src/utils';

import { PartnerItemKeys, PartnerItemType } from '../../../../../types';
import { PartnersTableRow } from './partners-table-row';

export interface PartnersTableProps {
  title: string;
  btnTitle: string;
  selectOptions?: string[];
  storeKey: PartnerItemKeys;
  btnOnClickHandler: VoidFunction;
  tableData: PartnerItemType[];
  tableHeadLabels: TableHeadType[];
  onRowClickHandler: (item: PartnerItemType) => VoidFunction;
  onProfileClickHandler: (item: PartnerItemType) => VoidFunction;
  onEditClickHandler: (item: PartnerItemType) => VoidFunction;
}

export const PartnersTable = (props: PartnersTableProps) => {
  const {
    btnOnClickHandler,
    btnTitle,
    title,
    tableData,
    onEditClickHandler,
    onRowClickHandler,
    tableHeadLabels,
    onProfileClickHandler,
    storeKey,
    selectOptions,
  } = props;

  const [filters, setFilters] = useState(defaultFilters);
  const table = useTable();
  const dispatch = useDispatch();
  const dataFiltered: PartnerItemType[] = applyFilter(sortCustom(tableData, table), filters);
  const handleFilters = useCallback((name: keyof TableFilters, value: TableFilterValue) => {
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table]);

  const onFiltersDeleteHandler = useCallback((filterKey: keyof TableFilters, valueToDelete: TableFilterValue) => {
    const filterValue = filters[filterKey];
    let newValue = defaultFilters[filterKey];
    if (Array.isArray(filterValue)) {
      newValue = filters.type.filter((item) => item !== valueToDelete);
    }
    handleFilters(filterKey, newValue);
  }, [filters, handleFilters]);

  const canReset =
    !!filters.search ||
    filters.type?.length > 0;
  const noDataFound = (dataFiltered?.length === 0 && canReset) || dataFiltered?.length === 0;

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  const handleDeleteRow = useCallback((id: string) => () => {
    dispatch(deletePartnerThunk(id, storeKey));
    table.onUpdatePageDeleteRow(dataInPage?.length);
  }, [dataInPage?.length, dispatch, storeKey, table]);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <Container maxWidth={false}>
      <Grid container style={{ marginBottom: '40px' }}>
        <Grid item xs={6}>
          <Typography variant='h4'>
            {title}
          </Typography>
        </Grid>
        <Grid
          item xs={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button variant='contained' onClick={btnOnClickHandler}>
            {btnTitle}
          </Button>
        </Grid>
      </Grid>
      <Card>
        <TableToolbar
          selectValue={filters.type}
          selectOptions={selectOptions}
          selectedOptions={filters.type}
          onFilters={handleFilters}
          selectLabel='Type'
          selectKey='type'
          searchFieldValue={filters.search}
          searchFieldKey='search'
        />
        {canReset && (
          <Stack
            sx={{
              paddingLeft: '20px',
              paddingBottom: '16px',
            }}
          >
            <TableFiltersResult
              filters={filters}
              defaultFilters={defaultFilters}
              onFiltersDeleteHandler={onFiltersDeleteHandler}
              onResetFilters={handleResetFilters}
              results={dataInPage?.length}
              chipVariants={chipVariants}
              chipColors={{ ...sourceColor, ...statusColor }}
            />
          </Stack>
        )}

        <TableCustom
          headLabels={tableHeadLabels}
          table={table}
          rowCount={tableData.length}
          noDataFound={noDataFound}
          bodyCells={(
            <>
              {dataInPage?.map((row) => (
                <PartnersTableRow
                  onDeleteClickHandler={handleDeleteRow(row._id)}
                  onEditClickHandler={onEditClickHandler(row)}
                  onProfileClickHandler={onProfileClickHandler(row)}
                  onRowClickHandler={onRowClickHandler(row)}
                  row={row}
                />
              ))}
            </>
          )}
        />

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
};

const applyFilter = (data: PartnerItemType[], filters: TableFilters) => {
  let resultData = _.cloneDeep(data);
  const {
    search,
    type,
  } = filters;

  if (search) {
    resultData = resultData?.filter(
      (item) =>
        item?.companyAddress?.toLowerCase().includes(search.toLowerCase()) ||
        item?.companySubType?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item?.companyContactEmail?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item?.companyName?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item?.companyContactName?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item?.fee?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item?.productAmount?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item?.companyContactPhone?.toString().toLowerCase().includes(search.toLowerCase()),
    );
  }

  if (type?.length > 0) {
    resultData = resultData?.filter(item => item?.companySubType?.some(subItem => type.includes(subItem)));
  }

  return resultData;
};
