import { Grid, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';
import { CardDonutChart, EcommerceWidgetSummary, ReportNote, TableCustom } from 'src/components';
import { DEFAULT_AREA, DEFAULT_CURRENCY } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { fNumber } from 'src/utils';

import { NoteSettingType, Scenario, UnitsArray } from '../../../../../../types';
import { TABLE_HEAD } from './mock-data';
import { ProposedSkeleton } from './proposed-skeleton';

export interface ProposedUnitsProps {
  scenario?: Scenario;
  noteSetting?: NoteSettingType;
}

interface ChartEntry {
  label: string;
  value: number;
}

interface Metric {
  title: string;
  value: number;
  postfix: string;
  hidden?: boolean;
}

export const ProposedUnits = (props: ProposedUnitsProps) => {
  const {
    scenario,
    noteSetting,
  } = props;
  const unitsArray = scenario?.calculate?.input?.units_array?.filter(unit => unit.property_type !== 'BRF Loan (Sweden only)');
  const mdUp = useResponsive('up', 'md');

  const calculation = useMemo(() => {
    const result: ChartEntry[] = [];
    const metrics: Metric[] = [
      {
        title: 'GDV',
        value: 0,
        postfix: ` ${DEFAULT_CURRENCY}`,
      },
      {
        title: 'Sellable',
        value: 0,
        postfix: ` ${DEFAULT_CURRENCY}/${DEFAULT_AREA}`,
      },
      {
        title: 'Avg. Cost Per Unit',
        value: 0,
        postfix: ` ${DEFAULT_CURRENCY}`,
        hidden: !mdUp,
      },
      {
        title: 'Total Floor Area',
        value: 0,
        postfix: ` ${DEFAULT_AREA}`,
        hidden: !mdUp,
      },
    ];

    let totalNumber = 0;
    unitsArray?.forEach((unit: UnitsArray) => {
      const unitSize = unit.size || 0;
      const unitPricePerUnit = unit.price_per_unit || 0;
      const unitNumber = unit.number || 0;
      const bedroomNo = unit.bedroom_no;
      const existingEntry = result.find(entry => entry.label === `${bedroomNo} room apartment`);

      totalNumber += unitNumber;
      // GDV
      metrics[0].value += unitPricePerUnit * unitNumber;
      // Sellable
      metrics[1].value = Number.isFinite(metrics[0].value / metrics[3].value) ? metrics[0].value / metrics[3].value : 0;
      // AVG
      metrics[2].value = metrics[0].value / totalNumber;
      // Total Floor Area
      metrics[3].value += unitSize * unitNumber;

      if (existingEntry) {
        existingEntry.value += unitNumber;
      } else {
        result.push({
          label: `${bedroomNo} room apartment`,
          value: unitNumber,
        });
      }
    });

    return {
      series: result,
      metrics,
    };
  }, [mdUp, unitsArray]);

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>Proposed Units</Typography>
      </Grid>
      {scenario ? (
        <Grid container item spacing={3}>
          {noteSetting?.isActive &&
                <Grid item md={12}>
                  {scenario
                    ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
                    : <Skeleton variant='rounded' height='150px' width='100%'/>
                  }
                </Grid>
          }
          <Grid container item xs={12} spacing={3} md={7}>
            {calculation.metrics.map((metric) => {
              return (
                <Grid key={metric.title} item sx={{ display: metric.hidden ? 'none' : 'grid' }} xs={12} md={6}>
                  <EcommerceWidgetSummary
                    title={metric.title}
                    total={metric.value}
                    postfix={metric.postfix}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12} md={5}>
            <CardDonutChart
              title='Total Units'
              chartHeader='Total Units'
              chart={{ series: calculation.series }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableCustom
              headLabels={mdUp ? TABLE_HEAD : _.slice(TABLE_HEAD, 1)}
              noDataFound={unitsArray?.length === 0}
              tableContainerSx={{
                borderRadius: '16px',
                border: '1px solid #f4f6f8',
                boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)',
              }}
              tableMinWidth={0}
              bodyCells={
                <>
                  {unitsArray?.map((unit, index) => {
                    return (
                      <TableRow
                        key={index}
                      >
                        {mdUp &&
                              <TableCell scope='row'>{unit.bedroom_no} {unit.property_type}</TableCell>
                        }
                        <TableCell scope='row' align='right'>{unit.number}</TableCell>
                        <TableCell
                          scope='row'
                          align='right'>
                          {fNumber(unit.price_per_unit)} {DEFAULT_CURRENCY}
                        </TableCell>
                        <TableCell scope='row' align='right'>{unit.size} {DEFAULT_AREA}</TableCell>
                      </TableRow>
                    );
                  })}
                </>
              }
            />
          </Grid>
        </Grid>
      ) :
        <ProposedSkeleton/>
      }
    </Grid>
  );
};
