import Stack from '@mui/material/Stack';
import { memo } from 'react';
import { navHorizontalConfig } from 'src/constants/nav-config';
import { hideScroll } from 'src/theme/css';

import { NavConfigProps, NavListProps, NavSectionProps } from '../../types';
import { NavListHorizontal } from './nav-list-horizontal';

export const NavSectionHorizontal = memo((props: NavSectionProps) => {
  const {
    data,
    config,
    sx,
    ...other
  } = props;
  return (
    <Stack
      direction='row'
      sx={{
        mx: 'auto',
        ...hideScroll.y,
        ...sx,
      }}
      {...other}
    >
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          items={group.items}
          config={navHorizontalConfig(config)}
        />
      ))}
    </Stack>
  );
});

type GroupProps = {
  items: NavListProps[];
  config: NavConfigProps;
};

const Group = (props: GroupProps) => {
  const {
    items,
    config,
  } = props;
  return (
    <>
      {items.map((list) => (
        <NavListHorizontal
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
};
