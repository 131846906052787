import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import TableCell from '@mui/material/TableCell';
import { StyledTableRow } from 'src/components';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { MoreOptions, OptionType } from 'src/components/more-options';
import { useBoolean, usePopover } from 'src/hooks';
import { fDate, fNumberWithDefaultCurrency } from 'src/utils';

import { RaiseMonitoringType } from '../../../../../types';

export interface RaiseMonitoringTableRowProps {
  row: RaiseMonitoringType;
  addOfferClickHandler?: VoidFunction;
  deleteClickHandler: VoidFunction;
  lastColumnName: string;
}

export const RaiseMonitoringTableRow = (props: RaiseMonitoringTableRowProps) => {
  const {
    deleteClickHandler,
    row,
    addOfferClickHandler,
    lastColumnName,
  } = props;
  const {
    offers,
    status,
    client,
  } = row;

  const confirm = useBoolean();
  const popover = usePopover();

  const moreOptions: OptionType[] = [
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];


  return (
    <>
      <StyledTableRow hover>
        <TableCell>
          <ListItemText
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
            primary={offers?.fullname || client?.name}
            secondary={fDate(offers?.createdAt || client?.createdAt)}
          />
        </TableCell>

        <TableCell>
          <ListItemText
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
            primary={offers?.email || client?.email}
            secondary={offers?.phone}
          />
        </TableCell>

        <TableCell>
          {lastColumnName === 'Offer' &&
            fNumberWithDefaultCurrency(offers?.offerProjectPrice)
          }
          {lastColumnName === 'Status' &&
            <Chip
              size='small'
              variant='soft'
              color='info'
              label={status}
            />
          }
        </TableCell>
        <TableCell
          size='small'
          padding='none'
          align='right'
          sx={{
            px: 1,
            whiteSpace: 'nowrap',
          }}
        >
          {lastColumnName === 'Status' &&
            <Button
              size='small'
              variant='outlined'
              onClick={addOfferClickHandler}
            >
              Add Offer
            </Button>
          }
          <MoreOptions
            options={moreOptions}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
      </StyledTableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={deleteClickHandler}>
            Delete
          </Button>
        }
      />
    </>
  );
};
