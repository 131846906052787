import { Skeleton } from '@mui/material';
import Card, { CardProps } from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ApexOptions } from 'apexcharts';
import { useEffect, useRef, useState } from 'react';
import { Chart, useChart } from 'src/components/chart';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { fBigNumber, fNumber, InputValue } from 'src/utils';

interface Props extends CardProps {
  title: string;
  subTitle?: string;
  total?: number | string;
  prefixTotal?: string;
  postfix?: string;
  showWidget?: boolean;
  formatFn?: (v: InputValue) => string;
  chart?: {
    colors?: string[];
    series: number[];
    options?: ApexOptions;
  };
}

export const EcommerceWidgetSummary = (props: Props) => {
  const {
    title,
    subTitle,
    total = UNSPECIFIED_VALUE,
    prefixTotal = '',
    postfix = '',
    formatFn = fNumber,
    chart,
    showWidget = true,
    sx,
    ...other
  } = props;
  const mdUp = useResponsive('up', 'md');
  const valueRef = useRef<HTMLSpanElement>(null);
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const formattedTotal = typeof total === 'number' ? formatFn(total) : total;
  const formattedBigTotal = (typeof total === 'number' && isOverflow) ? fBigNumber(Number(total)) : formattedTotal;
  const windowRegisterTotal = mdUp ? formattedTotal : formattedBigTotal;

  const chartOptions = useChart({
    chart: { sparkline: { enabled: true } },
    legend: { show: false },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          {
            offset: 100,
            color: '#FFAB00',
          },
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        dataLabels: {
          value: { offsetY: -5 },
          total: {
            label: '',
            formatter: () => windowRegisterTotal,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (valueRef.current) {
      const offsetWidth = valueRef.current.offsetWidth;
      const scrollWidth = valueRef.current.scrollWidth;
      const offsetHeight = valueRef.current.offsetHeight;
      const scrollHeight = valueRef.current.scrollHeight;
      const isOverflows = scrollWidth > offsetWidth || scrollHeight > offsetHeight;
      setIsOverflow(isOverflows);
    }
  }, []);

  return (
    <>
      {
        showWidget ?
          <Card
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              p: 3,
              ...sx,
            }}
            {...other}
          >
            <Grid container sx={{ justifyContent: 'space-between' }}>
              <Grid item md={chart ? 9 : 12} overflow='auto'>
                <Typography variant='subtitle2' sx={{ mb: 2 }}>
                  {title}
                </Typography>
                <Typography
                  noWrap
                  ref={valueRef}
                  width='100%'
                  variant='h3'
                  gutterBottom
                >
                  {prefixTotal + windowRegisterTotal + ' ' + postfix}
                </Typography>
                {subTitle && <Typography variant='body2' gutterBottom sx={{ color: 'rgb(99, 115, 129)' }}>
                  {subTitle}
                </Typography>}
              </Grid>
              {chart && <Grid item md={3}>
                <Chart dir='ltr' type='radialBar' series={chart.series} options={chartOptions} height={90}/>
              </Grid>}
            </Grid>
          </Card>
          : <Skeleton variant='rounded' height='142px' width='100%'/>
      }
    </>
  );
};
