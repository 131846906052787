import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { addOfferThunk } from 'src/store/financial';
import { fNumber, onlyNumbers } from 'src/utils';

import { DeptNameType } from '../../../../../../../types';

export const OverviewFinOffer = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const debt = detailsData.debt;
  const offers = debt?.offers;
  const type = debt?.section.toLowerCase().split(' ')[0];

  const [disabled, setDisabled] = useState<boolean>(!!offers?._id);
  const [totalFunds, setTotalFunds] = useState('');
  const [rate, setRate] = useState('');
  const [fee, setFee] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [ltgv, setLtgv] = useState('');
  const [ltc, setLtc] = useState('');

  useEffect(() => {
    if (debt?._id) {
      const ltgdv = offers?._id ? offers.ltgdv : debt.ltgdv;
      const fee = offers?._id ? offers.fee : debt.fee;
      const totalFunds = offers?._id ? offers.totalFunds : debt.totalFunds;
      const interestRate = offers?._id ? offers.interestRate : debt.interestRate;
      const ltc = offers?._id ? offers.ltc : debt.ltc;
      setLtgv(ltgdv?.toString());
      setFee(fee?.toString());
      setTotalFunds(totalFunds?.toString());
      setRate(interestRate?.toString());
      setLtc(ltc?.toString());
    }
  }, [debt?._id, debt?.fee, debt?.interestRate, debt?.ltc, debt?.ltgdv, debt?.totalFunds, offers?._id, offers?.fee, offers?.interestRate, offers?.ltc, offers?.ltgdv, offers?.totalFunds]);

  const onIntChange = (setter: Dispatch<SetStateAction<string>>) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setter(onlyNumbers(e.currentTarget.value));
  };

  const sendOfferHandler = () => {
    if (debt?.debtRequestId) {
      dispatch(addOfferThunk({
        _id: debt._id,
        totalFunds: Number(totalFunds),
        fee: Number(fee),
        ltc: Number(ltc),
        ltgdv: Number(ltgv),
        interestRate: Number(rate),
        partnerId: debt.partnerId,
        section: type as DeptNameType,
      }, debt.debtRequestId));
    }
    setDisabled(true);
  };

  return (
    <Card sx={{ background: alpha(theme.palette.info.main, 0.08) }}>
      <CardContent>
        <Stack gap={3}>
          <Typography variant='h6'>
            Your Financing Offer
          </Typography>

          <Stack gap={1}>
            <Typography variant='caption' color='text.secondary'>
              Type
            </Typography>
            <Stack direction='row' gap={1}>
              <Button
                variant='outlined'
                size='small'
                disabled
                sx={{
                  boxShadow: type === 'senior'
                    ? '0 0 0 2px currentColor !important'
                    : null,
                }}
                fullWidth
              >
                Senior
              </Button>
              <Button
                variant='outlined'
                size='small'
                disabled
                sx={{
                  boxShadow: type === 'junior'
                    ? '0 0 0 2px currentColor !important'
                    : null,
                }}
                fullWidth
              >
                Junior
              </Button>
              <Button
                variant='outlined'
                size='small'
                sx={{
                  boxShadow: type === 'bridge'
                    ? '0 0 0 2px currentColor !important'
                    : null,
                }}
                fullWidth
                disabled
              >
                Bridge
              </Button>
            </Stack>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }}/>

          <Stack gap={2} direction='row' flexWrap='wrap'>
            <TextField
              value={totalFunds ? fNumber(totalFunds) : ''}
              onChange={onIntChange(setTotalFunds)}
              label='* Total funds, kr'
              variant='outlined'
              sx={{ width: 'calc(50% - 8px)' }}
              fullWidth
              disabled={disabled}
            />
            <TextField
              value={rate}
              onChange={onIntChange(setRate)}
              label='* Rate, %'
              variant='outlined'
              sx={{ width: 'calc(50% - 8px)' }}
              fullWidth
              disabled={disabled}
            />
            <TextField
              value={fee}
              onChange={onIntChange(setFee)}
              label='* Fee, %'
              variant='outlined'
              sx={{ width: 'calc(50% - 8px)' }}
              fullWidth
              disabled={disabled}
            />
            <TextField
              value={timeframe ? onlyNumbers(timeframe) : ''}
              onChange={onIntChange(setTimeframe)}
              label='* Time Frame, mo.'
              variant='outlined'
              sx={{ width: 'calc(50% - 8px)' }}
              fullWidth
              disabled={disabled}
            />
            <TextField
              value={ltgv}
              onChange={onIntChange(setLtgv)}
              label='* LTGV, %'
              variant='outlined'
              sx={{ width: 'calc(50% - 8px)' }}
              fullWidth
              disabled={disabled}
            />
            <TextField
              value={ltc}
              onChange={onIntChange(setLtc)}
              label='* LTC, %'
              variant='outlined'
              sx={{ width: 'calc(50% - 8px)' }}
              fullWidth
              disabled={disabled}
            />
          </Stack>

          <Button
            variant='contained'
            size='large'
            fullWidth
            disabled={disabled}
            onClick={sendOfferHandler}
          >
            Send Offer
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
