import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import TableCell from '@mui/material/TableCell';
import { MouseEvent } from 'react';
import { ConfirmDialog, ListItemCustom, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { DEFAULT_AREA, DEFAULT_CURRENCY } from 'src/constants/constants';
import { useBoolean, usePopover } from 'src/hooks';
import { fNumber, isCLickOnRow } from 'src/utils';

import { SupplierProductType } from '../../../../../../types';

export interface SupplierTableRowProps {
  item: SupplierProductType;
  onDeleteRowHandler: VoidFunction;
  onRowClickHandler: VoidFunction;
}

export const SupplierProductTableRow = (props: SupplierTableRowProps) => {
  const {
    item,
    onRowClickHandler,
    onDeleteRowHandler,
  } = props;
  const {
    buildSystem,
    buildTimeFrameInMonths,
    idealMinBoaInSqm,
    idealMaxBoaInSqm,
    maxFloors,
    minFloors,
    coverImage,
    name,
    pricePerSqm,
    type,
    primaryMaterial,
  } = item;
  const confirm = useBoolean();
  const popover = usePopover();

  const moreOptions: OptionType[] = [
    {
      label: 'Edit',
      icon: 'edit',
      closePopover: popover.onClose,
      itemOnClickHandler: onRowClickHandler,
    },
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onRowClickHandler();
  };

  const onDeleteClickHandlerWrapper = () => {
    confirm.onFalse();
    onDeleteRowHandler();
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell>
          <ListItemCustom
            showAvatar
            listItemSx={{ alignItems: 'center' }}
            avatarSx={{
              borderRadius: 1.5,
              backgroundColor: '#eeeff1',
            }}
            listItemTextProps={{
              primaryTypographyProps: {
                whiteSpace: 'nowrap',
                variant: 'body2',
              },
            }}
            secondaryTextTypographySx={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
            avatarSrc={coverImage}
            customAvatarIcon={<PhotoSizeSelectActualIcon sx={{ fill: '#c0c7d1' }}/>}
            primaryText={name}
            secondaryText={type}
          />
        </TableCell>

        <TableCell>
          <ListItemText
            primary={buildSystem}
            secondary={`${minFloors}-${maxFloors}`}
            primaryTypographyProps={{
              typography: 'body2',
              ml: 'auto',
            }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
              typography: 'caption',
              ml: 'auto',
            }}
          />
        </TableCell>
        <TableCell>
          <ListItemText
            primary={`${fNumber(pricePerSqm)} ${DEFAULT_CURRENCY}`}
            secondary={`${buildTimeFrameInMonths} months`}
            primaryTypographyProps={{
              typography: 'body2',
              ml: 'auto',
            }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
              typography: 'caption',
              ml: 'auto',
            }}
          />
        </TableCell>
        <TableCell>
          <ListItemText
            primary={`${idealMinBoaInSqm}-${idealMaxBoaInSqm} ${DEFAULT_AREA}`}
            secondary={primaryMaterial}
            primaryTypographyProps={{
              typography: 'body2',
              ml: 'auto',
            }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
              typography: 'caption',
              ml: 'auto',
              textAlign: 'right',
            }}
          />
        </TableCell>
        <TableCell
          align='right'
          size='small'
          padding='none'
          sx={{ px: 1 }}
        >
          <MoreOptions
            options={moreOptions}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title='Delete'
          content='Are you sure want to delete?'
          action={
            <Button variant='contained' color='error' onClick={onDeleteClickHandlerWrapper}>
              Delete
            </Button>
          }
        />
      </StyledTableRow>
    </>
  );
};
