import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { CardRadialFilterBarChart } from 'src/components/card-radial-bar-chart/card-radial-filter-bar-chart';

import { FundingType } from '../../../../../../types';

type FundingTotalProps = {
  funding?: FundingType;
  title?: string;
}

export const FundingTotal = (props: FundingTotalProps) => {
  const {
    funding,
    title = 'Funding',
  } = props;
  const theme = useTheme();

  const generatedMockDataForRadialChart = useMemo(() => {
    return [
      {
        label: 'Soft Committed',
        value: funding?.softCommitedAmount || 0,
      },
      {
        label: 'Capital Secured',
        value: funding?.securedAmount || 0,
      },
    ];
  }, [funding?.securedAmount, funding?.softCommitedAmount]);

  return (
    <CardRadialFilterBarChart
      showFilter={false}
      title={title}
      chartHeader='Total Required'
      chart={{
        colors: [
          [theme.palette.primary.main, theme.palette.primary.main],
          [theme.palette.primary.lighter, theme.palette.primary.lighter],
        ],
        series: generatedMockDataForRadialChart,
      }}
    />
  );
};
