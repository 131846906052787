import { Stack } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Editor, EditorProps } from '../editor';
import { HiddenRHFTextField } from './rhf-text-field';

interface RHFEditorProps extends EditorProps {
  name: string;
}

export const RHFEditor = (props: RHFEditorProps) => {
  const {
    name,
    helperText,
    ...other
  } = props;

  const {
    control,
    watch,
    setValue,
    formState: { isSubmitSuccessful },
  } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (values[name] === '<p><br></p>') {
      setValue(name, '', { shouldValidate: !isSubmitSuccessful });
    }
  }, [isSubmitSuccessful, name, setValue, values]);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <Stack>
          <HiddenRHFTextField formName={field.name}/>
          <Editor
            id={name}
            value={field.value}
            onChange={field.onChange}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        </Stack>
      )}
    />
  );
};
