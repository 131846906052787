import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, Container, Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { omit } from 'lodash';
import { SyntheticEvent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog, CrumbType, FormProvider, MoreOptions, OptionType, ProjectToolbar, RHFAutocomplete, RHFRadioGroup, RHFTextField } from 'src/components';
import { UploadPhotos } from 'src/components/upload-photos/upload-photos';
import { paths } from 'src/config';
import { useBoolean, usePopover, useResponsive, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { createProduct, deleteProductThunk, getPartnerDetail } from 'src/store/partners';
import * as Yup from 'yup';

import { CoverImagesType, SupplierProductType } from '../../../../../../types';
import {
  apartmentRoomsVariants,
  housesRoomsVariants,
  materialVariants,
  productApartmentSubTypeVariants,
  productBuildSystemVariants,
  productHouseSubTypeVariants,
  productTypeVariants,
} from '../../mock-data';

export interface SupplierProductFormProps {
  currentProduct?: SupplierProductType;
  partnerId: string;
}

export const SupplierProductForm = (props: SupplierProductFormProps) => {
  const {
    currentProduct,
    partnerId,
  } = props;
  const mdUp = useResponsive('up', 'md');
  const router = useRouter();
  const dispatch = useDispatch();
  const partner = useSelector((state: RootState) => state.Partners.currentPartner);
  const popover = usePopover();
  const confirm = useBoolean();

  useEffect(() => {
    dispatch(getPartnerDetail(partnerId));
  }, [dispatch, partnerId]);

  const moreOptions: OptionType[] = [
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const NewSupplierProductSchema: Yup.ObjectSchema<Omit<SupplierProductType, '_id'>> = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    type: Yup.string().required('Type is required'),
    roofAngle: Yup.number(),
    coverImage: Yup.string(),
    subType: Yup.string().required('SubType is required'),
    buildSystem: Yup.string().required('Build system is required'),
    buildTimeFrameInMonths: Yup.number().moreThan(0, 'Build timeframe must be greater than 0').required('Build timeframe is required'),
    minFloors: Yup.number().required('Min floors is required').moreThan(0, 'Min floors must be greater than 0'),
    maxFloors: Yup.number()
      .required('Max floors is required')
      .test(
        'floors-max',
        'Max floors must be greater than min floors',
        (value, { parent }) => value > parent.minFloors,
      ),
    minRooms: Yup.number().required('Min rooms is required'),
    maxRooms: Yup.number().required('Max rooms is required').test(
      'rooms-max',
      'Max rooms boa must be greater than min rooms',
      (value, { parent }) => value > parent.minRooms,
    ),
    minApartmentSizeInSqm: Yup.number().required('Min apartment size is required').moreThan(0, 'Min apartment size must be greater than 0'),
    maxApartmentSizeInSqm: Yup.number().required('Max apartment size is required')
      .test(
        'size-max',
        'Max apartment size must be greater than min apartment size',
        (value, { parent }) => value > parent.minApartmentSizeInSqm,
      ),
    idealMinBoaInSqm: Yup.number().required('Ideal min noa is required').moreThan(0, 'Ideal min noa must be greater than 0'),
    idealMaxBoaInSqm: Yup.number().required('Ideal max boa is required')
      .test(
        'boa-max',
        'Ideal max boa must be greater than ideal max boa',
        (value, { parent }) => value > parent.idealMinBoaInSqm,
      ),
    primaryMaterial: Yup.string().required('Material is required'),
    pricePerSqm: Yup.number().required('Price psqm is required').moreThan(0, 'Price psqm must be greater than 0'),
    images: Yup.mixed(),
    imagesToDelete: Yup.mixed(),
  });

  const defaultValues: Omit<SupplierProductType, '_id'> = useMemo(() => ({
    name: currentProduct?.name || '',
    type: currentProduct?.type || '',
    roofAngle: currentProduct?.roofAngle || 0,
    subType: currentProduct?.subType || '',
    buildSystem: currentProduct?.buildSystem || '',
    buildTimeFrameInMonths: currentProduct?.buildTimeFrameInMonths || 0,
    minFloors: currentProduct?.minFloors || 0,
    maxFloors: currentProduct?.maxFloors || 0,
    minRooms: currentProduct?.minRooms || undefined,
    maxRooms: currentProduct?.maxRooms || undefined,
    minApartmentSizeInSqm: currentProduct?.minApartmentSizeInSqm || 0,
    maxApartmentSizeInSqm: currentProduct?.maxApartmentSizeInSqm || 0,
    idealMinBoaInSqm: currentProduct?.idealMinBoaInSqm || 0,
    idealMaxBoaInSqm: currentProduct?.idealMaxBoaInSqm || 0,
    primaryMaterial: currentProduct?.primaryMaterial || 'Wood',
    pricePerSqm: currentProduct?.pricePerSqm || 0,
    images: currentProduct?.images || [],
    imagesToDelete: [],
  }), [currentProduct]);

  const methods = useForm<Omit<SupplierProductType, '_id'>>({
    resolver: yupResolver(NewSupplierProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (currentProduct) {
      reset(defaultValues);
    }
  }, [currentProduct, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const updatedData: SupplierProductType = {
        ...data,
        _id: currentProduct?._id || '',
      };
      dispatch(createProduct(partnerId, omit(updatedData, ['coverImage']), () => {
        router.push(paths.supplierPartners.products(partnerId));
      }));

    } catch (error) {
      console.error(error);
    }
  });

  const subTypeVariantsOptions = values.type === 'Houses' ? productHouseSubTypeVariants : productApartmentSubTypeVariants;
  const roomVariantsOptions = values.type === 'Houses' ? housesRoomsVariants : apartmentRoomsVariants;

  const typeOnChangeHandler = (_: SyntheticEvent, value: string | string[] | null) => {
    setValue('type', value as string);
    clearErrors('type');
    setValue('minRooms', undefined);
    setValue('maxRooms', undefined);
    setValue('subType', '');
  };

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Title, type, build system...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Details'/>}

          <Stack spacing={3} sx={{ p: 3 }} direction='row' flexWrap='wrap'>
            <RHFTextField sx={{ width: 'calc(50% - 12px)' }} name='name' label='Name *'/>
            <RHFAutocomplete
              sx={{ width: 'calc(50% - 12px)' }}
              name='type'
              label='Type *'
              onChange={typeOnChangeHandler}
              autoHighlight
              options={productTypeVariants.map((option) => option)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option}>
                  {option}
                </li>
              )}
            />
            <RHFTextField type='number' sx={{ width: 'calc(50% - 12px)' }} name='roofAngle' label='Roof Angle'/>
            <RHFAutocomplete
              sx={{ width: 'calc(50% - 12px)' }}
              name='subType'
              label='Sub Type *'
              autoHighlight
              options={subTypeVariantsOptions.map((option) => option)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
            <RHFAutocomplete
              sx={{ width: 'calc(50% - 12px)' }}
              name='buildSystem'
              label='Build System *'
              autoHighlight
              options={productBuildSystemVariants.map((option) => option)}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => (
                <li {...props} key={option}>
                  {option}
                </li>
              )}
            />
            <RHFTextField
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='buildTimeFrameInMonths'
              label='Build Timeframe, month *'
            />
            <RHFTextField
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='minFloors'
              label='Min Floors *'
            />
            <RHFTextField
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='maxFloors'
              label='Max Floors *'
            />
            <RHFAutocomplete
              sx={{ width: 'calc(50% - 12px)' }}
              name='minRooms'
              autoHighlight
              value={values.minRooms}
              label='Min Rooms *'
              options={roomVariantsOptions.map((option) => option.value)}
              getOptionLabel={(option) => roomVariantsOptions.find(variant => variant.value === option)?.label || ''}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option}
                >
                  {roomVariantsOptions.find(variant => variant.value === option)?.label || ''}
                </li>
              )}
            />
            <RHFAutocomplete
              sx={{ width: 'calc(50% - 12px)' }}
              name='maxRooms'
              autoHighlight
              value={values.maxRooms}
              label='Max Rooms *'
              options={roomVariantsOptions.map((option) => option.value)}
              getOptionLabel={(option) => {
                return roomVariantsOptions.find(variant => variant.value === option)?.label || '';
              }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option}
                >
                  {roomVariantsOptions.find(variant => variant.value === option)?.label || ''}
                </li>
              )}
            />
            <RHFTextField
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='minApartmentSizeInSqm'
              label='Min Apartment Size *'
            />
            <RHFTextField
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='maxApartmentSizeInSqm'
              label='Max Apartment Size *'
            />
            <RHFTextField
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='idealMinBoaInSqm'
              label='Ideal Min BOA, sqm *'
            />
            <RHFTextField
              type='number'
              sx={{ width: 'calc(50% - 12px)' }}
              name='idealMaxBoaInSqm'
              label='Ideal Max BOA, sqm *'
            />
            <Stack direction='row'>
              <RHFRadioGroup
                row
                name='primaryMaterial'
                spacing={2}
                options={materialVariants.map(variant => ({
                  label: variant,
                  value: variant,
                }))}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderPricing = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Pricing
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Price related inputs
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Pricing'/>}
          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              type='number'
              name='pricePerSqm'
              label='Price psqm, kr *'
              helperText='* Excluding VAT and Groundworks'
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const uploadCoverImagesHandler = (files: CoverImagesType[]) => {
    setValue('images', [...(values.images || []), ...files]);
  };

  const setImageAsCoverHandler = (file: CoverImagesType) => {
    setValue('images', values.images?.map(image => {
      return {
        ...image,
        isCoverImage: image._id === file._id,
      };
    }));
  };

  const deleteCoverImageHandler = (imageToDelete: CoverImagesType, updatedImages: CoverImagesType[]) => {
    setValue('imagesToDelete', [...(values.imagesToDelete || []), imageToDelete]);
    setValue('images', updatedImages);
  };

  const sortCoverImages = (files: CoverImagesType[]) => {
    setValue('images', files);
  };

  const renderImages = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant='h6' sx={{ mb: 0.5 }}>
            Images
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            These images are displayed in the marketplace
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title='Images'/>}
          <Stack spacing={3} sx={{ p: 3 }}>
            <UploadPhotos
              deleteCoverImageHandler={deleteCoverImageHandler}
              setImageAsCoverHandler={setImageAsCoverHandler}
              uploadCoverImagesHandler={uploadCoverImagesHandler}
              sortCoverImages={sortCoverImages}
              coverImages={values.images}
              boxSx={values.images?.length ? {} : {
                height: 160,
                width: '100% !important',
              }}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const crumbs: CrumbType[] = [
    {
      title: 'Partners',
      href: paths.supplierPartners.list,
    },
    {
      title: 'Construction Suppliers',
      href: paths.supplierPartners.list,
    },
    {
      title: partner?.companyName,
      href: paths.supplierPartners.products(partner?._id || ''),
    },
    { title: currentProduct?._id ? currentProduct.name : 'New Product' },
  ];

  const renderActions = (
    <LoadingButton
      sx={{ ml: 'auto' }}
      type='submit'
      variant='contained'
      size='large'
      loading={isSubmitting}
    >
      {!currentProduct ? 'Create Product' : 'Save Changes'}
    </LoadingButton>
  );

  const onDeleteClickHandlerWrapper = () => {
    if (currentProduct?._id) {
      dispatch(deleteProductThunk(partnerId, currentProduct?._id));
    }
    confirm.onFalse();
    router.push(paths.supplierPartners.products(partnerId));
  };

  return (
    <Container maxWidth={false} sx={{ px: 2 }}>
      <ProjectToolbar
        showSkeleton={false}
        showBadge={false}
        showBackIcon={false}
        crumbs={crumbs}
        title={currentProduct?._id ? 'Edit' : 'Create a new product'}
      >
        {currentProduct?._id &&
            <MoreOptions
              options={moreOptions}
              popoverOnClose={popover.onClose}
              popoverOnOpen={popover.onOpen}
              popoverOpen={popover.open}
            />
        }
      </ProjectToolbar>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid
          container
          sx={{
            mx: 0,
            px: 3,
          }}
          rowGap={3}
        >
          {renderDetails}
          {renderPricing}
          {renderImages}
          {renderActions}
        </Grid>
      </FormProvider>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteClickHandlerWrapper}>
            Delete
          </Button>
        }
      />
    </Container>
  );
};
