import { Button, Stack, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { MouseEventHandler } from 'react';
import { CourseIcon } from 'src/assets';
import { blueGradient } from 'src/constants/colors';

export interface LeftBlockComponentProps {
  title: string;
  subTitle?: string;
  extraTitle?: string;
  btnText?: string;
  secondBtnText?: string;
  showPreview?: boolean;
  btnVariant?: 'text' | 'contained' | 'outlined' | 'soft';
  secondBtnVariant?: 'text' | 'contained' | 'outlined' | 'soft';
  btnClickHandler?: MouseEventHandler<HTMLButtonElement>;
  secondBtnClickHandler?: MouseEventHandler<HTMLButtonElement>;
  onPreviewClickHandler?: MouseEventHandler<HTMLButtonElement>;
  stackSx?: SxProps<Theme>;
  btnProps?: ButtonProps;
}

export const LeftBlockComponent = (props: LeftBlockComponentProps) => {
  const {
    title,
    subTitle,
    btnText,
    btnVariant = 'contained',
    btnClickHandler,
    extraTitle,
    stackSx,
    btnProps,
    secondBtnVariant = 'outlined',
    secondBtnText,
    secondBtnClickHandler,
    onPreviewClickHandler,
    showPreview,
  } = props;

  return (
    <Stack
      sx={{
        mr: 3,
        minWidth: 240,
        ...stackSx,
      }}
    >
      <Typography variant='h6' mb={0.5}>{title}</Typography>
      <Typography variant='body2' color='text.primary'>{subTitle}</Typography>
      <Typography variant='body2' color='text.primary'>{extraTitle}</Typography>
      <Stack direction='row' gap={2} mt={2}>
        {
          btnText &&
            <Button
              onClick={btnClickHandler}
              sx={{ width: 'fit-content' }}
              variant={btnVariant}
              {...btnProps}
            >
              {btnText}
            </Button>
        }
        {
          secondBtnText &&
            <Button
              onClick={secondBtnClickHandler}
              sx={{ width: 'fit-content' }}
              variant={secondBtnVariant}
            >
              {secondBtnText}
            </Button>
        }
        {showPreview &&
            <PreviewBlock onPreviewClickHandler={onPreviewClickHandler}/>
        }
      </Stack>
    </Stack>
  );
};

export type PreviewBlockProps = {
  onPreviewClickHandler?: MouseEventHandler<HTMLButtonElement>;
  title?: string;
  btnTitle?: string;
  width?: number;
}

export const PreviewBlock = (props: PreviewBlockProps) => {
  const {
    onPreviewClickHandler,
    title = 'Review the your request',
    btnTitle = 'Preview',
    width,
  } = props;

  const theme = useTheme();

  return (
    <Stack
      sx={{
        gap: 2,
        p: 2,
        borderRadius: 1,
        background: blueGradient,
      }}
      width={width}
    >
      <Stack
        textAlign='center'
        alignItems='center'
        justifyContent='center'
        gap={1}
      >
        <CourseIcon color={theme.palette.info.main} width={32} height={32}/>
        <Typography variant='body2' color='text.primary'>{title}</Typography>
      </Stack>
      <Button
        variant='contained'
        size='small'
        disabled
        fullWidth
        onClick={onPreviewClickHandler}
      >{btnTitle}</Button>
    </Stack>
  );
};
