import { Card, Stack } from '@mui/material';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeadType } from 'src/features/leads-page';
import { useBoolean, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { createDebtRequestThunk, deleteDebtThunk, loadFinancialData, setRequestItem } from 'src/store/financial';

import { FinancialSheet, FinancialTableType, FinancialType, OptionBlockKey, Project } from '../../../../../types';
import { defaultHeadLabels } from '../mock-data';
import { DebtInvoices } from './debt-invoices';
import { FinancialDrawerManager } from './financial-drawer-manager';
import { FinancialTable } from './financial-table';
import { NoData } from './no-data';

export interface FinancialProps {
  financialData?: FinancialType;
  project?: Project;
  financialSheet?: FinancialSheet[];
}

export type DrawerTabType = '' | 'offer' | 'update-offer' | 'request' | 'upload' | 'details' | 'agreementUpload';

export const Financial = (props: FinancialProps) => {
  const {
    financialData,
    project,
    financialSheet = [],
  } = props;
  const primaryDebtRequests = financialData?.preliminaryDebtRequests || [];
  const offers = financialData?.offers || [];
  const termSheets = useMemo(() => financialData?.termSheets || [], [financialData?.termSheets]);
  const debtFinancingAgreement = financialData?.debtFinancingAgreement;
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);

  const router = useRouter();
  const dispatch = useDispatch();
  const drawer = useBoolean();
  const [drawerTab, setDrawerTab] = useState<DrawerTabType>('');

  const noDataData = useMemo(() => {
    const title = _.isEmpty(financialData)
      ? 'Add a Preliminary Debt Request'
      : (primaryDebtRequests?.length === 0
        ? 'No Preliminary Debt Requests'
        : '');
    const subTitle = _.isEmpty(financialData)
      ? 'No debt requests have been created'
      : (primaryDebtRequests?.length === 0
        ? 'You have already added offers to all requests'
        : '');
    return {
      title,
      subTitle,
    };
  }, [financialData, primaryDebtRequests?.length]);

  const handleNewRequestClick = () => {
    if (project?._id) {
      dispatch(createDebtRequestThunk(project._id, router));
    }
  };

  const debtRequestHeadLabels: TableHeadType[] = useMemo(() => {
    return [
      {
        _id: 'bankName',
        label: 'Bank / Requested',
        width: 300,
      },
      ...defaultHeadLabels,
      {
        _id: 'controls',
        label: '',
        width: 200,
      },
    ];
  }, []);

  const offersHeadLabels: TableHeadType[] = useMemo(() => {
    return [
      {
        _id: 'bankName',
        label: 'Bank / Offer Date',
        width: 300,
      },
      ...defaultHeadLabels,
      {
        _id: '',
        label: 'Calculation',
        width: 200,
      },
      {
        _id: 'controls',
        label: '',
        width: 200,
      },
    ];
  }, []);

  const termSheetsHeadLabels: TableHeadType[] = useMemo(() => {
    return [
      {
        _id: 'bankName',
        label: 'Bank',
        width: 300,
      },
      ...defaultHeadLabels,
      {
        _id: 'status',
        label: 'Status',
        width: 150,
      },
      {
        _id: '',
        label: 'Calculation',
        width: 200,
      },
      {
        _id: 'controls',
        label: '',
        width: 200,
      },
    ];
  }, []);

  const resendRequestClickHandler = useCallback(() => () => {}, []);

  const handleDrawer = useCallback((tab: DrawerTabType, row: FinancialTableType, step: keyof FinancialType) => {
    setDrawerTab(tab);
    drawer.onTrue();
    dispatch(setRequestItem({
      step: step,
      itemId: row._id,
      partnerId: row.bankId || '',
      debtRequestId: row.debtRequestId,
      partnerContactName: row.bankContactName,
      partnerContactEmail: row.bankContactEmail,
    }));
  }, [dispatch, drawer]);

  const ternSheetRequestClickHandler = useCallback((row: FinancialTableType) => () => {
    handleDrawer('request', row, 'offers');
  }, [handleDrawer]);

  const addOfferClickHandler = useCallback((row: FinancialTableType) => () => {
    handleDrawer('offer', row, 'preliminaryDebtRequests');
  }, [handleDrawer]);

  const deleteClickHandler = useCallback((row: FinancialTableType, block: OptionBlockKey) => () => {
    dispatch(deleteDebtThunk(row, block));
    const isLastItem = (termSheets?.length + primaryDebtRequests?.length + offers?.length) <= 1;
    if (isLastItem && project?._id && !isDemo) {
      dispatch(loadFinancialData(project?._id));
    }
  }, [dispatch, isDemo, offers?.length, primaryDebtRequests?.length, project?._id, termSheets?.length]);

  const seeDetailsClickHandler = useCallback((row: FinancialTableType) => () => {
    handleDrawer('details', row, 'termSheets');
  }, [handleDrawer]);

  const uploadTermSheetClickHandler = useCallback((row: FinancialTableType) => () => {
    handleDrawer('upload', row, 'termSheets');
  }, [handleDrawer]);

  const editClickHandler = useCallback((tab: DrawerTabType) => (row: FinancialTableType, block: OptionBlockKey) => () => {
    handleDrawer(tab, row, block);
  }, [handleDrawer]);

  // const requestFinancingAgreement = useCallback((rowId?: string) => () => {
  //   router.push(paths.financing.debtFinancingAgreement(rowId));
  // }, [router]);
  //
  // const uploadDebtFinancingAgreement = useCallback((rowId: string) => () => {
  //   handleDrawer('agreementUpload', rowId, 'debtFinancingAgreement');
  // }, [handleDrawer]);
  //
  // const uploadDebtFinancingDocuments = useCallback((rowId: string) => () => {
  //   router.push(paths.financing.uploadFinancingAgreementDocuments(rowId));
  // }, [router]);

  const termSheetIncludesAllTypes = useMemo(() => {
    const types = termSheets?.map(sheet => sheet.type);
    const uniqueTypes = new Set(types);
    return uniqueTypes.size === 4;
  }, [termSheets]);

  return (
    <Stack gap={5}>
      <DebtInvoices
        financialSheets={financialSheet}
      />
      {/*Debt Financing Agreement*/}
      {/*{debtFinancingAgreement?.length !== 0 &&*/}
      {/*    <FinancialTable*/}
      {/*      title='Debt Financing Agreement'*/}
      {/*      moreOptionBlockKey='debtFinancingAgreement'*/}
      {/*      headLabels={termSheetsHeadLabels}*/}
      {/*      financialTableData={debtFinancingAgreement}*/}
      {/*      tableRowBtnOnClickHandler={ternSheetRequestClickHandler}*/}
      {/*      deleteClickHandler={deleteClickHandler}*/}
      {/*      seeDetailsClickHandler={seeDetailsClickHandler}*/}
      {/*      uploadDebtFinancingAgreement={uploadDebtFinancingAgreement}*/}
      {/*      uploadDebtFinancingDocuments={uploadDebtFinancingDocuments}*/}
      {/*    />*/}
      {/*}*/}
      {/*Term Sheets*/}
      {termSheets?.length !== 0 &&
        <FinancialTable
          showIsCalculate
          title='Term Sheets'
          moreOptionBlockKey='termSheets'
          // btnTitle='Request Debt Financing Agreement'
          // btnConfig={{
          // onClick: requestFinancingAgreement(),
          // variant: 'contained',
          // }}
          headLabels={termSheetsHeadLabels}
          financialTableData={termSheets}
          tableRowBtnOnClickHandler={ternSheetRequestClickHandler}
          deleteClickHandler={deleteClickHandler}
          seeDetailsClickHandler={seeDetailsClickHandler}
          uploadTermSheetClickHandler={uploadTermSheetClickHandler}
          // requestFinancingAgreement={requestFinancingAgreement}
        />
      }
      {/*Offers*/}
      {offers?.length !== 0 &&
        <FinancialTable
          showIsCalculate
          title='Offers'
          moreOptionBlockKey='offers'
          headLabels={offersHeadLabels}
          debtAgreements={debtFinancingAgreement}
          termSheets={termSheets}
          financialTableData={offers}
          tableRowBtnTitle='Term Sheet Request'
          tableRowBtnOnClickHandler={ternSheetRequestClickHandler}
          editClickHandler={editClickHandler('update-offer')}
          deleteClickHandler={deleteClickHandler}
          tableRowBtnConfig={{ disabled: termSheetIncludesAllTypes }}
        />
      }
      {/*Preliminary Debt Requests*/}
      {primaryDebtRequests?.length !== 0 &&
        <FinancialTable
          showTabs
          title='Preliminary Debt Requests'
          moreOptionBlockKey='preliminaryDebtRequests'
          headLabels={debtRequestHeadLabels}
          financialTableData={primaryDebtRequests}
          tableRowBtnTitle='Add Offer'
          btnTitle='New Request'
          btnConfig={{ onClick: handleNewRequestClick }}
          tableRowBtnOnClickHandler={addOfferClickHandler}
          resendRequestClickHandler={resendRequestClickHandler}
          deleteClickHandler={deleteClickHandler}
        />
      }
      {
        _.isEmpty(financialData)
          ? <NoData
            title={noDataData.title}
            stackConfig={{ mt: '215px' }}
            subTitle={noDataData.subTitle}
            btnTitle='New Request'
            btnConfig={{ onClick: handleNewRequestClick }}
          />
          : (primaryDebtRequests?.length === 0 ?
            <Card sx={{ py: '147px' }}>
              <NoData
                title={noDataData.title}
                subTitle={noDataData.subTitle}
                btnTitle='New Request'
                btnConfig={{ onClick: handleNewRequestClick }}
              />
            </Card>
            : null
          )
      }
      <FinancialDrawerManager
        showDrawer={drawer.value}
        drawerOnClose={drawer.onFalse}
        currentDrawerTab={drawerTab}
      />
    </Stack>
  );
};
