import { ReactNode } from 'react';

interface ReportPageProps {
  children: ReactNode;
}

export const ReportPage = (props: ReportPageProps) => {
  const { children } = props;

  return (
    <>
      {children}
    </>
  );
};
