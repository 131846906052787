import { Button, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

export interface DrawerHeadProps {
  drawerTitle?: string;
  drawerBtnTitle?: string;
  drawerBtnConfig?: ButtonProps;
}

export const DrawerHead = (props: DrawerHeadProps) => {
  const {
    drawerBtnConfig = { variant: 'text' },
    drawerTitle,
    drawerBtnTitle,
  } = props;
  return (
    <>
      <Typography variant='h6'>{drawerTitle}</Typography>
      <Button {...drawerBtnConfig}>{drawerBtnTitle}</Button>
    </>
  );
};
