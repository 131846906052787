import { styled } from '@mui/material/styles';

export const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ ownerState }) => ({
    color: '#DFE3E8',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && { color: '#DFE3E8' }),
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    '&.active .QontoStepIcon-circle': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);
