import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { Avatar, Button, ListItem, ListItemAvatar, ListItemText, ListItemTextProps, Stack, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';
import { MouseEvent, ReactNode } from 'react';
import { notSelectedColor, selectedColor } from 'src/constants/colors';

export interface ListItemCustomProps {
  avatarAlt?: string;
  avatarSrc?: string;
  primaryText?: string | ReactNode;
  secondaryText?: string | ReactNode;
  secondaryChildren?: ReactNode;
  iconChangeClause?: boolean;
  showBtnIcon?: boolean;
  showAvatar?: boolean;
  btnTitle?: string;
  btnConfig?: ButtonProps;
  listItemSx?: SxProps<Theme>;
  btnContainerSx?: SxProps<Theme>;
  avatarSx?: SxProps<Theme>;
  secondaryTextTypographySx?: SxProps<Theme>;
  secondaryTextVariant?: Variant;
  customAvatar?: ReactNode;
  children?: ReactNode;
  customAvatarIcon?: ReactNode;
  onItemClick?: (event: MouseEvent<HTMLLIElement>) => void;
  listItemTextProps?: ListItemTextProps;
}

export const ListItemCustom = (props: ListItemCustomProps) => {
  const {
    avatarAlt,
    avatarSrc,
    avatarSx,
    listItemTextProps,
    customAvatarIcon,
    showBtnIcon = false,
    showAvatar = false,
    iconChangeClause,
    secondaryChildren,
    primaryText,
    secondaryText,
    btnTitle,
    btnConfig,
    children,
    listItemSx,
    btnContainerSx,
    customAvatar,
    secondaryTextTypographySx,
    secondaryTextVariant,
    onItemClick,
  } = props;

  return (
    <ListItem
      onClick={onItemClick}
      sx={{
        px: 0,
        alignItems: 'flex-start',
        ...listItemSx,
      }}
    >
      {showAvatar &&
        <ListItemAvatar>
          {customAvatar ||
            <Avatar sx={avatarSx} alt={avatarAlt} src={avatarSrc}>
              {customAvatarIcon}
            </Avatar>
          }
        </ListItemAvatar>
      }
      <ListItemText
        primary={primaryText}
        secondary={
          <Stack>
            <Typography
              sx={{
                display: 'inline',
                ...secondaryTextTypographySx,
              }}
              component='span'
              variant={secondaryTextVariant || 'body2'}
              color='text.primary'
            >
              {secondaryText}
            </Typography>
            {secondaryChildren}
          </Stack>
        }
        {...listItemTextProps}
      />
      {children}
      {btnTitle &&
        <Stack sx={btnContainerSx} alignItems='flex-end'>
          <Button
            sx={{ width: 'fit-content' }}
            startIcon={showBtnIcon ?
              (
                iconChangeClause ?
                  <CheckIcon style={{ color: selectedColor }}/>
                  : <AddIcon style={{ color: notSelectedColor }}/>)
              : null
            }
            {...btnConfig}
          >
            <Typography variant='body2' color={iconChangeClause ? selectedColor : notSelectedColor}>
              {btnTitle}
            </Typography>
          </Button>
        </Stack>
      }
    </ListItem>
  );
};
