import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { capitalizeFirstLetter, fNumber, removeSubstrings } from 'src/utils';

import { MainBudget } from '../../../../../../types';

export interface TimelineCollapseProps {
  item: MainBudget;
  level: number;
  maxLevel: number;
  parentKey: string;
  siblingsItems: MainBudget[];
  expandedRows: Record<string, boolean>;
  onRowClick: (key: string) => void;
  borderLevel: number;
}

let lastParentElementExpanded = false;
export const TimelineCollapse = (props: TimelineCollapseProps) => {
  const {
    level,
    parentKey,
    item,
    onRowClick,
    expandedRows,
    maxLevel,
    borderLevel,
    siblingsItems,
  } = props;
  const key = `${parentKey}_${item.title}`;
  const isFirstItem = siblingsItems?.[0]?.title === item.title;
  const isLastItem = siblingsItems?.[siblingsItems?.length - 1]?.title === item.title;
  const isLastElementNotExpanded = level === borderLevel && isLastItem && (!expandedRows[key] || item?.subSections?.length === 0);

  const sideBorder = level > borderLevel - 1 ? '12px solid #f4f6f8' : 'none';
  const topBorder = (level === borderLevel && isFirstItem) ? '12px solid #f4f6f8' : 'none';
  const isLastElementExpanded = level === borderLevel && isLastItem && (expandedRows[key] && item?.subSections?.length > 0);
  let bottomBorder = 'none';

  if (isLastElementNotExpanded) {
    bottomBorder = '12px solid #f4f6f8';
    lastParentElementExpanded = false;
  }
  if (isLastElementExpanded) {
    lastParentElementExpanded = true;
  }

  if (level === maxLevel && isLastItem && lastParentElementExpanded) {
    bottomBorder = '12px solid #f4f6f8';
  }

  return (
    <>
      <TableRow
        style={{
          borderTop: topBorder,
          borderLeft: sideBorder,
          borderRight: sideBorder,
          borderBottom: bottomBorder,
        }}
      >
        <TableCell
          style={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: '350px',
          }}
        >
          {level < maxLevel && (
            <IconButton
              disabled={!item.subSections}
              size='small'
              onClick={() => onRowClick(key)}
              style={{
                marginRight: 27,
                backgroundColor: '#f7f8f9',
              }}
            >
              {(expandedRows[key] || !item.subSections) ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
            </IconButton>
          )}
          <Typography
            color='#212B36'
            component='span'
            fontWeight={level === 1 ? 'bold' : 'normal'}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              paddingLeft: level === maxLevel ? '61px' : '0px',
            }}
          >
            {capitalizeFirstLetter(removeSubstrings(['Cashflow'], item.title.toString()))}
          </Typography>
        </TableCell>
        {item.values.map((value, index) => {
          return (
            <TableCell
              key={index}
              align='right'
            >
              {value ? fNumber(value) : '-'}
            </TableCell>
          );
        })}
      </TableRow>
      {item.subSections && expandedRows[key] && (
        <>
          {item.subSections.map((subItem) => (
            <TimelineCollapse
              key={`${key}_${subItem.title}`}
              item={subItem}
              level={level + 1}
              maxLevel={maxLevel}
              parentKey={key}
              borderLevel={borderLevel}
              siblingsItems={item?.subSections}
              expandedRows={expandedRows}
              onRowClick={onRowClick}
            />
          ))}
        </>
      )}
    </>
  );
};
