import { Button, Link, List } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { CustomDialog, ListItemCustom } from 'src/components';
import { useBoolean } from 'src/hooks';
import { formatFullName } from 'src/utils';

import { ClientType } from '../../../../../types';
import { AddClientModal } from './add-client-modal';

export interface ClientsModalProps {
  open: boolean;
  onClose: () => void;
  selectedClients?: ClientType[];
  clientSelectHandler: (client: ClientType) => () => void;
  selectAllHandler: () => void;
  clients?: ClientType[];
  addClientHandler: (data: ClientType) => void;
}

export const ClientsModal = (props: ClientsModalProps) => {
  const {
    open,
    onClose,
    selectedClients = [],
    clients = [],
    clientSelectHandler,
    selectAllHandler,
    addClientHandler,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');

  const modalView = useBoolean();

  const handleFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const endIconOnClickHandler = () => {
    setSearchValue('');
  };

  const filteredClients = clients.filter((client) =>
    client.lastName?.toLowerCase().includes(searchValue.toLowerCase()) ||
    client.firstName?.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const addClientClickHandler = () => {
    modalView.onTrue();
  };

  return (
    <>
      <CustomDialog
        showCustomHead
        customHeadButtonTitle='Add'
        customHeadBtnProps={{ onClick: addClientClickHandler }}
        customHeadTitle='Clients'
        searchFieldProps={{
          value: searchValue,
          showEndIcon: Boolean(searchValue),
          endIconOnClickHandler: endIconOnClickHandler,
          handleFieldOnChange: handleFieldOnChange,
        }}
        dialogProps={{
          open,
          onClose,
        }}
        dialogContentChildren={
          <>
            <List sx={{ p: 0 }}>
              {filteredClients.map(client => {
                const clientExist = selectedClients.find(item => item._id === client._id);
                return (
                  <ListItemCustom
                    showBtnIcon
                    showAvatar
                    key={client._id}
                    avatarSrc={client.logoUrl}
                    avatarSx={{ borderRadius: 1.5 }}
                    avatarAlt={client.lastName}
                    primaryText={formatFullName(client.firstName, client.lastName)}
                    secondaryText={client.email}
                    btnConfig={{ onClick: clientSelectHandler(client) }}
                    btnTitle={Boolean(clientExist) ? 'Selected' : 'Select'}
                    iconChangeClause={Boolean(clientExist)}
                    secondaryChildren={
                      <>
                        <Link
                          underline='none'
                          target='_blank'
                          href={'/'}
                        >
                          Profile
                        </Link>
                      </>
                    }
                  />
                );
              })}
            </List>
          </>
        }
        dialogActionsChildren={
          <>
            <Button
              fullWidth
              variant='outlined'
              onClick={selectAllHandler}
            >
              Select All
            </Button>
          </>
        }
      />
      <AddClientModal
        modalView={modalView}
        addClientHandler={addClientHandler}
      />
    </>
  );
};
