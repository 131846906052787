import { Grid, Skeleton } from '@mui/material';

export const TimelineCashflowSkeleton = () => {
  return (
    <Grid container spacing={3} pl={3}>
      <Grid item xs={4}>
        <Skeleton variant='rounded' width='100%' height='380px'/>
      </Grid>
      <Grid container item xs={8} spacing={3} height='356px'>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant='rounded' width='100%' height='100%'/>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='rounded' width='100%' height='250px'/>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='rounded' width='100%' height='500px'/>
      </Grid>
    </Grid>
  );
};
