import { format, formatDistance, getTime, max, parseISO } from 'date-fns';

type InputValue = Date | string | number | null | undefined;

export const fDate = (date: InputValue, newFormat: string = 'dd MMM yyyy') => {
  return date ? format(new Date(date), newFormat) : '';
};

export const fDateTime = (date: InputValue, newFormat: string = 'dd MMM yyyy p') => {
  return date ? format(new Date(date), newFormat) : '';
};

export const fDistanceDate = (firstDate: InputValue, secondDate: Date = new Date()) => {
  return firstDate ? formatDistance(new Date(firstDate), secondDate, { addSuffix: false }) : '';
};

export const fTimestamp = (date: InputValue) => {
  return date ? getTime(new Date(date)) : '';
};

export const getNewestDate = (dates: string []) => {
  const parsedDates: Date[] = [];
  dates.forEach(date => {
    if (date && !date.includes('ago')) {
      parsedDates.push(parseISO(date));
    }
  });
  return parsedDates.length ? fDate(max(parsedDates)) : '';
};
