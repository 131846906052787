import { Box, Card, CardHeader, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import { EcommerceWidgetSummary, MapBox, ReportNote } from 'src/components/';
import { DEFAULT_CURRENCY } from 'src/constants/constants';

import { MarkerType, NoteSettingType, School, Station } from '../../../../../../types';

export interface LocalAreaType {
  locality?: string;
  narrativeAreaTitle?: string;
  narrative?: string;
  meanIncomeLocal?: number;
  medianAgeLocal?: number;
  mapCoordinates?: number[] | null;
  stations?: Station[];
  schools?: School[];
  showData?: boolean;
  noteSetting?: NoteSettingType;
}

export type StationRowType = {
  name: string;
  type: string;
  distance_from_site: number | string;
  travel_time_from_site: string;
}
export type SchoolRowType = {
  name: string;
  type: string;
  school_roll: string;
  distance_from_site: number | string;
  travel_time_from_site: string;
}

export const LocalArea = memo((props: LocalAreaType) => {
  const {
    medianAgeLocal,
    locality,
    narrativeAreaTitle,
    showData,
    meanIncomeLocal,
    stations = [],
    schools = [],
    mapCoordinates = null,
    narrative,
    noteSetting,
  } = props;

  const schoolObject = useMemo(() => {
    const schoolsRow: SchoolRowType[] = [];
    const schoolsMarkers: MarkerType[] = [];
    for (const item of schools) {
      schoolsRow.push({
        name: item.school || '-',
        type: item.type || '-',
        school_roll: item.totalNumberOfPupils || '-',
        distance_from_site: item.distance || '-',
        travel_time_from_site: item.time?.message || '-',
      });
      schoolsMarkers.push({
        location: {
          longitude: item?.coordinates?.geometry?.coordinates?.[0],
          latitude: item?.coordinates?.geometry?.coordinates?.[1],
        },
        info: {
          objectType: 'School',
          name: item.school,
        },
      });
    }
    return {
      markers: schoolsMarkers,
      row: schoolsRow,
    };
  }, [schools]);

  const stationObject = useMemo(() => {
    const stationsRow: StationRowType[] = [];
    const stationMarkers: MarkerType[] = [];
    for (const item of stations) {
      stationsRow.push({
        name: item.name || '-',
        type: item.closestDestination?.name || '-',
        distance_from_site: item.distance || '-',
        travel_time_from_site: item.time?.message || '-',
      });
      stationMarkers.push({
        location: {
          longitude: item?.coordinates?.geometry?.coordinates?.[0],
          latitude: item?.coordinates?.geometry?.coordinates?.[1],
        },
        info: {
          objectType: 'Station',
          name: item.name,
        },
      });
    }
    return {
      markers: stationMarkers,
      row: stationsRow,
    };
  }, [stations]);

  return <>
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>Local Area</Typography>
      </Grid>
      {noteSetting?.isActive &&
          <Grid item md={12}>
            {showData
              ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
              : <Skeleton variant='rounded' height='150px' width='100%'/>
            }
          </Grid>
      }
      <Grid item md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title='Area'
          subTitle='City neighborhood'
          total={locality}
        />
      </Grid>
      <Grid item xs={3} md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title={`Median Income, ${DEFAULT_CURRENCY}`}
          subTitle='City neighborhood'
          total={meanIncomeLocal}
        />
      </Grid>
      <Grid item xs={3} md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title='Median age'
          subTitle='Municipality'
          total={medianAgeLocal}
        />
      </Grid>
      {!showData &&
          <Grid item md={12}>
            <Skeleton variant='rounded' height='150px' width='100%'/>
          </Grid>
      }
      <Grid item md={12}>
        {showData ?
          <Card>
            <CardHeader title='About the Area'/>
            <Stack
              spacing={1.5}
              sx={{
                p: 3,
                typography: 'body2',
              }}
            >
              <Stack direction='row' alignItems='center'>
                <Typography variant='subtitle2' mr={0.5}>Area:</Typography>
                {narrativeAreaTitle || 'Unspecified'}
              </Stack>
              <Stack direction='row' alignItems='center'>
                {narrative || 'Unspecified'}
              </Stack>
              <Box sx={{ marginTop: '24px' }}>
                <MapBox
                  height='443px'
                  width='100%'
                  mapStyle='street'
                  markers={[...schoolObject.markers, ...stationObject.markers]}
                  coordinates={mapCoordinates || null}
                />
              </Box>
            </Stack>
          </Card>
          : <Skeleton variant='rounded' height='660px' width='100%'/>
        }
      </Grid>
      <Grid item md={12}>
        {showData ?
          <>
            {stationObject.row.length > 0 &&
                <Card>
                  <CardHeader title='Transport'/>
                  <Table sx={{ marginTop: '24px' }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align='left'>Type</TableCell>
                        <TableCell align='right'>Distance from site</TableCell>
                        <TableCell align='right'>Travel time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stationObject.row.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component='th' scope='row'>{row.name}</TableCell>
                          <TableCell align='left'>{row.type}</TableCell>
                          <TableCell
                            align='right'
                          >
                            {row.distance_from_site === '-' ? '-' : row.distance_from_site + ' km'}
                          </TableCell>
                          <TableCell align='right'>{row.travel_time_from_site}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
            }
          </>
          : <Skeleton variant='rounded' height='354px' width='100%'/>
        }
      </Grid>
      <Grid item md={12}>
        {showData ?
          <>
            {schoolObject.row.length > 0 &&
                <Card>
                  <CardHeader title='Schools'/>
                  <Table sx={{ marginTop: '24px' }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align='left'>Type</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>School roll</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>Distance from site</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>Travel time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {schoolObject.row.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component='th' scope='row'>{row.name}</TableCell>
                          <TableCell align='left'>{row.type}</TableCell>
                          <TableCell align='right'>{row.school_roll}</TableCell>
                          <TableCell
                            align='right'>
                            {row.distance_from_site === '-' ? '-' : row.distance_from_site + ' km'}
                          </TableCell>
                          <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
                            {row.travel_time_from_site}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
            }
          </>
          : <Skeleton variant='rounded' height='354px' width='100%'/>
        }
      </Grid>
    </Grid>
  </>;
});
