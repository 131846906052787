import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Slide, SlideProps, Snackbar, Stack, Typography } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { primaryDefaultTextColor } from 'src/constants/colors';
import { RootState } from 'src/store';
import { defaultSnackbarProps, updateSnackbarConfig } from 'src/store/storage';

export interface CustomSnackbarProps {
  show?: boolean;
  title?: string;
  iconKey?: keyof typeof customIcons;
  color?: 'success' | 'info' | 'warning' | 'error';
  timeout?: number;
}

export const customIcons = { 'success': <CheckCircleIcon sx={{ color: '#22C55E' }}/> };
export const backgroundColor = { 'success': '#22C55E29' };

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction='up'/>;
};

export const CustomSnackbar = () => {
  const snackbarConfig = useSelector((state: RootState) => state.Storage.snackbar);
  const dispatch = useDispatch();

  const onCloseHandler = (_: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(updateSnackbarConfig(defaultSnackbarProps));
  };

  return (
    <Snackbar
      open={snackbarConfig?.show}
      TransitionComponent={SlideTransition}
      color={snackbarConfig?.color}
      onClose={onCloseHandler}
      autoHideDuration={snackbarConfig?.timeout}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      sx={{
        minWidth: 382,
        display: 'flex',
        justifyContent: 'flex-start',
        p: 0.5,
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: 1,
        boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
      }}
    >
      <Stack p={0} direction='row' gap={1.5} alignItems='center'>
        <Box
          p={1.5}
          display='flex'
          alignItems='center'
          sx={{
            backgroundColor: backgroundColor[snackbarConfig?.iconKey || 'success'],
            borderRadius: 1,
          }}
        >
          {customIcons[snackbarConfig?.iconKey || 'success']}
        </Box>
        <Typography
          variant='subtitle2' color={primaryDefaultTextColor}
        >
          {snackbarConfig?.title}
        </Typography>
      </Stack>
    </Snackbar>
  );
};
