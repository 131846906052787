import { Card, Stack, Typography } from '@mui/material';
import { CheckmarkCircleIcon } from 'src/assets/svg';
import { dashedBorder, greyCheckmark, greyCircle } from 'src/constants/colors';

import { FinancialSheet } from '../../../../../types';

export interface DebtInvoicesProps {
  financialSheets: FinancialSheet[];
}

export const DebtInvoices = (props: DebtInvoicesProps) => {
  const { financialSheets } = props;
  return (
    <Card sx={{
      display: 'flex',
      py: 2,
      pl: '21.5px',
      gap: '21.5px',
    }}>
      {financialSheets.map((financialSheet, index) => {
        const {
          fillColor,
          strokeColor,
          type,
          maxSteps,
          completedSteps,
        } = financialSheet;
        const fillCheckmark = completedSteps === maxSteps && completedSteps !== 0;
        return (
          <Stack
            key={type}
            flexDirection='row'
            sx={{
              gap: 2.5,
              borderLeft: index === 0 ? 'none' : dashedBorder,
              pl: index === 0 ? '0' : '19.5px',
            }}
          >
            <CheckmarkCircleIcon
              fill={fillCheckmark ? fillColor : greyCheckmark}
              stroke={fillCheckmark ? strokeColor : greyCircle}
            />
            <Stack justifyContent='space-between' py='3px'>
              <Typography variant='subtitle1'>{type}</Typography>
              <Typography variant='body2' color='text.primary'>{`${completedSteps}/${maxSteps} Term Sheet`}</Typography>
            </Stack>
          </Stack>
        );
      })}
    </Card>
  );
};
