import { Dispatch, SetStateAction } from 'react';
import { UploadPhotos } from 'src/components';

import { CoverImagesType } from '../../../../../types';

export interface ImagesProps {
  coverImages: CoverImagesType[];
  coverImagesToDelete: CoverImagesType[];
  setCoverImages: Dispatch<SetStateAction<CoverImagesType[]>>;
  setNewCoverImageId: Dispatch<SetStateAction<string>>;
  setCoverImagesToDelete: Dispatch<SetStateAction<CoverImagesType[]>>;
}

export const Images = (props: ImagesProps) => {
  const {
    coverImages,
    setCoverImages,
    setCoverImagesToDelete,
    setNewCoverImageId,
    coverImagesToDelete,
  } = props;

  const uploadCoverImagesHandler = async (files: CoverImagesType[]) => {
    const availableToUpload = 100 - coverImages.length;
    const slicedFiles = availableToUpload > 0 ? files.splice(0, availableToUpload) : [];
    setCoverImages([...coverImages, ...slicedFiles]);
  };

  const deleteCoverImageHandler = (file: CoverImagesType, updatedImages: CoverImagesType[]) => {
    if (!file.file) {
      setCoverImagesToDelete([...coverImagesToDelete, file]);
    }
    if (updatedImages.length > 0 && file.isCoverImage) {
      setNewCoverImageId(updatedImages[0]._id);
    }
    setCoverImages(updatedImages);
  };

  const setImageAsCoverHandler = (file: CoverImagesType) => {
    const updatedCover: CoverImagesType[] = coverImages.map(image => {
      return {
        ...image,
        isCoverImage: file._id === image._id,
      };
    });
    setNewCoverImageId(file._id);
    setCoverImages(updatedCover);
  };

  const sortCoverImagesHandler = (files: CoverImagesType[]) => {
    setCoverImages(files);
  };

  return (
    <UploadPhotos
      coverImages={coverImages}
      sortCoverImages={sortCoverImagesHandler}
      deleteCoverImageHandler={deleteCoverImageHandler}
      setImageAsCoverHandler={setImageAsCoverHandler}
      uploadCoverImagesHandler={uploadCoverImagesHandler}
      boxSx={{ width: 'calc(33.33% - 6px)' }}
      imageSx={{ width: 'calc(33.33% - 6px)' }}
      chipSx={{
        top: '8px',
        left: '8px',
      }}
      noScrollToError
    />
  );
};
