import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';

import { FundingDataType, FundingType, MarketplaceOffer, MarketplaceStat } from '../../../../../../types';
import { DashboardStats } from './dashboard-stats';
import { FundingActivity } from './funding-activity';
import { FundingTotal } from './funding-total';
import { OngoingOfferings } from './ongoing-offerings';

type DashboardProps = {
  stats?: MarketplaceStat[];
  title?: string;
  projects?: MarketplaceOffer[];
  onOfferingClick: (offering: MarketplaceOffer) => void;
  onViewAllOfferingsClick?: () => void;
  chartData?: FundingDataType;
  funding?: FundingType;
}

export const Dashboard = (props: DashboardProps) => {
  const {
    title,
    stats = [],
    onOfferingClick,
    onViewAllOfferingsClick,
    projects = [],
    chartData,
    funding,
  } = props;

  return (
    <Stack gap={5}>
      {title && <Typography variant='h4'>{title}</Typography>}
      <DashboardStats stats={stats}/>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <FundingActivity chartData={chartData}/>
        </Grid>
        <Grid item xs={4}>
          <FundingTotal funding={funding}/>
        </Grid>
      </Grid>
      <OngoingOfferings
        offerings={projects}
        onOfferingClick={onOfferingClick}
        onViewAllClick={onViewAllOfferingsClick}
      />
    </Stack>
  );
};
