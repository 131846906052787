import { Box, Button, Card, CardHeader, Skeleton, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { useBoolean, useResponsive } from 'src/hooks';

import { MapBox } from '../map';
import { ModalCustom } from '../modal';
import { DetailsList } from './details-list';

interface LandDetailProps {
  headLabel: string;
  coordinates?: number[] | null;
  fields: FieldType[];
  showSkeleton: boolean;
  showMap?: boolean;
}

export type FieldType = {
  label: string;
  value?: string | number | ReactNode;
  color?: string;
  subFields?: FieldType[];
}

export const LandDetail = (props: LandDetailProps) => {

  const {
    headLabel,
    fields,
    coordinates = null,
    showSkeleton,
    showMap = true,
  } = props;
  const mdUp = useResponsive('up', 'md');
  const mapBool = useBoolean();

  return (
    <>
      {
        showSkeleton ? (
          <Skeleton variant='rounded' height='660px' width='100%'/>
        ) : (
          <Card>
            <CardHeader title={headLabel}/>
            <Stack
              spacing={2}
              sx={{ typography: 'body2' }}
            >
              <DetailsList fields={fields}/>
              {showMap && (
                <>
                  {mdUp ?
                    <Box sx={{ padding: '0 24px 24px 24px' }}>
                      <MapBox
                        height='443px'
                        width='100%'
                        mapStyle='satellite'
                        coordinates={coordinates}
                      />
                    </Box>
                    : <>
                      <Button
                        disabled={!coordinates}
                        onClick={mapBool.onTrue}
                        variant='outlined'
                        sx={{ m: '-10px 24px 30px' }}
                      >
                        Show Map
                      </Button>
                      <ModalCustom
                        open={mapBool.value}
                        onClose={mapBool.onFalse}
                        bodyChildren={
                          <MapBox
                            height='443px'
                            width='100%'
                            mapStyle='satellite'
                            coordinates={coordinates}
                          />
                        }
                        footerChildrenSx={{ display: 'none' }}
                      />
                    </>
                  }
                </>
              )}
            </Stack>
          </Card>
        )
      }
    </>
  );
};

