import { ReactNode } from 'react';

import { Page } from './page';

interface PageWrapProps {
  children: ReactNode;
}

export const PageWrap = ({ children }: PageWrapProps) => {
  return (
    <Page>
      {children}
    </Page>
  );
};
