import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack, { StackProps } from '@mui/material/Stack';

type BlockProps = StackProps & {
  label: string;
};

export const Block = (props: BlockProps) => {
  const {
    label,
    children,
    sx,
    ...other
  } = props;

  return (
    <Stack
      component={Paper}
      variant='outlined'
      spacing={1}
      direction='row'
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component='span' sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction='row' flexWrap='wrap'>
        {children}
      </Stack>
    </Stack>
  );
};
