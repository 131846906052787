import { List } from '@mui/material';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomDialog, ListItemCustom } from 'src/components';
import { formatFullName } from 'src/utils';

import { AssignmentMember, User } from '../../../../../types';


export interface ManagersModalProps {
  open: boolean;
  onClose: () => void;
  role?: string;
  handleAssign: (user: User) => () => void;
  users: User[];
  assignedUser?: AssignmentMember;
}

export const ManagersModal = (props: ManagersModalProps) => {
  const {
    open,
    role,
    onClose,
    handleAssign,
    users,
    assignedUser,
  } = props;
  const [currentUsers, setCurrentUsers] = useState<User[]>([]);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setCurrentUsers(users);
  }, [users]);

  const handleFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    filterManagersDebounce(newValue);
  };

  const filterManagers = useCallback((newValue: string) => {
    const filteredManagers = users.filter(user => {
      const lowerValue = newValue.toLowerCase();
      return user?.name?.toLowerCase()?.includes(lowerValue) || user?.surname?.toLowerCase()?.includes(lowerValue) || user?.email?.toLowerCase()?.includes(lowerValue);
    });
    setCurrentUsers(filteredManagers);
  }, [users]);

  const filterManagersDebounce = useMemo(() => {
    return debounce(filterManagers, 500);
  }, [filterManagers]);

  return (
    <CustomDialog
      showCustomHead
      customHeadTitle={role}
      searchFieldProps={{
        value: value,
        handleFieldOnChange: handleFieldOnChange,
      }}
      dialogProps={{
        open,
        onClose,
      }}
      dialogContentChildren={
        <>
          <List sx={{ p: 0 }}>
            {currentUsers.map((user) => {
              // const avatarSrc = `/api/users/${user.id}`;
              const avatarSrc = '';
              const fullName = formatFullName(user?.name, user?.surname);
              const isAssigned = user.id === assignedUser?.profileLink;
              return (
                <ListItemCustom
                  showBtnIcon
                  showAvatar
                  key={user.id}
                  iconChangeClause={isAssigned}
                  avatarSrc={avatarSrc}
                  avatarAlt={user?.name}
                  primaryText={fullName}
                  secondaryText={user.email}
                  btnConfig={{ onClick: handleAssign(user) }}
                  btnTitle={isAssigned ? 'Assigned' : 'Assign'}
                />
              );
            })}
          </List>
        </>
      }
    />
  );
};
