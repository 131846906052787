import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { memo } from 'react';
import { Logo, NavSectionMini, NavToggleButton, PartnerLogo, useMenuData } from 'src/components';
import { NAV } from 'src/constants/constants';
import { usePortalFlags } from 'src/hooks';
import { hideScroll } from 'src/theme/css';

export const NavMini = memo(() => {
  const menuData = useMenuData();
  const { isPartnerPortal } = usePortalFlags();
  const LogoComp = isPartnerPortal ? PartnerLogo : Logo;

  return (
    <Box
      component='nav'
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <LogoComp sx={{
          mx: 'auto',
          my: 2,
        }}/>

        <NavSectionMini
          data={menuData}
        />
      </Stack>
    </Box>
  );
});
