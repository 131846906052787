import { FinanceUploadFileType } from '../../../types';
import { createDocument, uploadDocument } from '../store/financial';
import { CreateDocumentRequestType } from '../store/financial/types';

export const useDocuments = () => {
  const createAndUploadDocument = async (file: FinanceUploadFileType, projectId?: string, leadId?: string, customConfig?: CreateDocumentRequestType) => {
    const defaultDocParams = projectId ? {
      projectId: projectId,
      sectionIds: [file._id],
    } : undefined;
    const leadConfig = {
      leadId: leadId,
      sectionIds: [file._id],
    };
    const documentId = await createDocument(customConfig || defaultDocParams || leadConfig);
    if (documentId) {
      const uploadedFile = await uploadDocument({
        ...file,
        _id: documentId?.[0] || '',
      });
      return uploadedFile;
    }
  };

  return { createAndUploadDocument };
};
