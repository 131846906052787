import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { memo, MouseEvent } from 'react';
import { ConfirmDialog, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { paths } from 'src/config';
import { useBoolean, usePopover } from 'src/hooks';
import { fNumberWithDefaultCurrency, isCLickOnRow, showEmptyValue } from 'src/utils';

import { DefaultPreparationStatusColors, ProjectPreparation } from '../../../../../types';

interface PreparationTableRowProps {
  row: ProjectPreparation;
  onViewRow: VoidFunction;
  onDeleteRow: VoidFunction;
  reviewOnClickHandler: (id: string, pathKey: keyof typeof paths.projectPreparation, tab: string) => () => void;
  statusColor: DefaultPreparationStatusColors;
}


export const PreparationTableRow = memo((props: PreparationTableRowProps) => {
  const theme = useTheme();

  const {
    row,
    onViewRow,
    onDeleteRow,
    reviewOnClickHandler,
    statusColor,
  } = props;
  const {
    _id,
    project,
    status,
    lastActivity,
  } = row;

  const confirm = useBoolean();
  const popover = usePopover();

  const preparationMoreOption: OptionType[] = [
    {
      label: 'View',
      icon: 'view',
      closePopover: popover.onClose,
      itemOnClickHandler: onViewRow,
    },
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onViewRow();
  };

  const reviewOnClickHandlerWrapper = () => {
    reviewOnClickHandler(_id, 'details', 'overview')();
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2' color='text.primary'>
                {project?.name}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}
              >
                {project?.scenario?.calculate?.input.type_of_project}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <Typography
            noWrap
            variant='body2'>
            {fNumberWithDefaultCurrency(project?.scenario?.calculate?.output?.GDV)}
          </Typography>
          <Typography
            noWrap
            variant='caption'
            color={theme.palette.text.secondary}
          >
            {fNumberWithDefaultCurrency(project?.scenario?.calculate?.output?.['Net profit'])}
          </Typography>
        </TableCell>
        <TableCell>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2' color='text.primary'>
                {showEmptyValue(lastActivity?.message)}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}
              >
                {showEmptyValue(lastActivity?.date)}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            color={statusColor[status] ?? 'default'}
            label={status}
          />
        </TableCell>
        <TableCell
          size='small'
          padding='none'
          align='right'
          sx={{
            px: 1,
            whiteSpace: 'nowrap',
          }}
        >
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            variant='outlined'
            size='small'
            onClick={reviewOnClickHandlerWrapper}
          >
            Review
          </Button>
          <MoreOptions
            options={preparationMoreOption}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
      </StyledTableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
});
