import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import useTheme from '@mui/material/styles/useTheme';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CasePartnersIcon } from 'src/assets';
import { CrumbType, CustomSelect, DetailView, generateSummaryField } from 'src/components';
import { paths } from 'src/config';
import { blueGradient } from 'src/constants/colors';
import { DEFAULT_AREA, sellList, sellStatusColor } from 'src/constants/constants';
import { useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { updateSellStatus } from 'src/store/sell';

import { SellStatusListType } from '../../../../types';

export const SellDetails = () => {
  const router = useRouter();
  const theme = useTheme();
  const dispatch = useDispatch();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);

  const project = detailsData?.project;
  const scenario = project?.scenario;
  const distribution = detailsData?.distribution;

  const colorsConfig = useMemo(() => {
    const colors: Record<SellStatusListType, string> = {
      'Not Selling': theme.palette.text.disabled,
      'Sold': theme.palette.success.main,
      'All': theme.palette.success.main,
      'On Sale': theme.palette.success.main,
    };
    return colors;
  }, [theme.palette.success.main, theme.palette.text.disabled]);

  const crumbs: CrumbType[] = [
    {
      title: 'Execution',
      href: paths.sell.list,
    },
    {
      title: 'Project for Sale',
      href: paths.financing.list,
    },
    { title: project?.name },
  ];

  const summaryItems = useMemo(() => {
    return generateSummaryField({
      phase: 'Preliminary',
      projectType: scenario?.calculate?.input.type_of_project,
      size: scenario?.calculate?.output['Total floor area'] + ` ${DEFAULT_AREA}`,
    });
  }, [scenario?.calculate?.input.type_of_project, scenario?.calculate?.output]);

  const handleSelectOnChange = (event: SelectChangeEvent<string[]>) => {
    const newStatus = event.target.value as SellStatusListType;
    dispatch(updateSellStatus(newStatus));
  };

  const onCloudBtnClickHandler = () => {
    router.push('sell-project');
  };

  const previewSaleReportClickHandler = () => {
    // openInNewTab(paths.reports.saleReport(id));
  };

  return (
    <DetailView
      showToolbarBadge
      showOverviewCloud
      customSummaryItemsOnOverview={summaryItems}
      componentsViews='default'
      crumbs={crumbs}
      showData={!isLoading}
      toolbarTitle={project?.name}
      toolbarBadgeVariant='outlined'
      toolbarBadgeTitle={distribution?.status || 'Not Selling'}
      toolbarBadgeColor={sellStatusColor[distribution?.status || 'Not Selling']}
      // todo: change note section
      noteSection='Sell'
      cloudProps={{
        showCloud: true,
        cardSx: { background: blueGradient },
        avatar: <CasePartnersIcon/>,
        btnTitle: 'Share',
        title: 'Sell Project',
        subtitle: 'Everything is ready to send proposal',
        titleColor: theme.palette.success.darker,
        subTitleColor: theme.palette.success.darker,
        onBtnClickHandler: onCloudBtnClickHandler,
      }}
      toolbarChildren={(
        <Stack direction='row' gap={1.5}>
          <CustomSelect
            showCircle
            controlSx={{ minWidth: 120 }}
            configKey='colors'
            value={[distribution?.status || 'Not Selling']}
            colorsConfig={colorsConfig}
            options={sellList}
            handleSelectOnChange={handleSelectOnChange}
          />
          <Button
            variant='outlined'
            startIcon={<VisibilityIcon/>}
            disabled
            onClick={previewSaleReportClickHandler}
          >
            Preview
          </Button>
        </Stack>
      )}
    />
  );
};
