import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IcUserGroupIcon } from 'src/assets/svg';
import { ManagersModal } from 'src/features/new-lead';
import { useBoolean } from 'src/hooks';
import { RootState } from 'src/store';
import { getUserList } from 'src/store/app';

import { AssignmentMember, User } from '../../../../../../../../types';

interface SalesManagerProps {
  manager?: AssignmentMember;
  setManager: (manager?: AssignmentMember) => void;
}

export const SalesManager = (props: SalesManagerProps) => {
  const {
    manager,
    setManager,
  } = props;
  const [modalManager, setModalManager] = useState<AssignmentMember | undefined>(manager);
  const drawer = useBoolean();
  const dispatch = useDispatch();
  const userList = useSelector((state: RootState) => state.App.userList);
  const currentUser = useSelector((state: RootState) => state.Auth.user);

  const filteredUserList = userList.filter(user => user.partnerId === currentUser?.partnerId);
  const isEmpty = !manager;

  useEffect(() => {
    !userList.length && dispatch(getUserList());
  }, [dispatch, userList.length]);

  useEffect(() => {
    setModalManager(manager);
  }, [manager, drawer.value]);

  const handleOnAssign = (user: User) => () => {
    const isSameUser = user.id === manager?.profileLink;
    setManager(!isSameUser ? {
      ...user,
      leadId: user.id,
      designation: 'Sales manager',
      profileLink: user.id,
    } : undefined);
  };

  return (
    <>
      <Card sx={{ flex: 1 }}>
        {isEmpty ?
          <CardContent>
            <Stack gap={3} textAlign='center' alignItems='center'>
              <Stack
                gap={2}
                textAlign='center'
                alignItems='center'
                justifyContent='center'
              >
                <IcUserGroupIcon width={64} height={64}/>
                <Typography variant='h6'>Sales Manager is not Assigned</Typography>
              </Stack>

              <Button onClick={drawer.onTrue} variant='contained' size='medium'>
                Assign
              </Button>
            </Stack>
          </CardContent> :
          <>
            <CardContent>
              <Stack gap={2} direction='row' alignItems='center'>
                <Avatar
                  sx={{
                    width: 48,
                    height: 48,
                    brs: 24,
                  }}
                  alt={manager.name}
                  src=''
                />
                <Stack
                  gap={2}
                  direction='row'
                  alignItems='flex-start'
                  justifyContent='space-between'
                  sx={{ flex: 1 }}
                >
                  <Stack gap={0.5}>
                    <Typography variant='subtitle2'>
                      {manager.name}
                    </Typography>
                    <Typography variant='caption' color='text.disabled'>
                      {manager.role}
                    </Typography>
                  </Stack>
                  <Button
                    onClick={drawer.onTrue}
                    variant='outlined' size='small'
                  >
                    Change
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </>
        }
      </Card>
      <ManagersModal
        open={drawer.value}
        role='Assign Sales Manager'
        users={filteredUserList}
        onClose={drawer.onFalse}
        handleAssign={handleOnAssign}
        assignedUser={modalManager}
      />
    </>
  );
};
