import { Avatar, IconButton, Link, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CrossIcon } from 'src/assets/svg';
import { DrawerCustom } from 'src/components';
import { paths } from 'src/config';
import { RootState } from 'src/store';
import { addOfferThunk, updateOfferThunk, uploadFileToTermSheetThunk } from 'src/store/financial';

import { FinanceUploadFileType, FinancialTableType } from '../../../../../types';
import { AddOfferDrawer } from './add-offer-drawer';
import { DetailsDrawer } from './details-drawer';
import { DrawerTabType } from './financial';
import { SheetRequestDrawer } from './sheet-request-drawer';
import { UploadTermSheetDrawer } from './upload-term-sheet-drawer';

export interface FinancialDrawerManagerProps {
  showDrawer: boolean;
  drawerOnClose: VoidFunction;
  currentDrawerTab: DrawerTabType;
}

export const FinancialDrawerManager = (props: FinancialDrawerManagerProps) => {
  const {
    showDrawer,
    drawerOnClose,
    currentDrawerTab,
  } = props;
  const currentItem = useSelector((state: RootState) => state.Financial.requestItem);
  const dispatch = useDispatch();

  const getDrawerHead = useCallback(() => {
    const heads: Record<DrawerTabType, string> = {
      'offer': 'Add Offer',
      'update-offer': 'Update Offer Details',
      'request': 'Term Sheet Request',
      'upload': 'Upload Term Sheet',
      'agreementUpload': 'Debt Financing Agreement Upload',
      'details': 'Details',
      '': '',
    };
    return heads[currentDrawerTab ?? ''];
  }, [currentDrawerTab]);

  const drawerHead = (
    <>
      <Stack direction='row' justifyContent='space-between' width='100%' mb={3}>
        <Typography variant='h6'>{getDrawerHead()}</Typography>
        <IconButton onClick={drawerOnClose}>
          <CrossIcon/>
        </IconButton>
      </Stack>
      <ListItem sx={{ p: 0 }}>
        <ListItemAvatar>
          <Avatar variant='rounded' alt='Bank' src={currentItem?.bankSrc}/>
        </ListItemAvatar>
        <ListItemText
          primary={currentItem?.bankName}
          secondary={
            <Link
              underline='none'
              target='_blank'
              color='#3698AF'
              href={paths.bankPartners.products(currentItem?.bankId || '')}
            >
              Profile
            </Link>
          }
        />
      </ListItem>
    </>
  );

  const uploadTermSheet = useCallback((item: FinancialTableType, file: FinanceUploadFileType) => {
    dispatch(uploadFileToTermSheetThunk(item._id, file, item.bankId || ''));
  }, [dispatch]);

  // const uploadDebtAgreement = useCallback((itemId: string, file: FinanceUploadFileType) => {
  // dispatch(uploadAgreementFile({
  //   itemId,
  //   file,
  // }));
  // }, [dispatch]);

  return (
    <DrawerCustom
      open={showDrawer}
      onCloseDrawerHandler={drawerOnClose}
      headChildren={drawerHead}
      headChildrenSx={{
        flexDirection: 'column',
        pb: 0,
      }}
      bodyChildren={
        <>
          {currentDrawerTab === 'offer' &&
            <AddOfferDrawer
              item={currentItem}
              dispatchFunction={addOfferThunk}
              closeDrawerHandler={drawerOnClose}
              btnTitle='Add Offer'
            />
          }
          {currentDrawerTab === 'update-offer' &&
            <AddOfferDrawer
              item={currentItem}
              dispatchFunction={updateOfferThunk}
              closeDrawerHandler={drawerOnClose}
              btnTitle='Save changes'
            />
          }
          {currentDrawerTab === 'request' &&
            <SheetRequestDrawer
              item={currentItem}
              closeDrawerHandler={drawerOnClose}
            />
          }
          {currentDrawerTab === 'upload' &&
            <UploadTermSheetDrawer
              item={currentItem}
              closeDrawerHandler={drawerOnClose}
              submitFunction={uploadTermSheet}
            />
          }
          {/*{currentDrawerTab === 'agreementUpload' &&*/}
          {/*    <UploadTermSheetDrawer*/}
          {/*      item={currentItem}*/}
          {/*      closeDrawerHandler={drawerOnClose}*/}
          {/*      submitFunction={uploadDebtAgreement}*/}
          {/*    />*/}
          {/*}*/}
          {currentDrawerTab === 'details' &&
            <DetailsDrawer
              item={currentItem}
            />
          }
        </>
      }
      bodyChildrenSx={{
        gap: 3,
        height: '100%',
      }}
    />
  );
};
