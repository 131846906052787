import { List } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ChangeEvent } from 'react';

import { FinanceUploadFileType } from '../../../../../types';
import { DocumentListItem } from './document-list-item';

export interface DocumentsListProps {
  selectedDocumentTypes: FinanceUploadFileType[];
  fileInputOnChangeHandler: (itemId: string) => (event: ChangeEvent<HTMLInputElement>) => void;
  deleteOnClickHandler: (itemId: string) => VoidFunction;
  showBorderTop?: boolean;
  showMoreOptions?: boolean;
  showDownloadButton?: boolean;
  listSx?: SxProps<Theme>;
}

export const DocumentsList = (props: DocumentsListProps) => {
  const {
    selectedDocumentTypes,
    fileInputOnChangeHandler,
    deleteOnClickHandler,
    listSx,
    showBorderTop,
    showMoreOptions,
    showDownloadButton,
  } = props;

  return (
    <List sx={{ p: 3, ...listSx }}>
      {selectedDocumentTypes.map((selectedDocument, index) => {
        return (
          <DocumentListItem
            showDownloadButton={showDownloadButton}
            showMoreOptions={showMoreOptions}
            key={selectedDocument._id}
            index={index}
            showBorderTop={showBorderTop}
            selectedDocument={selectedDocument}
            fileInputOnChangeHandler={fileInputOnChangeHandler(selectedDocument._id)}
            deleteOnClickHandler={deleteOnClickHandler(selectedDocument._id)}
          />
        );
      })}
    </List>
  );
};
