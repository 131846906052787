import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { ChatIcon } from 'src/assets';
import { ListItemCustom } from 'src/components';

import { ReportKeyType } from '../../../../../../../types';
import { OverviewFinOffer } from './overview-fin-offer';
import { OverviewOffer } from './overview-offer';
import { OverviewSaleOffer } from './overview-sale-offer';

type OverviewSidebarProps = {
  type: ReportKeyType;
}

export const OverviewSidebar = (props: OverviewSidebarProps) => {
  const { type } = props;
  const theme = useTheme();

  const isBank = type === 'bank';
  const isSale = type === 'seller';
  const isSupplier = type === 'supplier';
  const showContactPerson = !isSale;

  return (
    <Stack
      gap={3}
      sx={{
        width: 320,
        [theme.breakpoints.down('md')]: {
          margin: 'auto',
          minWidth: 320,
          width: '100%',
        },
      }}
    >
      {isBank &&
        <OverviewFinOffer/>
      }
      {isSupplier &&
        <OverviewOffer/>
      }

      {isSale &&
        <OverviewSaleOffer/>
      }

      {showContactPerson &&
        <Card>
          <CardContent>
            <Stack gap={4}>
              <ListItemCustom
                showAvatar
                avatarSrc='Max Smith'
                avatarAlt='Max Smith'
                primaryText='Max Smith'
                secondaryText='Project Manager'
                secondaryTextVariant='caption'
                secondaryTextTypographySx={{ color: theme.palette.text.disabled }}
              />
              <Button
                variant='outlined'
                size='large'
                startIcon={<ChatIcon width={24} height={24}/>}
              >
                Contact
              </Button>
            </Stack>
          </CardContent>
        </Card>
      }
    </Stack>
  );
};
