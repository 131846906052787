import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { CardBarFiltersChart, ChartWithFiltersType, SeriesWithFiltersType } from 'src/components';
import { monthsShortLabels } from 'src/constants/constants';

import { FundingDataType } from '../../../../../../types';

type FundingActivityProps = {
  chartData?: FundingDataType
  title?: string;
}

export const FundingActivity = (props: FundingActivityProps) => {
  const theme = useTheme();
  const {
    chartData,
    title = 'Funding Activity',
  } = props;

  const generatedMockDataForChart: ChartWithFiltersType = useMemo(() => {
    const series: SeriesWithFiltersType[] = [
      {
        seriesFilter: 'Week',
        data: [
          {
            name: 'Soft Committed',
            data: chartData?.week.softCommitted || [],
          },
          {
            name: 'Capital Secured',
            data: chartData?.week.secured || [],
          },
        ],
      },
      {
        seriesFilter: 'Month',
        data: [
          {
            name: 'Soft Committed',
            data: chartData?.month.softCommitted || [],
          },
          {
            name: 'Capital Secured',
            data: chartData?.month.secured || [],
          },
        ],
      },
      {
        seriesFilter: 'Year',
        data: [
          {
            name: 'Soft Committed',
            data: chartData?.year.softCommitted || [],
          },
          {
            name: 'Capital Secured',
            data: chartData?.year.secured || [],
          },
        ],
      },
    ];

    return {
      categories: monthsShortLabels,
      colors: [theme.palette.primary.lighter, theme.palette.primary.main],
      series: series,
    };
  }, [chartData?.month.secured, chartData?.month.softCommitted, chartData?.week.secured, chartData?.week.softCommitted, chartData?.year.secured, chartData?.year.softCommitted, theme.palette.primary.lighter, theme.palette.primary.main]);

  return (
    <CardBarFiltersChart
      cardProps={{
        sx: {
          flex: 1,
          height: '100%',
        },
      }}
      title={title}
      seriesValues={['Week', 'Month', 'Year']}
      chart={generatedMockDataForChart}
    />
  );
};
