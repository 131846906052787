import { Card, CardHeader, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { CardDonutChart, EcommerceWidgetSummary, ReportNote } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { RootState } from 'src/store';
import { fNumber } from 'src/utils';

import { NoteSettingType } from '../../../../../../types';
import { InfoBlock } from '../info-block';
import { useFinanceResult } from './use-finance-result';

export interface FinanceProps {
  noteSetting?: NoteSettingType;
}

export const Finance = (props: FinanceProps) => {
  const { noteSetting } = props;
  const scenario = useSelector((state: RootState) => state.Storage.detailsData.project?.scenario);
  const {
    rows,
    series,
  } = useFinanceResult(scenario?.calculate?.input, scenario?.calculate?.output);
  const mdUp = useResponsive('up', 'md');

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>Finance</Typography>
      </Grid>
      {noteSetting?.isActive &&
          <Grid hidden={!mdUp} item md={12}>
            {scenario
              ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
              : <Skeleton variant='rounded' height='150px' width='100%'/>
            }
          </Grid>
      }
      <Grid item md={8} xs={12}>
        <CardDonutChart
          showWidget={!!scenario}
          title='Total Project Cost'
          chartHeader={`Total project cost, ${DEFAULT_CURRENCY}`}
          chart={{ series: series }}
        />
      </Grid>
      <Grid container item md={4} rowGap={3} xs={12}>
        <Grid item md={12} xs={12}>
          <EcommerceWidgetSummary
            showWidget={!!scenario}
            title={`Total debt, ${DEFAULT_CURRENCY}`}
            total={scenario?.calculate?.output?.['Total debt']}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <EcommerceWidgetSummary
            showWidget={!!scenario}
            title={`Finance cost, ${DEFAULT_CURRENCY}`}
            total={scenario?.calculate?.output?.['Finance cost']}
          />
        </Grid>
        <Grid item md={12} hidden={!mdUp}>
          <EcommerceWidgetSummary
            showWidget={!!scenario}
            title='% profit for developer'
            total={scenario?.calculate?.output?.['% profit for developer']}
            postfix='%'
          />
        </Grid>
      </Grid>
      <Grid item md={12} hidden={!mdUp}>
        {scenario ?
          <Card>
            <CardHeader title='Finance Costs Breakdown'/>
            <Table sx={{ marginTop: '24px' }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell align='right'>Total funds, {DEFAULT_CURRENCY}</TableCell>
                  <TableCell align='right'>Costs, {DEFAULT_CURRENCY}</TableCell>
                  <TableCell align='right'>Rate</TableCell>
                  <TableCell align='right'>Fees</TableCell>
                  <TableCell align='right'>LTC</TableCell>
                  <TableCell align='right'>LTGDV</TableCell>
                  <TableCell align='right'>Profit share</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.type}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>{row.type}</TableCell>
                    <TableCell align='right'>{row.total_funds === 0 ? '-' : fNumber(row.total_funds)}</TableCell>
                    <TableCell align='right'>{row.costs === 0 ? '-' : fNumber(row.costs)}</TableCell>
                    <TableCell align='right'>{row.rate === 0 ? '-' : fNumber(row.rate) + '%'}</TableCell>
                    <TableCell align='right'>{row.fees === 0 ? '-' : fNumber(row.fees) + '%'}</TableCell>
                    <TableCell align='right'>{row.ltc === 0 ? '-' : fNumber(row.ltc) + '%'}</TableCell>
                    <TableCell align='right'>{row.ltgdv === 0 ? '-' : fNumber(row.ltgdv) + '%'}</TableCell>
                    <TableCell
                      align='right'
                    >
                      {row.profit_share === 0 ? '-' : fNumber(row.profit_share) + '%'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
          : <Skeleton variant='rounded' height='407px' width='100%'/>
        }
      </Grid>
      <Grid item xs={12} hidden={mdUp}>
        <InfoBlock/>
      </Grid>
    </Grid>
  );
};
