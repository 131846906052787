import { domMax, LazyMotion, m } from 'framer-motion';
import { ReactNode } from 'react';

type MotionLazyProps = {
  children: ReactNode;
};

export const MotionLazy = (props: MotionLazyProps) => {
  const { children } = props;
  return (
    <LazyMotion strict features={domMax}>
      <m.div style={{ height: '100%' }}> {children} </m.div>
    </LazyMotion>
  );
};
