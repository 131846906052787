import { menuItemClasses } from '@mui/material/MenuItem';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import { memo } from 'react';

import { StyledArrow } from './styles';
import { MenuPopoverProps } from './types';
import { getPosition } from './utils';

export const CustomPopover = memo((props: MenuPopoverProps) => {
  const {
    open,
    children,
    arrow = 'top-right',
    hiddenArrow,
    sx,
    ...other
  } = props;
  const {
    style,
    anchorOrigin,
    transformOrigin,
  } = getPosition(arrow);

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      anchorOrigin={anchorOrigin as PopoverOrigin}
      transformOrigin={transformOrigin as PopoverOrigin}
      slotProps={{
        paper: {
          sx: {
            width: 'auto',
            overflow: 'inherit',
            ...style,
            [`& .${menuItemClasses.root}`]: {
              '& svg': {
                mr: 2,
                flexShrink: 0,
              },
            },
            ...sx,
          },
        },
      }}
      {...other}
    >
      {!hiddenArrow && <StyledArrow arrow={arrow}/>}

      {children}
    </Popover>
  );
});
