import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { memo, useCallback, useMemo, useState } from 'react';
import { SuccessIcon } from 'src/assets';
import { Iconify, ListItemCustom, MoreOptions, OptionType, StyledTableRow, UploadedFilesType } from 'src/components';
import { DEFAULT_CURRENCY, EMPTY_VALUE, statusColor } from 'src/constants/constants';
import { useBoolean, usePopover } from 'src/hooks';
import { fNumber, fPercent } from 'src/utils';

import { DocumentListResponse, QuotationType } from '../../../../../../../../types';
import { SuccessDrawer } from '../../../preliminary-quotation-request/success-drawer';
import { quotationContractColor, quotationStageColor } from '../../constants';
import { DetailsDrawer } from './details-drawer';
import { RequestDrawer, RequestDrawerSubmit } from './request-drawer';
import { UploadDrawer } from './upload-drawer';
import { UploadFormDrawer, UploadFormDrawerSubmit } from './upload-form-drawer';

interface QuotationTableRowProps {
  row: QuotationType;
  deleteOnClickHandler: (quotationId: string) => void;
  sendReqItemizedHandler: (data: RequestDrawerSubmit) => void;
  sendItemizedHandler: (data: UploadFormDrawerSubmit) => void;
  sendReqDetailedHandler: (data: RequestDrawerSubmit) => void;
  sendDetailedHandler: (data: UploadFormDrawerSubmit) => void;
  sendReqTermSheetHandler: (data: RequestDrawerSubmit) => void;
  sendUploadTermSheetHandler: (data: UploadedFilesType) => void;
  getUploadedFiles: (item: QuotationType) => Promise<DocumentListResponse[]>;
}

export const QuotationTableRow = memo((props: QuotationTableRowProps) => {
  const {
    row,
    deleteOnClickHandler,
    sendDetailedHandler,
    sendReqDetailedHandler,
    sendReqTermSheetHandler,
    sendUploadTermSheetHandler,
    getUploadedFiles,
    sendReqItemizedHandler,
    sendItemizedHandler,
  } = props;
  const theme = useTheme();

  const {
    emailNote,
    partnerId,
    fee,
    requestType,
    detailedQuotation,
    itemisedDetail,
    preliminaryOffer,
    termSheet,
    stage,
    status,
    recalculateStatus,
  } = row;

  const constructionCost = termSheet?.response?.constructionCost || detailedQuotation?.response?.constructionCost || itemisedDetail?.response?.constructionCost || preliminaryOffer?.constructionCost;
  const constructionPsqm = termSheet?.response?.constructionPsqm || detailedQuotation?.response?.constructionPsqm || itemisedDetail?.response?.constructionPsqm || preliminaryOffer?.constructionPsqm;
  const buildTime = termSheet?.response?.buildTime || detailedQuotation?.response?.buildTime || itemisedDetail?.response?.buildTime || preliminaryOffer?.buildTime;
  const partnerFee = termSheet?.response?.partnerFee || detailedQuotation?.response?.partnerFee || itemisedDetail?.response?.partnerFee || preliminaryOffer?.partnerFee;
  const defaultName = termSheet?.request?.name || detailedQuotation?.request?.name || itemisedDetail?.request?.name || preliminaryOffer?.name || partnerId.companyContactName;
  const defaultEmail = termSheet?.request?.email || detailedQuotation?.request?.email || itemisedDetail?.request?.email || preliminaryOffer?.email || partnerId.companyContactEmail;
  const defaultMsg = termSheet?.request?.message || detailedQuotation?.request?.message || itemisedDetail?.request?.message || preliminaryOffer?.message || emailNote;

  const popover = usePopover();

  const successDrawer = useBoolean();
  const reqItemizedDrawer = useBoolean();
  const uploadItemizedDrawer = useBoolean();
  const uploadDetailedDrawer = useBoolean();
  const reqContractDrawer = useBoolean();
  const uploadContractDrawer = useBoolean();
  const detailsDrawer = useBoolean();
  const reqDetailedDrawer = useBoolean();

  const [successText, setSuccessText] = useState('');
  const [itemizedFiles, setItemizedFiles] = useState<UploadedFilesType[]>([]);
  const [detailedFiles, setDetailedFiles] = useState<UploadedFilesType[]>([]);
  const [contractFiles, setContractFiles] = useState<UploadedFilesType[]>([]);
  const [offerFiles, setOfferFiles] = useState<UploadedFilesType[]>([]);

  const onReqItemizedSubmit = (data: RequestDrawerSubmit) => {
    reqItemizedDrawer.onFalse();
    setSuccessText('Itemized Quotation Request Sent Successfully!');
    sendReqItemizedHandler(data);
    successDrawer.onTrue();
  };

  const onReqDetailedSubmit = (data: RequestDrawerSubmit) => {
    reqDetailedDrawer.onFalse();
    setSuccessText('Detailed Quotation Request Sent Successfully!');
    sendReqDetailedHandler(data);
    successDrawer.onTrue();
  };

  const onUploadItemizedSubmit = (data: UploadFormDrawerSubmit) => {
    sendItemizedHandler(data);
    uploadItemizedDrawer.onFalse();
    setSuccessText('Itemized Quotation Uploaded Successfully!');
    successDrawer.onTrue();
  };

  const onUploadDetailedSubmit = (data: UploadFormDrawerSubmit) => {
    sendDetailedHandler(data);
    uploadDetailedDrawer.onFalse();
    setSuccessText('Detailed Quotation Uploaded Successfully!');
    successDrawer.onTrue();
  };

  const onReqContractSubmit = (data: RequestDrawerSubmit) => {
    reqContractDrawer.onFalse();
    sendReqTermSheetHandler(data);
    setSuccessText('Term Sheet Request Sent Successfully!');
    successDrawer.onTrue();
  };

  const onContractUploadSubmit = (data: UploadedFilesType) => {
    sendUploadTermSheetHandler(data);
    uploadContractDrawer.onFalse();
    setSuccessText('Term Sheet Uploaded Successfully!');
    successDrawer.onTrue();
  };

  const deleteWrapper = useCallback(() => {
    popover.onClose();
    deleteOnClickHandler(row._id);
  }, [deleteOnClickHandler, popover, row._id]);

  const onSeeDetailsClickHandler = useCallback(async () => {
    popover.onClose();
    detailsDrawer.onTrue();
    const files = await getUploadedFiles(row);
    const contractFiles: DocumentListResponse[] = [];
    const itemizedFiles: DocumentListResponse[] = [];
    const detailedFiles: DocumentListResponse[] = [];
    const offerFiles: DocumentListResponse[] = [];
    files.forEach(file => {
      if (file.sectionFilename.includes('Term sheet')) {
        contractFiles.push(file);
        return;
      }
      if (file.sectionFilename.includes('Itemised')) {
        itemizedFiles.push(file);
        return;
      }
      if (file.sectionFilename.includes('Detailed')) {
        detailedFiles.push(file);
        return;
      }
      if (file.sectionFilename.includes('Preliminary')) {
        offerFiles.push(file);
        return;
      }
    });
    setContractFiles(contractFiles);
    setItemizedFiles(itemizedFiles);
    setDetailedFiles(detailedFiles);
    setOfferFiles(offerFiles);
  }, [detailsDrawer, getUploadedFiles, popover, row]);

  const moreOptions = useMemo<OptionType[]>(() => {
    const hideDetails = ['Preliminary', 'Itemized requested'].includes(stage || '');
    const showTermSheetUpload = status === 'Term sheet requested';
    const hideReqContract = ['Term sheet requested', 'Term Sheet'].includes(status || '');
    const hideDelete = stage === 'Detailed' && status === 'Term Sheet';
    return [
      {
        label: 'Request Itemized Quotation',
        closePopover: popover.onClose,
        itemOnClickHandler: reqItemizedDrawer.onTrue,
        hidden: stage !== 'Preliminary',
      },
      {
        label: 'Upload Itemized Quotation',
        closePopover: popover.onClose,
        itemOnClickHandler: uploadItemizedDrawer.onTrue,
        hidden: stage !== 'Itemized requested',
      },
      {
        label: 'Request Detailed Quotation',
        closePopover: popover.onClose,
        itemOnClickHandler: reqDetailedDrawer.onTrue,
        hidden: stage !== 'Itemized',
      },
      {
        label: 'Upload Detailed Quotations',
        closePopover: popover.onClose,
        itemOnClickHandler: uploadDetailedDrawer.onTrue,
        hidden: stage !== 'Detailed requested',
      },
      {
        label: 'Term Sheet Request',
        closePopover: popover.onClose,
        itemOnClickHandler: reqContractDrawer.onTrue,
        hidden: hideReqContract,
      },
      {
        label: 'Upload Term Sheet',
        closePopover: popover.onClose,
        itemOnClickHandler: uploadContractDrawer.onTrue,
        hidden: !showTermSheetUpload,
      },
      {
        label: 'See Details',
        closePopover: popover.onClose,
        itemOnClickHandler: onSeeDetailsClickHandler,
        hidden: hideDetails,
      },
      {
        label: 'Delete',
        closePopover: popover.onClose,
        itemOnClickHandler: deleteWrapper,
        color: theme.palette.error.main,
        hidden: hideDelete,
      },
    ];
  }, [stage, status, popover.onClose, reqItemizedDrawer.onTrue, uploadItemizedDrawer.onTrue, reqDetailedDrawer.onTrue, uploadDetailedDrawer.onTrue, reqContractDrawer.onTrue, uploadContractDrawer.onTrue, onSeeDetailsClickHandler, deleteWrapper, theme.palette.error.main]);

  const onCloseClickHandler = () => {
    successDrawer.onFalse();
  };

  return (
    <>
      <StyledTableRow hover>
        <TableCell>
          <ListItemCustom
            showAvatar
            key={partnerId._id}
            avatarSrc={partnerId.logoUrl}
            avatarSx={{ borderRadius: 1.5 }}
            avatarAlt={partnerId.companyName}
            primaryText={partnerId.companyName}
            secondaryText={partnerId.companySubType?.join(', ')}
            secondaryTextTypographySx={{ color: theme.palette.text.secondary }}
            secondaryTextVariant='caption'
          />
        </TableCell>

        <TableCell>
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
            disableTypography
            primary={
              <Typography variant='body2'>
                {fNumber(constructionCost)} {DEFAULT_CURRENCY}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}>
                {fNumber(constructionPsqm)} {DEFAULT_CURRENCY}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <Typography variant='body2' sx={{ marginLeft: 'auto' }}>
            {buildTime} m
          </Typography>
        </TableCell>

        <TableCell>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='body2'>
                {requestType}
              </Typography>
            }
            secondary={
              <Typography
                noWrap
                variant='caption'
                color={theme.palette.text.secondary}>
                {fPercent(fee)}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <Chip
            size='small'
            variant='soft'
            color={quotationStageColor[stage || 'Itemized']}
            label={stage}
          />
        </TableCell>

        <TableCell>
          {
            status ?
              <Chip
                size='small'
                variant='outlined'
                color={quotationContractColor[status]}
                label={
                  <Stack direction='row' alignItems='center' gap={0.25}>
                    {status === 'Term Sheet' && <SuccessIcon/>}
                    {status}
                  </Stack>
                }
              /> : EMPTY_VALUE
          }
        </TableCell>

        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            label={recalculateStatus || 'Not used'}
            color={statusColor[recalculateStatus || 'Not used']}
          />
        </TableCell>

        <TableCell
          size='small'
          align='right'
        >
          <Stack direction='row' alignItems='center'>
            <Button
              variant='outlined'
              size='small'
              endIcon={
                <Iconify icon='eva:arrow-ios-downward-fill' width={18}/>
              }
              onClick={popover.onOpen}
            >
              Action
            </Button>
            <MoreOptions
              options={moreOptions}
              popoverOnClose={popover.onClose}
              popoverOnOpen={popover.onOpen}
              popoverOpen={popover.open}
              clickElement={<></>}
            />
          </Stack>
        </TableCell>
      </StyledTableRow>

      <RequestDrawer
        key={`${reqItemizedDrawer.value}-req-itemized`}
        drawer={reqItemizedDrawer}
        onSubmit={onReqItemizedSubmit}
        supplier={partnerId}
        title='Request Itemized Quotation'
        defaultMsg={defaultMsg}
        defaultEmail={defaultEmail}
        defaultName={defaultName}
      />

      <RequestDrawer
        key={`${reqContractDrawer.value}-req-contract`}
        drawer={reqContractDrawer}
        onSubmit={onReqContractSubmit}
        supplier={partnerId}
        title='Term Sheet Request'
        defaultMsg={defaultMsg}
        defaultEmail={defaultEmail}
        defaultName={defaultName}
      />

      <UploadFormDrawer
        defaultFeePercent={partnerFee}
        defaultCosts={constructionCost}
        defaultPsqm={constructionPsqm}
        defaultBuildTimeMonths={buildTime}
        key={`${uploadItemizedDrawer.value}-upload-itemized`}
        drawer={uploadItemizedDrawer}
        onSubmit={onUploadItemizedSubmit}
        requestType={requestType}
        supplier={partnerId}
        title='Itemized Quotation'
        noRecalculation
      />

      <RequestDrawer
        key={`${reqDetailedDrawer.value}-req-detailed`}
        drawer={reqDetailedDrawer}
        onSubmit={onReqDetailedSubmit}
        supplier={partnerId}
        title='Request Detailed Quotation'
        defaultMsg={defaultMsg}
        defaultEmail={defaultEmail}
        defaultName={defaultName}
      />

      <UploadFormDrawer
        defaultFeePercent={partnerFee}
        defaultCosts={constructionCost}
        defaultPsqm={constructionPsqm}
        defaultBuildTimeMonths={buildTime}
        key={`${uploadDetailedDrawer.value}-upload-detailed`}
        drawer={uploadDetailedDrawer}
        onSubmit={onUploadDetailedSubmit}
        supplier={partnerId}
        requestType={requestType}
        title='Detailed Quotation'
        noRecalculation
      />

      <UploadDrawer
        key={`${uploadContractDrawer.value}-upload-contract`}
        drawer={uploadContractDrawer}
        onSubmit={onContractUploadSubmit}
        supplier={partnerId}
        title='Term Sheet Upload'
      />

      <SuccessDrawer
        onCloseClickHandler={onCloseClickHandler}
        successDrawer={successDrawer}
        title={successText}
      />

      <DetailsDrawer
        drawer={detailsDrawer}
        supplier={partnerId}
        requestType={requestType}
        itemizedFiles={itemizedFiles}
        detailedFiles={detailedFiles}
        contractFiles={contractFiles}
        offerFiles={offerFiles}
      />
    </>
  );
});
