import { Skeleton } from '@mui/material';
import Card, { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { styled, useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import { Chart, useChart } from 'src/components/chart';
import { fNumber } from 'src/utils';

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 72;

export const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': { height: '100% !important' },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    borderTop: `dashed 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

interface Props extends CardProps {
  title?: string;
  chartHeader?: string;
  subheader?: string;
  chart: ChartType;
  showWidget?: boolean;
}

export type ChartType = {
  colors?: string[];
  series: SeriesChartType[];
  options?: ApexOptions;
}

export type SeriesChartType = {
  label: string;
  value: number | string;
}


export const CardDonutChart = (props: Props) => {
  const {
    title,
    chartHeader,
    subheader,
    showWidget = true,
    chart,
    ...other
  } = props;

  const theme = useTheme();

  const {
    colors = ['#3698AF', '#FFAB00', '#00B8D9', '#FF5630'],
    series,
    options,
  } = chart;

  const seriesWithUniqueValues = _.values(_.groupBy(series, 'label')).map(group => ({
    label: group[0].label,
    value: _.sumBy(group, 'value'),
  }));

  const chartSeries = seriesWithUniqueValues.map((i) => typeof i.value === 'string' ? Number.parseFloat(i.value) : i.value);

  const chartOptions = useChart({
    chart: { sparkline: { enabled: true } },
    colors,
    labels: seriesWithUniqueValues.map((i) => i.label),
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      offsetY: 0,
      floating: true,
      position: 'bottom',
      horizontalAlign: 'center',
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value: number) => fNumber(value),
        title: { formatter: (seriesName: string) => `${seriesName}` },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: { formatter: (value: number | string) => fNumber(value) },
            total: {
              label: chartHeader || 'Total',
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fNumber(sum);
              },
            },
          },
        },
      },
    },
    ...options,
  });

  return (
    <>
      {
        showWidget ?
          <Card {...other}>
            <CardHeader title={title} subheader={subheader} sx={{ mb: 5 }}/>

            <StyledChart
              dir='ltr'
              type='donut'
              series={chartSeries}
              options={chartOptions}
              height={280}
            />
          </Card>
          : <Skeleton variant='rounded' height='492px' width='100%'/>
      }
    </>
  );
};
