export interface InlineBadgeProps {
  padding?: string;
  margin?: string;
  borderRadius?: number;
  textColor?: string;
  background?: string;
  value: number | string;
}

export const InlineBadge = (props: InlineBadgeProps) => {
  const {
    padding = '3px 8px',
    margin = '0 0 0 8px',
    textColor = '#FFFFFF',
    borderRadius = 8,
    background = '#FF5630',
    value,
  } = props;
  return (
    <p
      style={{
        background,
        padding,
        margin,
        borderRadius,
        color: textColor,
      }}
    >
      {value}
    </p>
  );
};
