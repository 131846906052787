import { AssetDocumentsType, AssetSection, ReportKeyType, ReportSettingsType } from '../../../../types';

export const getSettingConfig = (reportSettings: ReportSettingsType[], type: ReportKeyType, leadId: string, identifier?: string) => {
  const isSettingExist = reportSettings.find(setting =>
    setting.leadId === leadId
    && (identifier ? setting.identifier === identifier : true)
    && setting.reportName === type);
  return isSettingExist;
};

export const getDocumentsWithAttached = (finalFiles: AssetDocumentsType[], settings: ReportSettingsType) => {
  const updatedFiles: AssetDocumentsType[] = finalFiles.map(file => {
    return {
      ...file,
      attachedToAssetSection: {
        gallery: !!getNoteConfig(settings, 'Gallery')?.files?.find(noteFile => noteFile._id === file._id),
        aboutProject: !!getNoteConfig(settings, 'Project Summary')?.files?.find(noteFile => noteFile._id === file._id),
        ourRequest: !!getNoteConfig(settings, 'Our Request')?.files?.find(noteFile => noteFile._id === file._id),
        siteAndPurchase: !!getNoteConfig(settings, 'Site & Purchase')?.files?.find(noteFile => noteFile._id === file._id),
        proposedUnits: !!getNoteConfig(settings, 'Proposed Units')?.files?.find(noteFile => noteFile._id === file._id),
        construction: !!getNoteConfig(settings, 'Construction')?.files?.find(noteFile => noteFile._id === file._id),
        finance: !!getNoteConfig(settings, 'Finance')?.files?.find(noteFile => noteFile._id === file._id),
        sales: !!getNoteConfig(settings, 'Sales')?.files?.find(noteFile => noteFile._id === file._id),
        timelineAndCashflow: !!getNoteConfig(settings, 'Timeline & Cashflow')?.files?.find(noteFile => noteFile._id === file._id),
        sensitivityAnalysis: !!getNoteConfig(settings, 'Sensitivity Analysis')?.files?.find(noteFile => noteFile._id === file._id),
        localArea: !!getNoteConfig(settings, 'Local Area')?.files?.find(noteFile => noteFile._id === file._id),
        localMarket: !!getNoteConfig(settings, 'Local Market')?.files?.find(noteFile => noteFile._id === file._id),
        comparables: !!getNoteConfig(settings, 'Comparables')?.files?.find(noteFile => noteFile._id === file._id),
      },
    };
  });
  return updatedFiles;
};

export const getFullSectionConfig = (settings: ReportSettingsType, sectionName: string): AssetSection => {
  return {
    note: getNoteConfig(settings, sectionName)?.note,
    isNoteActive: getNoteConfig(settings, sectionName)?.isActive,
    isActive: getSectionConfig(settings, sectionName)?.isActive,
  };
};

export const getSectionConfig = (settings: ReportSettingsType, sectionName: string) => {
  return settings.sections?.find(sec => sec.section === sectionName) || {
    note: '',
    isActive: true,
    files: [],
    section: sectionName,
  };
};
export const getNoteConfig = (settings: ReportSettingsType, sectionName: string) => {
  return settings.notes?.find(note => note.section === sectionName) || {
    note: '',
    isActive: true,
    files: [],
    section: sectionName,
  };
};
