import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { DrawerCustom, Iconify, ListItemCustom, UploadedFilesType, UploadFile } from 'src/components';
import { paths } from 'src/config';
import { UseBooleanReturnType } from 'src/hooks';

import { PartnerItemType } from '../../../../../../../../types';

interface UploadDrawerProps {
  drawer: UseBooleanReturnType;
  onSubmit: (data: UploadedFilesType) => void;
  supplier: PartnerItemType;
  title: string;
}

export const UploadDrawer = (props: UploadDrawerProps) => {
  const {
    drawer,
    onSubmit,
    supplier,
    title,
  } = props;
  const theme = useTheme();

  const [files, setFiles] = useState<UploadedFilesType[]>([]);

  const canSubmit = files.length > 0;
  const onSubmitClick = () => {
    onSubmit(files[0]);
  };

  return (
    <>
      <DrawerCustom
        open={drawer.value}
        onCloseDrawerHandler={drawer.onFalse}
        headChildren={
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Typography variant='h6'>{title}</Typography>
            <IconButton
              onClick={drawer.onFalse}
            >
              <Iconify icon='mingcute:close-line' width={18}/>
            </IconButton>
          </Stack>
        }
        bodyChildren={
          <Stack gap={3}>
            <ListItemCustom
              showAvatar
              key={supplier._id}
              avatarSrc={supplier.logoUrl}
              avatarSx={{ borderRadius: 1.5 }}
              avatarAlt={supplier.companyName}
              primaryText={supplier.companyName}
              secondaryText={supplier.companyType}
              secondaryChildren={
                <>
                  <Link
                    underline='none'
                    target='_blank'
                    href={paths.supplierPartners.products(supplier._id)}
                  >
                    Profile
                  </Link>
                </>
              }
            />

            <UploadFile
              multiple
              containerSx={{ width: '100%' }}
              innerContainerSx={{ p: 2.5 }}
              uploadTitle='Upload files'
              titleStyleConfig={{
                variant: 'body2',
                color: theme.palette.text.disabled,
                mt: 0.5,
              }}
              uploadedFiles={files}
              deleteFilesHandler={setFiles}
              uploadFileHandler={setFiles}
              fileIconSx={{ mr: 0 }}
              customIcon={
                <Iconify
                  icon='eva:cloud-upload-fill'
                  width={40}
                  color={theme.palette.text.disabled}
                />
              }
            />
          </Stack>
        }
        footerChildren={
          <Button
            onClick={onSubmitClick}
            disabled={!canSubmit}
            variant='contained'
            size='large'
            fullWidth
          >
            Confirm
          </Button>
        }
      />
    </>
  );
};
