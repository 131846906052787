import { AboutProject, AboutProjectProps } from 'src/features/fin-upraisal/about-project';

import {
  Comparables,
  ComparablesProps,
  Construction,
  ConstructionProps,
  Finance,
  FinanceProps,
  LocalArea,
  LocalAreaType,
  LocalMarket,
  LocalMarketType,
  ProjectMultiples,
  ProjectMultiplesProps,
  ProposedUnits,
  ProposedUnitsProps,
  Sales,
  SalesProps,
  SensitivityAnalysis,
  SensitivityAnalysisProps,
  SiteAndPurchase,
  SiteAndPurchaseType,
  TimelineCashflow,
  TimelineCashflowProps,
} from './components';
import { ReportType } from './types';

export const getAboutProject = (props: AboutProjectProps): ReportType => {
  return {
    name: 'About Project',
    component: <AboutProject {...props}/>,
  };
};

export const getSiteAndPurchase = (props: SiteAndPurchaseType): ReportType => {
  return {
    name: 'Site & Purchase',
    component: <SiteAndPurchase {...props}/>,
  };
};

export const getProposedUnits = (props: ProposedUnitsProps): ReportType => {
  return {
    name: 'Proposed Units',
    component: <ProposedUnits {...props}/>,
  };
};

export const getConstruction = (props: ConstructionProps): ReportType => {
  return {
    name: 'Construction',
    component: <Construction {...props}/>,
  };
};

export const getFinance = (props: FinanceProps): ReportType => {
  return {
    name: 'Finance',
    component: <Finance {...props}/>,
  };
};

export const getTimelineCashflow = (props: TimelineCashflowProps): ReportType => {
  return {
    name: 'Timeline & Cashflow',
    component: <TimelineCashflow {...props}/>,
  };
};

export const getSales = (props: SalesProps): ReportType => {
  return {
    name: 'Sales',
    component: <Sales {...props}/>,
  };
};

export const getProjectMultiples = (props: ProjectMultiplesProps): ReportType => {
  return {
    name: 'Project Multiples',
    component: <ProjectMultiples {...props}/>,
  };
};

export const getSensitivityAnalysis = (props: SensitivityAnalysisProps): ReportType => {
  return {
    name: 'Sensitivity Analysis',
    component: <SensitivityAnalysis {...props}/>,
  };
};

export const getLocalArea = (props: LocalAreaType): ReportType => {
  return {
    name: 'Local Area',
    component: <LocalArea {...props}/>,
  };
};

export const getLocalMarket = (props: LocalMarketType): ReportType => {
  return {
    name: 'Local Market',
    component: <LocalMarket {...props}/>,
  };
};

export const getComparables = (props: ComparablesProps): ReportType => {
  return {
    name: 'Comparables',
    component: <Comparables {...props}/>,
  };
};
