import { ReactNode } from 'react';

export interface NoLayoutProps {
  children?: ReactNode;
}

export const NoLayout = ({ children }: NoLayoutProps) => {
  return (
    <>
      {children}
    </>
  );
};
