import { alpha, useTheme } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses, TableRowProps } from '@mui/material/TableRow';

import { StyledTableRow } from './styled-table-row';

export const BorderedTableRow = (props: TableRowProps) => {
  const {
    sx,
    ...rest
  } = props;
  const theme = useTheme();

  const styles = {
    borderTop: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
    '&:first-of-type': {
      borderTopLeftRadius: 16,
      borderBottomLeftRadius: 16,
      borderLeft: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
    },
    '&:last-of-type': {
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
      borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
    },
  };

  return (
    <StyledTableRow
      {...rest}
      sx={{
        borderRadius: 2,
        '&:last-of-type .MuiTableCell-root': { borderColor: alpha(theme.palette.grey[500], 0.16) },
        [`&.${tableRowClasses.selected}, &:hover`]: {
          backgroundColor: 'background.paper',
          boxShadow: theme.customShadows.z20,
          transition: theme.transitions.create(['background-color', 'box-shadow'], { duration: theme.transitions.duration.shortest }),
          '&:hover': {
            backgroundColor: 'background.paper',
            boxShadow: theme.customShadows.z20,
          },
        },
        [`& .${tableCellClasses.root}`]: { ...styles },
        ...sx,
      }}
    />
  );
};
