import { TabType } from 'src/components';

export const TABS: TabType[] = [
  {
    value: 'Overview',
    label: 'Overview',
  },
  {
    value: 'Reports',
    label: 'Reports',
  },
  {
    value: 'Documents',
    label: 'Documents',
  },
];
