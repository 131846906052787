import { TabType } from 'src/components';

import { MarketplaceDetailType, MarketplaceStat, PaginationParams } from '../../../../types';
import { TableHeadType } from '../leads-page';

const details: MarketplaceDetailType[] = [
  {
    _id: '1',
    name: 'Navian',
    imgSrc: 'Navian Logo',
    type: 'Internal',
    ongoingOfferings: 234,
    finalisedProjects: 103,
    totalRaised: 23_567_000,
    commision: null,
    investors: 456,
  },
  {
    _id: '2',
    name: 'Pareto',
    imgSrc: 'Pareto Logo',
    type: 'External',
    ongoingOfferings: 122,
    finalisedProjects: 205,
    totalRaised: 3_567_000,
    commision: 260_400,
    investors: 31,
  },
];

export const _mockMarketplaceDetails = (id: string): MarketplaceDetailType => {
  return details.find(d => d._id === id)!;
};

export const TABS: TabType[] = [
  {
    value: 'Dashboard',
    label: 'Dashboard',
  },
  {
    value: 'Projects',
    label: 'Projects',
  },
  {
    value: 'Investors',
    label: 'Investors',
  },
  {
    value: 'Settings',
    label: 'Settings',
  },
];

export const EXTERNAL_TABS: TabType[] = [
  {
    value: 'Dashboard',
    label: 'Dashboard',
  },
  {
    value: 'Projects',
    label: 'Projects',
  },
  {
    value: 'Partners fees',
    label: 'Partners fees',
  },
  {
    value: 'Investors',
    label: 'Investors',
  },
  {
    value: 'Settings',
    label: 'Settings',
  },
];

export const _mockMarketplaceStats: MarketplaceStat[] =
  [
    {
      label: 'Total Investors',
      increase: '1',
      amount: '368',
    },
    {
      label: 'Views',
      increase: '2',
      amount: '68',
    },
    {
      label: 'Soft Commited',
      increase: '12.2%',
      amount: '890m kr',
    },
    {
      label: 'Secured Capital',
      increase: '1%',
      amount: '368m kr',
    },
  ];


export const MARKETPLACE_INVESTORS_TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Name / Registered',
    width: 180,
  },
  {
    _id: '',
    label: 'Email / Phone',
    width: 220,
  },
  {
    _id: '',
    label: 'Invested / Soft Commited',
    width: 200,
    align: 'right',
  },
  {
    _id: '',
    label: 'AML / KYC',
    width: 190,
  },
  {
    _id: '',
    label: 'Organisation',
    width: 220,
  },
  {
    _id: 'controls',
    width: 64,
  },
];

export const defaultBody: PaginationParams = {
  page: 1,
  text: '',
  limit: 5,
  sortType: 'asc',
  sort: '',
};

export const partnerFees = {
  label: 'Partner fees',
  increase: '12.2%',
  amount: '12m kr',
};
