import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactNode } from 'react';
import { useLocales } from 'src/hooks';


type LocalizationProviderProps = {
  children: ReactNode;
};

export const LocalizationProvider = (props: LocalizationProviderProps) => {
  const { children } = props;
  const { currentLang } = useLocales();

  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang.adapterLocale}>
      {children}
    </MuiLocalizationProvider>
  );
};
