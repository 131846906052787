import { InputLabel, Stack, styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Key, ReactNode } from 'react';

export interface CustomSelectProps<T> {
  value?: T[];
  handleSelectOnChange?: (event: SelectChangeEvent<T[]>, child?: ReactNode) => void;
  label?: string;
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
  showCheckbox?: boolean;
  showCircle?: boolean;
  options?: T[];
  controlSx?: SxProps<Theme>;
  selectedOptions?: T[];
  selectSize?: 'small' | 'medium';
  labelSize?: 'small' | 'normal';
  configKey?: GetRenderSelectedValueKeys;
  colorsConfig?: Record<string, string>;
}

export type GetRenderSelectedValueKeys = 'default' | 'colors'

export const CustomSelect = <T, >(props: CustomSelectProps<T>) => {
  const {
    value,
    handleSelectOnChange,
    configKey = 'default',
    colorsConfig,
    label,
    multiple = false,
    required = false,
    disabled = false,
    showCheckbox = false,
    showCircle = false,
    options = [],
    controlSx,
    selectedOptions = [],
    selectSize = 'small',
    labelSize = 'small',
  } = props;

  const getRenderSelectedValue = (selected: T[]) => {
    const renderSelectedValueFunction: Record<GetRenderSelectedValueKeys, ReactNode> = {
      'default': selected?.map((value) => value).join(', '),
      'colors':
        <>
          {selected?.map((value, index) => {
            return (
              <Stack
                key={index}
                direction='row'
                alignItems='center'
              >
                <ColorCircle color={colorsConfig?.[value as string] || 'red'}/>
                <Typography
                  fontWeight={700}
                  fontSize='15px'
                >
                  {value as string}
                </Typography>
              </Stack>
            );
          })}
        </>,
    };
    return renderSelectedValueFunction[configKey];
  };

  const isSelectOptionChecked = (option: T): boolean => {
    return selectedOptions?.includes(option);
  };

  return (
    <FormControl
      sx={{
        height: 40,
        minWidth: 160,
        flexShrink: 0,
        ...controlSx,
      }}
      disabled={disabled}
      required={required}
    >
      <InputLabel size={labelSize}>{label}</InputLabel>
      <Select
        multiple={multiple}
        size={selectSize}
        value={value}
        onChange={handleSelectOnChange}
        input={
          <OutlinedInput
            label={label}
          />
        }
        renderValue={getRenderSelectedValue}
        sx={{ textTransform: 'capitalize' }}
      >
        {options.map((option) => (
          <MenuItem sx={{ minHeight: '34px !important' }} key={option as Key} value={option as string}>
            <>
              {showCircle &&
                <ColorCircle color={colorsConfig?.[option as string]}/>
              }
              {showCheckbox &&
                <Checkbox
                  disableRipple
                  size='small'
                  checked={isSelectOptionChecked(option)}
                />
              }
              {option}
            </>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const ColorCircle = styled('div')(({ color }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: 10,
}));
