import { NoteSettingType } from '../../../../../types';

export const reportList: NoteSettingType[] = [
  {
    isActive: true,
    section: 'Gallery',
  },
  {
    isActive: true,
    section: 'Project Summary',
  },
  {
    isActive: true,
    section: 'Our Request',
  },
  {
    isActive: true,
    section: 'Site & Purchase',
  },
  {
    isActive: true,
    section: 'Proposed Units',
  },
  {
    isActive: true,
    section: 'Construction',
  },
  {
    isActive: true,
    section: 'Finance',
  },
  {
    isActive: true,
    section: 'Timeline & Cashflow',
  },
  {
    isActive: true,
    section: 'Sales',
  },
  {
    isActive: true,
    section: 'Project Multiples',
  },
  {
    isActive: true,
    section: 'Sensitivity Analysis',
  },
  {
    isActive: true,
    section: 'Local Area',
  },
  {
    isActive: true,
    section: 'Local Market',
  },
  {
    isActive: true,
    section: 'Comparables',
  },
];

export const sectionKeyToValue: Record<string, string> = {
  gallery: 'Gallery',
  aboutProject: 'Project Summary',
  ourRequest: 'Our Request',
  siteAndPurchase: 'Site & Purchase',
  proposedUnits: 'Proposed Units',
  construction: 'Construction',
  finance: 'Finance',
  sales: 'Sales',
  timelineAndCashflow: 'Timeline & Cashflow',
  sensitivityAnalysis: 'Sensitivity Analysis',
  projectMultiples: 'Project Multiples',
  localArea: 'Local Area',
  localMarket: 'Local Market',
  comparables: 'Comparables',
};

export const notesList: NoteSettingType[] = reportList.map(report => {
  return {
    ...report,
    isActive: false,
  };
}).filter(item => item.section !== 'Gallery');
