import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ReactNode, useEffect } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';

type RTLProps = {
  themeDirection: 'rtl' | 'ltr';
  children: ReactNode;
};

export const RTL = (props: RTLProps) => {
  const {
    children,
    themeDirection,
  } = props;
  useEffect(() => {
    document.dir = themeDirection;
  }, [themeDirection]);

  const cacheRtl = createCache({
    key: 'rtl',
    prepend: true,
    stylisPlugins: [rtlPlugin],
  });

  if (themeDirection === 'rtl') {
    return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
  }

  return <>{children}</>;
};

export const direction = (themeDirection: 'rtl' | 'ltr') => {
  const theme = { direction: themeDirection };

  return theme;
};
