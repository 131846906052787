import { fDate } from 'src/utils';

import { Scenario } from '../../../../../../types';

export const useScenarioLocalMarket = (scenario?: Scenario) => {
  const medianPriceMonths: string[] = [];
  const medianPrices: number[] = [];
  const listingsPrices: number[] = [];
  const distributionLabels: string[] = Object.keys(scenario?.localMarket?.distribution || {});
  const distributionData: number[] = Object.values(scenario?.localMarket?.distribution || {});
  scenario?.localMarket?.timeSeries?.forEach(({
    median,
    month,
    count,
  }) => {
    medianPriceMonths.push(fDate(month, 'dd/MM/yyyy'));
    medianPrices.push(median);
    listingsPrices.push(count);
  });

  return {
    medianPriceMonths,
    medianPrices,
    listingsPrices,
    distributionData,
    distributionLabels,
  };
};
