import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserGroupIcon } from 'src/assets/svg';
import { paths } from 'src/config';
import { blueGradient } from 'src/constants/colors';
import { useRouter } from 'src/hooks';
import { updateAsset } from 'src/store/storage';

import { Asset } from '../../../../../../types';
import { EmailTemplate } from './email-template';
import { General } from './general';
import { Investing } from './investing';

enum TabType {
  general = 'General',
  investing = 'Investing',
  emailTemplate = 'Email Template',
}

export type TabsListItemType = {
  title: TabType,
  disable?: boolean
}

const tabsList: TabsListItemType[] = [
  { title: TabType.general },
  { title: TabType.investing, disable: true },
  { title: TabType.emailTemplate, disable: true },
];

export type DefaultAssetTabProps = {
  debouncedUpdateAsset: (asset: Asset) => void
}

export const Assets = () => {
  const theme = useTheme();
  const [tab, setTab] = useState<TabType>(TabType.general);
  const dispatch = useDispatch();
  const router = useRouter();

  const debouncedUpdateAsset = debounce((asset: Asset) => {
    if (asset?._id) {
      dispatch(updateAsset(asset));
    }
  }, 500);

  const onPreviewClickHandler = () => {
    router.push(paths.investorPortal.projects.preview('overview'));
  };

  const onTabChange = (_: MouseEvent<HTMLElement>, value: TabsListItemType) => {
    setTab(value.title);
  };

  return (
    <Stack direction='row' gap={5}>
      <Stack sx={{ minWidth: 180 }} gap={3}>
        <ToggleButtonGroup
          value={tab}
          onChange={onTabChange}
          aria-label='Assets section'
          orientation='vertical'
          exclusive
        >
          {tabsList.map((tabItem) => (
            <ToggleButton
              key={tabItem.title}
              sx={{
                color: (theme) => tab === tabItem.title ? theme.palette.text.primary : theme.palette.text.secondary,
                fontWeight: 600,
                justifyContent: 'flex-start',
              }}
              value={tabItem}
              disabled={tabItem.disable}
            >
              {tabItem.title}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Stack
          sx={{
            gap: 2,
            p: 2,
            borderRadius: 1,
            background: blueGradient,
          }}
        >
          <Stack
            textAlign='center'
            alignItems='center'
            justifyContent='center'
            gap={1}
          >
            <UserGroupIcon color={theme.palette.info.main} width={32} height={32}/>
            <Typography variant='body2' color='text.primary'>Review what the investors<br/> will see</Typography>
          </Stack>
          <Button
            variant='contained'
            size='small'
            onClick={onPreviewClickHandler}
            disabled
            fullWidth
          >Preview</Button>
        </Stack>
      </Stack>

      {tab === TabType.general && <General debouncedUpdateAsset={debouncedUpdateAsset}/>}
      {tab === TabType.investing && <Investing debouncedUpdateAsset={debouncedUpdateAsset}/>}
      {tab === TabType.emailTemplate && <EmailTemplate debouncedUpdateAsset={debouncedUpdateAsset}/>}
    </Stack>
  );
};
