import { Theme } from '@mui/material/styles';

export function radio(theme: Theme) {
  return {
    // CHECKBOX, RADIO, SWITCH
    MuiFormControlLabel: { styleOverrides: { label: { ...theme.typography.body2 } } },

    MuiRadio: { styleOverrides: { root: { padding: theme.spacing(1) } } },
  };
}
