import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { EcommerceWidgetSummary, FieldType, ItemType, LandDetail, Summary } from 'src/components';

export interface TeaserProps {
  showData?: boolean;
  summaryItems?: ItemType[];
  expectedReturns?: number | string;
  timeframe?: number | string;
  totalRequiredCapital?: number | string;
  riskProfile?: number;
  proposalDetails?: FieldType[];
  projectDetails?: FieldType[];
  summaryChildren?: ReactNode;
}

export const Teaser = (props: TeaserProps) => {
  const {
    showData = false,
    summaryItems = [],
    expectedReturns,
    timeframe,
    totalRequiredCapital,
    riskProfile,
    proposalDetails = [],
    projectDetails = [],
    summaryChildren,
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid container item xs={8} spacing={3} rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>Partner Proposal</Typography>
        </Grid>
        <Grid item xs={6}>
          <EcommerceWidgetSummary
            title='Expected Returns'
            total={expectedReturns}
            showWidget={showData}
          />
        </Grid>
        <Grid item xs={6}>
          <EcommerceWidgetSummary
            title='Timeframe'
            total={timeframe}
            showWidget={showData}
          />
        </Grid>
        <Grid item xs={6}>
          <EcommerceWidgetSummary
            title='Total Required Capital'
            total={totalRequiredCapital}
            showWidget={showData}
          />
        </Grid>
        <Grid item xs={6}>
          <EcommerceWidgetSummary
            title='Risk Profile, 1-10'
            total={riskProfile || 5}
            showWidget={showData}
            chart={{ series: [riskProfile || 5 * 10] }}
          />
        </Grid>
        <Grid item xs={12}>
          <LandDetail
            showMap={false}
            headLabel='Proposal Details'
            fields={proposalDetails}
            showSkeleton={!showData}
          />
        </Grid>
        <Grid item xs={12}>
          <LandDetail
            showMap={false}
            headLabel='Project Details'
            fields={projectDetails}
            showSkeleton={!showData}
          />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Summary
          title='Project Summary'
          showSkeleton={!showData}
          items={summaryItems}
        >
          {summaryChildren}
        </Summary>
      </Grid>
    </Grid>
  );
};
