import { useParams } from 'react-router-dom';
import { paths } from 'src/config';
import { useRouter } from 'src/hooks';

type MarketplaceProjectsOfferingsProps = {
  isExternal?: boolean;
}

export const MarketplaceProjectsOfferings = (props: MarketplaceProjectsOfferingsProps) => {
  const { isExternal } = props;
  const params = useParams<{ id: string }>();

  const router = useRouter();
  const onDetailPush = (id: string) => {
    const currentPath = paths.marketplaces.projectDetail;
    router.push(currentPath(params.id, id, 'offering'));
  };

  return (
    <></>
    // <ProjectsOfferingPage
    //   tableHead={MARKETPLACES_TABLE_HEAD}
    //   loadFn={loadProjectsOffering}
    //   unlistProjectsOffering={unlistProjectsOffering}
    //   onDetailPush={onDetailPush}
    //   showDetailsBtn={isExternal}
    //   showPublishedDate
    // />
  );
};

