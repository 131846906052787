import { Button, styled } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { ChangeEventHandler } from 'react';
import { UploadCloudIcon } from 'src/assets/svg';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export interface UploadButtonProps extends ButtonProps {
  btnTitle: string;
  fileInputOnChangeHandler: ChangeEventHandler<HTMLInputElement>;
  acceptedTypes?: string;
}

export const UploadButton = (props: UploadButtonProps) => {
  const {
    btnTitle,
    fileInputOnChangeHandler,
    acceptedTypes,
    startIcon = <UploadCloudIcon/>,
    ...rest
  } = props;
  return (
    <Button
      component='label'
      startIcon={startIcon}
      {...rest}
    >
      {btnTitle}
      <VisuallyHiddenInput
        accept={acceptedTypes}
        onChange={fileInputOnChangeHandler}
        type='file'
      />
    </Button>
  );
};
