import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { ReactNode, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface RHFSwitchProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  helperText?: ReactNode;
}

export const RHFSwitch = (props: RHFSwitchProps) => {
  const {
    name,
    helperText,
    ...other
  } = props;
  const { control } = useFormContext();
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <div>
          <FormControlLabel control={<Switch  {...field} ref={ref} checked={field.value}/>} {...other} />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
};
