import isEqual from 'lodash/isEqual';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsContext, SettingsValueProps, useLocalStorage } from 'src/hooks';
import { localStorageGetItem } from 'src/utils';

const STORAGE_KEY = 'settings';

type SettingsProviderProps = {
  children: ReactNode;
  defaultSettings: SettingsValueProps;
};

export const SettingsProvider = (props: SettingsProviderProps) => {
  const {
    children,
    defaultSettings,
  } = props;
  const {
    state,
    update,
    reset,
  } = useLocalStorage(STORAGE_KEY, defaultSettings);

  const [openDrawer, setOpenDrawer] = useState(false);

  const isArabic = localStorageGetItem('i18nextLng') === 'ar';

  const onChangeDirectionByLang = useCallback(
    (lang: string) => {
      update('themeDirection', lang === 'ar' ? 'rtl' : 'ltr');
    }, [update]);

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang('ar');
    }
  }, [isArabic, onChangeDirectionByLang]);

  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const canReset = !isEqual(state, defaultSettings);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      onUpdate: update,
      onChangeDirectionByLang,
      canReset,
      onReset: reset,
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
    }),
    [
      reset,
      update,
      state,
      canReset,
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      onChangeDirectionByLang,
    ],
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
};
