import { TextFieldProps } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FocusEvent, KeyboardEvent, ReactNode, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';


interface RHFAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  isTagAutoComplete?: boolean;
  helperText?: ReactNode;
}

export const RHFAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: Omit<RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) => {
  const {
    name,
    label,
    placeholder,
    helperText,
    required,
    isTagAutoComplete,
    ...other
  } = props;
  const {
    control,
    setValue,
    watch,
  } = useFormContext();

  const values = watch();
  const ref = useRef<HTMLDivElement | null>(null);

  const onBlurHandler = isTagAutoComplete ? (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();
    const existedValues = values[name] || [];
    if (!value || existedValues.includes(value)) return;
    setValue(name, [...existedValues, value], { shouldValidate: true });
  } : undefined;

  const onKeyUpInputHandler = isTagAutoComplete ? (event: KeyboardEvent<HTMLInputElement>) => {
    const changedEvent = event as unknown as FocusEvent<HTMLInputElement>;
    const code = event.code;
    if (code === 'Space') {
      onBlurHandler?.(changedEvent);
    }
  } : undefined;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            const changedEvent = event as unknown as ChangeEvent<HTMLInputElement>;
            const value = changedEvent.target.value;
            if (isTagAutoComplete && !value?.trim() && event.type === 'onKeyDown') {
              return;
            }
            setValue(name, newValue, { shouldValidate: true });
          }}
          onBlur={onBlurHandler}
          clearOnBlur={isTagAutoComplete}
          renderInput={(params) => (
            <TextField
              label={label}
              ref={ref}
              placeholder={placeholder}
              onKeyUp={onKeyUpInputHandler}
              required={required}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params as TextFieldProps}
            />
          )}
          {...other}
        />
      )}
    />
  );
};
