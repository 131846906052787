import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDropzone } from 'react-dropzone';
import { acceptedFilesType } from 'src/constants/constants';

import { Iconify } from '../iconify';
import Image from '../image';
import { UploadProps } from './types';


export const UploadBox = (props: UploadProps) => {
  const {
    label,
    placeholder,
    error,
    disabled,
    sx,
    icon,
    file,
    previewFileUrl,
    ...other
  } = props;
  const theme = useTheme();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } =
    useDropzone({
      disabled,
      ...other,
    });

  const hasError = isDragReject || error;
  const hasFile = !!file || !!previewFileUrl;
  const imgUrl = typeof file === 'string' ? file : file?.preview;

  const renderPreview = hasFile && (
    <Image
      alt='avatar'
      src={imgUrl || previewFileUrl}
      sx={{
        borderRadius: 1.5,
        height: '100%',
      }}
    />
  );

  return (
    <Box
      {...getRootProps()}
      sx={{
        m: 0.5,
        width: 64,
        height: 64,
        flexShrink: 0,
        borderRadius: 1.5,
        cursor: 'pointer',
        color: 'text.disabled',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 0.5,
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.16)}`,
        ...(isDragActive && { opacity: 0.72 }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
        ...(hasError && {
          color: 'error.main',
          borderColor: 'error.main',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        }),
        '&:hover': { opacity: 0.72 },
        ...sx,
      }}
    >
      <input
        {...getInputProps()}
        accept={acceptedFilesType}
      />
      {file ? renderPreview :
        placeholder || (
          <>
            <Iconify icon={icon || 'eva:cloud-upload-fill'} width={40}/>
            {label && <Typography variant='body2' color={theme.palette.text.disabled}>{label}</Typography>}
          </>
        )
      }
    </Box>
  );
};
