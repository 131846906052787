import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CopyIcon, ExternalIcon } from 'src/assets';
import { UseBooleanReturnType } from 'src/hooks';
import { copyToClipboard, openInNewTab } from 'src/utils';

type SuccessModalProps = {
  drawer: UseBooleanReturnType;
  marketplaceUrl: string;
  refLink: string;
  onClose: VoidFunction;
};

export const SuccessModal = (props: SuccessModalProps) => {
  const {
    drawer,
    marketplaceUrl,
    refLink,
    onClose,
  } = props;
  const theme = useTheme();

  const onCopy = (str: string) => () => {
    copyToClipboard(str);
  };

  const onOpenClick = (str: string) => () => {
    openInNewTab(str);
  };

  return (
    <Dialog open={drawer.value} onClose={onClose}>
      <DialogContent sx={{ width: 400 }}>
        <Stack
          sx={{ mt: 5 }}
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          gap={4}
        >
          <ExternalIcon
            color={theme.palette.success.main}
            width={64}
            height={64}
          />
          <Typography variant='h4'>
            Project Published Successfully!
          </Typography>
          <TextField
            value={refLink}
            label='Partner Referral Link'
            sx={{ width: '100%' }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                <IconButton sx={{ p: 0 }} onClick={onCopy(refLink)}>
                  <CopyIcon/>
                </IconButton>
              </InputAdornment>,
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{
        justifyContent: 'center',
        width: '100%',
      }}>
        <Button
          size='medium'
          variant='outlined'
          type='button'
          onClick={onClose}
          fullWidth
        >
          Close
        </Button>
        <Button
          size='medium'
          variant='contained'
          type='button'
          onClick={onOpenClick(marketplaceUrl)}
          fullWidth
        >
          Open On Marketplace
        </Button>
      </DialogActions>
    </Dialog>
  );
};
