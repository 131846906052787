import { Box, Breadcrumbs, Skeleton } from '@mui/material';
import { memo } from 'react';

import { BreadcrumbsLink } from './link-item';

export interface BreadcrumbsCustomProps {
  crumbs: CrumbType[];
  showSkeleton?: boolean;
  activeLast?: boolean;
}

export type CrumbType = {
  title?: string;
  color?: string;
  href?: string;
}

export const BreadcrumbsCustom = memo((props: BreadcrumbsCustomProps) => {
  const {
    crumbs,
    showSkeleton = false,
    activeLast = false,
  } = props;
  const lastLink = crumbs?.at(-1)?.title;

  return (
    <Breadcrumbs separator={<Separator/>}>
      {showSkeleton ? (
        Array.from({ length: 3 }, (_, index) => (
          <Skeleton key={index} variant='rounded' height='22px' width='100px'/>
        ))
      ) : (
        crumbs.map((crumb, index) => {
          return (
            <BreadcrumbsLink
              key={index}
              crumb={crumb}
              activeLast={activeLast}
              disabled={crumb.title === lastLink}
            />
          );
        })
      )}
    </Breadcrumbs>
  );
});

const Separator = () => {
  return (
    <Box
      component='span'
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
      }}
    />
  );
};
