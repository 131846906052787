import { Card, CardHeader, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useMemo } from 'react';
import { EcommerceWidgetSummary, LandDetail, ReportNote } from 'src/components';
import { DEFAULT_AREA, DEFAULT_CURRENCY } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { fNumber, removeSubstrings } from 'src/utils';

import { NoteSettingType, Scenario } from '../../../../../../types';
import { InfoBlock } from '../info-block';

interface Row {
  [key: string]: string | number;
}

export interface SiteAndPurchaseType {
  scenario?: Scenario;
  noteSetting?: NoteSettingType;
}

export type MergedProps = SiteAndPurchaseType

export const SiteAndPurchase = (props: MergedProps) => {
  const {
    scenario,
    noteSetting,
  } = props;

  const mdUp = useResponsive('up', 'md');
  const rows = useMemo(() => {
    const types: string[] = [], rows: Row[] = [];
    for (const elem in scenario?.calculate?.output?.['Land purchase costs summary']) {
      types.push(elem);
    }
    types.map((elem: string) => {
      rows.push({
        type: elem || 'Unspecified',
        amount: scenario?.calculate?.output?.['Land purchase costs summary']?.[elem]?.[0] || 'Unspecified',
        total_project_costs: scenario?.calculate?.output?.['Land purchase costs summary']?.[elem]?.[1] || 'Unspecified',
        per_unit: scenario?.calculate?.output?.['Land purchase costs summary']?.[elem]?.[2] || 'Unspecified',
      });
    });
    return rows;
  }, [scenario]);

  const landDetailFields = useMemo(() => [
    {
      label: 'Cadastrial',
      value: 'JV equity',
    },
    {
      label: `Size, ${DEFAULT_AREA}`,
      value: scenario?.calculate?.output?.['Total floor area'] || 'Unspecified',
    },

  ], [scenario]);

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>Site & Purchase</Typography>
      </Grid>
      {noteSetting?.isActive &&
          <Grid item md={12}>
            {scenario
              ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
              : <Skeleton variant='rounded' height='150px' width='100%'/>
            }
          </Grid>
      }
      <Grid hidden={!mdUp} item md={6} xs={12}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={`Total purchase costs, ${DEFAULT_CURRENCY}`}
          total={scenario?.calculate?.output?.['Total purchase costs']}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={`Asking Price, ${DEFAULT_CURRENCY}`}
          total={scenario?.calculate?.output?.['Land cost']}
        />
      </Grid>
      <Grid hidden={mdUp} item md={0} xs={12}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={`Total purchase costs, ${DEFAULT_CURRENCY}`}
          total={scenario?.calculate?.output?.['Total purchase costs']}
        />
      </Grid>
      <Grid item hidden={!mdUp} md={6} xs={12}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title='Stamp duty total'
          total={scenario?.calculate?.output?.['Stamp duty total']}
        />
      </Grid>
      <Grid item hidden={!mdUp} md={6} xs={12}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title='Residual land value**'
          total={scenario?.calculate?.output?.['Residual land value']}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <LandDetail
          headLabel='Land Plot Detail'
          coordinates={scenario?.locationService?.getGeoCodes?.geometry?.coordinates}
          fields={landDetailFields}
          showSkeleton={!scenario}
        />
      </Grid>
      {mdUp &&
          <Grid item md={12}>
            {scenario ?
              <Card>
                <CardHeader title='Purchase Costs Breakdown'/>
                <Table sx={{ marginTop: '24px' }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align='right'>Amount</TableCell>
                      <TableCell align='right'>% of total costs</TableCell>
                      <TableCell align='right'>Per unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      const correspondingRowType = removeSubstrings(['Purchase cost -', 'purchase costs'], row.type.toString());
                      return (
                        <TableRow
                          key={row.type}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '& td, & th': { color: fNumber(row.amount) === '0' ? '#919EAB' : '#212B36' },
                          }}
                        >
                          <TableCell component='th' scope='row'>{correspondingRowType}</TableCell>
                          <TableCell align='right'>{fNumber(row.amount)} {DEFAULT_CURRENCY}</TableCell>
                          <TableCell align='right'>{fNumber(row.total_project_costs)}%</TableCell>
                          <TableCell align='right'>{fNumber(row.per_unit)} {DEFAULT_CURRENCY}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Card>
              : <Skeleton variant='rounded' height='354px' width='100%'/>
            }
          </Grid>
      }
      {!mdUp &&
          <Grid item xs={12}>
            <InfoBlock/>
          </Grid>
      }
    </Grid>
  );
};
