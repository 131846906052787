import { flatMap, get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CrumbType } from 'src/components';
import { paths } from 'src/config';
import { RootState } from 'src/store';
import { requestDebtAgreement } from 'src/store/financial';

import { FinanceUploadFileType } from '../../../../types';
import { DebtRequest } from './debt-request';

const UploadFinancingAgreementDocuments = () => {
  const { id } = useParams<{
    id: string
  }>();
  const debtFinancingAgreement = useSelector((state: RootState) => state.Financial.financial.debtFinancingAgreement);

  const dispatch = useDispatch();

  const crumbs: CrumbType[] = [
    {
      title: 'Financing',
      href: paths.readyForInvestors.list,
    },
    {
      title: 'Ready For Investors',
      href: paths.readyForInvestors.list,
    },
    { title: 'Loan Distribution Partners' },
  ];

  const debts = useMemo(() => {
    const currentAgreement = debtFinancingAgreement?.find(agreement => agreement._id === id);
    return currentAgreement ? [currentAgreement] : [];
  }, [id, debtFinancingAgreement]);

  const uploadedDocuments: FinanceUploadFileType[] = flatMap(debts, debtFinancingAgreement => {
    return get(debtFinancingAgreement, 'debtFinancingAgreement.documents', []);
  });

  //TODO change
  const additionalSubmitFunction = useCallback((documentList: FinanceUploadFileType[]) => {
    debts?.forEach(debt => {
      const files = [...documentList, ...(debt.debtFinancingAgreement?.documents || [])];
      dispatch(requestDebtAgreement({
        termSheetId: debt._id,
        files,
      }));
    });
  }, [debts, dispatch]);

  return (
    <DebtRequest
      showRequestAbility={false}
      crumbs={crumbs}
      uploadDocTitle='Docs List for Loan Sign '
      uploadDocSubTitle='Request the necessary documents from the ownwer. Without the necessary documents, the application may not be considered.'
      debts={debts}
      uploadedDocuments={uploadedDocuments}
      badgeColorForUploaded='success'
      badgeColorForNew='default'
      badgeTitleForNew='Sign Loan'
      additionalSubmitFunction={additionalSubmitFunction}
    />
  );
};

export default UploadFinancingAgreementDocuments;
