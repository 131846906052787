import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { MouseEvent } from 'react';
import { ReportTemplateIcon } from 'src/assets';
import { BorderedTableRow } from 'src/components/table/bordered-table-row';
import { visibilityChipColor } from 'src/constants/constants';
import { isCLickOnRow, showEmptyValue } from 'src/utils';

import { MarketplaceProjectReport } from '../../../../../../../../types';

type ReportTableRowProps = {
  row: MarketplaceProjectReport;
  onOpenClick: VoidFunction;
}

export const ReportTableRow = (props: ReportTableRowProps) => {
  const {
    row,
    onOpenClick,
  } = props;
  const {
    svgColor,
    visibility,
    createdAt,
    updatedAt,
    reportName,
  } = row;

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onOpenClick();
  };

  return (
    <BorderedTableRow hover onClick={handleRowOnClick}>
      <TableCell>
        <Stack direction='row' gap={2} alignItems='center'>
          <ReportTemplateIcon fill={svgColor}/>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>{reportName}</Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant='body2' color='text.primary'>{createdAt}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant='body2' color='text.primary'>{showEmptyValue(updatedAt)}</Typography>
      </TableCell>

      <TableCell>
        <Chip
          size='small'
          variant='outlined'
          color={visibilityChipColor[visibility]}
          label={visibility}
          disabled={visibility === 'Not published'}
        />
      </TableCell>

      <TableCell align='right'>
        <Button
          onClick={onOpenClick}
          variant='outlined'
          size='small'
        >
          Open
        </Button>
      </TableCell>
    </BorderedTableRow>
  );
};
