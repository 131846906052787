import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
  '& .MuiTypography-root': {
    width: 'fit-content',
    cursor: 'text',
  },
  '& .MuiBox-root': {
    width: 'fit-content',
    cursor: 'text',
  },
  '& .MuiButtonBase-root .MuiBox-root': { cursor: 'pointer' },
}));
