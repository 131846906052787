import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { isEmpty, pickBy } from 'lodash';
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CrossIcon } from 'src/assets';
import { DrawerCustom, FormProvider, ItemType, ListItemCustom, RHFMultiCheckbox, RHFRadioGroup, RHFTextField, RHFUploadBox, SummaryListItem } from 'src/components';
import { accept } from 'src/features/marketplace-details/components/settings';
import { UseBooleanReturnType } from 'src/hooks';
import { isEmailValid, isUrlValid } from 'src/utils';
import * as Yup from 'yup';

import { CheckboxesPartnerType, PartnerFormType } from '../types';

export interface AddPartnerModalProps {
  partnerDrawer: UseBooleanReturnType;
  headerLabel: string;
  btnTitle: string;
  editMode?: boolean;
  primaryText?: string;
  secondaryText?: string;
  logoUrl?: string;
  setEditMode?: Dispatch<SetStateAction<boolean>>;
  detailsItems?: ItemType[];
  checkboxes?: CheckboxesPartnerType[];
  formData?: PartnerFormType;
  saveChangesHandler?: (item: PartnerFormType) => void;
  drawerSx?: SxProps<Theme>;
}

export const AddPartnerModal = (props: AddPartnerModalProps) => {
  const {
    drawerSx,
    partnerDrawer,
    headerLabel,
    btnTitle,
    checkboxes = [],
    detailsItems = [],
    formData,
    saveChangesHandler,
    setEditMode,
    editMode = true,
    logoUrl,
    secondaryText,
    primaryText,
  } = props;

  const NewPartnerSchema: Yup.ObjectSchema<PartnerFormType> = Yup.object().shape({
    companyName: Yup.string().required('Company name is required'),
    companySubType: Yup.array().min(1, 'Choose at least one option'),
    companySub1Type: checkboxes.find(box => box.groupName === 'companySub1Type') ? Yup.array().min(1, 'Choose at least one option') : Yup.array().optional(),
    designation: Yup.string(),
    companyContactName: Yup.string(),
    companyContactPhone: Yup.string(),
    website: Yup.string().optional().test(
      'website',
      'Website must be a valid url address',
      (value) => value ? isUrlValid(value) : true,
    ),
    companyContactEmail: Yup.string().optional().test(
      'email',
      'Email must be a valid email address',
      (value) => value ? isEmailValid(value) : true,
    ),
    logoUrl: Yup.string(),
    logo: Yup.mixed(),
  });

  const defaultValues = useMemo(() => {
    return {
      companyName: '',
      designation: '',
      companyContactName: '',
      companyContactPhone: '',
      companyContactEmail: '',
      website: '',
      logoUrl: '',
      logo: undefined,
      companySubType: [],
      companySub1Type: [],
    };
  }, []);

  const form = useForm<PartnerFormType>(
    {
      resolver: yupResolver(NewPartnerSchema),
      defaultValues: defaultValues,
    },
  );

  const {
    reset,
    setValue,
    handleSubmit,
  } = form;

  const resetValues = useMemo(() => {
    return {
      designation: formData?.designation,
      companyName: formData?.companyName,
      logoUrl: formData?.logoUrl,
      companySubType: formData?.companySubType as string[],
      companySub1Type: formData?.companySub1Type as string[],
      companyContactEmail: formData?.companyContactEmail,
      companyContactName: formData?.companyContactName,
      companyContactPhone: formData?.companyContactPhone,
      website: formData?.website,
    };
  }, [formData?.designation, formData?.companyName, formData?.logoUrl, formData?.companySubType, formData?.companySub1Type, formData?.companyContactEmail, formData?.companyContactName, formData?.companyContactPhone, formData?.website]);

  useEffect(() => {
    if (!isEmpty(formData) && partnerDrawer.value) {
      reset(resetValues);
    }
  }, [formData, partnerDrawer.value, reset, resetValues]);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    const newFile = Object.assign(file, { preview: URL.createObjectURL(file) });

    if (file) {
      setValue('logoUrl', newFile.preview);
      setValue('logo', newFile);
    }
  }, [setValue]);

  const onSubmit = handleSubmit(async (data: PartnerFormType) => {
    saveChangesHandler?.(pickBy(data));
    closeHandler();
  });

  const closeHandler = () => {
    partnerDrawer.onFalse();
    reset(defaultValues);
  };

  const onEditClickHandler = () => {
    setEditMode?.(true);
    reset(resetValues);
  };

  return (
    <DrawerCustom
      drawerSx={drawerSx}
      open={partnerDrawer.value}
      onCloseDrawerHandler={closeHandler}
      headChildren={
        <>
          <Typography variant='h6'>{headerLabel}</Typography>
          <IconButton onClick={closeHandler}>
            <CrossIcon/>
          </IconButton>
        </>
      }
      bodyChildren={
        <>
          {!editMode
            ?
            <Stack gap={3} height='100%'>
              <ListItemCustom
                showAvatar
                avatarSx={{ borderRadius: 1.5 }}
                listItemSx={{
                  p: 0,
                  width: 'fit-content',
                }}
                primaryText={primaryText}
                listItemTextProps={{ primaryTypographyProps: { typography: 'body2' } }}
                secondaryTextTypographySx={{
                  component: 'span',
                  typography: 'caption',
                  color: 'text.secondary',
                }}
                secondaryText={secondaryText}
                avatarSrc={logoUrl}
              />
              <Stack spacing={2}>
                {detailsItems.map(item => (
                  <SummaryListItem key={item.label} item={item}/>
                ))}
              </Stack>
              <Button fullWidth variant='outlined' sx={{ mt: 'auto' }} onClick={onEditClickHandler}>
                Edit
              </Button>
            </Stack>
            : <FormProvider fullHeight methods={form} onSubmit={onSubmit}>
              <Stack gap={3} height='100%'>
                <RHFUploadBox
                  accept={accept}
                  name='logoUrl'
                  maxSize={3_145_728}
                  onDrop={handleDrop}
                />
                <RHFTextField required fullWidth name='companyName' label='Company name'/>
                <Stack rowGap={3}>
                  {checkboxes.map((checkbox, index) => {
                    const options = checkbox.checkboxGroup || [];
                    return (
                      <Fragment key={index}>
                        {checkbox.multiple
                          ? <RHFMultiCheckbox name={checkbox.groupName} options={options}/>
                          : <RHFRadioGroup name={checkbox.groupName} options={options}/>
                        }
                        {index % 2 === 0 && checkboxes.length > 1 &&
                            <Divider/>
                        }
                      </Fragment>
                    );
                  })}
                </Stack>
                <RHFTextField fullWidth name='website' label='Company website'/>
                <RHFTextField fullWidth name='companyContactName' label='Contact person name'/>
                <RHFTextField fullWidth name='designation' label='Contact person position'/>
                <RHFTextField
                  rules={{
                    validate: value => {
                      if (!value) return;
                      return isEmailValid(value) || 'Invalid email format';
                    },
                  }}
                  fullWidth
                  name='companyContactEmail'
                  label='Contact person email'
                />
                <RHFTextField fullWidth name='companyContactPhone' label='Contact person phone'/>

                <Button type='submit' fullWidth variant='contained' sx={{ mt: 'auto' }}>
                  {btnTitle}
                </Button>
              </Stack>
            </FormProvider>
          }

        </>
      }
      bodyChildrenSx={{ height: '100%' }}
    />
  );
};
