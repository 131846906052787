import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';

import { MarketplaceOffer } from '../../../../../../types';
import { OngoingOffering } from './ongoing-offering';

const MAX_LIST_LEN = 3;

type OngoingOfferingsProps = {
  offerings: MarketplaceOffer[];
  onOfferingClick: (offering: MarketplaceOffer) => void;
  onViewAllClick?: () => void;
}

export const OngoingOfferings = (props: OngoingOfferingsProps) => {
  const {
    offerings,
    onOfferingClick,
    onViewAllClick,
  } = props;

  return (
    <Stack gap={3}>
      <Stack direction='row' gap={3} alignItems='center' justifyContent='space-between'>
        <Typography variant='h6'>Ongoing Offerings</Typography>
        {onViewAllClick &&
          <Button onClick={onViewAllClick} variant='text' size='medium'>
            View All
          </Button>
        }
      </Stack>
      <Stack direction='row' gap={3}>
        {offerings.slice(0, MAX_LIST_LEN).map(project => {
          return (
            <Stack
              key={project._id}
            >
              <OngoingOffering
                offering={project}
                onOfferingClick={onOfferingClick}
              />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
