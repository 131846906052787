import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Finance, FinancingResponse } from '../../../../types';
import { SetFinancesType } from './types';


interface State {
  finances: Finance[];
  currentFinance?: Finance;
  counters: FinancingResponse['counters'];
  metadata: FinancingResponse['metadata'];
}

export const initialState: State = {
  finances: [],
  counters: {
    All: 0,
    New: 0,
    'In Progress': 0,
    Done: 0,
  },
  metadata: {
    page: 0,
    total: 0,
  },
};

const Slice = createSlice({
  name: 'Financing',
  initialState,
  reducers: {
    setFinances(state, action: PayloadAction<SetFinancesType>) {
      state.finances = action.payload.finances;
      state.counters = action.payload.counters;
      state.metadata = action.payload.metadata;
    },
    deleteFinance(state, action: PayloadAction<string>) {
      const financeToDelete = state.finances.find(finance => finance._id === action.payload);
      if (financeToDelete?._id) {
        state.finances = state.finances.filter(finance => finance._id !== action.payload);
        state.metadata.total -= 1;
        state.counters.All -= 1;
        state.counters[financeToDelete.stage as keyof typeof state.counters] -= 1;
      }
    },
    setCurrentFinance(state, action: PayloadAction<Finance>) {
      state.currentFinance = action.payload;
    },
  },
});

export const {
  reducer: FinanceReducer,
  actions: {
    setFinances,
    deleteFinance,
  },
} = Slice;

export * from './actions';
