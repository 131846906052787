import { Box } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { SxProps, Theme } from '@mui/material/styles';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Upload, UploadAvatar, UploadBox, UploadProps } from '../upload';

interface RHFUploadAvatarProps extends Omit<UploadProps, 'file'> {
  name: string;
  multiple?: boolean;
  boxSx?: SxProps<Theme>;
}

export const RHFUploadAvatar = (props: RHFUploadAvatarProps) => {
  const {
    name,
    helperTextSx,
    boxSx,
    ...other
  } = props;
  const { control } = useFormContext();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <Box sx={boxSx} ref={ref}>
          <UploadAvatar error={!!error} file={field.value} {...other} />

          {!!error && (
            <FormHelperText
              error
              sx={{
                px: 2,
                textAlign: 'center', ...helperTextSx,
              }}
            >
              {error.message}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export const RHFUploadBox = (props: RHFUploadAvatarProps) => {
  const {
    name,
    ...other
  } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <UploadBox file={field.value} error={!!error} {...other} />
      )}
    />
  );
};


export const RHFUpload = (props: RHFUploadAvatarProps) => {
  const {
    name,
    multiple,
    helperText,
    ...other
  } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) =>
        multiple ? (
          <Upload
            multiple
            accept={{ 'image/*': [] }}
            files={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        ) : (
          <Upload
            accept={{ 'image/*': [] }}
            file={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }
    />
  );
};
