import 'highlight.js/styles/base16/tomorrow-night.css';

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any*/
import hljs from 'highlight.js';


declare global {
  interface Window {
    hljs: any;
  }
}

hljs.configure({ languages: ['javascript', 'sh', 'bash', 'html', 'scss', 'css', 'json'] });

if (typeof window !== 'undefined') {
  window.hljs = hljs;
}
