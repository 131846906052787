import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, RHFSwitch, RHFTextField, useFinanceResult } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { AppThunk, RootState } from 'src/store';
import { DefaultDebtResponseType } from 'src/store/financial/types';
import { convertToNumber, convertToOnlyNumber, fNumber, onlyNumbers } from 'src/utils';
import * as Yup from 'yup';

import { DebtDetailsFormType, FinancialTableType } from '../../../../../types';

export interface AddOfferDrawerProps {
  item?: FinancialTableType;
  closeDrawerHandler: VoidFunction;
  dispatchFunction: (body: DefaultDebtResponseType, debtRequestId: string) => AppThunk;
  btnTitle: string;
}

export const AddOfferDrawer = (props: AddOfferDrawerProps) => {
  const {
    item,
    closeDrawerHandler,
    dispatchFunction,
    btnTitle,
  } = props;

  const dispatch = useDispatch();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const [isCalculateTurnedOffManually, setIsCalculateTurnedOffManually] = useState<boolean>(false);
  const { rows } = useFinanceResult(detailsData?.project?.scenario?.calculate?.input, detailsData?.project?.scenario?.calculate?.output, false);

  const currentRowItem = rows?.find(row => row?.type?.includes(item?.type));

  const OfferSchema: Yup.ObjectSchema<DebtDetailsFormType> = Yup.object().shape({
    totalFunds: Yup.string().required('Total Funds is required').test('len', 'Must be less than 13 characters', val => onlyNumbers(val).length <= 13),
    rate: Yup.string().required('Rate is required').test('len', 'Must be less than 13 characters', val => val.length <= 13),
    fee: Yup.string().required('Fee is required').test('len', 'Must be less than 13 characters', val => val.length <= 13),
    ltc: Yup.string().required('LTC is required').test('len', 'Must be less than 13 characters', val => val.length <= 13),
    ltgdv: Yup.string().required('LTGDV is required').test('len', 'Must be less than 13 characters', val => val.length <= 13),
    isCalculate: Yup.boolean().optional(),
    recalculateNote: Yup.string().optional().test(
      'recalculateNote',
      'Recalculate Note is required',
      (value, { parent }) => parent.isCalculate ? !!value?.length : true,
    ),
  });
  const defaultValues: DebtDetailsFormType = useMemo(() => {
    return {
      totalFunds: item?.offer?.totalFunds?.toString() || item?.preliminaryDebtRequest?.totalFunds?.toString() || '',
      rate: item?.offer?.rate?.toString() || item?.preliminaryDebtRequest?.rate?.toString() || '',
      fee: item?.offer?.fee?.toString() || item?.preliminaryDebtRequest?.fee?.toString() || '',
      ltc: item?.offer?.ltc?.toString() || item?.preliminaryDebtRequest?.ltc?.toString() || '',
      ltgdv: item?.offer?.ltgdv?.toString() || item?.preliminaryDebtRequest?.ltgdv?.toString() || '',
      isCalculate: item?.offer?.isCalculate || item?.preliminaryDebtRequest?.isCalculate,
      recalculateNote: item?.offer?.recalculateNote || item?.preliminaryDebtRequest?.recalculateNote || '',
    };
  }, [item?.offer?.fee, item?.offer?.isCalculate, item?.offer?.ltc, item?.offer?.ltgdv, item?.offer?.rate, item?.offer?.recalculateNote, item?.offer?.totalFunds, item?.preliminaryDebtRequest?.fee, item?.preliminaryDebtRequest?.isCalculate, item?.preliminaryDebtRequest?.ltc, item?.preliminaryDebtRequest?.ltgdv, item?.preliminaryDebtRequest?.rate, item?.preliminaryDebtRequest?.recalculateNote, item?.preliminaryDebtRequest?.totalFunds]);

  const form = useForm<DebtDetailsFormType>({
    defaultValues,
    resolver: yupResolver(OfferSchema),
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = form;
  const values = watch();
  const isCalculateDisabled = (convertToOnlyNumber(values.totalFunds) === currentRowItem?.totalFunds)
    && (convertToOnlyNumber(values.fee) === currentRowItem?.fee)
    && (convertToOnlyNumber(values.rate) === currentRowItem?.rate);

  const {
    totalFunds,
    isCalculate,
  } = values;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (isCalculateTurnedOffManually) {
      isCalculateDisabled && setValue('isCalculate', false);
      return;
    }
    setValue('isCalculate', !isCalculateDisabled);
  }, [isCalculateDisabled, isCalculateTurnedOffManually, setValue]);

  const onSubmit = (data: DebtDetailsFormType) => {
    const {
      isCalculate,
      recalculateNote,
      ...rest
    } = data;
    if (item) {
      const convertedValues = convertToNumber(rest);
      if (convertedValues !== null) {
        dispatch(dispatchFunction({
          ...convertedValues,
          interestRate: convertedValues.rate,
          _id: item._id,
          section: item.type,
          bankContactEmail: item.bankContactEmail,
          bankContactName: item.bankContactName,
          partnerId: item.bankId || '',
          isCalculate,
          recalculateNote: isCalculate ? recalculateNote : '',
        }, item.debtRequestId));
        closeDrawerHandler();
      }
    }
  };

  const toggleCheckbox = () => {
    if (isCalculateDisabled) return;
    isCalculate && setIsCalculateTurnedOffManually(true);
    setValue('isCalculate', !isCalculate);
  };

  return (
    <>
      <Typography variant='subtitle2'>Bank Offer</Typography>
      <FormProvider methods={form} onSubmit={handleSubmit(onSubmit)} fullHeight>
        <Stack justifyContent='space-between' height='100%'>
          <Stack
            rowGap={3}
            gap={2}
            direction='row'
            flexWrap='wrap'
            maxWidth={432}
          >
            <RHFTextField
              sx={{ width: 'calc(50% - 8px)' }}
              name='totalFunds'
              label={`* Total Funds, ${DEFAULT_CURRENCY}`}
              value={fNumber(totalFunds)}
            />
            <RHFTextField
              sx={{ width: 'calc(50% - 8px)' }}
              name='rate'
              label='* Rate, %'
            />
            <RHFTextField
              sx={{ width: 'calc(50% - 8px)' }}
              name='fee'
              label='* Fee, %'
            />
            <RHFTextField
              sx={{ width: 'calc(50% - 8px)' }}
              name='ltgdv'
              label='* LTGV, %'
            />
            <RHFTextField
              sx={{ width: 'calc(50% - 8px)' }}
              name='ltc'
              label='* LTC, %'
            />
            <Stack
              borderRadius={2}
              p={2}
              width='100%'
              rowGap={2}
              sx={{ backgroundColor: (theme) => isCalculate ? alpha(theme.palette.info.main, 0.08) : theme.palette.grey[100] }}
            >
              <RHFSwitch
                disabled={isCalculateDisabled}
                name='isCalculate'
                labelPlacement='end'
                onClick={toggleCheckbox}
                label='Recalculate the financial details of the project'
              />
              {isCalculate &&
                <RHFTextField
                  multiline
                  disabled={isCalculateDisabled}
                  rows={4}
                  name='recalculateNote'
                  label='Note *'
                />
              }
            </Stack>
          </Stack>
          <LoadingButton
            fullWidth
            type='submit'
            variant='contained'
            size='large'
            sx={{ mt: 2 }}
            loading={isSubmitting}
          >
            {btnTitle}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </>
  );
};
