import { Icon } from '@iconify/react';
import Box, { BoxProps } from '@mui/material/Box';
import { forwardRef } from 'react';

import { IconifyProps } from './types';

interface Props extends BoxProps {
  icon: IconifyProps;
}

export const Iconify = forwardRef<SVGElement, Props>((props, ref) => {
  const {
    icon,
    width = 20,
    sx,
    ...other
  } = props;

  return (
    <Box
      ref={ref}
      component={Icon}
      className='component-iconify'
      icon={icon}
      sx={{
        width,
        height: width, ...sx,
      }}
      {...other}
    />
  );
});
