import { Box, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { ArrowIcon } from 'src/assets';
import { Iconify } from 'src/components';
import { statusColor } from 'src/constants/constants';
import { useBoolean, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { fDate } from 'src/utils';

import { DetailViewData } from '../../../../../../types';
import { ReserveSharesDrawer } from './reserve-shares-drawer';

export interface InvestorProjectDetailsHeaderProps {
  showBackArrow?: boolean;
  isPreview?: boolean;
  customBackArrowClick?: VoidFunction;
  detailsData?: DetailViewData;
}

export const InvestorProjectDetailsHeader = (props: InvestorProjectDetailsHeaderProps) => {
  const {
    showBackArrow,
    isPreview,
    customBackArrowClick,
    detailsData = {},
  } = props;
  const drawer = useBoolean();
  const theme = useTheme();
  const router = useRouter();
  const user = useSelector((state: RootState) => state.Auth.user);

  const textSecondary = theme.palette.text.secondary;
  const project = detailsData.project;
  const offeringDetails = detailsData.offeringDetails;
  const activities = offeringDetails?.activities?.data;
  const lead = detailsData.lead;
  const assets = detailsData.assets;
  const isUserInActivity = !!activities?.find(activity => activity.customerId === user?.id);

  const backArrowClick = () => {
    router.back();
  };

  return (
    <>
      <Stack
        sx={{ mb: 5 }}
        gap={1}
        direction='row'
        alignItems='flex-start'
        justifyContent='space-between'
      >
        <Stack gap={1}>
          <Stack direction='row' alignItems='center' gap={1}>
            {showBackArrow &&
              <Box sx={{ transform: 'rotate(180deg)' }}>
                <IconButton aria-label='back' onClick={customBackArrowClick || backArrowClick}>
                  <ArrowIcon/>
                </IconButton>
              </Box>
            }
            <Typography variant='h4'>
              {offeringDetails?.listingName || project?.name || assets?.general?.projectName}
            </Typography>
            <Chip
              label={project?.scenario?.status}
              variant='outlined'
              size='small'
              color={statusColor[project?.scenario?.status || 'In Progress']}
            />
          </Stack>
          <Stack direction='row' gap={2}>
            <Stack direction='row' gap={0.5}>
              <Iconify color={textSecondary} icon='mingcute:location-fill'/>
              <Typography variant='body2'>
                Location:
              </Typography>
              <Typography variant='subtitle2'>
                {lead?.location?.address || lead?.location?.placeName}
              </Typography>
            </Stack>
            <Typography
              variant='subtitle2'
              color={theme.palette.text.disabled}
            >
              •
            </Typography>
            <Stack direction='row' gap={0.5}>
              <Iconify color={textSecondary} icon='solar:calendar-date-bold'/>
              <Typography variant='body2'>
                Maturity Target:
              </Typography>
              <Typography variant='subtitle2'>
                {fDate(project?.scenario?.calculate?.output.Timeline?.exit_month)}
              </Typography>
            </Stack>
            <Typography
              variant='subtitle2'
              color={theme.palette.text.disabled}
            >
              •
            </Typography>
            <Stack direction='row' gap={0.5}>
              <Iconify color={textSecondary} icon='solar:calendar-date-bold'/>
              <Typography variant='body2'>
                Project Type:
              </Typography>
              <Typography variant='subtitle2'>
                {project?.scenario?.calculate?.output['Project type']}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button
          disabled={isPreview || isUserInActivity}
          onClick={drawer.onTrue}
          variant='contained'
          size='large'
        >
          Reserve Shares
        </Button>
      </Stack>

      <ReserveSharesDrawer drawer={drawer}/>
    </>
  );
};
