import { OverviewButtonsProps, OverviewInfo, OverviewInfoProps } from './components';

type OverviewProps = OverviewButtonsProps & OverviewInfoProps & {}

export const Overview = (props: OverviewProps) => {

  return (
    <OverviewInfo {...props} />
  );
};
