import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { SxProps, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ChangeEvent, memo, MouseEventHandler } from 'react';

import { Iconify } from '../iconify';

export interface SearchFieldProps {
  value?: string;
  placeholder?: string;
  isFullWidth?: boolean;
  showEndIcon?: boolean;
  height?: number;
  inputSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  endIconOnClickHandler?: MouseEventHandler<HTMLButtonElement>;
  handleFieldOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchField = memo((props: SearchFieldProps) => {
  const {
    value = '',
    placeholder = 'Search...',
    isFullWidth = true,
    showEndIcon = false,
    handleFieldOnChange,
    endIconOnClickHandler,
    inputSx,
    sx,
    height = 40,
  } = props;
  return (
    <TextField
      autoComplete='off'
      fullWidth={isFullWidth}
      value={value}
      onChange={handleFieldOnChange}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }}/>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={endIconOnClickHandler} sx={{ visibility: showEndIcon ? 'visible' : 'hidden' }}>
              <Iconify icon='solar:close-circle-bold' sx={{ color: '#919eab' }}/>
            </IconButton>
          </InputAdornment>
        ),
        sx: { height: height, ...inputSx },
      }}
      sx={sx}
    />
  );
});
