import { TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { fNumber, fPercent, showEmptyValue } from 'src/utils';

import { FinanceCostsBreakdown } from '../../../../../types';

export interface DebtRequestRowProps {
  row: FinanceCostsBreakdown;
}

export const DebtRequestRow = (props: DebtRequestRowProps) => {
  const { row } = props;
  const {
    fee,
    ltc,
    rate,
    ltgdv,
    profitShare,
    totalFunds,
    type,
  } = row;

  return (
    <TableRow>
      <TableCell>
        {type}
      </TableCell>
      <TableCell align='right'>
        {fNumber(totalFunds)} {DEFAULT_CURRENCY}
      </TableCell>
      <TableCell align='right'>
        {showEmptyValue(fPercent(rate))}
      </TableCell>
      <TableCell align='right'>
        {showEmptyValue(fPercent(fee))}
      </TableCell>
      <TableCell align='right'>
        {showEmptyValue(fPercent(ltc))}
      </TableCell>
      <TableCell align='right'>
        {showEmptyValue(fPercent(ltgdv))}
      </TableCell>
      <TableCell align='right'>
        {showEmptyValue(fPercent(profitShare))}
      </TableCell>
    </TableRow>
  );
};
