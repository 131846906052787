import { equals } from 'ramda';
import { setStorage } from 'src/hooks';

import { InitResponse } from '../../../../types';
import { apiFetch } from '../api';
import { AppThunk } from '../store';
import { authFailure, authSuccess, setAuthStatus, setNotVerified } from '.';

export const authInitAction = (): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    return;
  }

  try {
    dispatch(setAuthStatus('load'));
    const auth: InitResponse = await apiFetch('auth/init');

    dispatch(authSuccess(auth.user));
    dispatch(setNotVerified(auth.notVerifiedEmail ?? null));

  } catch (error) {
    dispatch(authFailure((error as Error).toString()));
  }

  const checkAuthPeriod = 30 * 60 * 1000;

  const checkAuth = async () => {
    try {
      const user = getState().Auth.user;
      const userId = user?.id;
      const auth: InitResponse = await apiFetch('auth/init');
      if (!equals(auth.user, user) || auth.user.id !== userId) {
        dispatch(authSuccess(auth.user));
        dispatch(setNotVerified(auth.notVerifiedEmail ?? null));
      }

      if (!userId && !auth.user) {
        setStorage('returnUrl', window.location.href);
        window.location.href = '/api/sso-login';
      }
    } catch (error) {
      dispatch(authFailure((error as Error).toString()));
    }
    setTimeout(checkAuth, checkAuthPeriod);
  };

  await checkAuth();
};


export const logoutThunk = (): AppThunk => async () => {
  window.location.href = '/api/sso-logout';
};
