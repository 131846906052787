import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Card, { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@mui/material/MenuItem';
// @mui
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import { useCallback, useState } from 'react';
import { Chart, CustomPopover, Iconify, useChart } from 'src/components';
import { usePopover } from 'src/hooks';

export type SeriesWithFiltersType = {
  seriesFilter?: string;
  additionalFilter?: string;
  data: {
    name: string;
    data: number[];
  }[];
}

export type ChartWithFiltersType = {
  categories?: string[];
  colors?: string[];
  series: SeriesWithFiltersType[];
  options?: ApexOptions;
};

export interface CardBarFiltersChartProps {
  title?: string;
  chartType?: 'area' | 'line' | 'bar' | 'pie' | 'donut' | 'radialBar' | 'scatter' | 'bubble' | 'heatmap' | 'candlestick' | 'boxPlot' | 'radar' | 'polarArea' | 'rangeBar' | 'rangeArea' | 'treemap';
  subheader?: string;
  seriesValues: string[];
  additionalSeriesValues?: string[];
  showAdditionalFilter?: boolean;
  chart: ChartWithFiltersType;
  cardProps?: CardProps;
}

export const CardBarFiltersChart = (props: CardBarFiltersChartProps) => {
  const {
    title,
    subheader,
    chart,
    seriesValues = [],
    additionalSeriesValues = [],
    showAdditionalFilter = false,
    cardProps,
    chartType = 'bar',
  } = props;
  const theme = useTheme();
  const [seriesFilter, setSeriesFilter] = useState<string>(seriesValues[0]);
  const [additionalFilter, setAdditionalFilter] = useState<string>(additionalSeriesValues[0]);

  const {
    colors = [theme.palette.success.main, theme.palette.success.dark],
    categories,
    series,
    options,
  } = chart;

  const popover = usePopover();
  const additionalPopover = usePopover();


  const chartOptions = useChart({
    colors: colors,
    chart: { stacked: true },
    xaxis: { categories },
    ...options,
  });

  const handleChangeSeries = useCallback((newValue: string = '') => {
    popover.onClose();
    setSeriesFilter(newValue);
  }, [popover]);

  const handleChangeAdditionalFilter = useCallback((newValue: string = '') => {
    additionalPopover.onClose();
    setAdditionalFilter(newValue);
  }, [additionalPopover]);

  return (
    <>
      <Card {...cardProps}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <Stack direction='row' gap={2}>
              {showAdditionalFilter &&
                  <ButtonBase
                    onClick={additionalPopover.onOpen}
                    sx={{
                      pl: 1,
                      py: 0.5,
                      pr: 0.5,
                      borderRadius: 1,
                      typography: 'subtitle2',
                      bgcolor: 'background.neutral',
                    }}
                  >
                    {additionalFilter}
                    <Iconify
                      width={16}
                      icon={additionalPopover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                      sx={{ ml: 0.5 }}
                    />
                  </ButtonBase>
              }
              <ButtonBase
                onClick={popover.onOpen}
                sx={{
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                }}
              >
                {seriesFilter}
                <Iconify
                  width={16}
                  icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                  sx={{ ml: 0.5 }}
                />
              </ButtonBase>
            </Stack>
          }
        />

        {series.map((item) => {
          return (
            <Box
              key={item.seriesFilter}
              sx={{
                mt: 3,
                mx: 3,
              }}
            >
              {!showAdditionalFilter &&
                  <>
                    {(item.seriesFilter === seriesFilter) && (
                      <Chart
                        dir='ltr'
                        type={chartType}
                        series={item.data}
                        options={chartOptions}
                        height={364}
                      />
                    )}
                  </>
              }
              {showAdditionalFilter &&
                  <>
                    {(
                      (item.additionalFilter === additionalFilter)
                        && item.seriesFilter === seriesFilter)
                      && (
                        <Chart
                          dir='ltr'
                          type={chartType}
                          series={item.data}
                          options={chartOptions}
                          height={364}
                        />
                      )}
                  </>
              }
            </Box>
          );
        })}
      </Card>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {seriesValues.map((option, index) => (
          <MenuItem
            key={index}
            selected={option === seriesFilter}
            onClick={() => handleChangeSeries(option)}
          >
            {option}
          </MenuItem>
        ))}
      </CustomPopover>

      <CustomPopover open={additionalPopover.open} onClose={additionalPopover.onClose} sx={{ width: 140 }}>
        {additionalSeriesValues.map((option, index) => (
          <MenuItem
            key={index}
            selected={option === additionalFilter}
            onClick={() => handleChangeAdditionalFilter(option)}
          >
            {option}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
};
