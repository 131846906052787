import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SellResponse } from '../../../../types';

export * from './actions';

interface State {
  items: SellResponse;
}

export const initialState: State = {
  items: {
    projects: [],
    counter: {
      All: 0,
      'Not Selling': 0,
      'On Sale': 0,
      Sold: 0,
    },
    metadata: {
      total: 0,
      page: 0,
    },
  },
};

const Slice = createSlice({
  name: 'Sell',
  initialState,
  reducers: {
    setSellItems(state, action: PayloadAction<SellResponse>) {
      state.items = action.payload;
    },
  },
});

export const {
  reducer: SellReducer,
  actions: { setSellItems },
} = Slice;
