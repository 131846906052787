import { omit } from 'lodash';
import { demoUserList } from 'src/constants/demo-mock-data';
import { CreateNewUserType, UpdateUserType } from 'src/features/users-page/types';

import { AddFileSectionResponseType, Config, FileSectionsType, FinanceUploadFileType, ShortUser, User } from '../../../../types';
import { apiFetch } from '../api';
import { authInitAction } from '../auth';
import { AppThunk } from '../store';
import {
  addFileSection,
  addUser,
  deleteUser,
  setConfig,
  setConfigError,
  setConfigStatus,
  setFileSections,
  setShortUserList,
  setUser,
  setUserList,
  setUserListStatus,
  setUserStatus,
  updateUser,
} from '.';

export const loadConfig = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setConfigStatus('load'));
    const config = await apiFetch('config', {
      method: 'get',
      returnError: true,
    }) as Config | {
      error: string
    };

    if ('error' in config) {
      dispatch(setConfigStatus('error'));
      dispatch(setConfigError(config.error));
    } else {
      dispatch(setConfig(config));
      dispatch(authInitAction());
      dispatch(setConfigStatus('success'));
    }

  } catch (error) {
    console.error(error);
    dispatch(setConfigStatus('error'));
    dispatch(setConfigError((error as Error).message));
  }
};

export const getUser = (userId?: string): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    const users = getState().App.userList;
    const currentUser = users.find(user => user.id === userId);

    if (currentUser) {
      dispatch(setUser(currentUser));
    }
    return;
  }

  try {
    dispatch(setUserStatus('load'));

    const user = await apiFetch(`user${userId ? `/${userId}` : ''}`);

    if (!user) {
      console.error('Can not update user');
      return;
    }

    dispatch(setUser(user));
    dispatch(setUserStatus('success'));
  } catch (error) {
    console.error(error);
    dispatch(setUser(null));
    dispatch(setUserStatus('error'));
  }
};

export const getUserList = (short?: boolean): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(setUserList(demoUserList));
    return;
  }

  try {
    dispatch(setUserListStatus('load'));

    const userList = await apiFetch(`user/list${short ? '/short' : ''}`) as User[] | ShortUser[];
    if (!userList) {
      console.error('Can not update user list');
      return;
    }

    if (short) {
      dispatch(setShortUserList((userList as ShortUser[]).sort((a, b) => a.displayName.toUpperCase() < b.displayName.toUpperCase() ? -1 : 1)));
    } else {
      dispatch(setUserList((userList as User[])));
    }
    dispatch(setUserListStatus('success'));
  } catch (error) {
    console.error(error);
    dispatch(setUserList([]));
    dispatch(setUserListStatus('error'));
  }
};

export const deleteUserThunk = (userId: string): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(deleteUser(userId));
    return;
  }

  try {
    await apiFetch(`user/delete/${userId}`, { method: 'delete' });
    dispatch(deleteUser(userId));
  } catch (error) {
    console.error(error);
  }
};

export const createNewUser = (newUser: CreateNewUserType): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(addUser({
      id: Math.random().toString(),
      role: 'user',
      roleNP: newUser.role,
      ...(omit(newUser, 'role')),
    }));
    return;
  }

  try {
    const createdUser = await apiFetch('user/create', {
      method: 'post',
      body: JSON.stringify(omit(newUser, ['role', 'avatar'])),
    });

    if (newUser.avatar && createdUser) {
      await apiFetch('userpic', {
        method: 'post',
        body: newUser.avatar,
        headers: { 'Content-Type': newUser.avatar.type },
      });
    }
  } catch (error) {
    console.error(error);
  }
};
export const updateUserThunk = (updatedUser: UpdateUserType): AppThunk => async (dispatch, getState) => {
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(updateUser(updatedUser));
    return;
  }

  try {
    // const createdUser = await apiFetch('user/create', {
    //   method: 'post',
    //   body: JSON.stringify(omit(newUser, ['role', 'avatar'])),
    // });

    // if (updatedUser.avatar && createdUser) {
    //   const uploadedAvatar = await apiFetch('userpic', {
    //     method: 'post',
    //     body: updatedUser.avatar,
    //     headers: { 'Content-Type': updatedUser.avatar.type },
    //   });
    // }
  } catch (error) {
    console.error(error);
  }
};

export const getFileSections = (leadId: string = '', projectId: string = ''): AppThunk => async (dispatch) => {
  try {
    const fileSections: FileSectionsType[] = await apiFetch(`file-section/list/${leadId}/${projectId}`, { method: 'get' });
    dispatch(setFileSections(fileSections || []));
  } catch (error) {
    console.error(error);
    dispatch(setFileSections([]));
  }
};

export const addFileSectionThunk = (paramBody: FinanceUploadFileType): AppThunk => async (dispatch) => {
  try {
    const correspondingBody = {
      section: paramBody.section,
      filename: paramBody.filename,
    };
    const response: AddFileSectionResponseType = await apiFetch('file-section/', {
      method: 'post',
      body: JSON.stringify(correspondingBody),
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response) {
      console.error('Can not create new file section');
      return;
    }

    const newSection: FileSectionsType = {
      section: paramBody.section || 'Lead',
      filenames: [{
        ...paramBody,
        files: [],
        _id: response.fileSectionId,
      }],
    };
    dispatch(addFileSection(newSection));
  } catch (error) {
    console.error(error);
    dispatch(setFileSections([]));
  }
};
