import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ChangeEvent, Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { isEmailValid } from 'src/utils';

export interface DrawerFooterProps {
  drawerRequestOnClickHandler?: MouseEventHandler<HTMLButtonElement>;
  textFieldValue: string;
  disableBtn?: boolean;
  textFieldError?: boolean;
  setTextFieldValue: Dispatch<SetStateAction<string>>;
  setTextFieldError?: Dispatch<SetStateAction<boolean>>;
}

export const DrawerFooter = (props: DrawerFooterProps) => {
  const {
    drawerRequestOnClickHandler,
    textFieldValue,
    setTextFieldValue,
    disableBtn = false,
    textFieldError = false,
    setTextFieldError,
  } = props;

  const handleTextFieldOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextFieldValue(value);
    if (isEmailValid(value)) {
      setTextFieldError?.(false);
    }
  };

  return (
    <>
      <TextField
        required
        autoComplete='off'
        error={textFieldError}
        sx={{
          mr: 2,
          width: '75%',
        }}
        value={textFieldValue}
        placeholder='Request to'
        InputLabelProps={{ shrink: true }}
        onChange={handleTextFieldOnChange}
        InputProps={{ sx: { height: 40 } }}
        label='Request to'
      />
      <Button
        disabled={disableBtn}
        onClick={drawerRequestOnClickHandler}
        variant='contained'
      >
        Request
      </Button>
    </>
  );
};
