import { Avatar, Button, Card, Skeleton } from '@mui/material';
import { useState } from 'react';
import { MoreOptions, OptionType } from 'src/components';
import { ManagersModal } from 'src/features/new-lead';
import { usePopover } from 'src/hooks';

import { AssignmentMember, User } from '../../../../types';
import { StyledCardHeader } from './styles';

export type RoleKey = 'analyst' | 'projectManager';

export type TeamMemberCardProps = {
  teamRole: string;
  assignedUser?: AssignmentMember;
  handleAssign?: (user: User) => void;
  users?: User[];
  showSkeleton?: boolean;
  showAssignBtn?: boolean;
}


export const TeamMemberCard = (props: TeamMemberCardProps) => {
  const {
    assignedUser,
    teamRole,
    handleAssign,
    showSkeleton = false,
    showAssignBtn = false,
    users = [],
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const popover = usePopover();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOnAssign = (user: User) => () => {
    handleAssign?.(user);
    setOpenModal(false);
  };

  const teamMoreOptions: OptionType[] = [
    {
      label: 'Edit',
      icon: 'edit',
      closePopover: popover.onClose,
      itemOnClickHandler: handleOpenModal,
    },
  ];


  const getActionComponent = () => {
    if (!showAssignBtn) return;
    if (!assignedUser) {
      return (
        <Button onClick={handleOpenModal} variant='outlined'>Assign</Button>
      );
    }
    if (popover) {
      return (
        <MoreOptions
          popoverOnClose={popover.onClose}
          popoverOnOpen={popover.onOpen}
          popoverOpen={popover.open}
          options={teamMoreOptions}
        />
      );
    }
  };

  return (
    <>
      {
        showSkeleton ? (
          <Skeleton variant='rounded' width='300px' height='70px'/>
        ) : (
          <Card sx={{ width: 'calc(50% - 12px)' }}>
            <StyledCardHeader
              avatar={
                <Avatar/>
              }
              action={getActionComponent()}
              title={assignedUser?.name || teamRole}
              subheader={assignedUser ? teamRole : 'Not Assigned'}
            />
            <ManagersModal
              open={openModal}
              role={teamRole}
              users={users}
              onClose={() => setOpenModal(false)}
              handleAssign={handleOnAssign}
              assignedUser={assignedUser}
            />
          </Card>
        )
      }
    </>

  );
};
