import { Grid, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { EcommerceWidgetSummary, ReportNote } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';
import { RootState } from 'src/store';

import { NoteSettingType } from '../../../../../../types';

export interface ProjectMultiplesProps {
  noteSetting?: NoteSettingType;
}

export const ProjectMultiples = (props: ProjectMultiplesProps) => {
  const { noteSetting } = props;
  const scenario = useSelector((state: RootState) => state.Storage.detailsData.project?.scenario);
  const mdUp = useResponsive('up', 'md');

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Typography variant='h4' gutterBottom>Project Multiples</Typography>
      </Grid>
      {noteSetting?.isActive &&
          <Grid hidden={!mdUp} item md={12}>
            {scenario
              ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
              : <Skeleton variant='rounded' height='150px' width='100%'/>
            }
          </Grid>
      }
      <Grid item md={3} xs={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title='Profit on cost'
          total={scenario?.calculate?.output?.['Profit on cost']}
          postfix='%'
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title='Profit on GDV'
          total={scenario?.calculate?.output?.['Profit on GDV']}
          postfix='%'
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title={`Net profit, ${DEFAULT_CURRENCY}`}
          total={scenario?.calculate?.output?.['Net profit']}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <EcommerceWidgetSummary
          showWidget={!!scenario}
          title='Return on Equity'
          total={scenario?.calculate?.output?.['Return on Equity']}
          postfix='%'
        />
      </Grid>
    </Grid>
  );
};
