import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Research, ResearchResponse } from '../../../../types';

export * from './actions';

interface State {
  researches: ResearchResponse;
  currentResearch?: Research;
}

export const initialState: State = {
  researches: {
    researches: [],
    counters: {
      All: 0,
      New: 0,
      Recalculate: 0,
      'In Progress': 0,
      'Approved': 0,
      'Rejected': 0,
    },
    netCount: 0,
  },
};

const Slice = createSlice({
  name: 'Research',
  initialState,
  reducers: {
    setResearches(state, action: PayloadAction<ResearchResponse>) {
      state.researches = action.payload;
    },
    deleteResearch(state, action: PayloadAction<string>) {
      state.researches.researches = state.researches.researches.filter(research => research._id !== action.payload);
    },
  },
});

export const {
  reducer: ResearchReducer,
  actions: {
    setResearches,
    deleteResearch,
  },
} = Slice;
