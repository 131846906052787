import { Stack, Step, StepLabel, Stepper } from '@mui/material';

import { Connector, StepIconComponent } from './components';

interface StepperCustomProps {
  steps: StepType[];
}

export type StepType = {
  label: string;
  active: boolean;
  completed: boolean;
}

export const StepperCustom = (props: StepperCustomProps) => {
  const { steps } = props;

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        connector={<Connector />}
      >
        {steps.map((step) => (
          <Step active={step.active} completed={step.completed} key={step.label}>
            <StepLabel sx={{ whiteSpace: 'nowrap' }} StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
