import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TenderingResponse } from '../../../../types';


interface State {
  tenderingList: TenderingResponse;
}

export const initialState: State = {
  tenderingList: {
    projects: [],
    counters: {
      All: 0,
      'In Progress': 0,
      'Done': 0,
      'New': 0,
    },
    metadata: {
      page: 0,
      total: 0,
    },
  },
};

const Slice = createSlice({
  name: 'Tendering',
  initialState,
  reducers: {
    setTenderingList(state, action: PayloadAction<TenderingResponse>) {
      state.tenderingList = action.payload;
    },
    deleteTendering(state, action: PayloadAction<string>) {
      const projectToDelete = state.tenderingList.projects.find(project => project._id === action.payload);
      state.tenderingList.projects = state.tenderingList.projects.filter(project => project._id !== projectToDelete?._id);
      state.tenderingList.metadata.total -= 1;
      state.tenderingList.counters.All -= 1;
      state.tenderingList.counters[projectToDelete?.status as keyof typeof state.tenderingList.counters] -= 1;
    },
  },
});

export const {
  reducer: TenderingReducer,
  actions: {
    deleteTendering,
    setTenderingList,
  },
} = Slice;

export * from './actions';
