import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import React, { useRef } from 'react';
import { Controller, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

type RHFDatePickerProps<T> = DatePickerProps<T> & {
  name: string;
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> | undefined
};

export const RHFDatePicker = <T, >(props: RHFDatePickerProps<T>) => {
  const {
    name,
    label,
    rules,
    ...other
  } = props;
  const { control } = useFormContext();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Controller
      name={name}
      rules={{ ...rules }}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => (
        <DatePicker
          {...field}
          label={label}
          ref={ref}
          format='dd/MM/yyyy'
          value={new Date(field.value || '') as T}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
            },
          }}
          {...other}
        />
      )}
    />
  );
};
