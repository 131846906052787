import { ReactNode } from 'react';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

type FormProviderProps = {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  fullHeight?: boolean;
};

export const FormProvider = (props: FormProviderProps) => {
  const { children, onSubmit, methods, fullHeight } = props;
  return (
    <Form {...methods} >
      <form style={{ height: fullHeight ? '100%' : 'inherit' }} onSubmit={onSubmit}>{children}</form>
    </Form>
  );
};
