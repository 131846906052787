import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { paths } from 'src/config';
import { useBoolean, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { createPartner } from 'src/store/partners';

import { PartnerItemType } from '../../../../../../types';
import { bankCheckboxes } from '../../mock-data';
import { PartnerFormType } from '../../types';
import { AddPartnerModal } from '../add-partner-modal';
import { PartnersProductList } from '../partners-product-list';
import { BankProductsTable } from './bank-products-table';

const BankProductList = () => {
  const { id } = useParams<{ id: string }>();
  const modalView = useBoolean();
  const dispatch = useDispatch();
  const router = useRouter();

  const currentPartner = useSelector((state: RootState) => state.Partners.currentPartner);

  const onAddProductClickHandler = () => {
    router.push(paths.bankPartners.createProduct(id));
  };

  const onEditClickHandler = () => {
    modalView.onTrue();
  };

  const saveChangesHandler = (data: PartnerFormType) => {
    const updatedData: PartnerItemType = {
      ...data,
      _id: id,
      companyType: 'Bank',
    };
    dispatch(createPartner(updatedData, 'banks'));
  };

  return (
    <>
      <PartnersProductList
        partner={currentPartner}
        crumbMiddleItem={{
          title: 'Banks',
          href: paths.bankPartners.list,
        }}
        productTable={<BankProductsTable partnerId={id}/>}
        onEditClickHandler={onEditClickHandler}
        onAddProductClickHandler={onAddProductClickHandler}
      />
      <AddPartnerModal
        formData={currentPartner}
        partnerDrawer={modalView}
        saveChangesHandler={saveChangesHandler}
        checkboxes={bankCheckboxes}
        headerLabel='Edit Bank'
        btnTitle='Save Changes'
      />
    </>
  );
};

export default BankProductList;

