import { Box, Card, CardHeader, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { CardBarChart, EcommerceWidgetSummary, MapBox, ReportNote } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { fBigStringRange } from 'src/utils';

import { MarkerType, MarketMappingValuesResult, NoteSettingType } from '../../../../../../types';

export interface LocalMarketType {
  marketMappingValues?: MarketMappingValuesResult[];
  showData?: boolean;
  quartile50?: number;
  quartile75?: number;
  quartile90?: number;
  narrative?: string;
  narrativeTitle?: string;
  coordinates?: number[] | null;
  medianPriceMonths?: string[];
  distributionLabels?: string[];
  medianPrices?: number[];
  distributionData?: number[];
  listingsPrices?: number[];
  noteSetting?: NoteSettingType;
}


export const LocalMarket = (props: LocalMarketType) => {
  const {
    marketMappingValues = [],
    medianPriceMonths = [],
    distributionLabels = [],
    medianPrices = [],
    distributionData = [],
    listingsPrices = [],
    quartile90,
    quartile50,
    quartile75,
    narrativeTitle,
    narrative,
    showData,
    noteSetting,
    coordinates = null,
  } = props;

  const markers = useMemo(() => {
    const markers: MarkerType[] = marketMappingValues?.map((elem: MarketMappingValuesResult) => {
      return {
        location: {
          longitude: elem.longitude,
          latitude: elem.latitude,
        },
        info: { objectType: 'circle' },
        groupData: { color: elem.color },
      };
    });
    return markers;
  }, [marketMappingValues]);

  return <>
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>Local Market</Typography>
      </Grid>
      {noteSetting?.isActive &&
          <Grid item md={12}>
            {showData
              ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
              : <Skeleton variant='rounded' height='150px' width='100%'/>
            }
          </Grid>
      }
      <Grid item md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title={`Med. price last year, ${DEFAULT_CURRENCY}`}
          subTitle={`Region: ${quartile50 || 'Unspecified'}`}
          total={quartile50}
        />
      </Grid>
      <Grid item xs={3} md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title={`75th %ile price last year, ${DEFAULT_CURRENCY}`}
          subTitle={`Region: ${quartile75 || 'Unspecified'}`}
          total={quartile75}
        />
      </Grid>
      <Grid item xs={3} md={4}>
        <EcommerceWidgetSummary
          showWidget={showData}
          title='90th %ile price last year'
          subTitle={`Region: ${quartile90 || 'Unspecified'}`}
          total={quartile90}
        />
      </Grid>
      {!showData &&
          <Grid item md={12}>
            <Skeleton variant='rounded' height='150px' width='100%'/>
          </Grid>
      }
      <Grid item md={12}>
        {showData ?
          <Card>
            <CardHeader title='About the Local Market'/>
            <Stack
              spacing={1.5}
              sx={{
                p: 3,
                typography: 'body2',
              }}
            >
              <Stack direction='row' alignItems='center'>
                <Typography variant='subtitle2' mr={0.5}>Area:</Typography>
                {narrativeTitle || 'Unspecified'}
              </Stack>
              <Stack direction='row' alignItems='center'>
                {narrative || 'Unspecified'}
              </Stack>
              <Box sx={{ marginTop: '24px' }}>
                <MapBox
                  height='443px'
                  width='100%'
                  mapStyle='street'
                  markers={markers}
                  coordinates={coordinates || null}
                />
              </Box>
            </Stack>
          </Card>
          : <Skeleton variant='rounded' height='660px' width='100%'/>
        }
      </Grid>
      <Grid item md={12}>
        <CardBarChart
          title='Median price over time and monthly listings'
          // TODO remove hardcode
          subheader='(+43%) than last year'
          chart={{
            options: { xaxis: { type: 'category' } },
            labels: medianPriceMonths,
            series: [
              {
                name: 'Monthly listings',
                type: 'column',
                fill: 'solid',
                data: listingsPrices,
              },
              {
                name: 'Median price',
                type: 'area',
                fill: 'gradient',
                data: medianPrices,
              },
            ],
          }}
        />
      </Grid>
      <Grid item md={12}>
        <CardBarChart
          title='Distribution of prices'
          chart={{
            options: { xaxis: { type: 'category' } },
            labels: distributionLabels.map(distributionLabel => fBigStringRange(distributionLabel)),
            series: [
              {
                name: 'Monthly listings',
                type: 'column',
                fill: 'solid',
                data: distributionData,
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  </>;
};
