import { Button, Card, CardHeader, Grid, Link, ListItemText, Stack, TableCell, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { alpha, useTheme } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CardBarFiltersChart,
  ChartWithFiltersType,
  DrawerCustom,
  ListItemCustom,
  MoreOptions,
  OptionType,
  SeriesWithFiltersType,
  StyledTableRow,
  TableCustom,
} from 'src/components';
import { CardRadialFilterBarChart } from 'src/components/card-radial-bar-chart/card-radial-filter-bar-chart';
import { paths } from 'src/config';
import { chipVariants, monthsShortLabels, projectsOfferingColor } from 'src/constants/constants';
import { marketplaces_table_head } from 'src/features/project-offering-details/mock-data';
import { useBoolean, usePopover, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { fDate, fNumberWithDefaultCurrency, fPercent } from 'src/utils';

import { MarketplaceOffer } from '../../../../../types';
import { FundingTable } from './funding-table';

export interface OfferingProps {
  noTableData: boolean;
  tableData?: MarketplaceOffer[];
}

export const Offering = (props: OfferingProps) => {
  const scenario = useSelector((state: RootState) => state.Storage.detailsData.project?.scenario);

  const {
    noTableData,
    tableData = [],
  } = props;
  const detailsDrawer = useBoolean();
  const popover = usePopover();
  const theme = useTheme();
  const router = useRouter();
  const [currentItem, setCurrentItem] = useState<MarketplaceOffer>();
  const detailsOnClickHandler = (itemId: string) => () => {
    const currentItem = tableData.find(item => item._id === itemId);
    if (currentItem) {
      setCurrentItem(currentItem);
      detailsDrawer.onTrue();
    }
  };

  const unlistClickHandler = useCallback(() => {
    popover.onClose();
    detailsDrawer.onFalse();
  }, [popover, detailsDrawer]);

  const unlistOptions: OptionType[] = useMemo(() => {
    return [
      {
        label: `Unlist Project From ${currentItem?.name} marketplace`,
        icon: 'delete',
        color: 'error.main',
        itemOnClickHandler: unlistClickHandler,
      },
    ];
  }, [currentItem?.name, unlistClickHandler]);

  const additionalSeriesValues = useMemo(() => {
    const marketplaces = tableData.map(item => item.name);
    marketplaces.unshift('All Marketplaces');
    return marketplaces;
  }, [tableData]);

  // TODO delete mock data
  const generatedMockDataForChart: ChartWithFiltersType = useMemo(() => {
    const series: SeriesWithFiltersType[] = [
      {
        seriesFilter: 'Week',
        additionalFilter: 'All Marketplaces',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index),
          },
          {
            name: 'Capital Secured',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 15 * index),
          },
        ],
      },
      {
        seriesFilter: 'Month',
        additionalFilter: 'All Marketplaces',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index * 4),
          },
          {
            name: 'Capital Secured',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 15 * index * 4),
          },
        ],
      },
      {
        seriesFilter: 'Year',
        additionalFilter: 'All Marketplaces',
        data: [
          {
            name: 'Soft Committed',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 200 * index * 48),
          },
          {
            name: 'Capital Secured',
            data: Array.from({ length: 12 }).map((_, index) => (index + 1) * 15 * index * 48),
          },
        ],
      },
    ];
    tableData.forEach((tableItem) => {
      const softCommitted = Array.from({ length: 12 }).map((_, index) => (index + 1) * 100 * index);
      const capitalSecured = Array.from({ length: 12 }).map((_, index) => (index + 2) * 50 * index);
      series.push({
        seriesFilter: 'Week',
        additionalFilter: tableItem.name,
        data: [
          {
            name: 'Soft Committed',
            data: softCommitted,
          },
          {
            name: 'Capital Secured',
            data: capitalSecured,
          },
        ],
      }, {
        seriesFilter: 'Month',
        additionalFilter: tableItem.name,
        data: [
          {
            name: 'Soft Committed',
            data: softCommitted.map(item => item * 4),
          },
          {
            name: 'Capital Secured',
            data: capitalSecured.map(item => item * 4),
          },
        ],
      }, {
        seriesFilter: 'Year',
        additionalFilter: tableItem.name,
        data: [
          {
            name: 'Soft Committed',
            data: softCommitted.map(item => item * 48),
          },
          {
            name: 'Capital Secured',
            data: capitalSecured.map(item => item * 48),
          },
        ],
      });
    });
    return {
      categories: monthsShortLabels,
      colors: [theme.palette.primary.lighter, theme.palette.primary.main],
      series: series,
    };
  }, [tableData, theme.palette.primary.lighter, theme.palette.primary.main]);

  const generatedMockDataForRadialChart: {
    seriesFilter?: string;
    label: string;
    value: number;
  }[] = useMemo(() => {
    const radialData = [
      {
        seriesFilter: 'All Marketplaces',
        label: 'Soft Committed',
        value: tableData.reduce((acc, cur) => {
          return acc + (cur.softCommitted || 0);
        }, 0),
      },
      {
        seriesFilter: 'All Marketplaces',
        label: 'Capital Secured',
        value: tableData.reduce((acc, cur) => {
          return acc + (cur.secured || 0);
        }, 0),
      },
    ];
    tableData.forEach(tableItem => {
      radialData.push(
        {
          seriesFilter: tableItem.name,
          label: 'Soft Committed',
          value: tableItem.softCommitted || 0,
        },
        {
          seriesFilter: tableItem.name,
          label: 'Capital Secured',
          value: tableItem.secured || 0,
        },
      );
    });
    return radialData;
  }, [tableData]);

  const addMarketplaceClickHandler = () => {
    if (scenario?._id) {
      router.push(paths.readyForInvestors.details(scenario?._id, 'overview'));
    }
  };

  return (
    <Stack gap={5}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <CardBarFiltersChart
            showAdditionalFilter={true}
            title='Funding Activity'
            seriesValues={['Week', 'Month', 'Year']}
            additionalSeriesValues={additionalSeriesValues}
            // TODO remove
            chart={generatedMockDataForChart}
          />
        </Grid>
        <Grid item xs={4}>
          <CardRadialFilterBarChart
            seriesValues={additionalSeriesValues}
            title='Overview'
            chartHeader='Total Required'
            chart={{
              colors: [
                [theme.palette.primary.main, theme.palette.primary.main],
                [theme.palette.primary.lighter, theme.palette.primary.lighter],
              ],
              series: generatedMockDataForRadialChart,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={
              <Stack pb={3} direction='row' justifyContent='space-between'>
                <Typography variant='h6'>Equity Partners</Typography>
                <Button variant='outlined' onClick={addMarketplaceClickHandler}>Share project</Button>
              </Stack>
            }
            />
            <TableCustom
              headLabels={marketplaces_table_head}
              noDataFound={noTableData}
              bodyCells={
                <>
                  {tableData.map((tableItem, index) => {
                    return (
                      <StyledTableRow
                        key={index}
                      >
                        <TableCell>
                          <ListItemCustom
                            showAvatar
                            avatarSx={{ borderRadius: 1.5 }}
                            avatarAlt={tableItem.name}
                            avatarSrc={tableItem.logo}
                            primaryText={tableItem.name}
                            secondaryText={tableItem.marketPlaceType}
                            listItemTextProps={{ primaryTypographyProps: { typography: 'body2' } }}
                            secondaryTextTypographySx={{
                              component: 'span',
                              typography: 'caption',
                              color: 'text.disabled',
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <ListItemText
                            primary={tableItem.views}
                            secondary={fDate(tableItem.published)}
                            primaryTypographyProps={{
                              typography: 'body2',
                              ml: 'auto',
                            }}
                            secondaryTypographyProps={{
                              component: 'span',
                              typography: 'caption',
                              color: 'text.disabled',
                              ml: 'auto',
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <ListItemText
                            primary={fNumberWithDefaultCurrency(tableItem.secured)}
                            secondary={fNumberWithDefaultCurrency(tableItem.softCommitted)}
                            primaryTypographyProps={{
                              typography: 'body2',
                              ml: 'auto',
                            }}
                            secondaryTypographyProps={{
                              component: 'span',
                              typography: 'caption',
                              color: 'text.disabled',
                              ml: 'auto',
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <ListItemText
                            primary={fNumberWithDefaultCurrency(tableItem.fee)}
                            secondary={fPercent((tableItem.fee || 0) / 100_000)}
                            primaryTypographyProps={{
                              typography: 'body2',
                              ml: 'auto',
                            }}
                            secondaryTypographyProps={{
                              component: 'span',
                              typography: 'caption',
                              color: 'text.disabled',
                              ml: 'auto',
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            size='small'
                            variant='soft'
                            color='default'
                            label={tableItem.investmentType}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            size='small'
                            disabled={tableItem.offerStatus === 'Unlisted'}
                            variant={chipVariants[tableItem.offerStatus]}
                            color={projectsOfferingColor[tableItem.offerStatus]}
                            label={tableItem.offerStatus}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            size='small'
                            variant='outlined'
                            onClick={detailsOnClickHandler(tableItem._id)}
                          >
                            Details
                          </Button>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              }
            />
          </Card>
        </Grid>
      </Grid>
      <DrawerCustom
        containerSx={{ width: 587 }}
        open={detailsDrawer.value}
        onCloseDrawerHandler={detailsDrawer.onFalse}
        headChildren={
          <>
            <ListItemCustom
              showAvatar
              avatarSx={{ borderRadius: 1.5 }}
              avatarAlt={currentItem?.name}
              avatarSrc={currentItem?.logo}
              primaryText={currentItem?.name}
              secondaryChildren={
                <>
                  <Link
                    color='#3698AF'
                    underline='none'
                    target='_blank'
                    href={currentItem?.logo}
                  >
                    Profile
                  </Link>
                </>
              }
              listItemTextProps={{ primaryTypographyProps: { typography: 'body2' } }}
            />
            <Chip
              size='small'
              disabled={currentItem?.offerStatus === 'Unlisted'}
              variant={chipVariants[currentItem?.offerStatus || 'Ongoing Offerings']}
              color={projectsOfferingColor[currentItem?.offerStatus || 'Ongoing Offerings']}
              label={currentItem?.offerStatus}
            />
            <MoreOptions
              options={unlistOptions}
              popoverOnClose={popover.onClose}
              popoverOnOpen={popover.onOpen}
              popoverOpen={popover.open}
            />
          </>
        }
        headChildrenSx={{
          p: '16px 8px 16px 20px',
          borderBottom: (theme) => `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
        }}
        bodyChildren={
          <Card>
            <CardHeader
              title='Funding Details'
              sx={{ p: 3 }}
              action={
                // TODO add handler
                <Button
                  variant='outlined'
                >
                  Open Listing
                </Button>
              }
            />
            <FundingTable tableItems={currentItem?.investors}/>
          </Card>
        }
      />
    </Stack>
  );
};
