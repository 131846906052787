import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';

import { MarketplaceStat } from '../../../../../../types';

type DashboardStatsProps = {
  stats: MarketplaceStat[];
}

export const DashboardStats = (props: DashboardStatsProps) => {
  const { stats } = props;

  return (
    <Stack direction='row' gap={3}>
      {stats.map((stat) =>
        <Card key={stat.label} sx={{ flex: 1 }}>
          <CardContent>
            <Typography variant='subtitle2'>{stat.label}</Typography>
            {/*<Stack direction='row' alignItems='center' gap={1} sx={{ mt: 2, mb: 1 }}>*/}
            {/*  <IncreaseArrow />*/}
            {/*  <Typography variant='subtitle2'>+{stat.increase}</Typography>*/}
            {/*</Stack>*/}
            <Typography variant='h3'>{stat.amount}</Typography>
          </CardContent>
        </Card>,
      )}
    </Stack>
  );
};
