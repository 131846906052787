import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import { alpha, Box, IconButton, ListItemIcon, ListItemText, Stack, Typography, TypographyProps } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { AnimatePresence, m } from 'framer-motion';
import _ from 'lodash';
import { MouseEvent, ReactElement, ReactNode, useCallback } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { varFade } from 'src/animate';
import { Iconify } from 'src/components';
import { secondaryDefaultTextColor } from 'src/constants/colors';
import { acceptedFilesType } from 'src/constants/constants';
import { convertBytesToKB, createBlobFile, openInNewTab } from 'src/utils';

import { FileForUpload, SectionValues } from '../../../../types';
import { getFileConfig } from './mock-data';

export type UploadedFilesType = FileForUpload & {
  icon?: ReactElement;
  filesize?: number;
}

export interface UploadFileProps extends DropzoneOptions {
  uploadedFiles?: UploadedFilesType[];
  filesToDelete?: UploadedFilesType[];
  section?: SectionValues;
  customIcon?: ReactElement;
  title?: string;
  uploadTitle?: string;
  titleStyleConfig?: TypographyProps;
  subTitleStyleConfig?: TypographyProps;
  uploadSubTitle?: string | ReactNode;
  containerSx?: SxProps<Theme>;
  innerContainerSx?: SxProps<Theme>;
  fileIconSx?: SxProps<Theme>;
  uploadFileHandler: (files: UploadedFilesType[]) => void;
  deleteFilesHandler?: (files: UploadedFilesType[]) => void;
}

export const UploadFile = (props: UploadFileProps) => {
  const {
    uploadedFiles = [],
    filesToDelete = [],
    section = 'Lead',
    title,
    uploadSubTitle,
    titleStyleConfig,
    subTitleStyleConfig,
    customIcon,
    uploadTitle = 'Upload file',
    containerSx,
    innerContainerSx,
    fileIconSx,
    uploadFileHandler,
    deleteFilesHandler,
    disabled,
    multiple = false,
    ...other
  } = props;

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    const updatedFiles: UploadedFilesType[] = await Promise.all(
      Array.from(acceptedFiles).map(async (file) => {
        return {
          uploadedAt: new Date().toISOString(),
          filename: file.name,
          size: file.size,
          uploadingFileType: file.type,
          section: section,
          type: getFileConfig(file.type).type,
          file: await createBlobFile(file),
          _id: Math.random().toString(),
        };
      }),
    );
    uploadFileHandler([...uploadedFiles, ...updatedFiles]);
  }, [section, uploadFileHandler, uploadedFiles]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    multiple,
    disabled,
    onDrop: handleDrop,
    ...other,
  });

  const hasError = isDragReject;

  const clickOnFileHandler = (file: UploadedFilesType) => () => {
    if (file.file) {
      const fileType = file.uploadingFileType || file.filetype || file.file.type;
      const blob = new Blob([file.file], { type: fileType });
      const fileUrl = URL.createObjectURL(blob);
      openInNewTab(fileUrl);
      return;
    }
    openInNewTab(file.url);
  };

  const deleteOnClickHandler = (id: string) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const fileToDelete = uploadedFiles?.find(file => file._id === id);
    const updatedFiles = _.reject(uploadedFiles, { _id: id });
    if (fileToDelete && !fileToDelete.file) {
      deleteFilesHandler?.([...filesToDelete, fileToDelete]);
    }
    uploadFileHandler(updatedFiles);
  };

  const defaultIcon = () => {
    return (
      <CloudUploadIcon
        style={{
          color: '#919EAB',
          width: '40px',
          height: '40px',
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        position: 'relative', ...containerSx,
      }}
    >
      {title &&
          <Typography variant='body2' fontWeight={600} mb={1.5}>
            {title}
          </Typography>
      }
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        {...getRootProps()}
        sx={{
          p: 5,
          outline: 'none',
          borderRadius: 1,
          cursor: 'pointer',
          overflow: 'hidden',
          position: 'relative',
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
          border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
          transition: (theme) => theme.transitions.create(['opacity', 'padding']),
          '&:hover': { opacity: 0.72 },
          ...(isDragActive && { opacity: 0.72 }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            color: 'error.main',
            borderColor: 'error.main',
            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          }),
          ...innerContainerSx,
        }}
      >
        <input
          {...getInputProps()}
          accept={acceptedFilesType}
        />
        {
          customIcon ?? defaultIcon()
        }
        <Typography variant='body2' color='#919EAB' {...titleStyleConfig}>
          {uploadTitle}
        </Typography>
        <Typography variant='body2' color={secondaryDefaultTextColor} mt={1} {...subTitleStyleConfig}>
          {uploadSubTitle}
        </Typography>
      </Box>
      {uploadedFiles?.length > 0 &&
          <Box sx={{ my: 3 }}>
            <AnimatePresence initial={false}>
              {uploadedFiles?.map((file) => {
                return (
                  <Stack
                    spacing={2}
                    key={file._id}
                    {...varFade().inUp}
                    component={m.div}
                    onClick={clickOnFileHandler(file)}
                    direction='row'
                    alignItems='center'
                    sx={{
                      my: 1,
                      py: 1,
                      px: 1.5,
                      borderRadius: 1,
                      cursor: 'pointer',
                      border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
                    }}
                  >
                    <ListItemIcon sx={fileIconSx}>
                      {getFileConfig(file.filetype || file.uploadingFileType || file.file?.type).icon || <ImageIcon/>}
                    </ListItemIcon>
                    <ListItemText
                      primary={file.filename}
                      secondary={typeof file.size === 'string'
                        ? file.size
                        : `${convertBytesToKB(file.size)} KB`}
                      sx={{
                        '& .MuiTypography-body1': {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                    />
                    <IconButton size='small' onClick={deleteOnClickHandler(file._id)}>
                      <Iconify icon='mingcute:close-line' width={16}/>
                    </IconButton>
                  </Stack>
                );
              })}
            </AnimatePresence>
          </Box>
      }
    </Box>
  );
};
