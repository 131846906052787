import { demoProject } from 'src/constants/demo-mock-data';

import { ProjectPreparation } from '../../../../types';
import { TableHeadType } from '../leads-page';
import { TableFilters } from './types';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    _demoId: 'project.name',
    label: 'Name / Type',
    width: 110,
  },
  {
    _id: '',
    _demoId: 'project.scenario.calculate.output.GDV',
    label: 'GDV / Profit',
    width: 110,
  },
  {
    _id: '',
    _demoId: 'lastActivity.message',
    label: 'Last Activity',
    width: 110,
  },
  {
    _id: '',
    label: 'Status',
    width: 110,
  },
  {
    _id: 'controls',
    width: 10,
  },
];

export const defaultFilters: TableFilters = {
  text: '',
  projectType: [],
  status: 'New',
};

export const allFilters: TableFilters = {
  ...defaultFilters,
  status: 'All',
};

export const _preparationItems: ProjectPreparation[] = [
  {
    _id: '1',
    project: {
      ...demoProject,
      name: 'Igelsta Strandentré',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 128_700_000,
            'Net profit': 37_300_000,
          },
          input: { ...demoProject.scenario?.calculate.input },
        },
      },
    },
    status: 'New',

  },
  {
    _id: '2',
    project: {
      ...demoProject,
      name: 'Gökegårds Tun',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 181_150_000,
            'Net profit': 45_279_000,
          },
          input: { ...demoProject.scenario?.calculate.input },
        },
      },
    },
    status: 'New',

  },
  {
    _id: '3',
    project: {
      ...demoProject,
      name: 'Igelsta Loft',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 61_950_000,
            'Net profit': 14_498_000,
          },
          input: { ...demoProject.scenario?.calculate.input },
        },
      },
    },
    status: 'New',
    lastActivity: {
      date: '1 days ago',
      message: 'Victor Klintåker',
    },
  },
  {
    _id: '4',
    project: {
      ...demoProject,
      name: 'Älvsjö Loft',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 432_160_000,
            'Net profit': 23_050_500,
          },
          input: { ...demoProject.scenario?.calculate.input },
        },
      },
    },
    status: 'New',
    lastActivity: {
      date: '10 days ago',
      message: 'Victor Klintåker',
    },
  },
  {
    _id: '5',
    project: {
      ...demoProject,
      name: 'Gunhild Ägarlägenheter',
      scenario: {
        ...demoProject.scenario!,
        calculate: {
          ...demoProject.scenario?.calculate,
          output: {
            ...demoProject.scenario?.calculate.output,
            GDV: 334_780_000,
            'Net profit': 51_010_500,
          },
          input: { ...demoProject.scenario?.calculate.input },
        },
      },
    },
    status: 'New',
    lastActivity: {
      date: '12 days ago',
      message: 'Victor Klintåker',
    },
  },
];
