import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { BlueReportIcon, GreenReportIcon, OrangeReportIcon, PurpleReportIcon } from 'src/assets/svg';
import { Report, ReportCard, ReportProps } from 'src/components';
import { blueGradient, greenGradient, orangeGradient } from 'src/constants/colors';

import { Teaser, TeaserProps } from './teaser';

export type ReportTab = 'List' | 'Full Report' | 'Teaser' | 'PM Report' | 'Lendor Report'

export interface ReportsProps {
  reportProps: ReportProps;
  createdDate?: string;
  teaserProps: TeaserProps;
  showData?: boolean;
}

export const Reports = (props: ReportsProps) => {
  const {
    reportProps,
    createdDate,
    teaserProps,
    showData = false,
  } = props;
  const [tab, setTab] = useState<ReportTab>('List');

  return (
    <>
      {tab === 'List' ?
        <Stack direction='row' gap={3}>
          <ReportCard
            text='Full Report'
            btnTitle='Open'
            subText={`Created ${createdDate}`}
            icon={<BlueReportIcon/>}
            textColor='#003768'
            subTextColor='#003768'
            background={blueGradient}
            onBtnClickHandler={() => setTab('Full Report')}
            showData={showData}
          />
          <ReportCard
            text='Teaser'
            btnTitle='Open'
            subText={`Created ${createdDate}`}
            icon={<GreenReportIcon/>}
            textColor='#004B50'
            subTextColor='#004B50'
            background={greenGradient}
            onBtnClickHandler={() => setTab('Teaser')}
            showData={showData}
          />
          <ReportCard
            text='PM Report'
            btnTitle='Open'
            subText={`Created ${createdDate}`}
            icon={<OrangeReportIcon/>}
            textColor='#7A4100'
            subTextColor='#7A4100'
            background={orangeGradient}
            onBtnClickHandler={() => {}}
            showData={showData}
          />
          <ReportCard
            text='Lendor Report'
            btnTitle='Open'
            subText={`Created ${createdDate}`}
            icon={<PurpleReportIcon/>}
            textColor='#27097A'
            subTextColor='#27097A'
            background='linear-gradient(135deg, rgba(198, 132, 255, 0.16) 0%, rgba(142, 51, 255, 0.16) 100%), #FFF;'
            onBtnClickHandler={() => {}}
            showData={showData}
          />
        </Stack>
        :
        <>
          <Button
            variant='outlined'
            onClick={() => setTab('List')}
            startIcon={<ArrowBackIosNewIcon/>}
            sx={{ mb: 2 }}
          >
            All Reports
          </Button>
          {tab === 'Full Report' && <Report {...reportProps}/>}
          {tab === 'Teaser' && <Teaser {...teaserProps}/>}
        </>
      }
    </>
  );
};
