interface OtherProps {
  returnError?: boolean;
}

export const apiFetch = async (url: string, opts: RequestInit & OtherProps = {}) => {
  return fetch(`/api/${url}`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...opts.headers,
    },
    ...opts,
  }).then((res) => {
    if (res.ok) {
      return res.status === 204 ? undefined : res;
    } else {
      console.error(`Got bad response from ${url}: ${res.status} ${res.statusText}`);
      if (opts.returnError) {
        return res;
      }
    }
  }).then((res) => res?.json?.());
};
