import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { rolesAccess } from 'src/config';
import { usePortalFlags } from 'src/hooks';
import { RootState } from 'src/store';

import { SvgColor } from '../svg-color';
import { MenuSection } from './types';

const icon = (name: string) => {
  return <SvgColor
    src={`/assets/menu-icons/${name}-icon.svg`}
    sx={{
      width: 1,
      height: 1,
    }}
  />;
};

const ICONS = {
  dashboard: icon('dashboard'),
  canban: icon('canban'),
  opportunities: icon('search'),
  packaging: icon('packaging'),
  distribution: icon('distribution'),
  marketplaces: icon('cart'),
  performance_control: icon('performance'),
  users: icon('users'),
  partners: icon('partners'),
  templates: icon('templates'),
  settings: icon('settings'),
  integrations: icon('integrations'),
  sell: icon('arrow'),
  projects: icon('ecommerce'),
  investment: icon('letter-doc'),
  commissions: icon('dollar-doc'),
  user: icon('user'),
};

interface MenuItem {
  title: string;
  path: string;
  disabled?: boolean;
  icon: JSX.Element;
  children?: Array<{
    title: string;
    path: string;
    disabled?: boolean;
  }>,
}

export const useMenuData = (): MenuSection[] => {
  const { isPartnerPortal } = usePortalFlags();

  const role = useSelector((state: RootState) => state.Auth.user?.role || 'user');
  const reducedMenu = useSelector((state: RootState) => state.App.config?.reducedMenu);
  const showSideMenu = useSelector((state: RootState) => state.Storage.showSideMenu);

  const filterItems = useCallback((items: MenuItem) => rolesAccess[role]?.allowedPaths?.includes?.(items.path), [role]);

  const partnerPortalList: { items: MenuItem[] }[] = useMemo(
    () => [
      {
        items: [
          {
            title: 'Overview',
            path: '/partner-portal/overview',
            icon: ICONS.dashboard,
          },
          {
            title: 'Projects',
            path: '/partner-portal/projects',
            icon: ICONS.projects,
            children: [
              {
                title: 'New opportunities',
                path: '/partner-portal/projects/opportunities',
              },
              {
                title: 'Ongoing Offerings',
                path: '/partner-portal/projects/offerings',
              },
              {
                title: 'Closed Deals',
                path: '/partner-portal/projects/deals',
              },
            ],
          },
          {
            title: 'Investment Activity',
            path: '/partner-portal/investment-activity',
            icon: ICONS.investment,
          },
          // {
          //   title: 'Commissions',
          //   path: '/partner-portal/commissions',
          //   icon: ICONS.commissions,
          // },
          {
            title: 'Contacts',
            path: '/partner-portal/contacts',
            icon: ICONS.users,
          },
          {
            title: 'Users',
            path: '/partner-portal/users',
            icon: ICONS.user,
          },
        ],
      },
    ],
    [],
  );

  const defaultList = useMemo(
    () => [
      // OVERVIEW
      {
        items: [
          {
            title: 'Dashboard',
            path: '/dashboard',
            icon: ICONS.dashboard,
          },
        ].filter((items) => filterItems(items)),
      },
      // PROJECTS
      {
        subheader: 'Projects',
        items: [
          {
            title: 'Opportunities',
            path: '/opportunities',
            icon: ICONS.opportunities,
            children: [
              {
                title: 'Leads',
                path: '/opportunities/leads',
              },
              {
                title: 'Research',
                path: '/opportunities/research',
              },
            ],
          },
          {
            title: 'Packaging',
            path: '/packaging',
            icon: ICONS.packaging,
            children: [
              {
                title: 'Project Preparation',
                path: '/packaging/project-preparation',
              },
              {
                title: 'Tendering',
                path: '/packaging/tendering',
                disabled: reducedMenu,
              },
            ],
          },
          {
            title: 'Financing',
            path: '/financing',
            icon: ICONS.commissions,
            children: [
              {
                title: 'Debt',
                path: '/financing/debt',
              }, {
                title: 'Equity',
                path: '/financing/equity',
                disabled: reducedMenu,
              },
            ],
          },
          {
            title: 'Sell',
            path: '/sell',
            icon: ICONS.sell,
          },
        ].filter((items) => filterItems(items)),
      },
      // MANAGEMENT
      {
        subheader: 'Management',
        items: [
          {
            title: 'Marketplaces',
            path: '/marketplaces',
            icon: ICONS.marketplaces,
            disabled: reducedMenu,
          },
          {
            title: 'Users',
            path: '/users',
            icon: ICONS.users,
            disabled: reducedMenu,
          },
          {
            title: 'Partners',
            path: '/partners',
            icon: ICONS.partners,
            children: [
              {
                title: 'Construction Suppliers',
                path: '/partners/supplier-partners',
                disabled: reducedMenu,
              },
              {
                title: 'Banks',
                path: '/partners/bank-partners',
              },
              {
                title: 'Financing Partners',
                path: '/partners/equity-partners',
              },
            ],
          },
          {
            title: 'Settings',
            path: '/settings',
            icon: ICONS.settings,
            disabled: reducedMenu,
          },
        ].filter((items) => filterItems(items)),
      },
    ].filter((section) => section.items.length > 0),
    [filterItems, reducedMenu],
  );

  return showSideMenu ? (isPartnerPortal ? partnerPortalList : defaultList) : [];
};
