import { demoPartnersSuppliers } from 'src/constants/demo-mock-data';

import { Suppliers } from '../../../../types';

export const requestTypeVariants = ['General Contractor', 'Factory', 'Construction Company', 'Development Partner',
  'Project Management'];

export const mockQuotationLists: Suppliers = {
  counter: {
    All: 0,
    'General Contractor': 0,
    'Project Management': 0,
    'Construction Company': 0,
    'Development Partner': 0,
    Factory: 0,
  },
  preliminaryQuotations: [
    {
      _id: '0',
      partnerId: demoPartnersSuppliers[0],
      requestType: 'General Contractor',
    },
    {
      _id: '1',
      partnerId: demoPartnersSuppliers[1],
      requestType: 'Development Partner',
    },
    {
      _id: '2',
      partnerId: demoPartnersSuppliers[2],
      requestType: 'Factory',
    },
    {
      _id: '3',
      partnerId: demoPartnersSuppliers[3],
      requestType: 'General Contractor',
    },
    {
      _id: '3',
      partnerId: demoPartnersSuppliers[4],
      requestType: 'General Contractor',
    },
  ],
  quotations: [],
};
