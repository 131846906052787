import _ from 'lodash';

export const removeEmptyValues = <T extends Object, >(obj: T): T => {
  return _.transform(obj, (result, value, key) => {
    if (_.isObject(value)) {
      result[key] = (_.isArray(value) ? value.filter(item => item !== null) : removeEmptyValues(_.omitBy(value, _.isNull))) as T[keyof T];
    } else if (!_.isNull(value)) {
      result[key] = value;
    }
  });
};
