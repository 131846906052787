import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DetailViewTabType, getAllItems, useMenuData } from '../components';
import { rolesAccess } from '../config';
import { RootState } from '../store';
import { usePathname } from './use-pathname';
import { useRouter } from './use-router';

export const useRestrictRouts = () => {
  const navData = useMenuData();
  const pathname = usePathname();
  const router = useRouter();
  const user = useSelector((state: RootState) => state.Auth.user);

  const allItems = getAllItems({ data: navData });
  const itemByPath = allItems.find(item => {
    return item.path.replaceAll('/', '') === pathname.replaceAll('/', '');
  });

  useEffect(() => {
    if (itemByPath?.disabled && user) {
      router.push(rolesAccess[user?.role].defaultPath);
    }
  }, [itemByPath?.disabled, router, user]);
};

export const useRestrictTabs = (tabs: DetailViewTabType[], activeTab: string) => {
  const router = useRouter();
  const user = useSelector((state: RootState) => state.Auth.user);
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const itemByPath = tabs.find(item => {
    return item.value === activeTab;
  });

  useEffect(() => {
    if (itemByPath?.disabled && user && detailsData.lead?._id) {
      router.replace('overview');
    }
  }, [detailsData.lead?._id, itemByPath?.disabled, router, user]);
};
