import { ListItem, ListItemText, TableCell, TableRow, Typography } from '@mui/material';
import { DEFAULT_AREA, DEFAULT_CURRENCY } from 'src/constants/constants';

import { ComparablesType } from '../../../../../../../types';


interface ComparablesTableRowProps {
  row: ComparablesType;
}

export const ComparablesTableRow = (props: ComparablesTableRowProps) => {
  const { row } = props;

  return (
    <TableRow>
      <TableCell>
        <ListItem sx={{ p: 0 }}>
          <ListItemText
            primary={`${row?.bedrooms} rooms`}
            secondary={row?.displayAddress}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </ListItem>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='#212B36' align='right'>
          {row?.totalFloorArea?.sqm ? `${row?.totalFloorArea?.sqm} ${DEFAULT_AREA}` : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='#212B36' align='right'>
          {
            row?.price.amount
              ? `${row?.price.amount} ${DEFAULT_CURRENCY}/${DEFAULT_AREA}`
              : '-'
          }
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='#212B36'>
          {row?.propertySubType}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
