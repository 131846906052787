import { TextFieldProps, TypographyProps } from '@mui/material';
import { CardProps } from '@mui/material/Card';
import { ControllerProps } from 'react-hook-form';
import { CustomSelectProps, DetailsListProps, ListItemCustomProps, RequestFileProps, TableCustomProps } from 'src/components';

import { TableHeadType } from '../leads-page';

export type FieldsTypes =
  Partial<TextFieldProps>
  & Partial<CustomSelectProps<string>>
  & Partial<ControllerProps>
  & Partial<TableCustomProps>
  & Partial<RequestFileProps>
  & Partial<ListItemCustomProps>
  & Partial<TypographyProps>
  & Partial<DetailsListProps<CardProps>>

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: 'type',
    label: 'Type',
  },
  {
    _id: 'totalFunds',
    label: 'Total Funds',
    align: 'right',
  },
  {
    _id: 'rate',
    label: 'Rate',
    align: 'right',
  },
  {
    _id: 'fee',
    label: 'Fee',
    align: 'right',
  },
  {
    _id: 'ltc',
    label: 'LTC',
    align: 'right',
  },
  {
    _id: 'ltgdv',
    label: 'LTGDV',
    align: 'right',
  },
  {
    _id: 'profitShare',
    label: 'Profit share',
    align: 'right',
  },
];

export const partnersModalTypeOptions = [
  'Bank',
  'Financing Partners',
];
