import _ from 'lodash';
import { FieldError, FieldErrors, FieldValues, Path } from 'react-hook-form';

export const phoneRegExp = /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/;
export const emailRegExp = /^[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;
export const numberRegExp = /^[\d,.]*$/;
export const urlRegExp = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const isPhoneNumberValid = (value: string) => {
  return phoneRegExp.test(value) && value.length < 20;
};

export const isEmailValid = (value: string) => {
  return emailRegExp.test(value);
};

export const isNumberInput = (value: string) => {
  return numberRegExp.test(value);
};

export const isUrlValid = (value: string) => {
  return urlRegExp.test(value);
};

export const getNestedFormError = <T extends FieldValues>(errors: FieldErrors<T>, key: Path<T>): FieldError | undefined => {
  const errorValue = _.get(errors, key);
  return errorValue as FieldError;
};
