import { Scenario } from '../../../types';

export const researchClickHelper = (proverestUrl: string = '', leadId: string, scenario?: Partial<Scenario>) => {
  const scenarioId = scenario?.id || scenario?._id;
  if (scenarioId) {
    window.open(`${proverestUrl}/calculator/${scenarioId}`, '_blank');
    return;
  }
  window.open(`${proverestUrl}/calculator/${leadId}/lead`, '_blank');
};
