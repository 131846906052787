import { useMemo } from 'react';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { fNumber, fPercent } from 'src/utils';

import { DebtDetailsType, FinancialStepType, Lead, QuotationType, Scenario } from '../../../types';

export const useGetProjectSummaryChainedItems = (scenario?: Scenario, lead?: Lead) => {
  const scenarioOutput = scenario?.calculate?.output;
  return useMemo(() => {
    return [
      {
        title: 'Type',
        value: scenarioOutput?.['Project type'],
      },
      {
        title: 'Location',
        value: lead?.location?.address,
      },
      {
        title: 'GDV',
        value: `${fNumber(scenarioOutput?.GDV)} ${DEFAULT_CURRENCY}`,
      },
      {
        title: 'Net Margin',
        value: fPercent(scenarioOutput?.['Net margin']),
      },
      {
        title: 'Profit on cost',
        value: fPercent(scenarioOutput?.['Profit on cost']),
      },
      {
        title: 'Net Profit',
        value: fNumber(scenarioOutput?.['Net profit']),
      },
      {
        title: 'Time Frame',
        value: `${scenarioOutput?.['Build time']} months`,
      },
    ];
  }, [lead?.location?.address, scenarioOutput]);
};

export const useGetQuotationChainedItems = (quotation?: QuotationType, scenario?: Scenario) => {
  const buildTime = scenario?.calculate?.input.build_time_months;
  const costs = scenario?.calculate?.input.total_construction_cost;
  return useMemo(() => {
    return [
      {
        title: 'Services',
        value: quotation?.requestType,
      },
      {
        title: 'Construction costs',
        value: `${fNumber(quotation?.preliminaryOffer?.constructionCost || costs)} ${DEFAULT_CURRENCY}`,
      },
      {
        title: 'Build time (months)',
        value: quotation?.preliminaryOffer?.buildTime || buildTime,
      },
      {
        title: 'Partner fee ',
        value: quotation?.preliminaryOffer?.partnerFee || quotation?.fee,
      },
    ];
  }, [buildTime, costs, quotation?.fee, quotation?.preliminaryOffer?.buildTime, quotation?.preliminaryOffer?.constructionCost, quotation?.preliminaryOffer?.partnerFee, quotation?.requestType]);
};

export const useGetDebtChainedItems = (scenario?: Scenario, firstPreliminaryRequest?: FinancialStepType, detailsList?: DebtDetailsType) => {
  const scenarioOutput = scenario?.calculate?.output;
  return useMemo(() => {
    return [
      {
        title: 'Total Funds',
        value: `${fNumber(firstPreliminaryRequest?.totalFunds || detailsList?.totalFunds)} ${DEFAULT_CURRENCY}`,
      },
      {
        title: 'Rate',
        value: fPercent(firstPreliminaryRequest?.rate || detailsList?.rate),
      },
      {
        title: 'Fee',
        value: fPercent(firstPreliminaryRequest?.fee || detailsList?.fee),
      },
      {
        title: 'LTC',
        value: fPercent(firstPreliminaryRequest?.ltc || detailsList?.ltc),
      },
      {
        title: 'LTGDV',
        value: fPercent(firstPreliminaryRequest?.ltgdv || detailsList?.ltgdv),
      },
      {
        title: 'Time Frame',
        value: `${scenarioOutput?.['Build time']} months`,
      },
    ];
  }, [detailsList?.fee, detailsList?.ltc, detailsList?.ltgdv, detailsList?.rate, detailsList?.totalFunds, firstPreliminaryRequest?.fee, firstPreliminaryRequest?.ltc, firstPreliminaryRequest?.ltgdv, firstPreliminaryRequest?.rate, firstPreliminaryRequest?.totalFunds, scenarioOutput]);
};

export const useGetHoldingItems = () => {
  return useMemo(() => {
    return [
      {
        title: 'Company name',
        value: 'Navian Tech AB',
      },
      {
        title: 'Company number',
        value: '559083-0179',
      },
      {
        title: 'Website',
        value: 'https://navian.io/',
      },
      {
        title: 'Address',
        value: `C/O K10, I Stockholm AB
        Kungsgatan 8
        Stockholm, Sweden, 111 43`,
      },
    ];
  }, []);
};

export const useGetBorrowerItems = () => {
  return useMemo(() => {
    return [
      {
        title: 'Company name',
        value: 'N/A',
      },
      {
        title: 'Company number',
        value: 'N/A',
      },
      {
        title: 'Legal structure',
        value: 'N/A',
      },
    ];
  }, []);
};
export const useGetBusinessItems = () => {
  return useMemo(() => {
    return [
      {
        title: 'Beneficial owners',
        value: 'N/A',
      },
      {
        title: 'Business description',
        value: 'Real Estate Developer',
      },
    ];
  }, []);
};
