import { Drawer, Stack } from '@mui/material';
import { StackProps } from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

export interface DrawerCustomProps {
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  open?: boolean;
  onCloseDrawerHandler?: VoidFunction;
  headChildren?: ReactNode;
  bodyChildren?: ReactNode;
  footerChildren?: ReactNode;
  drawerSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  headChildrenSx?: SxProps<Theme>;
  bodyChildrenSx?: SxProps<Theme>;
  footerChildrenSx?: SxProps<Theme>;
  stackContainerConfig?: StackProps;
}

export const DrawerCustom = (props: DrawerCustomProps) => {
  const {
    anchor = 'right',
    open,
    onCloseDrawerHandler,
    headChildren,
    bodyChildren,
    footerChildren,
    drawerSx,
    containerSx,
    footerChildrenSx,
    headChildrenSx,
    bodyChildrenSx,
    stackContainerConfig,
  } = props;

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onCloseDrawerHandler}
      sx={{
        '& .MuiDrawer-paper': { backgroundColor: (theme) => theme.palette.background.paper },
        ...drawerSx,
      }}
    >
      <Stack
        direction='column'
        sx={{
          minWidth: 432,
          height: '100%',
          ...containerSx,
        }}
        {...stackContainerConfig}
      >
        <Stack
          p={3}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ ...headChildrenSx }}
        >
          {headChildren}
        </Stack>
        <Stack
          p={3}
          direction='column'
          sx={{ ...bodyChildrenSx }}
        >
          {bodyChildren}
        </Stack>
        {footerChildren &&
            <Stack
              p={3}
              width='100%'
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                mt: 'auto',
                ...footerChildrenSx,
              }}
            >
              {footerChildren}
            </Stack>
        }
      </Stack>
    </Drawer>
  );
};
