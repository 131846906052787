import { Chip, ListItemText, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import { memo, MouseEvent } from 'react';
import { ListItemCustom, StyledTableRow } from 'src/components';
import { DEFAULT_CURRENCY, projectsOfferingColor } from 'src/constants/constants';
import { fDate, fNumber, fPercent, isCLickOnRow } from 'src/utils';

import { ProjectsOffering } from '../../../../../types';

interface LeadsTableRowProps {
  row: ProjectsOffering;
  onViewRow: VoidFunction;
  onDeleteRow: VoidFunction;
  detailsOnClickHandler: VoidFunction;
  showPublishedDate?: boolean;
  showDetailsBtn?: boolean;
  showPartnerFee?: boolean;
}

export const ProjectsOfferingTableRow = memo((props: LeadsTableRowProps) => {
  const {
    row,
    onViewRow,
    detailsOnClickHandler,
    showPublishedDate,
    showDetailsBtn,
    showPartnerFee,
  } = props;

  const {
    projectName,
    partnerFeesRoi,
    partnerFees,
    publishedAt,
    imgSrc,
    netSecuredAmount,
    netSoftCommittedAmount,
    projectType,
    required,
    status,
  } = row;

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onViewRow();
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell>
          <ListItemCustom
            showAvatar
            avatarSx={{ borderRadius: 1.5 }}
            listItemSx={{ width: 'fit-content' }}
            listItemTextProps={{
              primaryTypographyProps: {
                typography: 'body2',
                color: 'text.primary',
              },
            }}
            secondaryTextTypographySx={{
              component: 'span',
              typography: 'caption',
              color: 'text.secondary',
            }}
            avatarSrc={imgSrc}
            avatarAlt={projectName}
            primaryText={projectName}
            secondaryText={showPublishedDate
              ? fDate(publishedAt)
              : projectType
            }
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
          <Typography sx={{ ml: 'auto' }} color='text.primary' variant='body2'>
            {fNumber(netSoftCommittedAmount)} {DEFAULT_CURRENCY}
          </Typography>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
          <ListItemText
            primary={`${fNumber(netSecuredAmount)} ${DEFAULT_CURRENCY}`}
            secondary={`${fNumber(required)} ${DEFAULT_CURRENCY}`}
            primaryTypographyProps={{
              typography: 'body2',
              ml: 'auto',
              color: 'text.primary',
            }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
              typography: 'caption',
              ml: 'auto',
            }}
          />
        </TableCell>

        {showPartnerFee &&
          <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
            <ListItemText
              primary={`${fNumber(partnerFeesRoi)} ${DEFAULT_CURRENCY}`}
              secondary={fPercent(partnerFees)}
              primaryTypographyProps={{
                typography: 'body2',
                ml: 'auto',
                color: 'text.primary',
              }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
                typography: 'caption',
                ml: 'auto',
              }}
            />
          </TableCell>
        }

        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            color={projectsOfferingColor[status] ?? 'default'}
            label={status}
          />
        </TableCell>
        <TableCell
          align='right'
          size='small'
          padding='none'
          sx={{
            px: 1,
            whiteSpace: 'nowrap',
          }}
        >
          {showDetailsBtn &&
            <Button
              variant='outlined'
              size='small'
              onClick={detailsOnClickHandler}
            >
              Details
            </Button>
          }
        </TableCell>
      </StyledTableRow>
    </>
  );
});
