import { TableHeadType } from '../leads-page';
import { CheckboxesPartnerType, TableFilters } from './types';

export const shortDebtTypeName = ['Senior', 'Junior', 'Bridge'] as const;

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: 'companyName',
    label: 'Company Name',
    width: 300,
  },
  {
    _id: 'companyType',
    label: 'Company Type',
    width: 170,
  },
  {
    _id: 'companyContactName',
    label: 'Contact Person',
    width: 110,
  },
  {
    _id: 'companyContactEmail',
    label: 'Email / Phone',
    width: 150,
  },
  {
    _id: 'controls',
    width: 50,
  },
];

export const supplierTableHead: TableHeadType[] = [
  {
    _id: 'name',
    label: 'Name / Type',
    width: 200,
  },
  {
    _id: '',
    label: 'System / Floors',
    width: 110,
  },
  {
    _id: '',
    label: 'Price psqm  /  Time',
    width: 110,
  },
  {
    _id: '',
    label: 'NIA / Material',
    width: 110,
  },
  {
    _id: 'controls',
    width: 50,
  },
];

export const bankTableHead: TableHeadType[] = [
  {
    _id: 'name',
    label: 'Name / Type',
    width: 200,
  },
  {
    _id: '',
    label: 'Rates from',
    width: 110,
  },
  {
    _id: '',
    label: 'Min Loan',
    width: 110,
  },
  {
    _id: '',
    label: 'Max Loan',
    width: 110,
  },
  {
    _id: '',
    label: 'Max LTV',
    width: 110,
  },
  {
    _id: '',
    label: 'Max LTGDV ',
    width: 110,
  },
  {
    _id: 'controls',
    width: 50,
  },
];

export const defaultFilters: TableFilters = {
  search: '',
  type: [],
};

export const supplierCheckboxes: CheckboxesPartnerType[] = [{
  groupName: 'companySubType',
  multiple: true,
  required: true,
  checkboxGroup: [
    {
      label: 'General Contractor',
      value: 'General Contractor',
    },
    {
      label: 'Factory',
      value: 'Factory',
    },
    {
      label: 'Construction Company',
      value: 'Construction Company',
    },
    {
      label: 'Development Partner',
      value: 'Development Partner',
    },
    {
      label: 'Project Management',
      value: 'Project Management',
    },
  ],
}];

export const bankCheckboxes: CheckboxesPartnerType[] = [{
  groupName: 'companySubType',
  multiple: true,
  required: true,
  checkboxGroup: [
    {
      label: 'Investment bank',
      value: 'Investment bank',
    },
    {
      label: 'Bank',
      value: 'Bank',
    },
    {
      label: 'Fund',
      value: 'Fund',
    },
    {
      label: 'Crowd',
      value: 'Crowd',
    },
  ],
}];

export const materialVariants = ['Wood', 'Concrete', 'Brick', 'Composite materials'];
export const productTypeVariants = ['Houses', 'Apartments'];
export const payOutTermsVariants = ['Monthly', 'Instalments', 'At start'];
export const repaymentTermsVariants = ['Monthly', 'At end'];

export const productHouseSubTypeVariants = ['Semi-detached', 'Terraced', 'Detached'];
export const productApartmentSubTypeVariants = ['Loftgångshus (Walk-up Apartment)', 'Punkthus (Tower block)', 'Lamellhus (Linear block)'];

export const productBuildSystemVariants = ['Modular', 'Panel elements', 'Traditional Construction\n'];

export const apartmentRoomsVariants = [
  {
    label: '0 (Studio)',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
];

export const housesRoomsVariants = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
];

export const supplierPartnerSubTypeList = ['General Contractor', 'Factory', 'Construction Company', 'Development Partner',
  'Project Management'];
export const bankPartnerSubTypeList = ['Investment bank', 'Bank', 'Crowd', 'Fund'];
export const equityPartnerSubTypeList = ['Investment bank', 'Investment club', 'Crowd', 'Asset Manager', 'Family Office'];

export const equityCheckboxes: CheckboxesPartnerType[] = [
  {
    groupName: 'companySubType',
    multiple: true,
    required: true,
    checkboxGroup: [
      {
        label: 'Investment bank',
        value: 'Investment bank',
      },
      {
        label: 'Investment club',
        value: 'Investment club',
      },
      {
        label: 'Crowd',
        value: 'Crowd',
      },
      {
        label: 'Asset Manager',
        value: 'Asset Manager',
      },
      {
        label: 'Family Office',
        value: 'Family Office',
      },
    ],
  },
  {
    groupName: 'companySub1Type',
    multiple: true,
    required: true,
    checkboxGroup: [
      {
        label: 'Debt',
        value: 'Debt',
      },
      {
        label: 'Equity',
        value: 'Equity',
      },
    ],
  },
];
