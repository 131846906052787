import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { SortableElement, SortableElementProps } from 'react-sortable-hoc';

import { CoverImagesType } from '../../../../types';
import { Iconify } from '../iconify';
import Image from '../image';
import cls from './upload-photos.module.css';
import { boxSx } from './utils';

interface ElementProps extends SortableElementProps {
  isCover: boolean;
  photo: CoverImagesType;
  onActionsClick: (url: CoverImagesType, actionBtn: HTMLButtonElement) => void;
  imageSxProps?: SxProps<Theme>;
  chipSx?: SxProps<Theme>;
}

export const SortableItem = SortableElement<ElementProps>(
  ({
    isCover,
    photo,
    onActionsClick,
    imageSxProps,
    chipSx,
  }: ElementProps) => {
    const theme = useTheme();

    return (
      <Box
        className={cls.list_item}
        sx={imageSxProps}
      >
        <Box
          className={cls.img}
          sx={boxSx}
        >
          <Image
            sx={{
              ...boxSx,
              borderRadius: 1.5,
              boxShadow: isCover ? `0 0 0 3px ${theme.palette.error.main}` : '',
            }}
            src={photo.url}
          />
          {isCover && (
            <Chip
              className={cls.chip}
              label='Cover image'
              variant='filled'
              color='error'
              size='small'
              sx={chipSx}
            />
          )}

          <Box className={cls.overlay}/>
          <IconButton
            onClick={(e) => onActionsClick(photo, e.currentTarget)}
            className={cls.icon}
            size='small'
            sx={{ color: theme.palette.background.default }}
          >
            <Iconify icon='eva:more-vertical-fill'/>
          </IconButton>
        </Box>
      </Box>
    );
  },
);
