import { TableHeadType } from 'src/features/leads-page';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: 'name',
    label: 'Name / Size',
  },
  {
    _id: 'uploaded',
    label: 'Date Uploaded',
    width: 140,
  },
  {
    _id: 'download',
    width: 172,
  },
];
