import Stack from '@mui/material/Stack';
import { OverviewPhotos } from 'src/features/investor-portal/projects/project-details/overview/components/overview-photos';

import { DetailViewData } from '../../../../../../../../types';
import { OverviewInfo, OverviewInfoProps } from './components/overview-info/overview-info';

type OverviewProps = OverviewInfoProps & {
  detailsData?: DetailViewData;
}

export const Overview = (props: OverviewProps) => {
  const {
    detailsData = {},
    ...rest
  } = props;
  const coverImages = detailsData.assets?.marketPlaceListing?.coverImages;

  return (
    <Stack gap={5}>
      <OverviewPhotos photos={coverImages}/>
      <OverviewInfo detailsData={detailsData} {...rest}/>
    </Stack>
  );
};
