import { _preparationItems, ProjectPreparationBodyParams } from 'src/features/project-preparation-page';

import { ProjectPreparationResponse } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading } from '../storage';
import { AppThunk } from '../store';
import { deleteProjectPreparation, setProjectPreparation } from '.';

export const loadProjectPreparation = (paramBody: ProjectPreparationBodyParams): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  const isDemo = getState().App.config?.isDemo;
  if (isDemo) {
    dispatch(setProjectPreparation({
      projects: _preparationItems,
      counter: {
        'New': 0,
        'Done': 0,
        All: 0,
      },
      metadata: {
        total: 0,
        page: 0,
      },
    }));
    dispatch(setIsLoading(false));
    return;
  }
  try {
    const projectPreparationList: ProjectPreparationResponse = await apiFetch('project-preparation/list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch(setProjectPreparation(projectPreparationList));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const deleteProjectPreparationThunk = (id: string): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    dispatch(deleteProjectPreparation(id));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};
