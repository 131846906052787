import { Card, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { fNumber } from 'src/utils';

import { NoteSettingType } from '../../../../../../types';
import { ReportNote } from '../report-note';

export interface SensitivityAnalysisProps {
  noteSetting?: NoteSettingType;
}

export const SensitivityAnalysis = (props: SensitivityAnalysisProps) => {
  const { noteSetting } = props;

  const scenario = useSelector((state: RootState) => state.Storage.detailsData.project?.scenario);
  const firstTableCell = ['75%', '80%', '85%', '90%', '95%', '100%', '105%', '110%', '115%', '120%'];

  return <>
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant='h4' gutterBottom>Sensitivity analysis</Typography>
      </Grid>
      {noteSetting?.isActive &&
          <Grid item md={12}>
            {scenario
              ? <ReportNote note={noteSetting.note} files={noteSetting.files}/>
              : <Skeleton variant='rounded' height='150px' width='100%'/>
            }
          </Grid>
      }
      <Grid item md={12}>
        {scenario ?
          <Card
            sx={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='right'>Const.cost</TableCell>
                    <TableCell align='right'>75% of GDV</TableCell>
                    <TableCell align='right'>80% of GDV</TableCell>
                    <TableCell align='right'>85% of GDV</TableCell>
                    <TableCell align='right'>90% of GDV</TableCell>
                    <TableCell align='right'>95% of GDV</TableCell>
                    <TableCell align='right' sx={{ background: 'rgba(145, 158, 171, 0.20)' }}>100% of GDV</TableCell>
                    <TableCell align='right'>105% of GDV</TableCell>
                    <TableCell align='right'>110% of GDV</TableCell>
                    <TableCell align='right'>115% of GDV</TableCell>
                    <TableCell align='right'>120% of GDV</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{ background: 'linear-gradient(71deg, rgba(239, 168, 168, 0.20) 9.37%, rgba(153, 233, 151, 0.20) 69.76%)' }}>
                  {scenario?.sensitivityAnalysis?.map((row: number[], index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        align='right'
                        component='th'
                        scope='row'
                        sx={{
                          minWidth: '100px',
                          background: '#FFFF',
                        }}
                      >
                        {firstTableCell[index]}
                      </TableCell>
                      {row?.map((item: number, cellIndex) => {
                        return <TableCell
                          key={cellIndex}
                          align='right'
                          component='th'
                          scope='row'
                          sx={{
                            minWidth: '120px',
                            background: cellIndex === 5 ? 'rgba(201, 201, 201, 0.15)' : '',
                          }}
                        >
                          {fNumber(item)}
                        </TableCell>;
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          : <Skeleton variant='rounded' height='606px' width='100%'/>
        }
      </Grid>
    </Grid>
  </>;
};
