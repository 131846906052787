import './map.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Card } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Map, { FullscreenControl, Layer, MapRef, Marker, NavigationControl } from 'react-map-gl';
import { PinCircleIcon, PinIcon, PinSchoolsIcon, PinStationIcon } from 'src/assets/svg';
import { getLandPlot } from 'src/store/storage';

export interface GetLandResponse {
  _id: string;
  gmlId: string;
  inspiried: number;
  label: number;
  nationalCadastralReference: number;
  validFrom: string | Date;
  beginLifeSpanVersion: string | Date;
  centroid: number[];
  extreme0: number;
  extreme1: number;
  extreme2: number;
  extreme3: number;
  area: number;
  geojson: {
    type: string | 'Polygon';
    coordinates: number[][];
  };
}

export interface LinePlotData {
  type: 'Feature';
  properties: unknown;
  geometry: {
    type: 'LineString' | 'Polygon';
    coordinates: number[];
  };
}

export interface MapBoxProps {
  width: string | number;
  height: string | number;
  coordinates: number[] | null;
  mapStyle: 'street' | 'satellite';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markers?: any[];
  zoom?: number;
}

export const MapBox = (props: MapBoxProps) => {
  const {
    zoom,
    width,
    height,
    coordinates,
    mapStyle,
    markers,
  } = props;
  const mapRef = useRef<MapRef>(null);
  const maxZoomLevel = 17;
  const satelliteMapStyle = 'mapbox://styles/mapbox/satellite-v9';
  const streetMapStyle = 'mapbox://styles/mapbox/streets-v9';
  const mapboxToken = 'pk.eyJ1IjoibmF2aWFuZ3JvdXAiLCJhIjoiY2tsdGZ2ZnQwMGNoNjMxbXc3OGthNHBsNCJ9.0xgD8XiAqLvhmPQ38YgZ7g';
  const correctCoordinates = useMemo(() => [-0.074_854_880_805_958_15, 51.508_809_927_249_73], []);

  const [getLandPlotResponse, setGetLandPlotResponse] =
    useState<GetLandResponse>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedLandPlot, setSelectedLandPlot] = useState<any>();

  const getSinglePlot = (lat: number, lng: number) => {
    getLandPlot(lat, lng)
      .then(res => {
        if (res?.data) {
          setGetLandPlotResponse(res?.data);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const mapToPlotData = (landRes: GetLandResponse[]) => {
    const linePlotArray: LinePlotData[] = [];
    for (const item of landRes) {
      const linePlotData: LinePlotData = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: item?.geojson?.coordinates?.[0],
        },
      };
      linePlotArray.push(linePlotData);
    }
    return linePlotArray;
  };

  useEffect(() => {
    if (getLandPlotResponse) {
      const plotData = mapToPlotData([getLandPlotResponse]);
      setSelectedLandPlot(plotData[0]);
    }
  }, [getLandPlotResponse]);

  useEffect(() => {
    getSinglePlot(coordinates?.[0] || correctCoordinates[0], coordinates?.[1] || correctCoordinates[1]);
  }, [coordinates, correctCoordinates]);

  const fitToMapView = useCallback(() => {
    const bounds = new mapboxgl.LngLatBounds();
    if (markers && markers?.length > 0) {
      if (markers) for (const item of markers) {
        if (item?.location?.longitude && item?.location?.latitude)
          bounds.extend([+item?.location?.longitude, +item?.location?.latitude]);
      }
      mapRef?.current?.fitBounds([bounds.getSouthWest(), bounds.getNorthEast()], { padding: 40 });
    }
  }, [markers]);

  const handleMapInit = () => {
    if (markers) {
      fitToMapView();
    }
  };

  return (
    <Card>
      <div className='map'>
        <Map
          ref={mapRef}
          scrollZoom={false}
          onLoad={() => handleMapInit()}
          initialViewState={{
            longitude: coordinates?.[0] || correctCoordinates[0],
            latitude: coordinates?.[1] || correctCoordinates[1],
            zoom: zoom || maxZoomLevel,
            bearing: 0,
            pitch: 0,
            padding: {
              top: 0,
              bottom: 100,
              right: 0,
              left: 40,
            },
          }}
          mapboxAccessToken={mapboxToken}
          style={{
            width,
            height,
          }}
          mapStyle={mapStyle === 'street' ? streetMapStyle : satelliteMapStyle}
        >
          <FullscreenControl position='top-left'/>
          <NavigationControl position='top-left'/>
          {(coordinates && !markers) &&
            <Marker
              longitude={coordinates[0]}
              latitude={coordinates[1]}
            >
              <PinIcon/>
            </Marker>
          }
          {markers &&
            markers.map((item, index) => {
              return <Marker
                key={`marker-${index}`}
                longitude={Number(item?.location?.longitude)}
                latitude={Number(item?.location?.latitude)}
              >
                {item?.info?.objectType === 'Station' && (
                  <PinStationIcon/>
                )}
                {item?.info?.objectType === 'School' && (
                  <PinSchoolsIcon/>
                )}
                {item?.info?.objectType === 'circle' && (
                  <PinCircleIcon stroke={item.groupData?.color} fill={item.groupData?.color}/>
                )}
                {/*{markersType == "circle" && (*/}
                {/*  <CustomLocalMarkerImg*/}
                {/*    stroke="#FFFF"*/}
                {/*    fill={item?.groupData?.color}*/}
                {/*    onMouseEnter={() => {*/}
                {/*      item.info ? setPopupData(item) : setPopupData(null);*/}
                {/*    }}*/}
                {/*    onMouseLeave={() => setPopupData(null)}*/}
                {/*    onClick={() => openMapPopup(item)}*/}
                {/*    className="custom-marker"*/}
                {/*  />*/}
                {/*)}*/}
              </Marker>;
            })}
          {selectedLandPlot &&
            <Layer
              key='layer-selected'
              id='lineLayer-selected'
              type='line'
              source={{
                type: 'geojson',
                data: selectedLandPlot,
              }}
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
              }}
              paint={{
                'line-color': 'red',
                'line-width': 5,
              }}
            />
          }
        </Map>
      </div>
    </Card>
  );
};
