import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { paths } from 'src/config';
import { useBoolean, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { createPartner } from 'src/store/partners';

import { PartnerItemType } from '../../../../../../types';
import { supplierCheckboxes } from '../../mock-data';
import { PartnerFormType } from '../../types';
import { AddPartnerModal } from '../add-partner-modal';
import { PartnersProductList } from '../partners-product-list';
import { SupplierProductsTable } from './supplier-products-table';

const SuppliersProductList = () => {
  const { id } = useParams<{ id: string }>();
  const modalView = useBoolean();
  const dispatch = useDispatch();
  const router = useRouter();

  const currentPartner = useSelector((state: RootState) => state.Partners.currentPartner);

  const onAddProductClickHandler = () => {
    router.push(paths.supplierPartners.createProduct(id));
  };

  const onEditClickHandler = () => {
    modalView.onTrue();
  };

  const saveChangesHandler = (data: PartnerFormType) => {
    const updatedData: PartnerItemType = {
      ...data,
      _id: id,
      companyType: 'Supplier',
    };
    dispatch(createPartner(updatedData, 'suppliers'));
  };

  return (
    <>
      <PartnersProductList
        partner={currentPartner}
        crumbMiddleItem={{
          title: 'Supplier',
          href: paths.supplierPartners.list,
        }}
        productTable={<SupplierProductsTable partnerId={id}/>}
        onEditClickHandler={onEditClickHandler}
        onAddProductClickHandler={onAddProductClickHandler}
      />
      <AddPartnerModal
        formData={currentPartner}
        partnerDrawer={modalView}
        saveChangesHandler={saveChangesHandler}
        checkboxes={supplierCheckboxes}
        headerLabel='Edit Supplier'
        btnTitle='Save Changes'
      />
    </>
  );
};

export default SuppliersProductList;

