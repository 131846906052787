import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { DrawerCustom, Iconify, ListItemCustom } from 'src/components';
import { paths } from 'src/config';
import { UseBooleanReturnType } from 'src/hooks';

import { PartnerItemType } from '../../../../../../../../types';


export type RequestDrawerSubmit = {
  name: string;
  email: string;
  message: string;
}

interface RequestDrawerProps {
  drawer: UseBooleanReturnType;
  onSubmit: (data: RequestDrawerSubmit) => void;
  supplier: PartnerItemType;
  title: string;
  defaultMsg?: string;
  defaultName?: string;
  defaultEmail?: string;
}

export const RequestDrawer = (props: RequestDrawerProps) => {
  const {
    drawer,
    onSubmit,
    supplier,
    title,
    defaultMsg = '',
    defaultName = '',
    defaultEmail = '',
  } = props;

  const [name, setName] = useState<string>(defaultName);
  const [email, setEmail] = useState<string>(defaultEmail);
  const [message, setMessage] = useState<string>(defaultMsg);

  const canSubmit = !!name && !!email && !!message;
  const onSubmitClick = () => {
    onSubmit({
      name,
      email,
      message,
    });
  };

  return (
    <>
      <DrawerCustom
        open={drawer.value}
        onCloseDrawerHandler={drawer.onFalse}
        headChildren={
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Typography variant='h6'>{title}</Typography>
            <IconButton
              onClick={drawer.onFalse}
            >
              <Iconify icon='mingcute:close-line' width={18}/>
            </IconButton>
          </Stack>
        }
        bodyChildren={
          <Stack gap={3}>
            <ListItemCustom
              showAvatar
              key={supplier._id}
              avatarSrc={supplier.logoUrl}
              avatarSx={{ borderRadius: 1.5 }}
              avatarAlt={supplier.companyName}
              primaryText={supplier.companyName}
              secondaryText={supplier.companyType}
              secondaryChildren={
                <>
                  <Link
                    underline='none'
                    target='_blank'
                    href={paths.supplierPartners.products(supplier._id)}
                  >
                    Profile
                  </Link>
                </>
              }
            />
            <TextField
              value={name}
              onChange={e =>
                setName(e.currentTarget.value)
              }
              label='* Name'
              variant='outlined'
              fullWidth
            />
            <TextField
              value={email}
              onChange={e =>
                setEmail(e.currentTarget.value)
              }
              label='* Email'
              variant='outlined'
              fullWidth
            />
            <TextField
              value={message}
              onChange={e =>
                setMessage(e.currentTarget.value)
              }
              label='* Message'
              variant='outlined'
              rows={4}
              multiline
              fullWidth
            />
          </Stack>
        }
        footerChildren={
          <Button
            onClick={onSubmitClick}
            disabled={!canSubmit}
            variant='contained'
            size='large'
            fullWidth
          >
            Send Request
          </Button>
        }
      />
    </>
  );
};
