import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack/Stack';
import { debounce } from 'lodash';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { TableCustom, TableFiltersResult, TablePaginationCustom, TableToolbar } from 'src/components';
import { chipVariants, projectsOfferingColor } from 'src/constants/constants';
import { defaultBody, MARKETPLACE_INVESTORS_TABLE_HEAD } from 'src/features/marketplace-details/mock-data';
import { useTable } from 'src/hooks';
import { getContactsList } from 'src/store/partners-portal';

import { MarketplaceInvestorResponse, PaginationParams } from '../../../../../../types';
import { MarketplaceInvestorsTableRow } from './marketplace-investors-table-row';

export const Investors = () => {
  const table = useTable();
  const [tableData, setTableData] = useState<MarketplaceInvestorResponse>({
    page:1,
    total:0,
    data:[],
  });
  const [correspondingBody, setCorrespondingBody] = useState<PaginationParams>(defaultBody);

  const getData = useCallback(async (params:PaginationParams)=>{
    const response = await getContactsList(params);
    if (response){
      setTableData(response);
    }
  }, []);

  useEffect(() => {
    getData(defaultBody);
  }, [getData]);

  const headLabels = MARKETPLACE_INVESTORS_TABLE_HEAD;

  const noDataFound = tableData.data?.length === 0;

  const updateContacts = useCallback((newBodyPart: Partial<PaginationParams>) => {
    const newBody: PaginationParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    getData(newBody);
    setCorrespondingBody(newBody);
  }, [correspondingBody, getData]);


  const handleFilters = useCallback((name: keyof PaginationParams, value: string | string[] | null) => {
    table.onResetPage();
    updateContacts({
      [name]: value,
      page: 1,
      limit: 5,
    });
  }, [table, updateContacts]);

  const onFiltersDeleteHandler = useCallback((filterKey: keyof PaginationParams) => {
    handleFilters(filterKey, '');
  }, [handleFilters]);

  const canReset = !!correspondingBody.text;

  const handleResetFilters = useCallback(() => {
    updateContacts(defaultBody);
  }, [updateContacts]);

  const handleDeleteRow = useCallback((id: string) => () => {
    setTableData(prev => {
      return {
        ...prev,
        data:prev.data.filter(file => file._id !== id),
      };
    });
  }, []);


  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updateContacts({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateContacts({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onSortChange = debounce((id: string) => {
    const {
      orderBy,
      order,
    } = table;
    const isAsc = orderBy === id && order === 'asc';
    updateContacts({
      sort: id,
      sortType: isAsc ? 'desc' : 'asc',
    });
    table.onSort(id);
  }, 200);

  return (
    <Card>
      <TableToolbar
        searchFieldValue={correspondingBody.text}
        searchFieldKey='text'
        onFilters={handleFilters}
      />
      {canReset && (
        <Stack
          sx={{
            paddingLeft: '20px',
            paddingBottom: '16px',
          }}
        >
          <TableFiltersResult
            filters={{ text:correspondingBody.text }}
            defaultFilters={defaultBody}
            onFiltersDeleteHandler={onFiltersDeleteHandler}
            onResetFilters={handleResetFilters}
            results={tableData.total}
            chipVariants={chipVariants}
            chipColors={projectsOfferingColor}
          />
        </Stack>
      )}
      <TableCustom
        headLabels={headLabels}
        table={table}
        rowCount={tableData.total}
        noDataFound={noDataFound}
        onSort={onSortChange}
        bodyCells={(
          <>
            {tableData.data?.map((row) => (
              <MarketplaceInvestorsTableRow
                key={row._id}
                row={row}
                onDeleteRow={handleDeleteRow(row._id)}
              />
            ))}
          </>
        )}
      />
      <TablePaginationCustom
        count={tableData.total}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Card>
  );
};
