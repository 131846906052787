import _ from 'lodash';
import { paths } from 'src/config';
import { TableProps } from 'src/hooks';
import { sortCustom } from 'src/utils';

import { MarketplaceProjectReport, ReportKeyType } from '../../../../../../../../types';
import { ReportsProps } from './reports';

export type ReportCustomSortConditionProps = Pick<ReportsProps, 'showGeneralReport' | 'showBankReports' | 'showSupplierReports' | 'showGeneralReportModified' | 'showGeneralReportOriginal'>

export const getPathForReport = (leadId: string, report: MarketplaceProjectReport): MarketplaceProjectReport => {
  const {
    reportType,
    reportName,
    _id,
    identifier = '',
    updatedAt,
    createdAt,
  } = report;
  const loweredReportType = reportType.toLowerCase() as ReportKeyType;
  const reportUrl: Record<ReportKeyType, MarketplaceProjectReport> = {
    calculator: report,
    calculatorOriginal: report,
    calculatorModified: report,
    seller: {
      _id,
      reportType: loweredReportType,
      reportName: reportName,
      createdAt: createdAt || '',
      updatedAt: updatedAt,
      svgColor: '#004B50',
      url: paths.reports.saleReport(leadId, identifier),
      visibility: 'Published',
      isOpenInNewTab: true,
    },
    bank: {
      _id,
      reportType: loweredReportType,
      reportName: reportName,
      createdAt: createdAt || '',
      updatedAt: updatedAt,
      svgColor: '#B1DDE7',
      url: paths.reports.bankReport(leadId, identifier),
      visibility: 'Published',
      isOpenInNewTab: true,
    },
    supplier: {
      _id,
      reportType: loweredReportType,
      reportName: reportName,
      createdAt: createdAt || '',
      updatedAt: updatedAt,
      svgColor: '#3698AF',
      url: paths.reports.supplierReport(leadId, identifier),
      visibility: 'Published',
      isOpenInNewTab: true,
    },
  };
  return reportUrl[loweredReportType];
};

export const reportCustomSort = (reports: MarketplaceProjectReport[], table: TableProps, conditions: ReportCustomSortConditionProps) => {
  const order: Record<ReportKeyType, number> = {
    calculator: 1,
    calculatorOriginal: 1,
    calculatorModified: 2,
    supplier: 3,
    bank: 4,
    seller: 5,
  };

  const visibility: Record<ReportKeyType, boolean> = {
    calculator: !!conditions.showGeneralReport,
    calculatorOriginal: !!conditions.showGeneralReportOriginal,
    calculatorModified: !!conditions.showGeneralReportModified,
    supplier: !!conditions.showSupplierReports,
    bank: !!conditions.showBankReports,
    seller: true,
  };
  const groupedData = _.groupBy(reports, 'reportType');
  const finalArray = [];

  for (const reportType of Object.keys(order)) {
    const isShownReportList = visibility[reportType as ReportKeyType];
    const items = isShownReportList ? (groupedData[reportType] || []) : [];
    const sortedItems = sortCustom(items, table);
    finalArray.push(...sortedItems);
  }
  return finalArray;
};
