import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Lead, LeadResponse } from '../../../../types';


interface State {
  leads: LeadResponse;
}

export const initialState: State = {
  leads: {
    leads: [],
    counters: {
      All: 0,
      'Waiting for Review': 0,
      'In Progress': 0,
      'Approved': 0,
      'Rejected': 0,
    },
    netCount: 0,
  },
};

const Slice = createSlice({
  name: 'Lead',
  initialState,
  reducers: {
    setLeads(state, action: PayloadAction<LeadResponse>) {
      state.leads = action.payload;
    },
    addLead(state, action: PayloadAction<Lead>) {
      state.leads.leads.unshift(action.payload);
    },
    deleteLead(state, action: PayloadAction<string>) {
      const leadToDelete = state.leads.leads.find(lead => lead._id === action.payload);
      state.leads.leads = state.leads.leads.filter(lead => lead._id !== leadToDelete?._id);
      state.leads.netCount -= 1;
      state.leads.counters.All -= 1;
      state.leads.counters[leadToDelete?.status as keyof typeof state.leads.counters] -= 1;
    },
  },
})
;

export const {
  reducer: LeadReducer,
  actions: {
    setLeads,
    addLead,
    deleteLead,
  },
} = Slice;

export * from './actions';
