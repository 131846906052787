import { TableHeadType } from '../leads-page';

export const marketplaces_table_head: TableHeadType[] = [
  {
    _id: 'name',
    label: 'Name / Type ',
  },
  {
    _id: 'views',
    label: 'Views / Published ',
    align: 'right',
  },
  {
    _id: 'secured',
    label: 'Secured / Soft Committved',
    align: 'right',
  },
  {
    _id: 'fee',
    label: 'Fee',
    align: 'right',
  },
  {
    _id: 'type',
    label: 'Type',
  },
  {
    _id: 'offerStatus',
    label: 'Status',
  },
  {
    _id: 'controls',
    label: '',
  },
];

export const funding_details_table_head: TableHeadType[] = [
  {
    _id: 'name',
    label: 'Investor',
  },
  {
    _id: 'capitalSecured',
    label: 'Capital Secured ',
    align: 'right',
  },
  {
    _id: 'softCommitted',
    label: 'Soft Commited',
    align: 'right',
  },
];
