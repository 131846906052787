import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import TableCell from '@mui/material/TableCell';
import { memo, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmDialog, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { paths } from 'src/config';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useBoolean, usePopover, useRouter } from 'src/hooks';
import { createDebtRequestThunk } from 'src/store/financial';
import { fNumber, fPercent, isCLickOnRow } from 'src/utils';

import { Finance, LabelColor, StageType } from '../../../../../types';

interface LeadsTableRowProps {
  row: Finance;
  onViewRow: VoidFunction;
  onDeleteRow: VoidFunction;
  stageColor: Record<StageType, LabelColor>;
}

export const FinancingTableRow = memo((props: LeadsTableRowProps) => {
  const {
    row,
    onViewRow,
    onDeleteRow,
    stageColor,
  } = props;
  const {
    _id,
    projectId = '',
    debt,
    type,
    totalFunds,
    structure,
    name,
    equity,
    stage,
    lastActivity,
    lastActivityDate,
  } = row;

  const confirm = useBoolean();
  const router = useRouter();
  const popover = usePopover();
  const dispatch = useDispatch();

  const financingMoreOption: OptionType[] = [
    {
      label: 'View',
      icon: 'view',
      closePopover: popover.onClose,
      itemOnClickHandler: onViewRow,
    },
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onViewRow();
  };

  const reviewOnClickHandlerWrapper = () => {
    stage.includes('In Progress')
      ? router.push(paths.financing.details(_id, 'financial'))
      : dispatch(createDebtRequestThunk(projectId, router));
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell>
          <ListItemText
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
            primary={name}
            secondary={type}
          />
        </TableCell>

        <TableCell align='right'>
          {fNumber(totalFunds)} {DEFAULT_CURRENCY}
        </TableCell>
        <TableCell align='right'>
          <ListItemText
            primaryTypographyProps={{
              typography: 'body2',
              ml: 'auto',
            }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
              ml: 'auto',
            }}
            primary={fPercent(debt)}
            secondary={fPercent(equity)}
          />
        </TableCell>
        <TableCell>
          {Array.from(new Set(structure)).join(', ')}
        </TableCell>

        <TableCell>
          <ListItemText
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
            primary={lastActivity}
            secondary={lastActivityDate}
          />
        </TableCell>
        <TableCell>
          <Chip
            size='small'
            variant='outlined'
            color={stageColor[stage] ?? 'default'}
            label={stage}
          />
        </TableCell>
        <TableCell
          size='small'
          align='right'
          padding='none'
          sx={{
            px: 1,
            whiteSpace: 'nowrap',
          }}
        >
          {!stage.includes('Signed') && stage !== 'Done' && (
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              variant='outlined'
              size='small'
              onClick={reviewOnClickHandlerWrapper}
            >
              {stage.includes('In Progress') && 'Review'}
              {stage === 'New' && 'Debt Request'}
            </Button>
          )}
          <MoreOptions
            options={financingMoreOption}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
      </StyledTableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
});
