import { alpha, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Iconify } from 'src/components';

export interface InfoBlockProps {
  infoText?: string;
}

export const InfoBlock = (props: InfoBlockProps) => {
  const { infoText = 'You will find more information in the desktop version.' } = props;
  const theme = useTheme();
  return (
    <Stack
      p={2}
      direction='row'
      alignItems='flex-start'
      gap='14px'
      borderRadius={2}
      sx={{ backgroundColor: (theme) => alpha(theme.palette.info.main, 0.08) }}
    >
      <Iconify sx={{ minWidth: 20 }} color={theme.palette.primary.main} icon='eva:alert-circle-fill'/>
      <Typography variant='body1'>
        {infoText}
      </Typography>
    </Stack>
  );
};
