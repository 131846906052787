import ImageIcon from '@mui/icons-material/Image';
import { alpha, Card, IconButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { MouseEvent } from 'react';
import { getFileConfig, Iconify, Markdown } from 'src/components';
import { fNumber, openInNewTab } from 'src/utils';

import { FileForUpload } from '../../../../../../types';

export interface ReportNoteProps {
  note?: string;
  files?: FileForUpload[];
}

export const ReportNote = (props: ReportNoteProps) => {
  const {
    note = '',
    files = [],
  } = props;

  const onFileClickHandler = (file: FileForUpload) => () => {
    openInNewTab(file.url);
  };

  return (
    <>
      {note && (
        <Card
          sx={{
            p: 3,
            gap: 3,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Markdown children={note}/>
          <Stack gap={1}>
            {files?.map((file, index) => {
              return (
                <FileItem
                  key={index}
                  file={file}
                  onFileClickHandler={onFileClickHandler}
                />
              );
            })}
          </Stack>
        </Card>
      )}
    </>
  );
};

export interface FileItemProps {
  file: FileForUpload;
  onFileClickHandler?: (file: FileForUpload) => (event: MouseEvent<HTMLDivElement>) => void;
  icon?: string;
  onIconClickHandler?: (file: FileForUpload) => (event: MouseEvent<HTMLButtonElement>) => void;
}

export const FileItem = (props: FileItemProps) => {
  const {
    file,
    onFileClickHandler,
    onIconClickHandler,
    icon = 'eva:cloud-download-fill',
  } = props;
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        my: 1,
        py: 1,
        px: 1.5,
        borderRadius: 1,
        cursor: 'pointer',
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
      }}
      onClick={onFileClickHandler?.(file)}
    >
      <ListItemIcon>
        {getFileConfig(file.filetype).icon || <ImageIcon/>}
      </ListItemIcon>
      <ListItemText
        primary={file.filename}
        secondary={`${fNumber(file.size)} KB`}
        sx={{
          '& .MuiTypography-body1': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      />
      <IconButton size='small' onClick={onIconClickHandler?.(file)}>
        <Iconify icon={icon} width={18}/>
      </IconButton>
    </Stack>
  );
};
