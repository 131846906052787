import Stack from '@mui/material/Stack';
import { ImageStack } from 'src/components';

import { CoverImagesType } from '../../../../../../../../types';

export type OverviewPhotosProps = {
  photos?: CoverImagesType[];
  sliceImages?: number;
};

export const OverviewPhotos = (props: OverviewPhotosProps) => {
  const {
    photos = [],
    sliceImages = 4,
  } = props;

  if (!photos.length) {
    return null;
  }

  const mainPhoto = photos.find(photo => photo.isCoverImage);
  const updatedPhotos = mainPhoto ? [mainPhoto, ...photos.filter(photo => !photo.isCoverImage)] : photos;
  return (
    <Stack>
      <ImageStack
        showData
        images={updatedPhotos}
        sliceImages={sliceImages}
      />
    </Stack>
  );
};
