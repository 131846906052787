import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import TableCell from '@mui/material/TableCell';
import { memo, MouseEvent } from 'react';
import { ConfirmDialog, ListItemCustom, MoreOptions, OptionType, StyledTableRow } from 'src/components';
import { useBoolean, usePopover } from 'src/hooks';
import { isCLickOnRow } from 'src/utils';

import { PartnerItemType } from '../../../../../types';

interface PartnersTableRowProps {
  row: PartnerItemType;
  onRowClickHandler: VoidFunction;
  onProfileClickHandler: VoidFunction;
  onDeleteClickHandler: VoidFunction;
  onEditClickHandler: VoidFunction;
}


export const PartnersTableRow = memo((props: PartnersTableRowProps) => {
  const {
    row,
    onProfileClickHandler,
    onDeleteClickHandler,
    onRowClickHandler,
    onEditClickHandler,
  } = props;
  const {
    companyName,
    companyContactName,
    companyContactPhone,
    companyContactEmail,
    companySubType,
    designation,
    logoUrl,
    productAmount,
  } = row;

  const confirm = useBoolean();
  const popover = usePopover();

  const moreOption: OptionType[] = [
    {
      label: 'Edit',
      icon: 'edit',
      closePopover: popover.onClose,
      itemOnClickHandler: onEditClickHandler,
    },
    {
      label: 'Delete',
      icon: 'delete',
      closePopover: popover.onClose,
      itemOnClickHandler: confirm.onTrue,
      color: 'error.main',
    },
  ];

  const handleRowOnClick = (event: MouseEvent<HTMLTableRowElement>) => {
    isCLickOnRow(event) && onRowClickHandler();
  };

  const onDeleteClickHandlerWrapper = () => {
    confirm.onFalse();
    onDeleteClickHandler();
  };

  return (
    <>
      <StyledTableRow hover onClick={handleRowOnClick}>
        <TableCell>
          <ListItemCustom
            showAvatar
            listItemSx={{ alignItems: 'center' }}
            avatarSx={{
              borderRadius: 1.5,
              backgroundColor: '#eeeff1',
            }}
            listItemTextProps={{
              primaryTypographyProps: {
                whiteSpace: 'nowrap',
                variant: 'body2',
              },
            }}
            avatarSrc={logoUrl}
            customAvatarIcon={<PhotoSizeSelectActualIcon sx={{ fill: '#c0c7d1' }}/>}
            primaryText={companyName}
          />
        </TableCell>

        <TableCell>
          <Stack direction='row' flexWrap='wrap' gap={1} rowGap={1}>
            {companySubType?.map((subType => {
              return (
                <Chip
                  size='small'
                  variant='soft'
                  label={subType}
                />
              );
            }))}
          </Stack>
        </TableCell>
        {
          row.companyType === 'Supplier' &&
            <TableCell>
              {productAmount}
            </TableCell>
        }

        <TableCell>
          <ListItemText
            primary={companyContactName}
            secondary={designation}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </TableCell>
        <TableCell>
          <ListItemText
            primary={companyContactEmail}
            secondary={companyContactPhone}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              typography: 'caption',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell
          align='right'
          size='small'
          padding='none'
          sx={{
            px: 1,
            whiteSpace: 'nowrap',
          }}
        >
          <Button variant='outlined' size='small' onClick={onProfileClickHandler}>
            Profile
          </Button>
          <MoreOptions
            options={moreOption}
            popoverOnClose={popover.onClose}
            popoverOnOpen={popover.onOpen}
            popoverOpen={popover.open}
          />
        </TableCell>
      </StyledTableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Are you sure want to delete?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteClickHandlerWrapper}>
            Delete
          </Button>
        }
      />
    </>
  );
});
