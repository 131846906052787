import { TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FormComponent, NoLayout } from 'src/components';
import { ConfigType, LayoutTypes } from 'src/features/new-lead';
import { addTermSheetThunk } from 'src/store/financial';
import { isEmailValid } from 'src/utils';

import { FinancialTableType, TernSheetRequestType } from '../../../../../types';

export interface SheetRequestDrawerProps {
  item?: FinancialTableType;
  closeDrawerHandler: VoidFunction;
}

export const SheetRequestDrawer = (props: SheetRequestDrawerProps) => {
  const {
    item,
    closeDrawerHandler,
  } = props;
  const {
    control,
    handleSubmit,
  } = useForm<TernSheetRequestType>({
    defaultValues: {
      message: '',
      contactEmail: item?.bankContactEmail,
      contactPerson: item?.bankContactName,
    },
  });

  const dispatch = useDispatch();
  const sheetRequestConfig: ConfigType<TernSheetRequestType, TextFieldProps, LayoutTypes>[] = useMemo(() => {
    return [
      {
        rightBlock: [
          {
            required: true,
            fullWidth: true,
            name: 'contactPerson',
            label: 'Name',
            correspondingComponent: TextField,
          },
          {
            required: true,
            fullWidth: true,
            name: 'contactEmail',
            label: 'Email',
            rules: {
              validate: value => {
                if (!value) return;
                return isEmailValid(value) || 'Invalid email format';
              },
            },
            correspondingComponent: TextField,
          },
          {
            required: true,
            fullWidth: true,
            multiline: true,
            rows: 4,
            name: 'message',
            label: 'Message',
            correspondingComponent: TextField,
          },
        ],
        layout: NoLayout,
        stackWidth: 432,
        stackConfig: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          rowGap: 3,
          pl: 0,
        },
      },
    ];
  }, []);

  const onSubmit = (data: TernSheetRequestType) => {
    if (item) {
      dispatch(addTermSheetThunk({
        termSheetDetails: data,
        _id: item._id,
        partnerId: item.bankId || '',
        debtRequestId: item.debtRequestId,
      }, item.bankId));
      closeDrawerHandler();
    }
  };

  return (
    <>
      <FormComponent
        onSubmitHandler={handleSubmit(onSubmit)}
        formConfig={sheetRequestConfig}
        btnTitle='Request Term Sheet'
        control={control}
        formStackProps={{
          p: 0,
          height: '100%',
          justifyContent: 'space-between',
        }}
        configStackWrapperProps={{ p: 0 }}
        btnConfig={{
          fullWidth: true,
          variant: 'contained',
        }}
      />
    </>
  );
};
