import Stack from '@mui/material/Stack';

import { CommissionsChart } from './commissions-chart';
import { PartnerTransactions } from './transactions/transactions';

export const PartnersFees = () => {
  return (
    <Stack gap={5}>
      <CommissionsChart />
      <PartnerTransactions />
    </Stack>
  );
};
