import Box from '@mui/material/Box';
import { forwardRef, memo } from 'react';

import { StyledRootScrollbar, StyledScrollbar } from './styles';
import { ScrollbarProps } from './types';

export const Scrollbar = memo(forwardRef<HTMLDivElement, ScrollbarProps>((props, ref) => {
  const {
    children,
    sx,
    ...other
  } = props;
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar
        scrollableNodeProps={{ ref }}
        clickOnTrack={false}
        sx={sx}
        {...other}
      >
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
}));
