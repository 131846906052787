import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { CrumbType, RouterLink } from 'src/components';


type BreadcrumbsLinkProps = {
  crumb: CrumbType;
  activeLast?: boolean;
  disabled: boolean;
};

export const BreadcrumbsLink = (props: BreadcrumbsLinkProps) => {
  const {
    crumb,
    activeLast,
    disabled,
  } = props;
  const {
    title,
    href,
  } = crumb;

  const theme = useTheme();

  const styles = {
    typography: 'body2',
    alignItems: 'center',
    color: 'text.primary',
    display: 'inline-flex',
    ...(disabled &&
      !activeLast && {
      cursor: 'default',
      pointerEvents: 'none',
      color: theme.palette.text.disabled,
    }),
  };

  const renderContent = (
    <>
      {title}
    </>
  );

  if (href) {
    return (
      <Link component={RouterLink} href={href} sx={styles}>
        {renderContent}
      </Link>
    );
  }

  return <Box sx={styles}> {renderContent} </Box>;
};
