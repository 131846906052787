import { UpdateStatus, User } from '../../../types';
import { paths, rolesAccess } from '../config';
import { getStorage, removeStorage } from '../hooks';

export type authHelperParams = {
  authStatus: UpdateStatus,
  user?: User
}

export const authHelper = (params: authHelperParams) => {
  const {
    authStatus,
    user,
  } = params;
  const returnUrl = getStorage('returnUrl');
  const pathname = window.location.pathname;

  if ((pathname === '/partner-portal' || pathname === '/partner-portal/') && user) {
    window.location.href = paths.partnerPortal.overview;
  }

  if ((pathname === '/investor' || pathname === '/investor/') && user) {
    window.location.href = paths.investorPortal.projects.list;
  }

  if (pathname !== '/' || authStatus !== 'success') {
    return;
  }

  removeStorage('returnUrl');
  if (pathname === '/' && user) {
    window.location.href = returnUrl || rolesAccess[user?.role].defaultPath;
  }

};
