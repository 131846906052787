import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

export interface TimelineCustomProps {
  timelineSteps: TimeLineStep[];
  position?: 'alternate-reverse' | 'alternate' | 'left' | 'right';
  timelineSx?: SxProps<Theme>;
}

export type TimeLineStep = {
  dotColor?: 'inherit' | 'grey' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  timeLineContent?: ReactNode;
  timeLineDotChild?: ReactNode;
  showConnector?: boolean;
}

export const TimelineCustom = (props: TimelineCustomProps) => {
  const {
    timelineSteps,
    position = 'right',
    timelineSx,
  } = props;
  return (
    <Timeline position={position} sx={timelineSx}>
      {timelineSteps.map((step, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot color={step.dotColor}>
                {step.timeLineDotChild}
              </TimelineDot>
              {step.showConnector &&
                  <TimelineConnector/>
              }
            </TimelineSeparator>
            <TimelineContent>
              {step.timeLineContent}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
