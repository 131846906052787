import { ReactNode } from 'react';
import { useBoolean  } from 'src/hooks';

import { Header } from './header';
import { InvestorMain } from './main';

interface InvestorPageProps {
  children: ReactNode;
}

export const InvestorPage = (props: InvestorPageProps) => {
  const { children } = props;
  const nav = useBoolean();

  return (
    <>
      <Header onOpenNav={nav.onTrue}/>
      <InvestorMain>{children}</InvestorMain>
    </>
  );
};
