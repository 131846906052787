import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectsOffering } from '../../../../types';

interface State {
  projectsOffering: ProjectsOffering[];
  currentProjectsOffer?: ProjectsOffering;
}

export const initialState: State = { projectsOffering: [] };

const Slice = createSlice({
  name: 'Marketplace Projects Offering',
  initialState,
  reducers: {
    setProjectsOffering(state, action: PayloadAction<ProjectsOffering[]>) {
      state.projectsOffering = action.payload;
    },
    setCurrentProjectsOffer(state, action: PayloadAction<ProjectsOffering>) {
      state.currentProjectsOffer = action.payload;
    },
    //TODO remove
    setCurrentProjectsOfferById(state, action: PayloadAction<string>) {
      state.currentProjectsOffer = state.projectsOffering.find(item => item._id === action.payload);
    },
  },
});

export const {
  reducer: ProjectsOfferingReducer,
  actions: {
    setCurrentProjectsOffer,
    setCurrentProjectsOfferById,
    setProjectsOffering,
  },
} = Slice;

export * from './actions';
