import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { UploadAvatar, UploadProps } from 'src/components/upload';
import { fData } from 'src/utils';

export const accept = {
  'image/png': ['.png'],
  'image/svg': ['.svg'],
};

export const Settings = () => {
  const theme = useTheme();

  // Avatar
  const [avatar, setAvatar] = useState<UploadProps['file']>(null);
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file) {
      setAvatar(
        Object.assign(file, { preview: URL.createObjectURL(file) }),
      );
    }
  }, []);

  // Form fields
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Card sx={{ height: '100%' }}>
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <UploadAvatar
              sx={{ my: 0 }}
              accept={accept}
              file={avatar}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant='body2'
                  color={theme.palette.text.secondary}
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                  }}
                >
                  Allowed *.png, *.svg
                  <br/> max size of {fData(3_145_728)}
                </Typography>
              }
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={8}>
        <Card>
          <CardContent>
            <Stack gap={3}>
              <Typography variant='h6'>
                General Info
              </Typography>
              <Stack gap={3}>
                <TextField
                  value={name}
                  onChange={e => setName(e.currentTarget.value)}
                  label='Name'
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  value={email}
                  onChange={e => setEmail(e.currentTarget.value)}
                  label='Email'
                  variant='outlined'
                  fullWidth
                />
                <TextField
                  value='www.example.com'
                  label='Domain'
                  variant='outlined'
                  fullWidth
                  disabled
                />
                <Button variant='contained' size='medium' sx={{ ml: 'auto' }}>
                  Save Changes
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
