import { Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import _, { debounce } from 'lodash';
import { ChangeEvent, memo, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTab, TableCustom, TableFiltersResult, TablePaginationCustom, TableToolbar, TabType } from 'src/components';
import { paths } from 'src/config';
import { chipVariants, stageColor } from 'src/constants/constants';
import { useRouter, useTable } from 'src/hooks';
import { RootState } from 'src/store';
import { deleteProjectPreparationThunk, loadProjectPreparation } from 'src/store/project-preparation';
import { sortCustom } from 'src/utils';

import { DefaultPreparationStatusColors, PaginationParams, ProjectPreparation, ProjectPreparationStatusType, ProjectType } from '../../../../types';
import { _projectTypeList } from '../projects-offering-page';
import { PreparationTableRow } from './components';
import { allFilters, defaultFilters, TABLE_HEAD } from './mock-data';
import { TableFilters, TableFilterValue } from './types';

const statusColor: DefaultPreparationStatusColors = {
  'New': 'secondary',
  'Done': 'success',
  'All': 'default',
};

export type ProjectPreparationBodyParams = PaginationParams & {
  status: ProjectPreparationStatusType;
  projectType?: ProjectType[];
}

export const ProjectPreparationPage = memo(() => {
  const [filters, setFilters] = useState(defaultFilters);
  const projectPreparations = useSelector((state: RootState) => state.ProjectPreparation.projectPreparations);
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);
  const [correspondingBody, setCorrespondingBody] = useState<ProjectPreparationBodyParams>({
    page: 1,
    limit: 5,
    status: 'New',
  });
  const table = useTable();
  const router = useRouter();
  const dispatch = useDispatch();

  const dataFiltered: ProjectPreparation[] = isDemo ? applyFilter(sortCustom(projectPreparations?.projects, table), filters) : projectPreparations?.projects;
  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  useEffect(() => {
    dispatch(loadProjectPreparation({
      page: 1,
      limit: 5,
      status: 'New',
    }));
  }, [dispatch]);

  const getPreparationLen = useCallback((status: ProjectPreparationStatusType) => {
    return projectPreparations?.projects?.filter((item) => item.status === status)?.length;
  }, [projectPreparations]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'New',
        label: 'New',
        count: isDemo ? getPreparationLen('New') : projectPreparations?.counter['New'],
      },
      {
        value: 'Done',
        label: 'Done',
        count: isDemo ? getPreparationLen('Done') : projectPreparations?.counter['Done'],
      },
      {
        value: 'All',
        label: 'All',
        count: isDemo ? projectPreparations?.projects.length : projectPreparations?.counter.All,
      },
    ];
  }, [getPreparationLen, isDemo, projectPreparations?.counter, projectPreparations?.projects.length]);

  const updatePreparation = useCallback((newBodyPart: Partial<ProjectPreparationBodyParams>) => {
    const newBody: ProjectPreparationBodyParams = {
      ...correspondingBody,
      ...newBodyPart,
    };
    dispatch(loadProjectPreparation(newBody));
    setCorrespondingBody(newBody);
  }, [correspondingBody, dispatch]);

  const handleFilters = useCallback((name: keyof TableFilters, value: TableFilterValue) => {
    updatePreparation({
      [name]: value,
      page: 1,
      limit: 5,
    });
    table.onResetPage();
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, [table, updatePreparation]);

  const handleFilterStatus = useCallback((event: SyntheticEvent, newValue: string) => {
    handleFilters('status', newValue as ProjectPreparationStatusType);
  }, [handleFilters]);

  const handleResetFilters = useCallback(() => {
    updatePreparation(allFilters);
    setFilters(allFilters);
  }, [updatePreparation]);

  const canReset =
    !!filters.text ||
    filters.projectType?.length > 0 ||
    filters.status !== 'All';
  const noDataFound = (dataFiltered?.length === 0 && canReset) || dataFiltered?.length === 0;

  const onPageChange = debounce((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
    updatePreparation({ page: page + 1 });
    table.onChangePage(event, page);
  }, 200);

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updatePreparation({
      limit: +event.target.value,
      page: 1,
    });
    table.onChangeRowsPerPage(event);
  };

  const onRowClickHandler = (id: string, pathKey: keyof typeof paths.projectPreparation, tab: string = 'overview') => () => {
    const currentPath = paths.projectPreparation[pathKey];
    router.push(typeof currentPath === 'function' ? currentPath(id, tab) : currentPath);
  };

  const onFiltersDeleteHandler = useCallback((filterKey: keyof TableFilters, valueToDelete: TableFilterValue) => {
    const filterValue = filters[filterKey];
    let newValue = allFilters[filterKey];
    if (Array.isArray(filterValue)) {
      newValue = filters.projectType.filter((item) => item !== valueToDelete);
    }
    handleFilters(filterKey, newValue);
  }, [filters, handleFilters]);

  const handleDeleteRow = useCallback((id: string) => () => {
    dispatch(deleteProjectPreparationThunk(id));
    table.onUpdatePageDeleteRow(dataInPage?.length);
  }, [dataInPage?.length, dispatch, table]);

  const onSortChange = debounce((id: string) => {
    const {
      orderBy,
      order,
    } = table;
    const isAsc = orderBy === id && order === 'asc';
    updatePreparation({
      sort: id,
      sortType: isAsc ? 'desc' : 'asc',
    });
    table.onSort(id);
  }, 200);

  return (
    <Container maxWidth={false}>
      <Grid container style={{ marginBottom: '40px' }}>
        <Grid item xs={6}>
          <Typography variant='h4'>
            Project Preparation
          </Typography>
        </Grid>
      </Grid>
      <Card>
        <CustomTab
          tabs={TABS}
          defaultTabValue='All'
          currentTabValue={filters.status}
          handleTabOnChange={handleFilterStatus}
        />
        <TableToolbar
          selectValue={filters.projectType}
          selectOptions={_projectTypeList}
          selectedOptions={filters.projectType}
          onFilters={handleFilters}
          selectLabel='Project type'
          selectKey='projectType'
          searchFieldValue={filters.text}
          searchFieldKey='text'
        />
        {canReset && (
          <Stack
            sx={{
              paddingLeft: '20px',
              paddingBottom: '16px',
            }}
          >
            <TableFiltersResult
              filters={filters}
              defaultFilters={allFilters}
              onFiltersDeleteHandler={onFiltersDeleteHandler}
              onResetFilters={handleResetFilters}
              results={isDemo ? dataInPage?.length : projectPreparations?.metadata.total}
              chipVariants={chipVariants}
              chipColors={{ ...stageColor }}
            />
          </Stack>
        )}

        <TableCustom
          headLabels={TABLE_HEAD}
          table={table}
          rowCount={isDemo ? projectPreparations?.projects.length : projectPreparations?.metadata.total}
          noDataFound={noDataFound}
          onSort={onSortChange}
          bodyCells={(
            <>
              {(isDemo ? dataInPage : projectPreparations?.projects)?.map((row) => (
                <PreparationTableRow
                  key={row._id}
                  statusColor={statusColor}
                  row={row}
                  reviewOnClickHandler={onRowClickHandler}
                  onViewRow={onRowClickHandler(row._id, 'details')}
                  onDeleteRow={handleDeleteRow(row._id)}
                />
              ))}
            </>
          )}
        />

        <TablePaginationCustom
          count={isDemo ? dataFiltered.length : projectPreparations?.metadata.total}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
});

const applyFilter = (data: ProjectPreparation[], filters: TableFilters) => {
  let resultData = _.cloneDeep(data);
  const {
    text,
    projectType,
    status,
  } = filters;

  if (text) {
    resultData = resultData?.filter((item) => {
      return item.lastActivity?.date?.toLowerCase().includes(text.toLowerCase()) ||
        item.lastActivity?.message?.toString().includes(text.toString()) ||
        item.project?.name?.toString().includes(text.toString()) ||
        item.project?.scenario?.calculate?.input?.type_of_project?.toString().includes(text.toString()) ||
        item.project?.scenario?.calculate?.output?.GDV?.toString?.()?.includes?.(text.toString()) ||
        item.project?.scenario?.calculate?.output?.['Net profit']?.toString?.()?.includes?.(text.toString()) ||
        item.status.toString().includes(text.toString());
    });
  }

  if (projectType?.length > 0) {
    resultData = resultData?.filter(item => projectType.includes(item.project?.scenario?.calculate?.input?.type_of_project as ProjectType));
  }
  if (status !== 'All') {
    resultData = resultData?.filter((item) => item.status === status);
  }

  return resultData;
};
