import { TableHeadType } from 'src/features/leads-page';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: 'units',
    label: 'Units',
  },
  {
    _id: 'quantity',
    label: 'Quantity',
    align: 'right',
  },
  {
    _id: 'price',
    label: 'Price',
    align: 'right',
  },
  {
    _id: 'size',
    label: 'Size',
    align: 'right',
  },
];
