import { Button, Card, CardHeader, IconButton, ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { memo, ReactNode } from 'react';
import { CrossIcon } from 'src/assets/svg';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';
import { useBoolean } from 'src/hooks';

import { DrawerCustom } from '../drawer-custom';

interface SummaryProps {
  title: string;
  showSkeleton: boolean;
  showLearnMoreButton?: boolean;
  learnMoreItems?: ItemType[];
  items: ItemType[];
  children?: ReactNode;
  cardSx?: SxProps<Theme>;
  summaryListItemSX?: SxProps<Theme>;
  skeletonHeight?: number;
}

export type ItemType = {
  label: string;
  value?: string | number | JSX.Element;
  icon: JSX.Element;
}

export const Summary = memo((props: SummaryProps) => {

  const {
    title,
    items,
    showSkeleton,
    learnMoreItems = [],
    showLearnMoreButton = false,
    children,
    cardSx,
    summaryListItemSX,
    skeletonHeight = 660,
  } = props;

  const learnMore = useBoolean();

  const onLearnMoreClickHandler = () => {
    learnMore.onTrue();
  };

  return (
    <>
      {showSkeleton ? (
        <Skeleton variant='rounded' height={skeletonHeight} width='100%'/>
      ) : (
        <Card sx={cardSx}>
          <CardHeader
            title={title}
          />
          <Stack spacing={2} sx={{ padding: '24px 24px 16px 24px' }}>
            {items.map(item => (
              <SummaryListItem summaryListItemSX={summaryListItemSX} key={item.label} item={item}/>
            ))}
            {showLearnMoreButton &&
                <Button
                  variant='outlined'
                  onClick={onLearnMoreClickHandler}
                >
                    Learn more
                </Button>
            }
            {children}
          </Stack>
        </Card>
      )}
      <DrawerCustom
        open={learnMore.value}
        onCloseDrawerHandler={learnMore.onFalse}
        headChildren={
          <>
            <Typography variant='h6'>Lead Summary</Typography>
            <IconButton onClick={learnMore.onFalse}>
              <CrossIcon/>
            </IconButton>
          </>
        }
        bodyChildren={
          <Stack gap={2}>
            {learnMoreItems.map(item => (
              <SummaryListItem key={item.label} item={item}/>
            ))}
          </Stack>
        }
      />
    </>
  );
});

type SummaryListItemProps = {
  item: ItemType;
  summaryListItemSX?: SxProps<Theme>;
}

export const SummaryListItem = (props: SummaryListItemProps) => {
  const {
    item,
    summaryListItemSX,
  } = props;

  return (
    <Stack spacing={1.5} direction='row'>
      {item.icon}
      <ListItemText
        primary={item.label}
        sx={summaryListItemSX}
        secondary={item.value || UNSPECIFIED_VALUE}
        primaryTypographyProps={{
          typography: 'body2',
          color: 'text.secondary',
          mb: 0.5,
        }}
        secondaryTypographyProps={{
          typography: 'subtitle2',
          color: 'text.primary',
          component: 'span',
        }}
      />
    </Stack>
  );
};
