import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Markdown } from 'src/components';
import { useResponsive } from 'src/hooks';
import { fDate } from 'src/utils';

export type ChainedItemType = {
  title: string;
  value?: string | number;
  hidden?: boolean;
}

export interface CardReportItemProps {
  title?: string;
  suTitle?: string;
  postChainedSuTitle?: string;
  date?: string;
  tags?: string[];
  chainedItems?: ChainedItemType[];
  oppositeItems?: ChainedItemType[];
}

export const CardReportItem = (props: CardReportItemProps) => {
  const {
    title,
    suTitle,
    date,
    postChainedSuTitle,
    chainedItems = [],
    oppositeItems = [],
    tags = [],
  } = props;
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant='h6'>{title}</Typography>
          {suTitle &&
            <Typography variant='subtitle2'>
              {suTitle}
            </Typography>
          }
          {oppositeItems.length ?
            <Stack gap={2}>
              {oppositeItems.map(item => {
                return (
                  <Stack direction='row' gap={4} key={item.title}>
                    <Typography minWidth={160} variant='body2' color='text.secondary'>
                      {item.title}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {item.value}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
            : null
          }
          {chainedItems.length ?
            <Stack direction='row' gap={1.5} flexWrap='wrap'>
              {chainedItems.map(chain => {
                return (
                  <Stack
                    gap={0.5}
                    key={chain.title}
                    sx={{
                      borderRadius: 1,
                      px: 2,
                      py: 1,
                      flexGrow: mdUp ? 0 : 1,
                      display: chain.hidden ? 'none' : 'flex',
                      backgroundColor: theme.palette.background.neutral,
                    }}
                  >
                    <Typography variant='body2' color={theme.palette.text.secondary}>
                      {chain.title}
                    </Typography>
                    <Typography variant='subtitle2'>{chain.value}</Typography>
                  </Stack>
                );
              })}
            </Stack>
            : null
          }
          {postChainedSuTitle &&
            <Markdown children={postChainedSuTitle}/>
          }
          {tags.length ?
            <Stack gap={2}>
              <Typography variant='subtitle1'>Tags</Typography>
              <Stack direction='row' gap={1}>
                {tags.map(tag => {
                  return (
                    <Chip
                      key={tag}
                      variant='soft'
                      size='medium'
                      color='default'
                      label={tag}
                    />
                  );
                })}
              </Stack>
            </Stack>
            : null}
          {date &&
            <Typography variant='caption' color='text.secondary'>
              Date: {fDate(date)}
            </Typography>
          }
        </Stack>
      </CardContent>
    </Card>
  );
};
