import { CrumbType } from 'src/components';

import { DebtRequest } from './debt-request';

export interface FinancingDebtRequestProps {
  crumbs: CrumbType[];
}

const FinancingDebtRequest = (props: FinancingDebtRequestProps) => {
  const { crumbs } = props;
  const updatedCrumbs: CrumbType[] = [
    ...crumbs,
    { title: 'Debt Request' },
  ];

  return (
    <DebtRequest
      isEditEnable
      uploadDocTitle='No documents'
      uploadDocSubTitle='Request the necessary documents from the ownwer. Without the necessary documents, the application may not be considered.'
      crumbs={updatedCrumbs}
    />
  );
};

export default FinancingDebtRequest;
