import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { FileItem, Markdown } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { RootState } from 'src/store';
import { fNumber, fPercent, openInNewTab } from 'src/utils';

import { CoverImagesType, FileForUpload, NoteSettingType } from '../../../../types';
import { OverviewPhotos } from '../investor-portal/projects/project-details/overview/components/overview-photos';

export interface AboutProjectProps {
  showGallery?: boolean;
  isGetDataFromSettings?: boolean;
  showAboutProject?: boolean;
  showVatNote?: boolean;
  noteSetting?: NoteSettingType;
  images?: CoverImagesType[];
}

export const AboutProject = (props: AboutProjectProps) => {
  const {
    showGallery = false,
    showVatNote = false,
    isGetDataFromSettings = false,
    showAboutProject = true,
    images = [],
    noteSetting,
  } = props;

  const theme = useTheme();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const project = detailsData.project;
  const scenario = project?.scenario;
  const assets = detailsData?.assets;
  const scenarioOutput = scenario?.calculate?.output;
  const scenarioInput = scenario?.calculate?.input;
  const coverImages = isGetDataFromSettings ? images : assets?.marketPlaceListing?.coverImages;
  const description = isGetDataFromSettings ? noteSetting?.note : assets?.marketPlaceListing?.description;
  const showDescription = isGetDataFromSettings ? noteSetting?.isActive : true;

  const onFileClickHandler = (file: FileForUpload) => () => {
    openInNewTab(file.url);
  };

  return (
    <>
      {showGallery && <OverviewPhotos photos={coverImages}/>}
      {showAboutProject &&
        <Card>
          <CardContent>
            <Stack gap={3}>
              <Stack justifyContent='space-between' direction='row'>
                <Typography variant='h6'>Project Summary</Typography>
                {showVatNote &&
                  <Typography variant='body2' color='#919EAB'>
                    Please note that all values are inclusive of VAT
                  </Typography>
                }
              </Stack>
              <Stack direction='row' gap={1.5} flexWrap='wrap'>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Type
                  </Typography>
                  <Typography variant='subtitle2'>{scenarioInput?.type_of_project}</Typography>
                </Stack>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    GDV
                  </Typography>
                  <Typography variant='subtitle2'>
                    {fNumber(scenarioOutput?.GDV)} {DEFAULT_CURRENCY}
                  </Typography>
                </Stack>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    ROE
                  </Typography>
                  <Typography variant='subtitle2'>
                    {fPercent(scenarioOutput?.ROI)}
                  </Typography>
                </Stack>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Total Costs
                  </Typography>
                  <Typography variant='subtitle2'>
                    {fNumber(scenarioOutput?.['Total costs'])} {DEFAULT_CURRENCY}
                  </Typography>
                </Stack>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Net Margin
                  </Typography>
                  <Typography variant='subtitle2'>{fPercent(scenarioOutput?.['Net margin'])}</Typography>
                </Stack>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Profit on cost
                  </Typography>
                  <Typography
                    variant='subtitle2'>{fPercent(scenarioOutput?.['Profit on cost'])}</Typography>
                </Stack>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Sales value per sqm:
                  </Typography>
                  <Typography variant='subtitle2'>
                    {fNumber(scenarioOutput?.['Sales value psqft'])} {DEFAULT_CURRENCY}
                  </Typography>
                </Stack>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Build cost per sqm:
                  </Typography>
                  <Typography variant='subtitle2'>
                    {fNumber(scenarioOutput?.['Build cost psqft'])} {DEFAULT_CURRENCY}
                  </Typography>
                </Stack>
                <Stack
                  gap={0.5}
                  sx={{
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    backgroundColor: theme.palette.background.neutral,
                  }}
                >
                  <Typography variant='body2' color={theme.palette.text.secondary}>
                    Net Profit
                  </Typography>
                  <Typography variant='subtitle2'>
                    {fNumber(scenarioOutput?.['Net profit'])} {DEFAULT_CURRENCY}
                  </Typography>
                </Stack>
              </Stack>
              {showDescription &&
                <Stack gap={3}>
                  <Markdown children={description || ''}/>
                  {noteSetting?.isActive &&
                    <Stack gap={1}>
                      {noteSetting?.files?.map((file, index) => {
                        return (
                          <FileItem
                            key={index}
                            file={file}
                            onFileClickHandler={onFileClickHandler}
                          />
                        );
                      })}
                    </Stack>
                  }
                </Stack>
              }
            </Stack>
          </CardContent>
        </Card>
      }
    </>
  );
};
