import { UploadedFilesType } from 'src/components';
import { useResponsive } from 'src/hooks';

import { Documents } from '../investor-portal/projects/project-details/documents/documents';
import { TableHeadType } from '../leads-page';

export interface ReportDocumentsProps {
  files?: UploadedFilesType[];
}

export const ReportDocuments = (props: ReportDocumentsProps) => {
  const { files } = props;
  const mdUp = useResponsive('up', 'md');

  const TABLE_HEAD: TableHeadType[] = [
    {
      _id: 'name',
      label: 'Name / Size',
    },
    {
      _id: 'uploaded',
      label: 'Uploaded',
      width: 240,
      hidden: !mdUp,
    },
    {
      _id: 'download',
      width: 172,
    },
  ];


  return (
    <Documents
      showUploadedBy
      hideUploadCell={!mdUp}
      files={files}
      tableHead={TABLE_HEAD}
    />
  );
};
