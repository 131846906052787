import Stack from '@mui/material/Stack';
import { memo } from 'react';
import { navMiniConfig } from 'src/constants/nav-config';

import { NavConfigProps, NavListProps, NavSectionProps } from '../../types';
import { NavListMini } from './nav-list-mini';

export const NavSectionMini = memo((props: NavSectionProps) => {
  const {
    data,
    config,
    sx,
    ...other
  } = props;
  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group
          disabled={group.disabled}
          key={group.subheader || index}
          items={group.items}
          config={navMiniConfig(config)}
        />
      ))}
    </Stack>
  );
});

type GroupProps = {
  items: NavListProps[];
  config: NavConfigProps;
  disabled?: boolean;
};

const Group = (props: GroupProps) => {
  const {
    items,
    config,
  } = props;
  return (
    <>
      {items.map((list) => (
        <NavListMini
          key={list.title + list.path}
          data={list}
          disabled={list.disabled}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
};
