import { Stack } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EcommerceWidgetSummary, OmittedSellOfferType } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { useBoolean, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { addSellOfferThunk, deleteSellItemThunk } from 'src/store/sell';
import { fPercent } from 'src/utils';

import { RaiseMonitoringType } from '../../../../../types';
import { AddRaiseOffer } from './add-raise-offer';
import { RaiseMonitoringTable } from './raise-monitoring-table';

export const RaiseMonitoring = () => {

  const router = useRouter();
  const offerBool = useBoolean();
  const dispatch = useDispatch();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const [currentItem, setCurrentItem] = useState<RaiseMonitoringType | undefined>();

  const raiseMonitoring = detailsData.distribution;
  const lead = detailsData.lead;
  const price = lead?.price || 0;
  const buyersFee = lead?.buyerFeeAmount || 0;
  const buyersFeeResult = fPercent(buyersFee / price * 100);

  const deleteItemClickHandler = (itemToDelete: RaiseMonitoringType) => () => {
    if (itemToDelete?._id) {
      dispatch(deleteSellItemThunk(itemToDelete));
    }
  };

  const shareProjectClickHandler = () => {
    router.push('sell-project');
  };

  const addOfferClickHandler = (item: RaiseMonitoringType) => () => {
    offerBool.onTrue();
    setCurrentItem(item);
  };

  const closeOfferDrawerHandler = () => {
    offerBool.onFalse();
    setCurrentItem(undefined);
  };

  const sendOfferClickHandler = (data: OmittedSellOfferType) => {
    if (currentItem?._id) {
      dispatch(addSellOfferThunk(data, currentItem));
    }
    closeOfferDrawerHandler();
  };

  return (
    <Stack gap={5}>
      <Stack direction='row' gap={3}>
        <EcommerceWidgetSummary
          title='Price'
          total={price}
          postfix={DEFAULT_CURRENCY}
          sx={{
            height: 'inherit',
            alignItems: 'flex-start',
            flex: 1,
          }}
        />
        <EcommerceWidgetSummary
          title={'Buyer\'s fee'}
          total={buyersFee}
          subTitle={buyersFeeResult}
          postfix={DEFAULT_CURRENCY}
          sx={{
            width: '33%',
            flex: 1,
          }}
        />
        <EcommerceWidgetSummary
          title='Shared'
          total={raiseMonitoring?.sharedCounter || 0}
          subTitle={`${raiseMonitoring?.openedCounter || 0} opened`}
          sx={{
            width: '33%',
            flex: 1,
          }}
        />
      </Stack>
      {raiseMonitoring?.offers?.length ? (
        <RaiseMonitoringTable
          tableData={raiseMonitoring.offers}
          tableTitle='Offers'
          lastColumnName={{
            _id: '',
            width: 160,
            label: 'Offer',
          }}
          deleteClickHandler={deleteItemClickHandler}
        />
      ) : null}
      <RaiseMonitoringTable
        tableData={raiseMonitoring?.shared}
        tableTitle='Shared with'
        lastColumnName={{
          _id: '',
          width: 160,
          label: 'Status',
        }}
        deleteClickHandler={deleteItemClickHandler}
        btnTitle='Share Project'
        btnConfig={{ onClick: shareProjectClickHandler }}
        addOfferClickHandler={addOfferClickHandler}
      />
      <AddRaiseOffer
        defaultEmail={currentItem?.client?.email}
        showDrawer={offerBool.value}
        drawerOnClose={closeOfferDrawerHandler}
        sendOfferClickHandler={sendOfferClickHandler}
      />
    </Stack>
  );
};
