import Box, { BoxProps } from '@mui/material/Box';
import { memo } from 'react';
import { INVESTOR_PORTAL, NAV } from 'src/constants/constants';
import { useResponsive, useSettingsContext } from 'src/hooks';

const SPACING = 48;

export const InvestorMain = memo((props: BoxProps) => {
  const {
    children,
    sx,
    ...other
  } = props;

  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
        minHeight: 1,
        maxWidth: settings.themeStretch ? '100%' : INVESTOR_PORTAL.MAX_WIDTH,
        display: 'flex',
        flexDirection: 'column',
        mx: 'auto',
        py: `${INVESTOR_PORTAL.HEADER_H + SPACING}px`,
        ...(!lgUp && { width: '100%' }),
        ...(lgUp && { width: `calc(100% - ${NAV.W_VERTICAL}px)` }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
});
