import { Button, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { ReactNode } from 'react';
import { ModalCustom } from 'src/components';

export interface InfoModalProps {
  open: boolean;
  onClose: () => void;
  icon: ReactNode;
  title: string;
  subTitle?: string;
  btnTitle: string;
  btnConfig: ButtonProps;
  secondBtnTitle?: string;
  secondBtnConfig?: ButtonProps;
}

export const InfoModal = (props: InfoModalProps) => {
  const {
    open,
    onClose,
    icon,
    btnConfig,
    secondBtnConfig,
    secondBtnTitle,
    title,
    subTitle,
    btnTitle,
  } = props;


  return (
    <ModalCustom
      open={open}
      onClose={onClose}
      bodyChildren={
        <>
          {icon}
          <Typography
            variant='h4'
            sx={{ textAlign: 'center' }}
          >
            {title}
          </Typography>
          {subTitle &&
              <Typography
                variant='body2'
                sx={{ textAlign: 'center' }}
              >
                {subTitle}
              </Typography>
          }
        </>
      }
      bodyChildrenSx={{
        pt: 5,
        pb: 0,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      footerChildren={
        <>
          <Button
            fullWidth
            variant='outlined'
            {...btnConfig}
          >
            {btnTitle}
          </Button>
          {secondBtnTitle &&
              <Button
                fullWidth
                variant='outlined'
                {...secondBtnConfig}
              >
                {secondBtnTitle}
              </Button>
          }
        </>
      }
      footerChildrenSx={{ gap: 1.5 }}
    />
  );
};
