import { useParams } from 'react-router-dom';

import { BankProductForm } from './bank-product-form';

const NewBankProduct = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <BankProductForm partnerId={id}/>
  );
};

export default NewBankProduct;
