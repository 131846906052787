import { ProjectType } from '../../../../types';
import { TableHeadType } from '../leads-page';
import { TableFilters } from './types';

export const _projectTypeList: ProjectType[] = [
  'New build apartments', 'New build houses', 'New build houses and apartments', 'Investment',
  'Conversion', 'Permitted development'];

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: '',
    label: 'Name / Type',
    width: 300,
  },
  {
    _id: '',
    label: 'Soft Committed',
    width: 170,
    align: 'right',
  },
  {
    _id: '',
    label: 'Raised / Required',
    width: 170,
    align: 'right',
  },
  {
    _id: '',
    label: 'Status',
    width: 110,
  },
  {
    _id: 'controls',
    width: 50,
  },
];

export const MARKETPLACE_TABLE_HEAD: TableHeadType[] = [
  {
    ...TABLE_HEAD[0],
    label: 'Name / Published',
    width: 200,
  },
  ...TABLE_HEAD.slice(1),
];

export const PARTNER_TABLE_HEAD = MARKETPLACE_TABLE_HEAD.slice(0, 3).concat({
  _id: '',
  label: 'Partner fee',
  width: 120,
  align: 'right',
}, TABLE_HEAD.slice(3, 5));

export const defaultFilters: TableFilters = {
  text: '',
  status: 'All',
  projectType: [],
};
