import { Button, Divider, Grid, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Fragment, MouseEvent, useState } from 'react';
import { ArrowIcon } from 'src/assets/svg';
import { INVESTOR_PORTAL } from 'src/constants/constants';
import { AboutProjectProps } from 'src/features/fin-upraisal/about-project';
import { useResponsive } from 'src/hooks';
import { scrollToSection } from 'src/utils';

import {
  ComparablesProps,
  ConstructionProps,
  FinanceProps,
  InfoBlock,
  LocalAreaType,
  LocalMarketType,
  ProjectMultiplesProps,
  ProposedUnitsProps,
  SalesProps,
  SensitivityAnalysisProps,
  SiteAndPurchaseType,
  TimelineCashflowProps,
} from './components';
import {
  getAboutProject,
  getComparables,
  getConstruction,
  getFinance,
  getLocalArea,
  getLocalMarket,
  getProjectMultiples,
  getProposedUnits,
  getSales,
  getSensitivityAnalysis,
  getSiteAndPurchase,
  getTimelineCashflow,
} from './get-components';
import { Props, ReportKey, ReportsConfigType, ReportType } from './types';

export type ReportProps = {
  aboutProject?: AboutProjectProps;
  siteAndPurchase?: SiteAndPurchaseType;
  proposedUnits?: ProposedUnitsProps;
  construction?: ConstructionProps;
  finance?: FinanceProps;
  timelineCashflow?: TimelineCashflowProps;
  sales?: SalesProps;
  projectMultiples?: ProjectMultiplesProps;
  sensitivityAnalysis?: SensitivityAnalysisProps;
  localMarket?: LocalMarketType;
  localArea?: LocalAreaType;
  comparables?: ComparablesProps;
  showAll?: boolean;
  showMenu?: boolean;
}

const reportsConfig: ReportsConfigType[] = [
  {
    name: 'aboutProject',
    report: getAboutProject,
  },
  {
    name: 'siteAndPurchase',
    report: getSiteAndPurchase,
  },
  {
    name: 'proposedUnits',
    report: getProposedUnits,
  },
  {
    name: 'construction',
    report: getConstruction,
  },
  {
    name: 'finance',
    report: getFinance,
  },
  {
    name: 'timelineCashflow',
    report: getTimelineCashflow,
  },
  {
    name: 'sales',
    report: getSales,
  },
  {
    name: 'projectMultiples',
    report: getProjectMultiples,
  },
  {
    name: 'sensitivityAnalysis',
    report: getSensitivityAnalysis,
  },
  {
    name: 'localMarket',
    report: getLocalMarket,
  },
  {
    name: 'localArea',
    report: getLocalArea,
  },
  {
    name: 'comparables',
    report: getComparables,
  },
];

export const Report = (props: ReportProps) => {
  const {
    showAll,
    showMenu = true,
  } = props;
  const mdUp = useResponsive('up', 'md');
  const reports: ReportType[] = reportsConfig.reduce((accumulator: ReportType[], {
    name,
    report,
  }) => {
    if (props[name]) {
      accumulator.push(report(props[name] as Props));
    }
    return accumulator;
  }, []);

  const [view, setView] = useState(reports[0]?.name);

  const handleChange = (_: MouseEvent<HTMLElement>, nextView: ReportKey) => {
    if (!nextView) return;
    setView(nextView);
    if (showAll) {
      scrollToSection(`section-${nextView}`, INVESTOR_PORTAL.HEADER_H * -1);
    }
  };

  const getReport = (sign: 'next' | 'prev') => {
    let reportIndex = reports.findIndex(el => el.name === view);
    reportIndex = sign === 'next' ? reportIndex + 1 : reportIndex - 1;
    return reports?.[reportIndex];
  };

  const handleArrowClick = (sign: 'next' | 'prev') => () => {
    const reportName = getReport(sign).name;
    setView(reportName);
    window.scrollTo(0, 0);
  };

  return (
    <Grid container spacing={5} sx={{ justifyContent: 'space-between' }}>
      <Grid item md={showMenu ? 9.5 : 12} sx={{ maxWidth: '100%' }}>
        {showAll
          ? <>
            <Stack gap={8}>
              {reports.map((report, index) => {
                return (
                  <Fragment key={index}>
                    <Stack>
                      <div id={`section-${report.name}`}>
                        {report.component}
                      </div>
                    </Stack>
                    {index !== reports.length - 1 && <Divider/>}
                  </Fragment>
                );
              },
              )}
              <Grid xs={12} item hidden={mdUp}>
                <InfoBlock
                  infoText='In the desktop version, in addition to the aforementioned information,
          you can also explore the following sections:
           Timeline, Cashflow, Sensitivity, Local Area, Local Market and Comparables'
                />
              </Grid>
            </Stack>
          </>
          : <>
            {reports.find((elem) => view === elem.name)?.component}
          </>
        }

        {!showAll &&
            <>
              <Grid
                container
                sx={{
                  justifyContent: 'space-between',
                  marginTop: '40px',
                }}
              >
                <Grid item>
                  {getReport('prev')?.name &&
                          <Button
                            variant='outlined'
                            size='large'
                            onClick={handleArrowClick('prev')}
                          >
                            <ArrowIcon width='24px' height='24px' style={{ transform: 'rotate(180deg)' }}/>
                            {getReport('prev')?.name}
                          </Button>
                  }
                </Grid>
                <Grid item>
                  {getReport('next')?.name &&
                          <Button
                            variant='outlined'
                            size='large'
                            onClick={handleArrowClick('next')}
                          >
                            {getReport('next')?.name}
                            <ArrowIcon width='24px' height='24px'/>
                          </Button>
                  }
                </Grid>
              </Grid>
            </>
        }

      </Grid>
      {showMenu &&
          <Grid hidden={!mdUp} item md={2.5}>
            <ToggleButtonGroup
              orientation='vertical'
              value={view}
              exclusive
              onChange={handleChange}
              sx={showAll
                ? {
                  width: '100%',
                  position: 'sticky',
                  top: INVESTOR_PORTAL.HEADER_H + 8,
                }
                : { width: '100%' }}
            >
              {reports.map((elem, index) => {
                return <ToggleButton
                  key={index}
                  sx={{
                    width: '96%',
                    color: (theme) => view === elem.name ? theme.palette.text.primary : theme.palette.text.secondary,
                    fontWeight: 600,
                    background: (theme) => view === elem.name ? alpha(theme.palette.text.secondary, 0.08) : 'none',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    textAlign: 'left',
                    display: 'inline-block',
                  }}
                  value={elem.name}
                  aria-label='list'
                >
                  {elem.name}
                </ToggleButton>;
              })}
            </ToggleButtonGroup>
          </Grid>
      }
    </Grid>
  );
};
