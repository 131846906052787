import { Button, Card, CardHeader, Skeleton } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

export interface HelpCloudProps {
  avatar?: ReactNode;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  showCloud?: Boolean;
  showSkeleton?: Boolean;
  btnTitle?: string;
  onBtnClickHandler?: VoidFunction;
  cardSx?: SxProps<Theme>;
  titleColor?: string;
  subTitleColor?: string;
}

export const HelpCloud = (props: HelpCloudProps) => {
  const {
    subtitle,
    avatar,
    title,
    showCloud = false,
    showSkeleton = false,
    btnTitle,
    onBtnClickHandler,
    cardSx,
    titleColor,
    subTitleColor,
  } = props;

  const defaultCardSx: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'space-between',
    p: '20px 24px',
    boxShadow: 'none',
  };

  return (
    <>
      {showCloud &&
          <>
            {showSkeleton
              ? <Skeleton variant='rounded' height='96px' width='100%'/>
              : <Card sx={{ ...defaultCardSx, ...cardSx }}>
                <CardHeader
                  sx={{
                    p: 0,
                    color: titleColor,
                  }}
                  avatar={avatar}
                  subheaderTypographyProps={{ color: subTitleColor }}
                  title={title}
                  subheader={subtitle}
                />
                {btnTitle &&
                    <Button
                      sx={{ p: '12px 16px' }}
                      variant='contained'
                      onClick={onBtnClickHandler}
                    >
                      {btnTitle}
                    </Button>
                }
              </Card>
            }
          </>
      }
    </>
  );
};
