import {
  AlertCircleIcon,
  CalendarIcon,
  CaseIcon,
  ClockIcon,
  FilterIcon,
  FullscreenIcon,
  KeyIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
  SearchIcon,
  UserCardIcon,
  UserIcon,
} from 'src/assets/svg';

const SearchSummaryIcon = <SearchIcon stroke='rgba(33, 43, 54, 1)'/>;

export const allSummaryItems = {
  'contactPerson': {
    label: 'Contact person',
    icon: <UserIcon/>,
  },
  'companyName': {
    label: 'Company name',
    icon: <CaseIcon/>,
  },
  'type': {
    label: 'Type',
    icon: <CaseIcon/>,
  },
  'location': {
    label: 'Location',
    icon: <LocationIcon/>,
  },
  'personPosition': {
    label: 'Contact person position',
    icon: <UserCardIcon/>,
  },
  'totalUnits': {
    label: 'Total units',
    icon: <KeyIcon/>,
  },
  'units': {
    label: 'Units',
    icon: <KeyIcon/>,
  },
  'size': {
    label: 'Size',
    icon: <FullscreenIcon/>,
  },
  'mainTypeOfUnits': {
    label: 'Main type of units',
    icon: <FilterIcon/>,
  },
  'email': {
    label: 'Email',
    icon: <MailIcon/>,
  },
  'website': {
    label: 'Website',
    icon: <MailIcon/>,
  },
  'phone': {
    label: 'Phone number',
    icon: <PhoneIcon/>,
  },
  'source': {
    label: 'Source',
    icon: SearchSummaryIcon,
  },
  'createdBy': {
    label: 'Created by',
    icon: <UserIcon/>,
  },
  'dateCreated': {
    label: 'Date created',
    icon: <CalendarIcon/>,
  },
  'added': {
    label: 'Added',
    icon: <CalendarIcon/>,
  },
  'analyst': {
    label: 'Analyst',
    icon: <UserIcon/>,
  },
  'projectManager': {
    label: 'Project manager',
    icon: <UserIcon/>,
  },
  'rejected': {
    label: 'Rejected',
    icon: <AlertCircleIcon/>,
  },
  'projectType': {
    label: 'Project type',
    icon: <CaseIcon/>,
  },
  'phase': {
    label: 'Phase',
    icon: <ClockIcon/>,
  },
  'lastEdit': {
    label: 'Last Edit',
    icon: <FilterIcon/>,
  },
  'totalRequiredCapital': {
    label: 'Total Required Capital',
    icon: <></>,
  },
  'expectedReturns': {
    label: 'Expected Returns',
    icon: <></>,
  },
  'investmentType': {
    label: 'Investment Type',
    icon: <></>,
  },
  'partnerFee': {
    label: 'Partner Fee',
    icon: <></>,
  },
  'projectStage': {
    label: 'Project Stage',
    icon: <></>,
  },
  'myInvestment': {
    label: 'My Investment',
    icon: <></>,
  },
  'annReturnTarget': {
    label: 'Ann. Return Target',
    icon: <></>,
  },
  'maturityTarget': {
    label: 'Maturity target',
    icon: <></>,
  },
  'projectROE': {
    label: 'Project ROE',
    icon: <></>,
  },
  'fundingProgress': {
    label: 'Funding Progress',
    icon: <></>,
  },
  'developing': {
    label: 'Developing',
    icon: <></>,
  },
  'sales': {
    label: 'Sales',
    icon: <></>,
  },
  'numberOfInvestors': {
    label: 'Number of investors',
    icon: <></>,
  },
  'annualReturnTarget': {
    label: 'Annual Return Target',
    icon: <></>,
  },
  'minAmountToInvest': {
    label: 'Min. amount to invest',
    icon: <></>,
  },
  'timeToMaturity': {
    label: 'Time to maturity',
    icon: <></>,
  },
  'raiseTarget': {
    label: 'Raise Target',
    icon: <></>,
  },
  'duration': {
    label: 'Duration',
    icon: <></>,
  },
  'annualReturn': {
    label: 'Annual Return',
    icon: <></>,
  },
  'amount': {
    label: 'Amount',
    icon: <></>,
  },
  'project': {
    label: 'Project',
    icon: <></>,
  },
  'investor': {
    label: 'Investor',
    icon: <></>,
  },
  'commission': {
    label: 'Commission',
    icon: <></>,
  },
  'amlKyc': {
    label: 'AML / KYC',
    icon: <></>,
  },
  'commitment': {
    label: 'Commitment',
    icon: <></>,
  },
  'invoice': {
    label: 'Invoice',
    icon: <></>,
  },
  'receipt': {
    label: 'Receipt',
    icon: <></>,
  },
};
