import './index.css';

import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from './app';

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement,
);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App/>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);

