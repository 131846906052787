import { Button, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FileUploadIcon } from 'src/assets/svg';
import { CustomDialog, CustomSelect, Iconify, UploadedFilesType, UploadFile } from 'src/components';
import { sectionsList } from 'src/constants/constants';
import { RootState } from 'src/store';

import { FinanceUploadFileType, SectionValues } from '../../../../../types';

export interface FileModalProps {
  openModal: boolean;
  title?: string;
  onClose: VoidFunction;
  modalUploadFileHandler: (file: FinanceUploadFileType) => void;
}

export const FileModal = (props: FileModalProps) => {
  const {
    openModal,
    onClose,
    modalUploadFileHandler,
    title = 'New Document',
  } = props;

  const [uploadModalFiles, setUploadModalFiles] = useState<UploadedFilesType[]>([]);
  const [section, setSection] = useState<string[]>([]);
  const [type, setType] = useState<string[]>([]);
  const [typeOptions, setTypeOptions] = useState<string[]>([]);
  const [typeId, setTypeId] = useState<string>('');

  const fileSections = useSelector((state: RootState) => state.App.fileSections);

  const onCloseHandler = () => {
    setType(['']);
    setSection(['']);
    setTypeId('');
    setTypeOptions([]);
    setUploadModalFiles([]);
    onClose();
  };

  const removeAllHandler = () => {
    uploadFileHandler([]);
  };

  const uploadFileHandler = (files: UploadedFilesType[]) => {
    if (files.length > 0) {
      setUploadModalFiles([files[files.length > 1 ? 1 : 0]]);
      return;
    }
    setUploadModalFiles([]);
  };

  const uploadSubTitle = (
    <>
      Drop files here or click <span style={{ color: '#3698AF' }}>browse</span> thorough your machine
    </>
  );

  const sectionOnChangeHandler = (event: SelectChangeEvent<string[]>) => {
    const newSection = event.target.value as string;
    setSection([newSection]);
    setType([]);
    const typeOptions = fileSections.find(section => section.section === newSection)?.filenames.filter(names => names._id).map(names => names.filename) as string[];
    setTypeOptions(typeOptions || []);
  };

  const typeOnChangeHandler = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string;
    setType([value]);
    if (section[0]) {
      const sectionId = fileSections.find(fileSection => fileSection?.section === section[0])
        ?.filenames.find(file => file.filename === value)?._id;
      setTypeId(sectionId || '');
    }
  };

  const onUploadModalClick = async () => {
    const fileForUpload: FinanceUploadFileType = {
      ...uploadModalFiles[0],
      _id: typeId,
      sectionFilename: type[0],
      section: section[0] as SectionValues,

    };
    modalUploadFileHandler(fileForUpload);
    onCloseHandler();
  };

  return (
    <CustomDialog
      showDialogTitle
      dialogProps={{
        onClose: onCloseHandler,
        open: openModal,
      }}
      dialogHeadChildren={title}
      dialogContentChildren={
        <>
          <CustomSelect
            required
            controlSx={{ width: '100%' }}
            value={section}
            handleSelectOnChange={sectionOnChangeHandler}
            label='Section'
            options={sectionsList}
          />
          <CustomSelect
            required
            controlSx={{ width: '100%' }}
            value={type}
            handleSelectOnChange={typeOnChangeHandler}
            label='Type'
            options={typeOptions}
          />
          <UploadFile
            containerSx={{ width: '100%' }}
            innerContainerSx={{ p: 4 }}
            uploadTitle='Select File'
            titleStyleConfig={{
              variant: 'h6',
              color: '#212B36',
              mt: 3,
            }}
            uploadSubTitle={uploadSubTitle}
            uploadedFiles={uploadModalFiles}
            uploadFileHandler={uploadFileHandler}
            customIcon={<FileUploadIcon/>}
          />
        </>
      }
      dialogActionsChildren={
        <>
          <Button
            disabled={(!uploadModalFiles?.length || !section.length || !type.length)}
            variant='contained'
            startIcon={<Iconify icon='eva:cloud-upload-fill'/>}
            onClick={onUploadModalClick || onClose}
          >
            Upload
          </Button>

          {!!uploadModalFiles?.length && (
            <Button variant='outlined' color='inherit' onClick={removeAllHandler}>
              Remove all
            </Button>
          )}

        </>
      }
    />
  );
};
