import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { StackProps } from '@mui/material/Stack';
import { FormEvent } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { ConfigType, LayoutTypes } from 'src/features/new-lead/mock-data';
import { getNestedFormError } from 'src/utils';

export type FormComponentProps<P extends FieldValues, C> = {
  onSubmitHandler: (event: FormEvent<HTMLFormElement>) => void
  formConfig: ConfigType<P, C, LayoutTypes>[];
  disabledSubmitBtn?: boolean;
  isLoadingSubmitBtn?: boolean;
  btnTitle?: string;
  leftBlockMinWidth?: string | number;
  control?: Control<P>;
  formStackProps?: StackProps;
  configStackWrapperProps?: StackProps;
  btnConfig?: ButtonProps;
  returnBtnConfig?: ButtonProps;
  returnBtnTitle?: string;
}

export const FormComponent = <P extends FieldValues, C, >(props: FormComponentProps<P, C>) => {
  const {
    onSubmitHandler,
    formConfig,
    disabledSubmitBtn = false,
    isLoadingSubmitBtn = false,
    btnTitle,
    control,
    leftBlockMinWidth = 344,
    formStackProps,
    configStackWrapperProps,
    btnConfig,
    returnBtnConfig,
    returnBtnTitle,
  } = props;
  return (
    <Stack
      component='form'
      autoComplete='off'
      onSubmit={onSubmitHandler}
      rowGap={3}
      pr={3}
      {...formStackProps}
    >
      <Stack paddingLeft={3} rowGap={3} {...configStackWrapperProps}>
        {formConfig.map((formConfigElement, index) => {
          if (formConfigElement === undefined) return;
          const {
            layout: ConfigLayout,
            leftBlock,
            rightBlock,
            stackConfig,
            stackWidth = '100%',
          } = formConfigElement;
          return (
            <Stack key={index} direction='row' justifyContent='space-between'>
              {leftBlock &&
                <Box minWidth={leftBlockMinWidth}>
                  {leftBlock}
                </Box>
              }
              <ConfigLayout sx={{ flex: 1 }}>
                <Stack {...stackConfig} sx={{ width: stackWidth }}>
                  {rightBlock.map((config, index) => {
                    const {
                      correspondingComponent: CorrespondingComponent,
                      name,
                      helperText,
                      rules,
                    } = config;
                    return (
                      <Controller
                        name={name}
                        key={index}
                        control={control}
                        rules={{ ...rules }}
                        render={({
                          field,
                          formState: { errors },
                        }) => {
                          const error = getNestedFormError(errors, name);
                          return (
                            <CorrespondingComponent
                              {...field}
                              {...config}
                              error={Boolean(error)}
                              helperText={error ? error?.message : helperText}
                            />
                          );
                        }}
                      />
                    );
                  })}
                </Stack>
              </ConfigLayout>
            </Stack>
          );
        })}
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        gap={3}
      >
        <LoadingButton
          loading={isLoadingSubmitBtn}
          disabled={disabledSubmitBtn}
          sx={{
            borderRadius: 1,
            alignSelf: 'end',
            p: '11px 16px',
          }}
          variant='contained'
          type='submit'
          {...btnConfig}
        >
          {btnTitle}
        </LoadingButton>
        {returnBtnTitle &&
          <Button
            variant='contained'
            {...returnBtnConfig}
          >
            {returnBtnTitle}
          </Button>
        }
      </Stack>
    </Stack>
  );
};
