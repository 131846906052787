import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { _mockFinancialSheets } from 'src/features/financing-details';

import { FinancialSheet, FinancialTableType, FinancialType } from '../../../../types';
import { AddOfferActionType, DebtAgreementType, SetRequestItemActionType, TermSheetRequestActionType, UploadFileToTermSheetActionType } from './types';

interface State {
  financial: FinancialType;
  financialSheet: FinancialSheet[];
  requestItem?: FinancialTableType;
}

export const initialState: State = {
  financial: {
    preliminaryDebtRequests: [],
    offers: [],
    termSheets: [],
    debtFinancingAgreement: [],
  },
  financialSheet: _mockFinancialSheets,
};


const Slice = createSlice({
  name: 'Financial',
  initialState,
  reducers: {
    addOffer(state, action: PayloadAction<AddOfferActionType>) {
      const selectedDebtRequest = state.financial.preliminaryDebtRequests?.find(debtRequest => debtRequest.debtRequestId === action.payload.preliminaryDebtRequestId && debtRequest.bankId === action.payload.partnerId);
      if (selectedDebtRequest?.preliminaryDebtRequest) {
        const updatedDebtRequest: FinancialTableType = {
          ...selectedDebtRequest,
          debtRequestId: action.payload.preliminaryDebtRequestId,
          _id: action.payload.offerId,
          offer: {
            ...action.payload.offerDetails,
            date: new Date().toISOString(),
          },
        };
        state.financial.offers = [
          ...(state.financial.offers || []),
          updatedDebtRequest,
        ];
        Slice.caseReducers.updateRequestItem(state, {
          payload: updatedDebtRequest,
          type: action.type,
        });
        state.financial.preliminaryDebtRequests =
          state.financial.preliminaryDebtRequests
            ?.filter(debtRequest => debtRequest.debtRequestId !== action.payload.preliminaryDebtRequestId || debtRequest.bankId !== action.payload.partnerId);
      }
    },
    updateOffer(state, action: PayloadAction<AddOfferActionType>) {
      const offerToUpdate = state.financial.offers?.find(offer => offer._id === action.payload.offerId && offer.bankId === action.payload.partnerId);
      if (offerToUpdate?.offer) {
        const updatedOffer: FinancialTableType = {
          ...offerToUpdate,
          offer: {
            ...offerToUpdate.offer,
            ...action.payload.offerDetails,
          },
        };

        state.financial.offers = (state.financial.offers || []).map(offer => {
          return (offer._id === action.payload.offerId && offer.bankId === action.payload.partnerId) ? updatedOffer : offer;
        });
        Slice.caseReducers.updateRequestItem(state, {
          payload: updatedOffer,
          type: action.type,
        });
      }
    },
    termSheetRequest(state, action: PayloadAction<TermSheetRequestActionType>) {
      const selectedDebtRequest = state.financial.offers?.find(offer => offer.debtRequestId === action.payload.debtRequestId && offer.bankId === action.payload.partnerId);
      if (selectedDebtRequest?.offer) {
        const termSheet: FinancialTableType = {
          ...selectedDebtRequest,
          debtRequestId: action.payload.debtRequestId,
          _id: action.payload._id,
          termSheet: {
            ...action.payload.termSheetDetails,
            date: new Date().toISOString(),
          },
          status: 'Requested',
        };
        state.financial.termSheets = [
          ...(state.financial.termSheets || []),
          termSheet,
        ];

        state.financial.termSheets = state.financial.termSheets?.map(item => (item.debtRequestId === termSheet.debtRequestId && item.bankId === termSheet.bankId) ? termSheet : item);
        state.financial.offers = state.financial.offers?.filter(offer => offer.debtRequestId !== action.payload.debtRequestId || offer.bankId !== action.payload.partnerId);
        state.financialSheet = state.financialSheet.map(sheet => sheet.type === selectedDebtRequest.type ? {
          ...sheet,
          maxSteps: sheet.maxSteps + 1,
        } : sheet);
        Slice.caseReducers.updateRequestItem(state, {
          payload: termSheet,
          type: action.type,
        });
      }
    },
    requestDebtAgreement(state, action: PayloadAction<DebtAgreementType>) {
      const selectedTermSheet = state.financial.termSheets?.find(offer => offer._id === action.payload.termSheetId);
      if (selectedTermSheet?.termSheet) {
        const debtAgreement: FinancialTableType = {
          ...selectedTermSheet,
          debtFinancingAgreement: { documents: action.payload.files },
          status: action.payload.files.length === 0 ? 'Agreement Requested'
            : (action.payload.files.every(file => file.uploaded)
              ? 'Agreement Requested'
              : 'Documents Requested'),
        };
        state.financial.debtFinancingAgreement = [
          ...(state.financial.debtFinancingAgreement || []),
          debtAgreement,
        ];

        state.financial.termSheets = state.financial.termSheets?.filter(termSheet => termSheet._id !== action.payload.termSheetId);
        Slice.caseReducers.updateRequestItem(state, {
          payload: debtAgreement,
          type: action.type,
        });
      }
    },
    deleteItem(state, action: PayloadAction<SetRequestItemActionType>) {
      const debtToDelete = state.financial[action.payload.step]?.find(item => item._id === action.payload.itemId);
      if (debtToDelete) {
        state.financial[action.payload.step] = state.financial[action.payload.step]?.filter(item => item._id !== action.payload.itemId || item.bankId !== action.payload.partnerId);
        if (action.payload.step === 'termSheets') {
          state.financialSheet = state.financialSheet
            .map(sheet => sheet.type === debtToDelete.type ? {
              ...sheet,
              maxSteps: sheet.maxSteps - 1,
              completedSteps: sheet.completedSteps - (debtToDelete.status === 'Signed' ? 1 : 0),
            } : sheet);
        }
      }
    },
    addPreliminaryDebtRequest(state, action: PayloadAction<FinancialTableType>) {
      state.financial.preliminaryDebtRequests = [...(state.financial.preliminaryDebtRequests || []), action.payload];
    },
    setPreliminaryDebtRequests(state, action: PayloadAction<FinancialTableType[]>) {
      state.financial.preliminaryDebtRequests = action.payload;
    },
    setOffers(state, action: PayloadAction<FinancialTableType[]>) {
      state.financial.offers = action.payload;
    },
    setTermSheets(state, action: PayloadAction<FinancialTableType[]>) {
      state.financial.termSheets = action.payload;
      state.financialSheet = _mockFinancialSheets;
      action.payload.forEach(termSheet => {
        state.financialSheet = state.financialSheet
          .map(sheet => sheet.type === termSheet.type ? {
            ...sheet,
            maxSteps: sheet.maxSteps + 1,
            completedSteps: sheet.completedSteps + (termSheet.status === 'Signed' ? 1 : 0),
          } : sheet);
      });
    },
    // updateDebtStatus(state, action: PayloadAction<UpdateDebtStatusActionType>) {
    //   state.financial.termSheets = state.financial.termSheets?.map(termSheet => {
    //     return termSheet._id === action.payload.debt._id
    //       ? {
    //         ...termSheet,
    //         status: action.payload.newStatus,
    //       }
    //       : termSheet;
    //   });
    // },
    uploadFileToTermSheet(state, action: PayloadAction<UploadFileToTermSheetActionType>) {
      state.financial.termSheets = state.financial.termSheets?.map(termSheet =>
        (termSheet._id === action.payload.itemId && termSheet.bankId === action.payload.partnerId)
          ? {
            ...termSheet,
            status: 'Signed',
            termSheet: {
              ...termSheet.termSheet,
              date: new Date().toISOString(),
              file: action.payload.file,
            },
          }
          : termSheet,
      );
      state.financial.termSheets?.forEach(termSheet => {
        state.financialSheet = state.financialSheet
          .map(sheet => sheet.type === termSheet.type ? {
            ...sheet,
            completedSteps: sheet.completedSteps + 1,
          } : sheet);
      });
    },
    setRequestItem(state, action: PayloadAction<SetRequestItemActionType>) {
      const selectedItem = state.financial[action.payload.step]?.find(item => (item._id === action.payload.itemId && item.bankId === action.payload.partnerId));
      if (selectedItem) {
        state.requestItem = selectedItem;
      }
    },
    updateRequestItem(state, action: PayloadAction<Partial<FinancialTableType>>) {
      if (state.requestItem) {
        state.requestItem = {
          ...state.requestItem,
          ...action.payload,
        };
      }
    },
  },
});

export const {
  reducer: FinancialReducer,
  actions: {
    addOffer,
    termSheetRequest,
    uploadFileToTermSheet,
    setRequestItem,
    updateRequestItem,
    deleteItem,
    addPreliminaryDebtRequest,
    requestDebtAgreement,
    setPreliminaryDebtRequests,
    setOffers,
    updateOffer,
    setTermSheets,
  },
} = Slice;

export * from './actions';
