import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectPreparation, ProjectPreparationResponse } from '../../../../types';


interface State {
  projectPreparations: ProjectPreparationResponse;
  currentProjectPreparation?: ProjectPreparation;
}

export const initialState: State = {
  projectPreparations: {
    projects: [],
    counter: {
      'New': 0,
      'Done': 0,
      All: 0,
    },
    metadata: {
      total: 0,
      page: 0,
    },
  },
};

const Slice = createSlice({
  name: 'Project Preparation',
  initialState,
  reducers: {
    setProjectPreparation(state, action: PayloadAction<ProjectPreparationResponse>) {
      state.projectPreparations = action.payload;
    },
    deleteProjectPreparation(state, action: PayloadAction<string>) {
      const projectToDelete = state.projectPreparations.projects.find(project => project.project?._id === action.payload);
      state.projectPreparations.projects = state.projectPreparations.projects.filter(project => project.project?._id !== projectToDelete?._id);
      state.projectPreparations.metadata.total -= 1;
      state.projectPreparations.counter.All -= 1;
      state.projectPreparations.counter[projectToDelete?.status as keyof typeof state.projectPreparations.counter] -= 1;
    },
  },
});

export const {
  reducer: ProjectPreparationReducer,
  actions: {
    deleteProjectPreparation,
    setProjectPreparation,
  },
} = Slice;

export * from './actions';
