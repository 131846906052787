import { Box, Button, Stack, useTheme } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles';
import { ComponentType, Fragment } from 'react';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';
import { useResponsive } from 'src/hooks';

import { FieldType } from './land-detail';

export interface DetailsListProps<T> {
  fields?: FieldType[];
  wrappedComponent?: ComponentType;
  wrappedProps?: T;
  btnTitle?: string,
  disableEmptyValue?: boolean;
  disabledFields?: string[];
  btnConfig?: ButtonProps;
  boxConfig?: BoxProps;
  itemsContainerSx?: SxProps<Theme>;
  valueContainerSx?: SxProps<Theme>;
  labelContainerSx?: SxProps<Theme>;
}

export const DetailsList = <T, >(props: DetailsListProps<T>) => {
  const {
    fields = [],
    wrappedProps,
    wrappedComponent: Component = Fragment,
    btnConfig,
    btnTitle,
    boxConfig,
    itemsContainerSx,
    valueContainerSx,
    labelContainerSx,
    disableEmptyValue,
    disabledFields = [],
  } = props;
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();

  return (
    <Component {...wrappedProps}>
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
        p={3}
        {...boxConfig}
      >
        {fields.map((field, index) => {
          const {
            value,
            color,
            label,
            subFields,
          } = field;
          const labelColor = disableEmptyValue ? (disabledFields.find(field => field === label) ? 'text.disabled' : 'text.secondary') : 'text.secondary';
          return (
            <Fragment key={index}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent={mdUp ? 'flex-start' : 'space-between'}
                sx={itemsContainerSx}
              >
                <Box
                  component='span'
                  sx={{
                    color: labelColor,
                    width: mdUp ? 240 : 'fit-content',
                    ...labelContainerSx,
                  }}
                >
                  {label}
                </Box>
                <Box
                  component='span'
                  sx={{
                    color: color ?? theme.palette.text.primary,
                    fontWeight: 600,
                    fontSize: '14px',
                    ...valueContainerSx,
                  }}
                >
                  {value || UNSPECIFIED_VALUE}
                </Box>
              </Stack>
              {subFields?.map((subField, index) => {
                return (
                  <Stack key={index} direction='row' alignItems='center'>
                    <Box component='span' sx={{
                      color: 'text.secondary',
                      width: 240,
                      ml: '10px',
                    }}>
                      {subField.label}
                    </Box>
                    <Box
                      component='span'
                      sx={{
                        color: color ?? '#212B36',
                        fontWeight: 600,
                        ml: '-10px',
                      }}
                    >
                      {subField.value}
                    </Box>
                  </Stack>
                );
              })}
            </Fragment>
          );
        })}
      </Box>
      {
        btnTitle &&
        <Button
          variant='outlined'
          sx={{
            mt: 3,
            mr: 3,
          }}
          {...btnConfig}
        >
          {btnTitle}
        </Button>
      }
    </Component>
  );
};
