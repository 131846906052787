import { Box, Button, Link, Skeleton, Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { memo, ReactNode } from 'react';
import { useRouter } from 'src/hooks';

import { ChipVariantsType, LabelColor } from '../../../../types';
import { Label } from '../label';

export interface ReportCardProps {
  background: string;
  icon: ReactNode;
  text?: string;
  btnTitle?: string;
  linkText?: string;
  label?: string;
  labelColor?: LabelColor;
  labelVariant?: ChipVariantsType;
  subText?: string;
  textColor?: string;
  linkColor?: string;
  replacedLink?: string;
  subTextColor?: string;
  onBtnClickHandler?: VoidFunction;
  showData?: boolean;
  skeletonWidth?: string;
  stackSx?: SxProps<Theme>;
}

export const ReportCard = memo((props: ReportCardProps) => {
  const {
    onBtnClickHandler,
    text,
    subText,
    icon,
    background,
    textColor = '#212B36',
    subTextColor = '#212B36',
    showData = false,
    skeletonWidth = '252px',
    label,
    labelColor = 'success',
    labelVariant = 'outlined',
    linkText,
    linkColor,
    btnTitle,
    stackSx,
    replacedLink,
  } = props;

  const router = useRouter();

  const onLinkClickHandler = () => {
    replacedLink && router.replace(replacedLink);
  };

  return (
    <>
      {
        showData ?
          <Stack
            sx={{
              background: background,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              borderRadius: 2,
              pt: 5,
              pb: 5,
              width: 252,
              height: 266,
              ...stackSx,
            }}
          >
            {icon &&
                <Box
                  sx={{
                    width: 64,
                    height: 64,
                    mb: 1.5,
                  }}
                >
                  {icon}
                </Box>
            }
            {label &&
                <Label
                  variant={labelVariant}
                  color={labelColor}
                  sx={{ mb: 1.5 }}
                >
                  {label}
                </Label>
            }
            <Typography
              fontSize='18px'
              fontWeight={700}
              color={textColor}
            >
              {text}
            </Typography>
            <Stack direction='row' gap={0.5}>
              <Typography color={subTextColor} variant='subtitle2'>{subText}</Typography>
              {linkText &&
                  <Link
                    fontWeight={700}
                    color={linkColor}
                    component='button'
                    underline='none'
                    onClick={onLinkClickHandler}
                  >
                    {linkText}
                  </Link>
              }
            </Stack>
            {btnTitle &&
                <Button
                  variant='outlined'
                  onClick={onBtnClickHandler}
                  size='large'
                  sx={{ mt: 1.5 }}
                >
                  {btnTitle}
                </Button>
            }
          </Stack>
          : <Skeleton variant='rounded' height='266px' width={skeletonWidth}/>}
    </>
  );
});
