import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { Header, Main, NavHorizontal, NavMini, NavVertical } from 'src/components';
import { useBoolean, useResponsive, useSettingsContext } from 'src/hooks';

interface PageProps {
  children: ReactNode;
}

export const Page = (props: PageProps) => {
  const { children } = props;
  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');
  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';
  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini/>;
  const renderHorizontal = <NavHorizontal/>;
  const renderNavVertical =
    <NavVertical
      openNav={nav.value}
      onCloseNav={nav.onFalse}
    />;

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue}/>

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue}/>

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }


  return (
    <>
      <Header onOpenNav={nav.onTrue}/>

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  );
};
