import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calculateUnitTypeQuantity, CrumbType, ProjectToolbar, UploadedFilesType } from 'src/components';
import { paths } from 'src/config';
import { ProjectDocuments } from 'src/features/project-preparation-details/components/assets/project-documents/project-documents';
import { useBoolean, useDocuments, useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { getDocumentsList } from 'src/store/financial';
import { getPartners } from 'src/store/partners';
import { createTenderingRequest, getTenderingRequestDetails } from 'src/store/tendering';
import { TenderingRequestDetails } from 'src/store/tendering/types';
import { fNumber } from 'src/utils';

import { FinanceUploadFileType, PartnerItemType } from '../../../../../../types';
import { loadLeadDetails } from '../../../../store/lead';
import { requestTypeVariants } from '../../mock-data';
import { SuccessDrawer } from './success-drawer';
import { SuppliersModal } from './suppliers-modal';

export const PreliminaryQuotationRequest = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const suppliersDrawer = useBoolean();
  const router = useRouter();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const suppliersFromDB = useSelector((state: RootState) => state.Partners.suppliers);
  const isDemo = useSelector((state: RootState) => state.App.config?.isDemo);

  const [documentsModalList, setDocumentsModalList] = useState<UploadedFilesType[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<UploadedFilesType[]>([]);
  const [suppliers, setSuppliers] = useState<PartnerItemType[]>([]);
  const [requestDetails, setRequestDetails] = useState<TenderingRequestDetails | null>(null);
  const [requestType, setRequestType] = useState<string>('');
  const [partnerFee, setPartnerFee] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const { createAndUploadDocument } = useDocuments();

  const isPartner = requestType === 'Development Partner';
  const isSendDisabled = !suppliers.length || !requestType || (isPartner && !partnerFee);

  useEffect(() => {
    if (!isPartner && partnerFee) {
      setPartnerFee('');
    }
  }, [isPartner, partnerFee]);

  const loadTenderingRequestDetails = useCallback(async () => {
    if (detailsData.project?._id) {
      const requestDetails = await getTenderingRequestDetails(detailsData.project?._id, isDemo);
      requestDetails && setRequestDetails(requestDetails);
    }
  }, [detailsData.project?._id, isDemo]);

  const loadDocuments = useCallback(async () => {
    if (detailsData.project?._id) {
      const documentsModalList = await getDocumentsList('projectId', detailsData.project?._id);
      setDocumentsModalList(documentsModalList.map(document => ({
        ...document,
        size: document.filesize,
        uploadingFileType: document.filetype,
        filename: document.sectionFilename,
      })).filter(doc => doc.url));
    }
  }, [detailsData.project?._id]);

  useEffect(() => {
    dispatch(getPartners('Supplier', 'suppliers'));
  }, [dispatch]);

  useEffect(() => {
    loadDocuments();
    loadTenderingRequestDetails();
  }, [loadDocuments, loadTenderingRequestDetails]);

  const addSuppliers = (arr: PartnerItemType[]) => {
    const selectedIds = suppliers.map(s => s._id);
    const filtered = arr.filter(s => !selectedIds.includes(s._id));
    setSuppliers(prev => [...prev, ...filtered]);
  };

  const deleteSupplier = (id: string) => () => {
    setSuppliers(prev => prev.filter(v => v._id !== id));
  };

  const successDrawer = useBoolean();
  const onSendClick = () => {
    if (!isSendDisabled && requestDetails?.projectId && requestDetails.scenarioId) {
      successDrawer.onTrue();
      dispatch(createTenderingRequest({
        projectId: requestDetails.projectId,
        fee: partnerFee,
        scenarioId: requestDetails.scenarioId,
        documents: uploadedDocuments.map(document => document._id),
        emailNote: email,
        requestType: requestType,
        partnerId: suppliers.map(supplier => supplier._id),
      }, () => dispatch(loadLeadDetails(detailsData.lead?._id || ''))));
    }
  };

  const crumbs: CrumbType[] = [
    {
      title: 'Packaging',
      href: paths.tendering.list,
    },
    {
      title: 'Tendering',
      href: paths.tendering.list,
    },
    {
      title: detailsData.lead?.location?.address,
      href: paths.tendering.details(detailsData.project?._id || '', 'overview'),
    },
    { title: 'Preliminary Quotation Request' },
  ];

  const onRemoveUploadedFileClickHandler = async (file: UploadedFilesType) => {
    const filteredDocuments = uploadedDocuments.filter(doc => doc._id !== file._id);
    setUploadedDocuments(filteredDocuments);
  };

  const onAddDocumentsClickHandler = (files: UploadedFilesType[]) => {
    setUploadedDocuments?.(prev => [...prev, ...files]);
  };

  const onCloseClickHandler = () => {
    successDrawer.onFalse();
    if (detailsData.lead?._id) {
      router.push(paths.tendering.details(detailsData.lead?._id, 'suppliers'));
    }
  };

  const modalUploadFileHandler = async (file: FinanceUploadFileType) => {
    const uploadedFile = await createAndUploadDocument(file, detailsData.project?._id, detailsData.lead?._id);
    if (uploadedFile) {
      setDocumentsModalList([...documentsModalList, {
        ...uploadedFile,
        size: Math.round((uploadedFile.size || 0) / 1024),
        filename: uploadedFile.sectionFilename || uploadedFile.filename,
      }]);
    }
  };

  const textFilOnChangHandler = (setter: Dispatch<SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setter(value);
  };

  return (
    <>
      <ProjectToolbar
        showSkeleton={false}
        showBadge={false}
        title='Preliminary Quotation Request'
        crumbs={crumbs}
      />
      <Stack gap={3}>
        <Stack gap={3} direction='row'>
          <Typography sx={{ minWidth: 344 }} variant='h6'>Suppliers</Typography>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <Stack gap={3}>
                <TextField
                  required
                  onClick={suppliersDrawer.onTrue}
                  label={
                    suppliers.length > 0 ? 'Suppliers' : 'Click to select suppliers'
                  }
                  InputProps={{
                    readOnly: true,
                    startAdornment:
                      <InputAdornment
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          overflow: 'hidden',
                          width: '96%',
                          height: '100%',
                        }}
                        position='start'
                      >
                        {suppliers?.map((s) => (
                          <Chip
                            key={s._id}
                            label={s.companyName}
                            size='small'
                            color='default'
                            variant='soft'
                            sx={{ mr: 1 }}
                            onDelete={deleteSupplier(s._id)}
                          />
                        ))}
                      </InputAdornment>,
                  }}
                />

                <SuppliersModal
                  open={suppliersDrawer.value}
                  onClose={suppliersDrawer.onFalse}
                  selected={suppliers}
                  suppliers={suppliersFromDB}
                  addSuppliers={addSuppliers}
                  deleteSupplier={deleteSupplier}
                />

                <TextField
                  required
                  id='request-type-select'
                  select
                  label='Request Type'
                  value={requestType}
                  onChange={textFilOnChangHandler(setRequestType)}
                >
                  {requestTypeVariants.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                {isPartner &&
                  <TextField
                    label='Partner Fee'
                    value={partnerFee}
                    onChange={textFilOnChangHandler(setPartnerFee)}
                  />
                }

                <Stack
                  gap={2}
                  sx={{
                    p: 3,
                    borderRadius: 1.5,
                    border: `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <Stack direction='row' gap={2}>
                    <Typography
                      sx={{ minWidth: 210 }}
                      variant='body2'
                      color={theme.palette.text.secondary}
                    >
                      Project type
                    </Typography>
                    <Typography variant='subtitle2'>
                      {requestDetails?.projectType}
                    </Typography>
                  </Stack>
                  <Stack direction='row' gap={2}>
                    <Typography
                      sx={{ minWidth: 210 }}
                      variant='body2'
                      color={theme.palette.text.secondary}
                    >
                      Location
                    </Typography>
                    <Typography variant='subtitle2'>
                      {requestDetails?.location}
                    </Typography>
                  </Stack>
                  <Stack direction='row' gap={2}>
                    <Typography
                      sx={{ minWidth: 210 }}
                      variant='body2'
                      color={theme.palette.text.secondary}
                    >
                      Total BOA
                    </Typography>
                    <Typography variant='subtitle2'>
                      {requestDetails?.totalBoa}
                    </Typography>
                  </Stack>
                  <Stack direction='row' gap={2}>
                    <Typography
                      sx={{ minWidth: 210 }}
                      variant='body2'
                      color={theme.palette.text.secondary}
                    >
                      Build time (months)
                    </Typography>
                    <Typography variant='subtitle2'>
                      {requestDetails?.buildTime}
                    </Typography>
                  </Stack>
                  <Stack direction='row' gap={2}>
                    <Typography
                      sx={{ minWidth: 210 }}
                      variant='body2'
                      color={theme.palette.text.secondary}
                    >
                      Construction costs
                    </Typography>
                    <Typography variant='subtitle2'>
                      {fNumber(requestDetails?.constructionCost.reduce((acc, el) => {
                        return acc + (el.total_cost || 0);
                      }, 0))}
                    </Typography>
                  </Stack>
                  <Stack direction='row' gap={2}>
                    <Typography
                      sx={{ minWidth: 210 }}
                      variant='body2'
                      color={theme.palette.text.secondary}
                    >
                      Unit type
                    </Typography>
                    <Typography variant='subtitle2'>
                      {calculateUnitTypeQuantity(requestDetails?.unitType).map(unit => {
                        return (
                          <>
                            {unit.label} ({unit.value})<br/>
                          </>
                        );
                      })}
                    </Typography>
                  </Stack>
                </Stack>

                <TextField
                  label='Email note'
                  variant='outlined'
                  minRows={3}
                  value={email}
                  onChange={textFilOnChangHandler(setEmail)}
                  multiline
                  fullWidth
                />
              </Stack>
            </CardContent>
          </Card>
        </Stack>

        <Stack gap={3} direction='row'>
          <Typography sx={{ minWidth: 344 }} variant='h6'>Documents</Typography>
          <Box sx={{ flex: 1 }}>
            <ProjectDocuments
              onAddDocumentsClickHandler={onAddDocumentsClickHandler}
              onRemoveUploadedFileClickHandler={onRemoveUploadedFileClickHandler}
              modalUploadFileHandler={modalUploadFileHandler}
              documentsModalList={documentsModalList}
              uploadedDocuments={uploadedDocuments}
            />
          </Box>
        </Stack>

        <Stack alignItems='flex-end'>
          <Button disabled={isSendDisabled} onClick={onSendClick} variant='contained' size='large'>
            Send Request
          </Button>
        </Stack>

        <SuccessDrawer
          title='Request Sent Successfully!'
          successDrawer={successDrawer}
          onCloseClickHandler={onCloseClickHandler}
        />
      </Stack>
    </>
  );
};
